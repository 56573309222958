@font-face {
  font-family: 'Mark Pro';
  src: local('Mark Pro'), url('./fonts/MarkPro/MarkPro.otf') format('opentype');
}

@font-face {
  font-family: 'Mark Pro Medium';
  src: local('Mark Pro Medium'),
    url('./fonts/MarkPro/MarkProMedium.otf') format('opentype');
}

@font-face {
  font-family: 'Mark Pro Bold';
  src: local('Mark Pro Bold'),
    url('./fonts/MarkPro/MarkProBold.otf') format('opentype');
}

@font-face {
  font-family: 'Mark Pro Light';
  src: local('Mark Pro Light'),
    url('./fonts/MarkPro/MarkProLight.otf') format('opentype');
}

@font-face {
  font-family: 'Mark Pro Book';
  src: local('Mark Pro Book'),
    url('./fonts/MarkPro/MarkProBook.otf') format('opentype');
}
