@import '../../../../styles/variables.scss';
.away-teamListCard {
  a:hover {
    cursor: pointer;
    text-decoration: none;
  }

  .team-list {
    margin-bottom: 10px;
    align-items: center;
    padding: 20px 10px;
    margin-left: 0;
    margin-right: 0;
    @media only screen and (max-width: 768px) {
      .team-list-avatar {
        .away-avatar {
          .avatar {
            margin-bottom: 20px;
            margin-left: inherit;
          }
          &:hover {
            * {
              text-decoration: none;
              cursor: pointer;
            }
          }
        }
      }
    }
    &.team-list-full {
      justify-content: center;
    }

    .team-list-name {
      font-size: 18px;
      font-weight: normal;
      font-family: $font-bold;
      color: $text-color;
    }
    .team-list-text {
      font-size: 16px;
      font-weight: 400;
      color: $text-color;
      @media only screen and (max-width: 768px) {
        margin-bottom: 10px;
      }
    }
    .team-list-label {
      font-size: 12px;
      font-weight: 400;
      color: $grey;
    }
  }
}
