@import '../../../styles/variables.scss';
.card-shadow {
  &.upcoming-vacations {
    padding-bottom: 10px;
    padding-top: 20px;
    .top-row {
      align-items: center;
      margin-bottom: 20px;
    }
    h3 {
      margin-bottom: 0;
    }
    p {
      &.when {
        color: $grey;
        font-size: 12px;
        font-family: $font-book;
        margin-bottom: 13px;
      }
    }
    $count: 1000;
    .away-avatar {
      display: inline-block;
      border: solid 2px $white;
      border-radius: 50%;
      margin-right: -13px;
      margin-bottom: 20px;
      position: relative;
      $target: 0;
      $index: $count;
      @while $index > 0 {
        &:nth-child(#{$target}) {
          z-index: #{$index};
        }
        $target: $target + 1;
        $index: $index - 1;
      }
    }
  }
}
