@import './variables.scss';

.away-colorpicker {
  max-width: 400px;
  width: 100%;
  padding: 20px;
  margin-top: -20px;
  border: none;
  border-radius: 5px;
  box-shadow: 0 2px 20px 0 $dateRangeShadow;

  .popover-body {
    padding: 0;
    padding-bottom: 23px;
  }

  .away-colorpicker-content {
    display: flex;
    flex-direction: column;

    .slider-picker {
      margin-bottom: 38px;
      flex: 1;
    }

    .btn-primary {
      align-self: center;
    }

    .chrome-picker {
      width: auto !important;
      box-shadow: none !important;
      margin-bottom: 15px;

      div {
        &:last-of-type {
          .flexbox-fix {
            &:last-of-type {
              display: none !important;
            }
          }
        }
      }
    }
  }
}
