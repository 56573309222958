@import './variables.scss';

.away-modal {
  .away-modal-body {
    padding: 50px;
    padding-bottom: 10px;

    .away-modal-line {
      margin-bottom: 30px;
      display: flex;
      flex-direction: row;
      justify-content: center;

      .form-group {
        width: 100%;
      }
    }
    .alert-danger {
      color: $red;
      margin-bottom: 50px;
      font-size: 12px;
      background-color: $white;
      border-color: $red;
      &.alert-dismissible {
        .close {
          padding: 9px;
        }
      }
    }
    .away-dropdown {
      outline: none;
      width: 100%;

      &:focus,
      &:hover {
        outline: none;
      }
    }
    .public-holiday-content {
      font-size: 14px;
    }
    .away-modal-buttons {
      display: flex;
      flex-direction: column;
      margin-top: 40px;
      .btn {
        align-self: center;
        margin-bottom: 5px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    .away-color-option {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 16px;
      padding: 5px;

      &:hover {
        cursor: pointer;
      }

      .away-color-option-bubble {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 10px;
      }
    }
  }
  .reject-person-info {
    text-align: center;
  }
  .vacation-type {
    font-size: 12px;
    text-transform: uppercase;
  }
}
