@import '../../../styles/variables.scss';
.away-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;

  h2 {
    display: flex;
    align-items: center;
    margin-bottom: 0;

    img {
      margin-right: 5px;
      padding-top: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      vertical-align: inherit;
      cursor: pointer;
    }
  }
  .back {
    margin-right: 12px;
    padding-bottom: 5px;
    cursor: pointer;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    padding-top: 5px;
    padding-bottom: 25px;

    .btn-primary {
      margin-top: 15px;
    }
  }
}
