@import '../../../styles/variables.scss';
.away-modal {
  .away-modal-body {
    padding: 50px;
    padding-bottom: 10px;
    @media only screen and (max-width: 768px) {
      padding: 20px !important;
    }
    .away-modal-line {
      margin-bottom: 30px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      h3 {
        font-family: $font-bold;
      }
      .form-group {
        width: 100%;
      }
    }
    .away-label {
      color: $grey;
      font-size: 12px;
      font-family: $font-book;
      margin-bottom: 5px;
    }
    .margin-bot-30 {
      margin-bottom: 30px;
    }
    .away-dropdown {
      outline: none;
      width: 100%;

      &:focus,
      &:hover {
        outline: none;
      }
    }

    .away-modal-buttons {
      display: flex;
      flex-direction: column;

      .btn {
        align-self: center;
        margin-bottom: 5px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}
