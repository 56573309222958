@import './variables.scss';

.away-table-b {
  .react-bs-table-container {
    margin-bottom: 40px;

    .react-bs-table {
      border-radius: 0 !important;
      background: $white;

      .at-row {
        font-size: 12px;
        font-family: $font-book;
        font-weight: normal;
        overflow: unset;
        background-color: $white;

        &:hover {
          cursor: pointer;
          background-color: $grey-6;

          .expand-icon {
            .fa {
              color: $orange;
            }
          }
        }
      }

      .user-logList-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-right: 20px;

        .user-logList {
          font-size: 12px;
          font-family: $font-book;
          font-weight: normal;
          margin-bottom: 0;
        }
      }

      .at-header {
        background-color: $white;
        th {
          padding: 8px;
          background: $white;
          position: relative;
          border-bottom-width: 1px;

          span {
            &.at-th,
            &.at-th-label,
            &.at-th-title {
              font-weight: normal;
              font-family: $font-book;
              color: $grey-2;
              font-size: 12px;
              display: block;
            }
          }

          .order {
            bottom: -18px;
            position: absolute;
            right: 3px;

            .caret {
              display: inline-block;
              width: 0;
              height: 0;
              margin-left: 0.255em;
              margin-right: 0 !important;
              vertical-align: 0.255em;
              border-right: 0.3em solid $white;
              border-left: 0.3em solid $white;
              border-top: 0.3em solid;
              border-bottom: 0;
              color: $grey-2 !important;
              content: '';
            }
          }

          .dropdown,
          .dropup {
            .caret {
              display: inline-block;
              width: 0;
              height: 0;
              margin-left: 0.255em;
              vertical-align: 0.255em;
              border-right: 0.3em solid $white;
              border-left: 0.3em solid $white;
              color: $grey-2 !important;
              content: '';
            }
          }

          .dropdown {
            .caret {
              border-top: 0.3em solid;
              border-bottom: 0;
            }
          }

          .dropup {
            .caret {
              border-top: 0;
              border-bottom: 0.3em solid;
            }
          }
        }
      }

      .at-th {
        font-weight: bold;
        font-size: 16px;
        word-wrap: break-word;
        .at-th-label {
          font-weight: 400;
          font-size: 12px;
        }

        .at-th-title {
          text-transform: capitalize;
        }
      }

      .at-name {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $text-color;

        a {
          color: $text-color;

          &:hover {
            color: $orange;
          }
        }
      }

      .at-cap {
        text-transform: capitalize;
      }

      .delete-icon,
      .expand-icon {
        .fa {
          color: $grey;
          font-family: FontAwesome !important;

          &.fa-small {
            padding: 0;
          }
        }

        .fa {
          cursor: pointer;
        }
      }

      .delete-icon {
        &:focus,
        &:hover,
        &:active {
          .fa {
            color: $red;
          }
        }
      }

      .at-message {
        position: relative;
        display: inline-flex;
        justify-content: center;
        width: 100%;
        padding-right: 20px;

        .expand-icon {
          position: absolute;
          right: 0;
        }
      }
    }

    .react-bs-table-pagination {
      .react-bs-table-sizePerPage-dropdown {
        .dropdown-toggle {
          border-radius: 25px;
          font-size: 14px;
          font-family: $font-regular;
          padding: 13px;
          letter-spacing: 0.4px;
          line-height: 1;
          outline: none;
          box-shadow: none;
          -webkit-box-shadow: none;
          border: none;
          color: $white;
          @media only screen and (max-width: 768px) {
            font-size: 12px;
            padding: 10px;
          }
          &:focus,
          &:active {
            outline: none;
            box-shadow: none;
          }

          background-image: linear-gradient(-121deg, #ff7575 0%, #ffb230 100%);
          min-width: 100px;
          transition: all 0.15s ease-in-out;
          @media only screen and (max-width: 768px) {
            min-width: 100px;
          }
        }

        .dropdown-menu {
          min-width: 0;
          margin-bottom: 8px;
          width: 124px;

          @media only screen and (max-width: 768px) {
            left: 50%;
            transform: translateX(-62px);
            margin-bottom: 0px;
          }

          li {
            &:focus,
            &:hover,
            &:active {
              background-color: #f8f9fa;
            }
            a {
              color: $orange;
            }
          }
        }
      }

      .pagination {
        margin-top: 0;
        margin-bottom: 0;
        margin-right: 0;
        align-self: flex-end;

        @media only screen and (max-width: 768px) {
          margin-top: 20px;
          margin-bottom: 0;
          margin-right: 0;
          align-self: center;
        }

        * {
          outline: none;
          box-shadow: none;
        }
        .page-item {
          .page-link {
            margin-left: 8px;
            margin-right: 8px;
            background-color: transparent;
            border: 0;
            border-bottom: 1px solid transparent;
            color: $orange;
            @media only screen and (max-width: 768px) {
              margin-left: 4px;
              margin-right: 4px;
              font-size: 14px;
            }
            &:hover {
              background-color: transparent;
              color: $text-color;
            }
          }

          &.active {
            .page-link {
              background-color: transparent;
              border-color: $orange;
              color: $orange;
            }
          }
        }
        .away-pagination-next {
          &.disabled {
            a {
              color: $grey;
            }
          }
        }
        .away-pagination-prev {
          &.disabled {
            a {
              color: $grey;
            }
          }
        }
      }

      @media only screen and (max-width: 768px) {
        .row {
          .col-xs-6 {
            display: flex !important;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
}
