@import '../../../styles/variables.scss';
.away-progressbar {
  &.progressbar-sm {
    min-height: 42px;
  }

  .progress {
    background-color: $grey-3;
    border-radius: 6px;
    height: 7px;
    margin-top: 11px;
    margin-bottom: 10px;

    &.pg-small {
      height: 5px;
      margin-bottom: 0;
    }

    &.pg-primary {
      .progress-bar {
        background-color: $green;
      }
    }
    &.pg-warning {
      .progress-bar {
        background-color: $yellow;
      }
    }
    &.pg-danger {
      .progress-bar {
        background-color: $red;
      }
    }
  }

  .label {
    font-weight: 400;

    &.label-sm {
      font-size: 12px;
      color: $grey;

      &.label-center {
        text-align: center;
      }
    }

    &.label-top {
      font-size: 16px;
      margin-bottom: 10px;
      font-weight: 500;
      color: $text-color;

      p {
        margin-bottom: 0;
      }
    }
    &.label-bottom {
      font-size: 14px;
      color: $grey-2;
    }
  }
}
