.away-avatar-editor {
  margin-bottom: 20px;

  label {
    width: 100%;
  }

  .aae-cell {
    margin-bottom: 20px;
  }

  .away-avatar-preview {
    display: flex;
    flex-direction: column;
    align-items: center;

    .away-avatar-preview-img {
      width: 140px;
      height: 140px;
      border-radius: 50%;
      border: 1px dashed grey;
      overflow: hidden;
    }
  }
}
