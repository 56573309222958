@import '../../../styles/variables.scss';
.card-shadow {
  &.vacation-requests {
    padding-bottom: 10px;
    padding-top: 20px;
    .top-row {
      align-items: center;
      margin-bottom: 25px;
    }
    h3 {
      display: inline-block;
      margin-bottom: 0;
    }
    .btn-link {
      position: absolute;
      right: 50px;
      top: 2px;
      padding: 0;
    }
    p {
      margin-bottom: 20px;
    }
    $count: 1000;
    .away-avatar {
      display: inline-block;
      border: solid 2px $white;
      border-radius: 50%;
      margin-right: -13px;
      margin-bottom: 20px;
      position: relative;
      $target: 0;
      $index: $count;
      @while $index > 0 {
        &:nth-child(#{$target}) {
          z-index: #{$index};
        }
        $target: $target + 1;
        $index: $index - 1;
      }
    }
  }
}
