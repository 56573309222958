@import '../../../styles/variables.scss';
.section-title {
  margin-bottom: 16px;
  font-size: 1rem;
  font-weight: 500;
  border-bottom: 1px solid #f6f8fc;
  color: #a5adbc;
}
.away-modal-body .section-title {
  margin-bottom: 24px;
  border-bottom: 0;
}

.info-text {
  .btn.btn-link {
    margin: 0;
    padding: 0;
    font-size: 16px;
  }
}
