@import '../../../../styles/variables.scss';

.away-info-card {
  .card-shadow {
    padding: 50px 58px 71px 50px;
  }

  .top-row {
    margin-bottom: 37px;

    &.no-margin {
      margin-bottom: 10px;
    }
    h3 {
      margin-bottom: 2px;
    }
    p {
      font-size: 16px;
      font-family: $font-book;
      margin-bottom: 5px;
    }
    span {
      font-size: 14px;
      color: $grey-2;
      font-family: $font-book;
      img {
        width: 13px;
        margin-right: 7px;
        vertical-align: inherit;
      }
    }

    .btn-link {
      padding-left: 0;
    }
  }

  .desc-row {
    margin-bottom: 66px;

    p {
      margin-bottom: 0;
    }
  }

  .row-space {
    margin-bottom: 14px;
  }

  .info-label {
    font-size: 12px;
    font-weight: 400;
    color: $grey;
    margin-bottom: 4px;

    &.info-label-big {
      margin-bottom: 15px;
    }

    .info-label-title {
      text-transform: capitalize;
    }
  }

  .info-text {
    font-size: 16px;
    font-weight: 400;
    color: $text-color;
    margin-bottom: 21px;
  }

  .away-avatar {
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 20px;
  }

  .aic-divider {
    border-bottom: 1px solid $grey-3;
    margin-bottom: 30px;
    margin-top: 30px;
  }

  .aic-subtitle {
    margin-bottom: 30px;
  }

  .log-list {
    align-self: flex-end;
    padding-right: 105px;
    margin-top: 5px;
    margin-bottom: 15px;
  }

  .stats-link {
    margin-top: -7px;
  }
}
