@import '../../../styles/variables.scss';
.away-avatar {
  &:hover {
    * {
      text-decoration: none;
      cursor: default;
    }
  }

  &.away-avatar-link {
    &:hover {
      * {
        text-decoration: none;
        cursor: pointer;
      }
    }
  }

  &.avatar-center {
    display: flex;
    justify-content: center;
  }

  .avatar {
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    img {
      height: 100%;
      min-width: 100%;
      left: 50%;
      position: relative;
      transform: translateX(-50%);
      border-radius: 50%;
    }

    &.avatar-text {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.avatar-red {
      background-color: $red;
    }
    &.avatar-blue {
      background-color: $blue;
    }
    &.avatar-purple {
      background-color: $purple;
    }
    &.avatar-green {
      background-color: $light-green;
    }
    &.avatar-pink {
      background-color: $pink;
    }
    &.avatar-orange {
      background-color: $orange;
    }
    &.avatar-light-blue {
      background-color: $light-blue;
    }
    &.avatar-dark-green {
      background-color: $dark-green;
    }

    .avatar-placeholder-text {
      text-align: center;
      color: $white;
    }

    .avatar-edit-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      display: flex;

      &:hover {
        .avatar-edit-flag {
          display: none;
        }

        .avatar-edit-container {
          display: flex;
        }

        .edit-icon,
        .delete-icon {
          .fa {
            color: $grey-3;
            font-family: FontAwesome !important;
          }

          .fa {
            cursor: pointer;
          }

          &.not-allowed {
            .fa {
              cursor: not-allowed;
            }
          }
        }

        .edit-icon {
          margin-top: 3px;
          margin-right: 6px;

          .fa {
            margin-right: 0;
          }
        }

        .delete-icon {
          margin-right: 0;

          .fa {
            margin-right: 0;
          }
        }
      }

      .avatar-edit-container {
        background-color: $avatarOverlay;
        width: 100%;
        height: 100%;
        display: none;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        z-index: 2;
        padding-top: 5px;
        border-radius: 50%;

        .edit-icon,
        .delete-icon {
          .fa {
            font-size: 20px;
          }
        }
      }

      .avatar-edit-flag {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: $grey-4;
        display: none;
        justify-content: center;
        align-items: center;
        align-self: flex-end;

        @media only screen and (max-width: 768px) {
          display: flex;
        }

        .fa-small {
          color: $text-color;
        }
      }
    }

    &.avatar-xs {
      width: 30px;
      height: 30px;

      .avatar-placeholder-text {
        font-size: 11px;
      }
    }

    &.avatar-sm {
      width: 40px;
      height: 40px;

      .avatar-placeholder-text {
        font-size: 13px;
      }
    }

    &.avatar-md {
      width: 50px;
      height: 50px;

      .avatar-placeholder-text {
        font-size: 18px;
      }
    }

    &.avatar-lg {
      width: 60px;
      height: 60px;

      .avatar-placeholder-text {
        font-size: 20px;
      }
    }

    &.avatar-xl {
      width: 90px;
      height: 90px;

      .avatar-placeholder-text {
        font-size: 24px;
      }
    }

    &.avatar-xxl {
      width: 100px;
      height: 100px;

      .avatar-placeholder-text {
        font-size: 28px;
      }
    }

    &.avatar-xxxl {
      width: 140px;
      height: 140px;

      .avatar-placeholder-text {
        font-size: 36px;
      }
    }
  }

  .avatar-label {
    font-size: 12px;
    color: $text-color;
    margin-top: 5px;
    max-width: 65px;
    min-width: 65px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
}
