@import '../../styles/variables.scss';
.away-manage-team {
  .create-team {
    margin-bottom: 20px;
    padding: 20px 10px 5px 10px;
    border-radius: 20px;
    transition: all 0.7 ease;
    position: relative;
    .form-group {
      margin: 16px;
    }
    .create-team-btn {
      background-image: linear-gradient(-121deg, #ff7575 0%, #ffb230 100%);
    }
  }
  .add-shadow {
    box-shadow: 0px 2px 20px 6px rgba(34, 51, 73, 0.05);
    background-color: $white;
  }
}
