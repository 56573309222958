@import '../../styles/variables.scss';

.reports-row {
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }

  .report-card {
    min-width: 300px;
    max-width: 300px;
    margin-right: 40px;

    &:last-of-type {
      margin-right: 0;
    }

    @media only screen and (max-width: 768px) {
      margin-right: 0;
    }

    .card-shadow {
      min-height: 360px;
      padding: 40px 25px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      .report-title {
        text-align: center;
        margin-bottom: 20px;
      }

      .report-icon {
        margin-bottom: 40px;
        img {
          max-height: 100px;
        }
      }
    }
  }
}
