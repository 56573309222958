@import '../../../styles/variables.scss';

.switch-container {
  display: inline-flex; // Changed to inline-flex to adapt to content
  justify-content: center;
  margin: 20px 0;
}

.switch {
  position: relative;
  display: flex;
  min-width: 170px; // Minimum width
  max-width: 300px; // Maximum width to prevent excessive stretching
  width: auto; // Allow content to determine width
  height: 30px;
  border-radius: 15px;
  padding: 2px;
  cursor: pointer;
  background-color: #f6f8fc;
  border: 0;
}

.option {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  font-family: $font-book;
  color: $orange;
  transition: color 0.3s ease;
  padding: 5px 12px; // Add horizontal padding for text
  font-size: 14px;
  white-space: nowrap; // Prevent text wrapping
  min-width: 60px; // Minimum width for each option
}

.option.selected {
  color: white;
}

.slider {
  position: absolute;
  top: 2px;
  height: calc(100% - 4px);
  background-image: linear-gradient(-121deg, #ff7575 0%, #ffb230 100%);
  border-radius: 13px;
  transition: all 0.3s ease;
}

// Use JavaScript to calculate and set slider width and position
