@import '../../../styles/variables.scss';

.card-shadow {
  &.upcoming-holidays {
    .top-row {
      margin-bottom: 10px;
    }
    span {
      font-size: 12px;
    }
  }
}
