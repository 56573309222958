@import '../../../../styles/variables.scss';

.card-shadow {
  &.calendar {
    padding: 0;
    &.admin-mobile {
      margin-top: 30px;
    }
    @media only screen and (max-width: 550px) {
      display: none;
    }
  }
}
.away-calendar {
  height: 740px;
  .rbc-month-view {
    border: 0;
    height: auto;
    .rbc-overlay {
      border-radius: 5px;
      box-shadow: 0 2px 14px 0 rgba(12, 15, 19, 0.09);
      border: 0;
      width: min-content;
      display: flex;
      flex-direction: column;
      z-index: 20;
      .rbc-overlay-header {
        font-size: 16px;
        font-family: $font-bold;
        order: 1;
      }
    }
    .rbc-month-header {
      border-top: 1px solid $grey-6;
      .rbc-header {
        font-size: 100%;
        text-align: right;
        padding: 5px 10px 5px 0;
        border-bottom: 1px solid $grey-6;
        border-left: 1px solid $grey-6;
        &:first-child {
          border-left: 0;
        }
        span {
          font-family: $font-medium;
          color: $text-color;
          font-size: 12px;
          font-weight: normal;
        }
      }
    }
    .rbc-event-continues-prior {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      .publicHolidayEvent,
      .publicHolidayExtraEvent {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
      }
      .event-border {
        visibility: hidden;
      }
    }
    .rbc-day-bg + .rbc-day-bg {
      border-left: 1px solid $grey-6;
    }
    .rbc-day-bg {
      &.rbc-today {
        background: transparent;
      }
      &:hover,
      &:active,
      &:focus {
        cursor: pointer;
      }
    }
    .rbc-month-row + .rbc-month-row {
      border-top: 1px solid $grey-6;
    }
    .rbc-off-range-bg {
      background: transparent;
    }
    .rbc-selected-cell {
      background: rgba(0, 0, 0, 0.05);
    }
    .rbc-date-cell {
      padding-right: 10px;
      padding-top: 2px;
      padding-bottom: 3px;
      &.rbc-now {
        a {
          font-size: 11px;
          background: $red;
          border-radius: 50%;
          width: 23px;
          height: 23px;
          display: inline-flex;
          align-items: center;
          line-height: 1;
          justify-content: center;
          color: $white !important;
        }
      }
      &.rbc-off-range {
        a {
          color: $grey-5;
        }
      }
      a {
        font-size: 12px;
        font-family: $font-medium;
        color: $text-color;
        font-weight: normal;
      }

      &:hover,
      &:active,
      &:focus {
        cursor: pointer;
      }
    }
    .holiday-row {
      white-space: normal;
      position: relative;
      display: flex;
      p {
        max-width: 100px;
        margin-left: 10px !important;
        line-height: 1 !important;
      }
    }
    .rbc-event {
      padding-left: 0;
      padding: 0;
      width: calc(100% - 10px);
      margin-bottom: 3px;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      .publicHolidayEvent,
      .publicHolidayExtraEvent {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
      }
      &.rbc-event-continues-after {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        width: 100%;
        .publicHolidayEvent,
        .publicHolidayExtraEvent {
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
        }
      }
      &:hover,
      &:active,
      &:focus {
        outline: none;
      }
      &.publicHolidayEvent {
        p {
          color: #ff1744;
        }
      }
      &.publicHolidayExtraEvent {
        p {
          color: rgba(255, 23, 68, 0.8);
        }
      }
      &.publicHolidayEvent,
      &.publicHolidayExtraEvent {
        background-color: transparent;
        display: block;
        z-index: 9;
        &:hover,
        &:active,
        &:focus {
          background-color: transparent !important;
          cursor: default;
        }
      }
      .row {
        margin: 0;
      }
      p {
        color: $text-color;
        margin: 0 0 0 7px;
        font-size: 10px;
        font-family: $font-medium;
        font-weight: normal;
        line-height: 1.2;
        padding: 4px 0;
      }
      .user-name {
        color: $text-color;
        margin: 0 0 0 7px;
        font-size: 10px;
        font-family: $font-medium;
        font-weight: normal;
        line-height: 1;
        padding: 4px 0;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 95px;
      }
    }
    .rbc-row-segment {
      // height: 22px;
      padding: 0;
      z-index: 11;
      margin-bottom: -3px;
    }
    .rbc-row-content {
      z-index: inherit;

      &:hover,
      &:active,
      &:focus {
        cursor: pointer;
      }
    }
    .rbc-show-more {
      font-size: 10px;
      color: #b9c0cf;
      font-family: $font-regular;
      font-weight: normal;
      text-align: center;
    }
    .publicHoliday,
    .publicHolidayExtra {
      position: relative;
      z-index: 10;
      &:after {
        content: '';
        width: 4px;
        height: 100%;
        position: absolute;
        left: -1px;
        top: 0;
        z-index: 10;
      }

      &.weekEnd {
        &:hover,
        &:active,
        &:focus {
          cursor: default !important;
        }
      }
    }
    .publicHoliday {
      background-color: rgba(255, 0, 0, 0.1);
      &:after {
        background-color: #ff1744;
      }
    }
    .publicHolidayExtra {
      background-color: rgba(255, 23, 68, 0.05);
      &:after {
        background-color: rgba(255, 23, 68, 0.3);
      }
    }

    .weekEnd {
      background-color: rgba(0, 0, 0, 0.02);
      position: relative;
      z-index: 10;

      &.rbc-selected-cell {
        background: rgba(0, 0, 0, 0.05);
      }

      &.publicHoliday {
        background-color: rgba(255, 0, 0, 0.05);
      }
      &.publicHolidayExtra {
        background-color: rgba(255, 23, 68, 0.05);
      }

      &:hover,
      &:active,
      &:focus {
        cursor: default;
      }
    }
  }
  .rbc-toolbar {
    padding: 15px;
  }
}
.popover {
  &.calendar-popover {
    max-width: 320px;
    min-width: 250px;
    border: 0;
    top: -10px !important;
    border-radius: 5px;
    box-shadow: 0 2px 14px 0 rgba(12, 15, 19, 0.09);
    .row-margin {
      margin-bottom: 15px;
    }
    h3 {
      font-size: 18px;
      font-family: $font-bold;
      font-weight: normal;
      margin-bottom: 15px;
    }
    label {
      color: $grey;
      font-family: $font-light;
      font-size: 10px;
      margin-bottom: 0;
      font-weight: normal;
    }
    p {
      font-family: $font-book;
      font-size: 13px;
      color: $text-color;
      font-weight: normal;
      margin-bottom: 0;
      line-height: 1.2;
    }
    .popover-inner {
      border-radius: 5px;
      padding: 20px;
      background-color: $white;
    }
    .popover-body {
      border: 0;
      padding: 0;
    }
  }
}
.bs-popover-top .arrow::before,
.bs-popover-auto[x-placement^='top'] .arrow::before {
  border-top-color: transparent !important;
}
.bs-popover-bottom .arrow::before,
.bs-popover-auto[x-placement^='bottom'] .arrow::before {
  border-bottom-color: transparent !important;
}
