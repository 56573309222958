@import '../../styles/variables.scss';

.team-admin-row {
  margin-top: -55px;
  padding: relative;
  .btn-primary {
    margin-left: 20px;
  }
  @media only screen and (max-width: 768px) {
    margin-top: 0;
  }
  .filter-area {
    position: relative;
    top: -15px;
    @media only screen and (max-width: 768px) {
      flex-direction: column;
      justify-content: center;
      top: 0;

      .admin-filters {
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        margin-bottom: 0;

        .away-dropdown {
          margin-right: 0;
          margin-bottom: 20px;
          max-width: 250px;
          min-width: 250px;
        }
      }

      .btn-primary {
        max-width: 130px;
        min-width: 130px;
        align-self: center;
        margin-bottom: 10px;
      }
    }
  }
}

.filter-area {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-bottom: 15px;

  .away-dropdown {
    margin-right: 20px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  @media only screen and (max-width: 768px) {
    padding-bottom: 20px;
    justify-content: center;
    .away-dropdown {
      max-width: 130px;
      min-width: 130px;
      &:first-child {
        z-index: 1002;
      }
      &:nth-child(2n) {
        z-index: 1001;
      }
      .away__single-value {
        font-size: 12px;
      }
    }
  }
  .filter-grid {
    display: flex;
    min-height: 36px;
    align-items: center;
    justify-content: center;
    margin-left: 20px;

    .fa {
      margin-right: 5px;
      vertical-align: inherit;
      cursor: pointer;

      &:last-of-type {
        margin-right: 0;
      }
    }

    .fl-active {
      opacity: 1;
    }

    .fl-notActive {
      opacity: 0.5;
    }
  }
}
