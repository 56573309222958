@import '../../../styles/variables.scss';
.card-shadow {
  &.profile-card {
    padding-bottom: 10px;
    padding-top: 30px;
    @media only screen and (max-width: 768px) {
      .avatar {
        margin-bottom: 20px;
        margin-left: inherit;
      }
    }

    .fa {
      font-family: FontAwesome !important;
    }

    .top-row {
      margin-bottom: 30px;
      h3 {
        margin-bottom: 2px;
      }
      p {
        font-size: 16px;
        font-family: $font-book;
        margin-bottom: 5px;
      }
      span {
        font-size: 14px;
        color: $grey-2;
        font-family: $font-book;
        img {
          width: 13px;
          margin-right: 7px;
          vertical-align: inherit;
        }
      }
    }
  }
}
