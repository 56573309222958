@import './variables.scss';

.away-list {
  .list-title-section {
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: 768px) {
      margin-top: 20px;
    }

    .list-title {
      margin-bottom: 0;
    }
  }

  .list-header {
    background-color: $light-grey;
    padding-top: 13px;
    padding-bottom: 12px;
    padding-left: 20px;
    padding-right: 20px;

    @media only screen and (max-width: 768px) {
      display: none;
    }

    .list-cell {
      display: flex;
      flex-direction: row;
      align-items: center;

      .list-cell-text {
        font-size: 12px;
        color: $grey;
      }
    }
  }

  .list-item {
    padding: 15px 20px;
    border-bottom: 1px solid $grey-6;
    position: relative;
    &:last-of-type {
      border: none;
    }
    .away-loader {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      top: auto;
      left: 50%;
      transform: translate(-50%, 0%);
      transform: -webkit-translate(-50%, 0%);
      transform: -moz-translate(-50%, 0%);
      transform: -ms-translate(-50%, 0%);
    }
    @media only screen and (max-width: 768px) {
      padding-top: 30px;
    }

    .li-action-buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      span {
        cursor: pointer;
        margin-right: 15px;

        &:last-of-type {
          margin-right: 0;
        }

        .fa {
          color: $grey;
        }

        &.edit-icon {
          margin-top: 2px;
          margin-right: 6px;

          .fa {
            margin-right: 0;
          }

          &:focus,
          &:hover,
          &:active {
            .fa {
              color: $blue;
            }
          }
        }

        &.delete-icon {
          margin-right: 0;

          .fa {
            margin-right: 0;
          }

          &:focus,
          &:hover,
          &:active {
            .fa {
              color: $red;
            }
          }

          &.not-allowed {
            cursor: not-allowed;
          }
        }

        .svg-approve {
          &:hover {
            rect {
              fill: #9ccc65;
            }
            polyline {
              stroke: $white;
            }
          }
        }
        .svg-delete {
          &:hover {
            rect {
              fill: #ff5252;
            }
            path {
              stroke: $white;
            }
          }
        }
      }
    }

    .li-color {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }

    .li-row {
      .li-cell {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: $font-book;

        @media only screen and (max-width: 768px) {
          margin-bottom: 20px;
        }

        &.li-cell-end {
          justify-content: flex-end;

          @media only screen and (max-width: 768px) {
            justify-content: flex-start;
          }
        }

        &.li-capitalized {
          text-transform: capitalize;
        }

        .li-card-text {
          margin-left: 20px;
        }

        .li-status {
          font-size: 16px;

          &.li-status-Approved {
            color: $green-2;
          }
          &.li-status-Pending {
            color: $yellow-2;
          }
          &.li-status-Rejected {
            color: $red;
          }
        }
      }

      .li-cell-mobile {
        display: none;
        @media only screen and (max-width: 768px) {
          display: block;
        }

        .li-cell-mobile-text {
          font-size: 12px;
          color: $grey;
          margin-bottom: 5px;
          display: block;
        }
      }
    }
  }
}

.away-list-divider {
  margin-bottom: 40px;
}
