@import './variables.scss';

.away-tabs {
  .card-shadow {
    padding: 0;
    min-height: 500px;
  }

  .at-header-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: 1px solid $grey-6;

    .at-header {
      border-bottom: none;
    }

    @media only screen and (max-width: 768px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .at-header {
        width: 100%;
        border-bottom: 1px solid $grey-6;
      }
    }
  }

  .at-header {
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid $grey-6;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }

    .at-link {
      font-size: 16px;
      font-weight: normal;
      font-family: $font-book;
      color: $grey;
      background-color: transparent;
      padding-top: 15px;
      padding-bottom: 11px;
      margin-right: 40px;
      border: none;
      border-bottom: 4px solid transparent;
      outline: none;
      transition: all 0.1s ease-in-out;
      cursor: pointer;

      @media only screen and (max-width: 768px) {
        margin-right: 0;
        border-bottom: 1px solid $grey-6;
        padding-bottom: 14px;
        &:last-of-type {
          border: none;
        }
      }

      &:hover {
        color: $text-color;
      }

      &.active {
        background-color: transparent;
        border-bottom: 4px solid $orange;
        color: $text-color;
        padding-bottom: 12px;
        margin-bottom: -1px;
      }
    }
  }

  .at-body {
    padding: 20px;

    @media only screen and (max-width: 768px) {
      padding-top: 0;
    }

    &.at-body-big {
      padding-top: 40px;

      @media only screen and (max-width: 768px) {
        padding-top: 0;
      }
    }

    .at-no-requests {
      padding: 15px 20px;
      margin-bottom: 0;
    }
  }
}
