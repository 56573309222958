@import '../../../../styles/variables.scss';
.toolbar-container {
  padding: 14px 20px;
  .away-dropdown {
    max-width: 150px;
    min-width: 150px;
  }
  .filters-container {
    display: flex;
    justify-content: flex-end;
    flex-wrap: nowrap;

    .select-item {
      margin: 0 5px;
    }

    .overflow-menu {
      button,
      .btn-secondary.dropdown-toggle {
        border-radius: 18px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #f6f8fc;
        border: 0;
        padding: 0;
        font-size: 14px;
        color: hsl(0, 0%, 20%);
        &:focus {
          box-shadow: 0 0 0 1px #ff6600;
        }
        .text {
          padding: 2px 0 2px 8px;
        }

        &::after {
          display: none;
        }
        svg {
          margin: 4px 4px 4px 0;
          fill: hsl(0, 0%, 80%);
        }

        &.is-open {
          svg {
            fill: hsl(0, 0%, 40%);
          }
        }
      }

      .overflow-item {
        padding: 0px 10px;
        .away-dropdown {
          z-index: unset !important;
        }
      }
      .bank-holidays-control {
        padding: 0px 10px;
        h6 {
          font-size: 14px;
          color: $grey;
          margin-bottom: 0;
        }
        label {
          font-size: 14px;
          font-family: $font-light;
          color: $text-color;

          input[type='radio'] {
            appearance: none;
            width: 12px;
            height: 12px;
            border: 2px solid $orange;
            border-radius: 50%;
            outline: none;
            cursor: pointer;
            transition: background 0.3s, border-color 0.3s;

            &:checked {
              background-color: $orange;
              border-color: $orange;
            }

            &:hover {
              border-color: darken($orange, 10%);
            }

            &:focus {
              box-shadow: 0 0 0 3px rgba($orange, 0.3);
            }
          }
        }
        .country-select {
          font-size: 12px;
          min-width: 250px;
          max-width: 250px;
          margin-bottom: 10px;
        }

        button {
          padding: 8px 10px;
          font-size: 14px;
          font-family: $font-medium;
          color: $white;
          background-color: $orange;
          border: none;
          cursor: pointer;
          transition: background-color 0.3s;

          &:hover {
            background-color: darken($orange, 10%);
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1260px) {
    .away-dropdown {
      max-width: 150px;
      min-width: 150px;
      &:first-child {
        margin-bottom: 10px;
      }
      .away__single-value {
        font-size: 12px;
      }
    }
  }
  .row {
    align-items: center;
  }
  .toolbar-togglers {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    right: 0;
    top: -60px;
    letter-spacing: 0;
    font-family: $font-medium;
  }
  .back-next-buttons {
    display: flex;
    align-items: center;
    .toolbar-date {
      display: flex;
      align-items: center;
      justify-content: center;

      .btn-today {
        padding: 5px;
      }
    }
    .label-date {
      font-family: $font-medium;
      font-weight: normal;
      font-size: clamp(14px, 1.1vw, 20px);
      margin-bottom: 0;
      @media only screen and (max-width: 768px) {
        text-align: center;
        margin-left: 5px;
        margin-right: 5px;
      }
      b {
        font-weight: normal;
      }
      .year {
        font-size: clamp(14px, 1.1vw, 18px);
      }
    }
    .btn-link {
      padding: 10px;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
