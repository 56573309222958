@import '../../../../styles/variables.scss';
.away-teamGridCard {
  a:hover {
    cursor: pointer;
    text-decoration: none;
  }

  .team-grid {
    box-shadow: 0px 2px 20px 6px rgba(34, 51, 73, 0.05);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.small-grid {
      min-height: 325px;
    }

    &.large-grid {
      min-height: 368px;
    }

    .fa {
      font-size: 15px;
    }
    .team-grid-avatar {
      margin-bottom: 28px;
      justify-content: center;

      .away-avatar {
        *:hover {
          cursor: pointer;
        }
      }
    }
    .team-grid-name {
      font-size: 18px;
      font-weight: normal;
      font-family: $font-bold;
      color: $text-color;
      text-align: center;
    }
    .team-grid-text {
      font-size: 16px;
      font-weight: normal;
      font-family: $font-book;
      color: $text-color;
      margin-bottom: 21px;
      text-align: center;
    }
    .team-grid-label {
      font-size: 14px;
      font-weight: 400;
      color: $grey-2;
      margin-bottom: 24px;
      text-align: center;

      img {
        width: 13px;
        margin-right: 7px;
        vertical-align: inherit;
      }
    }
  }
}
