@import '../../styles/variables.scss';

.password-wrap {
  justify-content: center;
  align-items: center;
  height: 100vh;
  .card-shadow {
    margin-bottom: 0;
  }
  img {
    width: 120px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-bottom: 50px;
  }
  .btn {
    margin-top: 50px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    &.btn-link {
      margin-top: 10px;
    }
  }
  .pass-error {
    font-size: 80%;
    color: #ff5252;
    margin-bottom: 40px;
    text-align: center;
  }
  .greeting {
    font-size: 20;
    margin-bottom: 35px;
    p {
      margin-bottom: 5px;
    }

    .center {
      text-align: center;
    }
  }
}
