@import url(https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
.loading {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100vh;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }
  .loading img {
    width: 200px; }

.away-avatar:hover * {
  text-decoration: none;
  cursor: default; }

.away-avatar.away-avatar-link:hover * {
  text-decoration: none;
  cursor: pointer; }

.away-avatar.avatar-center {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center; }

.away-avatar .avatar {
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  position: relative; }
  .away-avatar .avatar img {
    height: 100%;
    min-width: 100%;
    left: 50%;
    position: relative;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    border-radius: 50%; }
  .away-avatar .avatar.avatar-text {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; }
  .away-avatar .avatar.avatar-red {
    background-color: #ff5252; }
  .away-avatar .avatar.avatar-blue {
    background-color: #303f9f; }
  .away-avatar .avatar.avatar-purple {
    background-color: #ba68c8; }
  .away-avatar .avatar.avatar-green {
    background-color: #a5d6a7; }
  .away-avatar .avatar.avatar-pink {
    background-color: #ff1493; }
  .away-avatar .avatar.avatar-orange {
    background-color: #ff6600; }
  .away-avatar .avatar.avatar-light-blue {
    background-color: #0099cc; }
  .away-avatar .avatar.avatar-dark-green {
    background-color: #004e3e; }
  .away-avatar .avatar .avatar-placeholder-text {
    text-align: center;
    color: #ffffff; }
  .away-avatar .avatar .avatar-edit-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: -webkit-flex;
    display: flex; }
    .away-avatar .avatar .avatar-edit-wrapper:hover .avatar-edit-flag {
      display: none; }
    .away-avatar .avatar .avatar-edit-wrapper:hover .avatar-edit-container {
      display: -webkit-flex;
      display: flex; }
    .away-avatar .avatar .avatar-edit-wrapper:hover .edit-icon .fa,
    .away-avatar .avatar .avatar-edit-wrapper:hover .delete-icon .fa {
      color: #f6f8fc;
      font-family: FontAwesome !important; }
    .away-avatar .avatar .avatar-edit-wrapper:hover .edit-icon .fa,
    .away-avatar .avatar .avatar-edit-wrapper:hover .delete-icon .fa {
      cursor: pointer; }
    .away-avatar .avatar .avatar-edit-wrapper:hover .edit-icon.not-allowed .fa,
    .away-avatar .avatar .avatar-edit-wrapper:hover .delete-icon.not-allowed .fa {
      cursor: not-allowed; }
    .away-avatar .avatar .avatar-edit-wrapper:hover .edit-icon {
      margin-top: 3px;
      margin-right: 6px; }
      .away-avatar .avatar .avatar-edit-wrapper:hover .edit-icon .fa {
        margin-right: 0; }
    .away-avatar .avatar .avatar-edit-wrapper:hover .delete-icon {
      margin-right: 0; }
      .away-avatar .avatar .avatar-edit-wrapper:hover .delete-icon .fa {
        margin-right: 0; }
    .away-avatar .avatar .avatar-edit-wrapper .avatar-edit-container {
      background-color: rgba(0, 0, 0, 0.6);
      width: 100%;
      height: 100%;
      display: none;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      z-index: 2;
      padding-top: 5px;
      border-radius: 50%; }
      .away-avatar .avatar .avatar-edit-wrapper .avatar-edit-container .edit-icon .fa,
      .away-avatar .avatar .avatar-edit-wrapper .avatar-edit-container .delete-icon .fa {
        font-size: 20px; }
    .away-avatar .avatar .avatar-edit-wrapper .avatar-edit-flag {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: #e2e6ed;
      display: none;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
      -webkit-align-self: flex-end;
              align-self: flex-end; }
      @media only screen and (max-width: 768px) {
        .away-avatar .avatar .avatar-edit-wrapper .avatar-edit-flag {
          display: -webkit-flex;
          display: flex; } }
      .away-avatar .avatar .avatar-edit-wrapper .avatar-edit-flag .fa-small {
        color: #2d2f44; }
  .away-avatar .avatar.avatar-xs {
    width: 30px;
    height: 30px; }
    .away-avatar .avatar.avatar-xs .avatar-placeholder-text {
      font-size: 11px; }
  .away-avatar .avatar.avatar-sm {
    width: 40px;
    height: 40px; }
    .away-avatar .avatar.avatar-sm .avatar-placeholder-text {
      font-size: 13px; }
  .away-avatar .avatar.avatar-md {
    width: 50px;
    height: 50px; }
    .away-avatar .avatar.avatar-md .avatar-placeholder-text {
      font-size: 18px; }
  .away-avatar .avatar.avatar-lg {
    width: 60px;
    height: 60px; }
    .away-avatar .avatar.avatar-lg .avatar-placeholder-text {
      font-size: 20px; }
  .away-avatar .avatar.avatar-xl {
    width: 90px;
    height: 90px; }
    .away-avatar .avatar.avatar-xl .avatar-placeholder-text {
      font-size: 24px; }
  .away-avatar .avatar.avatar-xxl {
    width: 100px;
    height: 100px; }
    .away-avatar .avatar.avatar-xxl .avatar-placeholder-text {
      font-size: 28px; }
  .away-avatar .avatar.avatar-xxxl {
    width: 140px;
    height: 140px; }
    .away-avatar .avatar.avatar-xxxl .avatar-placeholder-text {
      font-size: 36px; }

.away-avatar .avatar-label {
  font-size: 12px;
  color: #2d2f44;
  margin-top: 5px;
  max-width: 65px;
  min-width: 65px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center; }

.away-daterange-line {
  width: 100%; }
  .away-daterange-line .away-daterange-inputs {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center; }
    .away-daterange-line .away-daterange-inputs .form-group {
      margin-bottom: 0; }

.away-daterange-line {
  width: 100%; }
  .away-daterange-line .away-daterange-inputs {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center; }
    .away-daterange-line .away-daterange-inputs .form-group {
      margin-bottom: 0; }

.away-colorpicker-line {
  width: 100%; }
  .away-colorpicker-line .away-colorpicker-inputs {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center; }
    .away-colorpicker-line .away-colorpicker-inputs .form-group {
      margin-bottom: 0; }

.color-input {
  position: relative; }
  .color-input .color-badge {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 10px;
    position: absolute;
    left: 0;
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
    top: 0;
    margin-top: -2px; }
  .color-input .has-color {
    padding-left: 30px !important; }

.away-navbar .navbar {
  background-color: #ffffff;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.05);
  padding: 0;
  margin-bottom: 30px; }
  @media only screen and (max-width: 768px) {
    .away-navbar .navbar {
      margin-bottom: 15px; } }
  .away-navbar .navbar.navbar-expand-lg > .container {
    padding-left: 10px;
    padding-right: 10px; }
  .away-navbar .navbar .hamburger {
    display: none; }
    @media only screen and (max-width: 991px) {
      .away-navbar .navbar .hamburger {
        display: -webkit-flex;
        display: flex; } }
  .away-navbar .navbar .logo {
    padding-top: 18px;
    padding-bottom: 18px;
    margin-right: 0; }
    .away-navbar .navbar .logo img {
      width: 120px; }
  .away-navbar .navbar .navbar-collapse {
    -webkit-justify-content: space-between;
            justify-content: space-between; }
  .away-navbar .navbar .navbar-nav {
    -webkit-align-items: center;
            align-items: center; }
    .away-navbar .navbar .navbar-nav .nav-item {
      margin-left: 10px;
      margin-right: 10px; }
      .away-navbar .navbar .navbar-nav .nav-item.active .nav-link {
        border-bottom: 4px solid #ff6600;
        color: #2d2f44; }
      .away-navbar .navbar .navbar-nav .nav-item:hover {
        color: #2d2f44; }
    .away-navbar .navbar .navbar-nav .nav-link {
      font-size: 16px;
      font-family: "Mark Pro Bold";
      color: #a5adbc;
      padding-top: 28px;
      padding-bottom: 25px;
      border-bottom: 4px solid transparent;
      transition: all 0.1s ease-in-out; }
      @media only screen and (max-width: 768px) {
        .away-navbar .navbar .navbar-nav .nav-link {
          padding-top: 10px;
          padding-bottom: 10px;
          border-bottom: 0; } }
      .away-navbar .navbar .navbar-nav .nav-link.active {
        border-bottom: 4px solid #ff6600;
        color: #2d2f44; }
        @media only screen and (max-width: 768px) {
          .away-navbar .navbar .navbar-nav .nav-link.active {
            border-bottom: 0; } }
    .away-navbar .navbar .navbar-nav .profile-img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      background-color: #3be9ab; }
      .away-navbar .navbar .navbar-nav .profile-img span {
        font-size: 12px;
        color: #ffffff;
        text-transform: uppercase;
        font-weight: 600; }
    .away-navbar .navbar .navbar-nav .badge {
      font-size: 12px; }
      .away-navbar .navbar .navbar-nav .badge.badge-secondary {
        background-color: #ff6600; }
  @media (max-width: 991.98px) {
    .away-navbar .navbar .away-nav-dropdown {
      margin-bottom: 30px; }
      .away-navbar .navbar .away-nav-dropdown button {
        margin-left: auto;
        margin-right: auto; }
      .away-navbar .navbar .away-nav-dropdown .dropdown-menu .dropdown-item a {
        text-align: center; } }
  .away-navbar .navbar .away-nav-dropdown .dropdown-toggle {
    background-color: transparent;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    outline: none;
    border: none; }
    .away-navbar .navbar .away-nav-dropdown .dropdown-toggle span {
      margin-left: 15px;
      color: #2d2f44;
      font-size: 16px;
      font-weight: bold; }
    .away-navbar .navbar .away-nav-dropdown .dropdown-toggle:focus, .away-navbar .navbar .away-nav-dropdown .dropdown-toggle:hover, .away-navbar .navbar .away-nav-dropdown .dropdown-toggle:active {
      outline: none;
      border: none;
      box-shadow: none;
      background-color: transparent; }
    .away-navbar .navbar .away-nav-dropdown .dropdown-toggle.btn-secondary {
      color: #c7cdd9; }
    .away-navbar .navbar .away-nav-dropdown .dropdown-toggle::after {
      margin-left: 8px;
      margin-top: 4px; }
  .away-navbar .navbar .away-nav-dropdown .dropdown-menu {
    padding: 0;
    width: 100%;
    border-radius: 0;
    border: none;
    margin-top: 12px;
    box-shadow: 0 2px 2px 0 rgba(34, 51, 73, 0.05);
    z-index: 1049;
    min-width: 200px; }
    .away-navbar .navbar .away-nav-dropdown .dropdown-menu .dropdown-item {
      outline: none;
      padding: 0; }
      .away-navbar .navbar .away-nav-dropdown .dropdown-menu .dropdown-item:focus, .away-navbar .navbar .away-nav-dropdown .dropdown-menu .dropdown-item:hover {
        background-color: #ffffff; }
      .away-navbar .navbar .away-nav-dropdown .dropdown-menu .dropdown-item button,
      .away-navbar .navbar .away-nav-dropdown .dropdown-menu .dropdown-item a {
        width: 100%;
        text-align: left;
        padding: 15px 25px;
        border-bottom: 1px solid #f6f8fc; }
        @media (max-width: 991.98px) {
          .away-navbar .navbar .away-nav-dropdown .dropdown-menu .dropdown-item button,
          .away-navbar .navbar .away-nav-dropdown .dropdown-menu .dropdown-item a {
            text-align: center; } }
      .away-navbar .navbar .away-nav-dropdown .dropdown-menu .dropdown-item * {
        color: #a5adbc;
        font-size: 16px;
        line-height: 18px; }
        .away-navbar .navbar .away-nav-dropdown .dropdown-menu .dropdown-item *:focus, .away-navbar .navbar .away-nav-dropdown .dropdown-menu .dropdown-item *:hover {
          text-decoration: none;
          color: #2d2f44;
          background-color: #ffffff;
          cursor: pointer; }
      .away-navbar .navbar .away-nav-dropdown .dropdown-menu .dropdown-item button {
        box-shadow: none;
        outline: none;
        border: none;
        border-radius: 0; }
        .away-navbar .navbar .away-nav-dropdown .dropdown-menu .dropdown-item button:focus, .away-navbar .navbar .away-nav-dropdown .dropdown-menu .dropdown-item button:hover {
          text-decoration: none;
          color: #2d2f44;
          background-color: #ffffff;
          box-shadow: none;
          outline: none;
          border: none;
          border-bottom: 1px solid #f6f8fc; }
      .away-navbar .navbar .away-nav-dropdown .dropdown-menu .dropdown-item a,
      .away-navbar .navbar .away-nav-dropdown .dropdown-menu .dropdown-item button {
        border-bottom: 1px solid #f6f8fc; }
        .away-navbar .navbar .away-nav-dropdown .dropdown-menu .dropdown-item a.active,
        .away-navbar .navbar .away-nav-dropdown .dropdown-menu .dropdown-item button.active {
          color: #2d2f44;
          border-color: #ff6600; }
  @media only screen and (max-width: 768px) {
    .away-navbar .navbar .away-nav-dropdown .dropdown.show .dropdown-menu {
      position: relative !important;
      -webkit-transform: none !important;
              transform: none !important;
      margin-top: 0;
      box-shadow: none;
      border-top: 1px solid #f6f8fc; }
      .away-navbar .navbar .away-nav-dropdown .dropdown.show .dropdown-menu .dropdown-item:last-of-type button {
        border: none; } }

.hamburger {
  padding: 15px 15px;
  display: -webkit-flex;
  display: flex;
  outline: none !important;
  cursor: pointer;
  transition-property: opacity, -webkit-filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }

.hamburger:hover {
  opacity: 0.7; }

.hamburger.is-active:hover {
  opacity: 0.7; }

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #ff6600; }

.hamburger-box {
  width: 35px;
  height: 24px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px; }

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 35px;
  height: 3px;
  background-color: #ff6600;
  border-radius: 4px;
  position: absolute;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 0.15s;
  transition-timing-function: ease; }

.hamburger-inner::before,
.hamburger-inner::after {
  content: '';
  display: block; }

.hamburger-inner::before {
  top: -10px; }

.hamburger-inner::after {
  bottom: -10px; }

.hamburger--3dx .hamburger-box {
  -webkit-perspective: 80px;
          perspective: 80px; }

.hamburger--3dx .hamburger-inner {
  transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dx .hamburger-inner::before,
.hamburger--3dx .hamburger-inner::after {
  transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dx.is-active .hamburger-inner {
  background-color: transparent !important;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg); }

.hamburger--3dx.is-active .hamburger-inner::before {
  -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
          transform: translate3d(0, 10px, 0) rotate(45deg); }

.hamburger--3dx.is-active .hamburger-inner::after {
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
          transform: translate3d(0, -10px, 0) rotate(-45deg); }

.hamburger--3dx-r .hamburger-box {
  -webkit-perspective: 80px;
          perspective: 80px; }

.hamburger--3dx-r .hamburger-inner {
  transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dx-r .hamburger-inner::before,
.hamburger--3dx-r .hamburger-inner::after {
  transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dx-r.is-active .hamburger-inner {
  background-color: transparent !important;
  -webkit-transform: rotateY(-180deg);
          transform: rotateY(-180deg); }

.hamburger--3dx-r.is-active .hamburger-inner::before {
  -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
          transform: translate3d(0, 10px, 0) rotate(45deg); }

.hamburger--3dx-r.is-active .hamburger-inner::after {
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
          transform: translate3d(0, -10px, 0) rotate(-45deg); }

.hamburger--3dy .hamburger-box {
  -webkit-perspective: 80px;
          perspective: 80px; }

.hamburger--3dy .hamburger-inner {
  transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dy .hamburger-inner::before,
.hamburger--3dy .hamburger-inner::after {
  transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dy.is-active .hamburger-inner {
  background-color: transparent !important;
  -webkit-transform: rotateX(-180deg);
          transform: rotateX(-180deg); }

.hamburger--3dy.is-active .hamburger-inner::before {
  -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
          transform: translate3d(0, 10px, 0) rotate(45deg); }

.hamburger--3dy.is-active .hamburger-inner::after {
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
          transform: translate3d(0, -10px, 0) rotate(-45deg); }

.hamburger--3dy-r .hamburger-box {
  -webkit-perspective: 80px;
          perspective: 80px; }

.hamburger--3dy-r .hamburger-inner {
  transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dy-r .hamburger-inner::before,
.hamburger--3dy-r .hamburger-inner::after {
  transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dy-r.is-active .hamburger-inner {
  background-color: transparent !important;
  -webkit-transform: rotateX(180deg);
          transform: rotateX(180deg); }

.hamburger--3dy-r.is-active .hamburger-inner::before {
  -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
          transform: translate3d(0, 10px, 0) rotate(45deg); }

.hamburger--3dy-r.is-active .hamburger-inner::after {
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
          transform: translate3d(0, -10px, 0) rotate(-45deg); }

.hamburger--3dxy .hamburger-box {
  -webkit-perspective: 80px;
          perspective: 80px; }

.hamburger--3dxy .hamburger-inner {
  transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dxy .hamburger-inner::before,
.hamburger--3dxy .hamburger-inner::after {
  transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dxy.is-active .hamburger-inner {
  background-color: transparent !important;
  -webkit-transform: rotateX(180deg) rotateY(180deg);
          transform: rotateX(180deg) rotateY(180deg); }

.hamburger--3dxy.is-active .hamburger-inner::before {
  -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
          transform: translate3d(0, 10px, 0) rotate(45deg); }

.hamburger--3dxy.is-active .hamburger-inner::after {
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
          transform: translate3d(0, -10px, 0) rotate(-45deg); }

.hamburger--3dxy-r .hamburger-box {
  -webkit-perspective: 80px;
          perspective: 80px; }

.hamburger--3dxy-r .hamburger-inner {
  transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dxy-r .hamburger-inner::before,
.hamburger--3dxy-r .hamburger-inner::after {
  transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dxy-r.is-active .hamburger-inner {
  background-color: transparent !important;
  -webkit-transform: rotateX(180deg) rotateY(180deg) rotateZ(-180deg);
          transform: rotateX(180deg) rotateY(180deg) rotateZ(-180deg); }

.hamburger--3dxy-r.is-active .hamburger-inner::before {
  -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
          transform: translate3d(0, 10px, 0) rotate(45deg); }

.hamburger--3dxy-r.is-active .hamburger-inner::after {
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
          transform: translate3d(0, -10px, 0) rotate(-45deg); }

.hamburger--arrow.is-active .hamburger-inner::before {
  -webkit-transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1);
          transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1); }

.hamburger--arrow.is-active .hamburger-inner::after {
  -webkit-transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1);
          transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1); }

.hamburger--arrow-r.is-active .hamburger-inner::before {
  -webkit-transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1);
          transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1); }

.hamburger--arrow-r.is-active .hamburger-inner::after {
  -webkit-transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1);
          transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1); }

.hamburger--arrowalt .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt.is-active .hamburger-inner::before {
  top: 0;
  -webkit-transform: translate3d(-8px, -10px, 0) rotate(-45deg) scale(0.7, 1);
          transform: translate3d(-8px, -10px, 0) rotate(-45deg) scale(0.7, 1);
  transition: top 0.1s ease, -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22), -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

.hamburger--arrowalt.is-active .hamburger-inner::after {
  bottom: 0;
  -webkit-transform: translate3d(-8px, 10px, 0) rotate(45deg) scale(0.7, 1);
          transform: translate3d(-8px, 10px, 0) rotate(45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22), -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

.hamburger--arrowalt-r .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt-r .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt-r.is-active .hamburger-inner::before {
  top: 0;
  -webkit-transform: translate3d(8px, -10px, 0) rotate(45deg) scale(0.7, 1);
          transform: translate3d(8px, -10px, 0) rotate(45deg) scale(0.7, 1);
  transition: top 0.1s ease, -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22), -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

.hamburger--arrowalt-r.is-active .hamburger-inner::after {
  bottom: 0;
  -webkit-transform: translate3d(8px, 10px, 0) rotate(-45deg) scale(0.7, 1);
          transform: translate3d(8px, 10px, 0) rotate(-45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22), -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

.hamburger--arrowturn.is-active .hamburger-inner {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg); }

.hamburger--arrowturn.is-active .hamburger-inner::before {
  -webkit-transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1);
          transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1); }

.hamburger--arrowturn.is-active .hamburger-inner::after {
  -webkit-transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1);
          transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1); }

.hamburger--arrowturn-r.is-active .hamburger-inner {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg); }

.hamburger--arrowturn-r.is-active .hamburger-inner::before {
  -webkit-transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1);
          transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1); }

.hamburger--arrowturn-r.is-active .hamburger-inner::after {
  -webkit-transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1);
          transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1); }

.hamburger--boring .hamburger-inner,
.hamburger--boring .hamburger-inner::before,
.hamburger--boring .hamburger-inner::after {
  transition-property: none; }

.hamburger--boring.is-active .hamburger-inner {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }

.hamburger--boring.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0; }

.hamburger--boring.is-active .hamburger-inner::after {
  bottom: 0;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg); }

.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse .hamburger-inner::after {
  top: -20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }

.hamburger--collapse .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
          transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }

.hamburger--collapse.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; }

.hamburger--collapse.is-active .hamburger-inner::before {
  top: 0;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); }

.hamburger--collapse-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse-r .hamburger-inner::after {
  top: -20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }

.hamburger--collapse-r .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse-r.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, -10px, 0) rotate(45deg);
          transform: translate3d(0, -10px, 0) rotate(45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }

.hamburger--collapse-r.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; }

.hamburger--collapse-r.is-active .hamburger-inner::before {
  top: 0;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); }

.hamburger--elastic .hamburger-inner {
  top: 2px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic .hamburger-inner::before {
  top: 10px;
  transition: opacity 0.125s 0.275s ease; }

.hamburger--elastic .hamburger-inner::after {
  top: 20px;
  transition: -webkit-transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55), -webkit-transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, 10px, 0) rotate(135deg);
          transform: translate3d(0, 10px, 0) rotate(135deg);
  transition-delay: 0.075s; }

.hamburger--elastic.is-active .hamburger-inner::before {
  transition-delay: 0s;
  opacity: 0; }

.hamburger--elastic.is-active .hamburger-inner::after {
  -webkit-transform: translate3d(0, -20px, 0) rotate(-270deg);
          transform: translate3d(0, -20px, 0) rotate(-270deg);
  transition-delay: 0.075s; }

.hamburger--elastic-r .hamburger-inner {
  top: 2px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic-r .hamburger-inner::before {
  top: 10px;
  transition: opacity 0.125s 0.275s ease; }

.hamburger--elastic-r .hamburger-inner::after {
  top: 20px;
  transition: -webkit-transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55), -webkit-transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic-r.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, 10px, 0) rotate(-135deg);
          transform: translate3d(0, 10px, 0) rotate(-135deg);
  transition-delay: 0.075s; }

.hamburger--elastic-r.is-active .hamburger-inner::before {
  transition-delay: 0s;
  opacity: 0; }

.hamburger--elastic-r.is-active .hamburger-inner::after {
  -webkit-transform: translate3d(0, -20px, 0) rotate(270deg);
          transform: translate3d(0, -20px, 0) rotate(270deg);
  transition-delay: 0.075s; }

.hamburger--emphatic {
  overflow: hidden; }

.hamburger--emphatic .hamburger-inner {
  transition: background-color 0.125s 0.175s ease-in; }

.hamburger--emphatic .hamburger-inner::before {
  left: 0;
  transition: top 0.05s 0.125s linear, left 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335); }

.hamburger--emphatic .hamburger-inner::after {
  top: 10px;
  right: 0;
  transition: top 0.05s 0.125s linear, right 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335); }

.hamburger--emphatic.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent !important; }

.hamburger--emphatic.is-active .hamburger-inner::before {
  left: -80px;
  top: -80px;
  -webkit-transform: translate3d(80px, 80px, 0) rotate(45deg);
          transform: translate3d(80px, 80px, 0) rotate(45deg);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear, -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1), -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }

.hamburger--emphatic.is-active .hamburger-inner::after {
  right: -80px;
  top: -80px;
  -webkit-transform: translate3d(-80px, 80px, 0) rotate(-45deg);
          transform: translate3d(-80px, 80px, 0) rotate(-45deg);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear, -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1), -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }

.hamburger--emphatic-r {
  overflow: hidden; }

.hamburger--emphatic-r .hamburger-inner {
  transition: background-color 0.125s 0.175s ease-in; }

.hamburger--emphatic-r .hamburger-inner::before {
  left: 0;
  transition: top 0.05s 0.125s linear, left 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335); }

.hamburger--emphatic-r .hamburger-inner::after {
  top: 10px;
  right: 0;
  transition: top 0.05s 0.125s linear, right 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335); }

.hamburger--emphatic-r.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent !important; }

.hamburger--emphatic-r.is-active .hamburger-inner::before {
  left: -80px;
  top: 80px;
  -webkit-transform: translate3d(80px, -80px, 0) rotate(-45deg);
          transform: translate3d(80px, -80px, 0) rotate(-45deg);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear, -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1), -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }

.hamburger--emphatic-r.is-active .hamburger-inner::after {
  right: -80px;
  top: 80px;
  -webkit-transform: translate3d(-80px, -80px, 0) rotate(45deg);
          transform: translate3d(-80px, -80px, 0) rotate(45deg);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear, -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1), -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }

.hamburger--minus .hamburger-inner::before,
.hamburger--minus .hamburger-inner::after {
  transition: bottom 0.08s 0s ease-out, top 0.08s 0s ease-out, opacity 0s linear; }

.hamburger--minus.is-active .hamburger-inner::before,
.hamburger--minus.is-active .hamburger-inner::after {
  opacity: 0;
  transition: bottom 0.08s ease-out, top 0.08s ease-out, opacity 0s 0.08s linear; }

.hamburger--minus.is-active .hamburger-inner::before {
  top: 0; }

.hamburger--minus.is-active .hamburger-inner::after {
  bottom: 0; }

.hamburger--slider .hamburger-inner {
  top: 2px; }

.hamburger--slider .hamburger-inner::before {
  top: 10px;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  transition-timing-function: ease;
  transition-duration: 0.15s; }

.hamburger--slider .hamburger-inner::after {
  top: 20px; }

.hamburger--slider.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
          transform: translate3d(0, 10px, 0) rotate(45deg); }

.hamburger--slider.is-active .hamburger-inner::before {
  -webkit-transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
          transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
  opacity: 0; }

.hamburger--slider.is-active .hamburger-inner::after {
  -webkit-transform: translate3d(0, -20px, 0) rotate(-90deg);
          transform: translate3d(0, -20px, 0) rotate(-90deg); }

.hamburger--slider-r .hamburger-inner {
  top: 2px; }

.hamburger--slider-r .hamburger-inner::before {
  top: 10px;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  transition-timing-function: ease;
  transition-duration: 0.15s; }

.hamburger--slider-r .hamburger-inner::after {
  top: 20px; }

.hamburger--slider-r.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, 10px, 0) rotate(-45deg);
          transform: translate3d(0, 10px, 0) rotate(-45deg); }

.hamburger--slider-r.is-active .hamburger-inner::before {
  -webkit-transform: rotate(45deg) translate3d(5.71429px, -6px, 0);
          transform: rotate(45deg) translate3d(5.71429px, -6px, 0);
  opacity: 0; }

.hamburger--slider-r.is-active .hamburger-inner::after {
  -webkit-transform: translate3d(0, -20px, 0) rotate(90deg);
          transform: translate3d(0, -20px, 0) rotate(90deg); }

.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; }

.hamburger--spin .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin.is-active .hamburger-inner {
  -webkit-transform: rotate(225deg);
          transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }

.hamburger--spin.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; }

.hamburger--spin.is-active .hamburger-inner::after {
  bottom: 0;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

.hamburger--spin-r .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin-r .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; }

.hamburger--spin-r .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin-r.is-active .hamburger-inner {
  -webkit-transform: rotate(-225deg);
          transform: rotate(-225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }

.hamburger--spin-r.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; }

.hamburger--spin-r.is-active .hamburger-inner::after {
  bottom: 0;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  transition: bottom 0.1s ease-out, -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

.hamburger--spring .hamburger-inner {
  top: 2px;
  transition: background-color 0s 0.13s linear; }

.hamburger--spring .hamburger-inner::before {
  top: 10px;
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring .hamburger-inner::after {
  top: 20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring.is-active .hamburger-inner {
  transition-delay: 0.22s;
  background-color: transparent !important; }

.hamburger--spring.is-active .hamburger-inner::before {
  top: 0;
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
          transform: translate3d(0, 10px, 0) rotate(45deg); }

.hamburger--spring.is-active .hamburger-inner::after {
  top: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transform: translate3d(0, 10px, 0) rotate(-45deg);
          transform: translate3d(0, 10px, 0) rotate(-45deg); }

.hamburger--spring-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring-r .hamburger-inner::after {
  top: -20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0s linear; }

.hamburger--spring-r .hamburger-inner::before {
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring-r.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
          transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }

.hamburger--spring-r.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s 0.22s linear; }

.hamburger--spring-r.is-active .hamburger-inner::before {
  top: 0;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1); }

.hamburger--stand .hamburger-inner {
  transition: background-color 0s 0.075s linear, -webkit-transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear;
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear, -webkit-transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--stand .hamburger-inner::before {
  transition: top 0.075s 0.075s ease-in, -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--stand .hamburger-inner::after {
  transition: bottom 0.075s 0.075s ease-in, -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--stand.is-active .hamburger-inner {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  background-color: transparent !important;
  transition: background-color 0s 0.15s linear, -webkit-transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear, -webkit-transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1); }

.hamburger--stand.is-active .hamburger-inner::before {
  top: 0;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  transition: top 0.075s 0.1s ease-out, -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }

.hamburger--stand.is-active .hamburger-inner::after {
  bottom: 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  transition: bottom 0.075s 0.1s ease-out, -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }

.hamburger--stand-r .hamburger-inner {
  transition: background-color 0s 0.075s linear, -webkit-transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear;
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear, -webkit-transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--stand-r .hamburger-inner::before {
  transition: top 0.075s 0.075s ease-in, -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--stand-r .hamburger-inner::after {
  transition: bottom 0.075s 0.075s ease-in, -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--stand-r.is-active .hamburger-inner {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  background-color: transparent !important;
  transition: background-color 0s 0.15s linear, -webkit-transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear, -webkit-transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1); }

.hamburger--stand-r.is-active .hamburger-inner::before {
  top: 0;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  transition: top 0.075s 0.1s ease-out, -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }

.hamburger--stand-r.is-active .hamburger-inner::after {
  bottom: 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  transition: bottom 0.075s 0.1s ease-out, -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }

.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--squeeze .hamburger-inner::before {
  transition: top 0.075s 0.12s ease, opacity 0.075s ease; }

.hamburger--squeeze .hamburger-inner::after {
  transition: bottom 0.075s 0.12s ease, -webkit-transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--squeeze.is-active .hamburger-inner {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }

.hamburger--squeeze.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.075s ease, opacity 0.075s 0.12s ease; }

.hamburger--squeeze.is-active .hamburger-inner::after {
  bottom: 0;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  transition: bottom 0.075s ease, -webkit-transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

.hamburger--vortex .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }

.hamburger--vortex .hamburger-inner::before,
.hamburger--vortex .hamburger-inner::after {
  transition-duration: 0s;
  transition-delay: 0.1s;
  transition-timing-function: linear; }

.hamburger--vortex .hamburger-inner::before {
  transition-property: top, opacity; }

.hamburger--vortex .hamburger-inner::after {
  transition-property: bottom, -webkit-transform;
  transition-property: bottom, transform;
  transition-property: bottom, transform, -webkit-transform; }

.hamburger--vortex.is-active .hamburger-inner {
  -webkit-transform: rotate(765deg);
          transform: rotate(765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }

.hamburger--vortex.is-active .hamburger-inner::before,
.hamburger--vortex.is-active .hamburger-inner::after {
  transition-delay: 0s; }

.hamburger--vortex.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0; }

.hamburger--vortex.is-active .hamburger-inner::after {
  bottom: 0;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.hamburger--vortex-r .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }

.hamburger--vortex-r .hamburger-inner::before,
.hamburger--vortex-r .hamburger-inner::after {
  transition-duration: 0s;
  transition-delay: 0.1s;
  transition-timing-function: linear; }

.hamburger--vortex-r .hamburger-inner::before {
  transition-property: top, opacity; }

.hamburger--vortex-r .hamburger-inner::after {
  transition-property: bottom, -webkit-transform;
  transition-property: bottom, transform;
  transition-property: bottom, transform, -webkit-transform; }

.hamburger--vortex-r.is-active .hamburger-inner {
  -webkit-transform: rotate(-765deg);
          transform: rotate(-765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }

.hamburger--vortex-r.is-active .hamburger-inner::before,
.hamburger--vortex-r.is-active .hamburger-inner::after {
  transition-delay: 0s; }

.hamburger--vortex-r.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0; }

.hamburger--vortex-r.is-active .hamburger-inner::after {
  bottom: 0;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg); }

.login-wrap {
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 100vh; }
  .login-wrap .card-shadow {
    margin-bottom: 0; }
  .login-wrap img {
    width: 120px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-bottom: 50px; }
  .login-wrap .btn {
    margin-top: 50px;
    display: block;
    margin-left: auto;
    margin-right: auto; }
    .login-wrap .btn.btn-link {
      margin-top: 10px; }
  .login-wrap .pass-error {
    font-size: 80%;
    color: #ff5252;
    margin-bottom: 40px;
    text-align: center; }

@font-face {
  font-family: 'Mark Pro';
  src: local("Mark Pro"), url(/static/media/MarkPro.12d6724a.otf) format("opentype"); }

@font-face {
  font-family: 'Mark Pro Medium';
  src: local("Mark Pro Medium"), url(/static/media/MarkProMedium.8531ae94.otf) format("opentype"); }

@font-face {
  font-family: 'Mark Pro Bold';
  src: local("Mark Pro Bold"), url(/static/media/MarkProBold.476d44b0.otf) format("opentype"); }

@font-face {
  font-family: 'Mark Pro Light';
  src: local("Mark Pro Light"), url(/static/media/MarkProLight.57a6370d.otf) format("opentype"); }

@font-face {
  font-family: 'Mark Pro Book';
  src: local("Mark Pro Book"), url(/static/media/MarkProBook.3b042cbc.otf) format("opentype"); }

/*!
 * Bootstrap v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #303f9f;
  --indigo: #6610f2;
  --purple: #ba68c8;
  --pink: #ff1493;
  --red: #ff5252;
  --orange: #ff6600;
  --yellow: #f8c13c;
  --green: #3be9ab;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #ffffff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #303f9f;
  --secondary: #6c757d;
  --success: #3be9ab;
  --info: #17a2b8;
  --warning: #f8c13c;
  --danger: #ff5252;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1400px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fbfdfd; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #303f9f;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #1e2864;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014   \A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fbfdfd;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #ff1493;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #ffffff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 100%; } }
  @media (min-width: 768px) {
    .container {
      max-width: 100%; } }
  @media (min-width: 992px) {
    .container {
      max-width: 1300px; } }
  @media (min-width: 1400px) {
    .container {
      max-width: 1300px; } }

.container-fluid {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px; }

.col {
  -webkit-flex-basis: 0;
          flex-basis: 0;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  max-width: 100%; }

.col-auto {
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  -webkit-flex: 0 0 8.33333%;
          flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  -webkit-flex: 0 0 16.66667%;
          flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  -webkit-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -webkit-flex: 0 0 33.33333%;
          flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  -webkit-flex: 0 0 41.66667%;
          flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  -webkit-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -webkit-flex: 0 0 58.33333%;
          flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  -webkit-flex: 0 0 66.66667%;
          flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  -webkit-flex: 0 0 75%;
          flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -webkit-flex: 0 0 83.33333%;
          flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  -webkit-flex: 0 0 91.66667%;
          flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  -webkit-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  -webkit-order: -1;
          order: -1; }

.order-last {
  -webkit-order: 13;
          order: 13; }

.order-0 {
  -webkit-order: 0;
          order: 0; }

.order-1 {
  -webkit-order: 1;
          order: 1; }

.order-2 {
  -webkit-order: 2;
          order: 2; }

.order-3 {
  -webkit-order: 3;
          order: 3; }

.order-4 {
  -webkit-order: 4;
          order: 4; }

.order-5 {
  -webkit-order: 5;
          order: 5; }

.order-6 {
  -webkit-order: 6;
          order: 6; }

.order-7 {
  -webkit-order: 7;
          order: 7; }

.order-8 {
  -webkit-order: 8;
          order: 8; }

.order-9 {
  -webkit-order: 9;
          order: 9; }

.order-10 {
  -webkit-order: 10;
          order: 10; }

.order-11 {
  -webkit-order: 11;
          order: 11; }

.order-12 {
  -webkit-order: 12;
          order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    -webkit-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    -webkit-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -webkit-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    -webkit-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -webkit-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    -webkit-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -webkit-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    -webkit-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    -webkit-order: -1;
            order: -1; }
  .order-sm-last {
    -webkit-order: 13;
            order: 13; }
  .order-sm-0 {
    -webkit-order: 0;
            order: 0; }
  .order-sm-1 {
    -webkit-order: 1;
            order: 1; }
  .order-sm-2 {
    -webkit-order: 2;
            order: 2; }
  .order-sm-3 {
    -webkit-order: 3;
            order: 3; }
  .order-sm-4 {
    -webkit-order: 4;
            order: 4; }
  .order-sm-5 {
    -webkit-order: 5;
            order: 5; }
  .order-sm-6 {
    -webkit-order: 6;
            order: 6; }
  .order-sm-7 {
    -webkit-order: 7;
            order: 7; }
  .order-sm-8 {
    -webkit-order: 8;
            order: 8; }
  .order-sm-9 {
    -webkit-order: 9;
            order: 9; }
  .order-sm-10 {
    -webkit-order: 10;
            order: 10; }
  .order-sm-11 {
    -webkit-order: 11;
            order: 11; }
  .order-sm-12 {
    -webkit-order: 12;
            order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    -webkit-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    -webkit-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -webkit-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    -webkit-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -webkit-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    -webkit-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -webkit-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    -webkit-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    -webkit-order: -1;
            order: -1; }
  .order-md-last {
    -webkit-order: 13;
            order: 13; }
  .order-md-0 {
    -webkit-order: 0;
            order: 0; }
  .order-md-1 {
    -webkit-order: 1;
            order: 1; }
  .order-md-2 {
    -webkit-order: 2;
            order: 2; }
  .order-md-3 {
    -webkit-order: 3;
            order: 3; }
  .order-md-4 {
    -webkit-order: 4;
            order: 4; }
  .order-md-5 {
    -webkit-order: 5;
            order: 5; }
  .order-md-6 {
    -webkit-order: 6;
            order: 6; }
  .order-md-7 {
    -webkit-order: 7;
            order: 7; }
  .order-md-8 {
    -webkit-order: 8;
            order: 8; }
  .order-md-9 {
    -webkit-order: 9;
            order: 9; }
  .order-md-10 {
    -webkit-order: 10;
            order: 10; }
  .order-md-11 {
    -webkit-order: 11;
            order: 11; }
  .order-md-12 {
    -webkit-order: 12;
            order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    -webkit-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    -webkit-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -webkit-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    -webkit-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -webkit-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    -webkit-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -webkit-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    -webkit-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    -webkit-order: -1;
            order: -1; }
  .order-lg-last {
    -webkit-order: 13;
            order: 13; }
  .order-lg-0 {
    -webkit-order: 0;
            order: 0; }
  .order-lg-1 {
    -webkit-order: 1;
            order: 1; }
  .order-lg-2 {
    -webkit-order: 2;
            order: 2; }
  .order-lg-3 {
    -webkit-order: 3;
            order: 3; }
  .order-lg-4 {
    -webkit-order: 4;
            order: 4; }
  .order-lg-5 {
    -webkit-order: 5;
            order: 5; }
  .order-lg-6 {
    -webkit-order: 6;
            order: 6; }
  .order-lg-7 {
    -webkit-order: 7;
            order: 7; }
  .order-lg-8 {
    -webkit-order: 8;
            order: 8; }
  .order-lg-9 {
    -webkit-order: 9;
            order: 9; }
  .order-lg-10 {
    -webkit-order: 10;
            order: 10; }
  .order-lg-11 {
    -webkit-order: 11;
            order: 11; }
  .order-lg-12 {
    -webkit-order: 12;
            order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1400px) {
  .col-xl {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    -webkit-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    -webkit-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -webkit-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    -webkit-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -webkit-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    -webkit-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -webkit-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    -webkit-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    -webkit-order: -1;
            order: -1; }
  .order-xl-last {
    -webkit-order: 13;
            order: 13; }
  .order-xl-0 {
    -webkit-order: 0;
            order: 0; }
  .order-xl-1 {
    -webkit-order: 1;
            order: 1; }
  .order-xl-2 {
    -webkit-order: 2;
            order: 2; }
  .order-xl-3 {
    -webkit-order: 3;
            order: 3; }
  .order-xl-4 {
    -webkit-order: 4;
            order: 4; }
  .order-xl-5 {
    -webkit-order: 5;
            order: 5; }
  .order-xl-6 {
    -webkit-order: 6;
            order: 6; }
  .order-xl-7 {
    -webkit-order: 7;
            order: 7; }
  .order-xl-8 {
    -webkit-order: 8;
            order: 8; }
  .order-xl-9 {
    -webkit-order: 9;
            order: 9; }
  .order-xl-10 {
    -webkit-order: 10;
            order: 10; }
  .order-xl-11 {
    -webkit-order: 11;
            order: 11; }
  .order-xl-12 {
    -webkit-order: 12;
            order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }
  .table .table {
    background-color: #fbfdfd; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c5c9e4; }

.table-hover .table-primary:hover {
  background-color: #b4b9dc; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #b4b9dc; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c8f9e7; }

.table-hover .table-success:hover {
  background-color: #b1f6dd; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1f6dd; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fdeec8; }

.table-hover .table-warning:hover {
  background-color: #fce6af; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #fce6af; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #ffcfcf; }

.table-hover .table-danger:hover {
  background-color: #ffb6b6; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #ffb6b6; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fbfdfd;
  background-color: #212529;
  border-color: #32383e; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fbfdfd;
  background-color: #212529; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #32383e; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1399.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #ffffff;
    border-color: #7885d6;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(48, 63, 159, 0.25); }
  .form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:-ms-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::-ms-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #ffffff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #3be9ab; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #212529;
  background-color: rgba(59, 233, 171, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #3be9ab; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #3be9ab;
    box-shadow: 0 0 0 0.2rem rgba(59, 233, 171, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #3be9ab; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #3be9ab; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #aef6dc; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #69eebf; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fbfdfd, 0 0 0 0.2rem rgba(59, 233, 171, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #3be9ab; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::after, .custom-file-input.is-valid ~ .custom-file-label::after {
    border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(59, 233, 171, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #ff5252; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgba(255, 82, 82, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #ff5252; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #ff5252;
    box-shadow: 0 0 0 0.2rem rgba(255, 82, 82, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #ff5252; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #ff5252; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #ffd2d2; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #ff8585; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fbfdfd, 0 0 0 0.2rem rgba(255, 82, 82, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #ff5252; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::after, .custom-file-input.is-invalid ~ .custom-file-label::after {
    border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(255, 82, 82, 0.25); }

.form-inline {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-align-items: center;
          align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: -webkit-flex;
      display: flex;
      -webkit-flex: 0 0 auto;
              flex: 0 0 auto;
      -webkit-flex-flow: row wrap;
              flex-flow: row wrap;
      -webkit-align-items: center;
              align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover, .btn:focus {
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(48, 63, 159, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #ffffff;
  background-color: #303f9f;
  border-color: #303f9f; }
  .btn-primary:hover {
    color: #ffffff;
    background-color: #273382;
    border-color: #242f78; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(48, 63, 159, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #ffffff;
    background-color: #303f9f;
    border-color: #303f9f; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #ffffff;
    background-color: #242f78;
    border-color: #212c6e; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(48, 63, 159, 0.5); }

.btn-secondary {
  color: #ffffff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #ffffff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #ffffff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #ffffff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-success {
  color: #212529;
  background-color: #3be9ab;
  border-color: #3be9ab; }
  .btn-success:hover {
    color: #212529;
    background-color: #1ae49c;
    border-color: #18d994; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(59, 233, 171, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #212529;
    background-color: #3be9ab;
    border-color: #3be9ab; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #212529;
    background-color: #18d994;
    border-color: #17cd8c; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(59, 233, 171, 0.5); }

.btn-info {
  color: #ffffff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #ffffff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #ffffff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #ffffff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #f8c13c;
  border-color: #f8c13c; }
  .btn-warning:hover {
    color: #212529;
    background-color: #f7b517;
    border-color: #f6b10b; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 193, 60, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #f8c13c;
    border-color: #f8c13c; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #f6b10b;
    border-color: #eca908; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 193, 60, 0.5); }

.btn-danger {
  color: #ffffff;
  background-color: #ff5252;
  border-color: #ff5252; }
  .btn-danger:hover {
    color: #ffffff;
    background-color: #ff2c2c;
    border-color: #ff1f1f; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 82, 82, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #ffffff;
    background-color: #ff5252;
    border-color: #ff5252; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #ffffff;
    background-color: #ff1f1f;
    border-color: #ff1212; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 82, 82, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-dark {
  color: #ffffff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #ffffff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #ffffff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #ffffff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-primary {
  color: #303f9f;
  background-color: transparent;
  background-image: none;
  border-color: #303f9f; }
  .btn-outline-primary:hover {
    color: #ffffff;
    background-color: #303f9f;
    border-color: #303f9f; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(48, 63, 159, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #303f9f;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #ffffff;
    background-color: #303f9f;
    border-color: #303f9f; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(48, 63, 159, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #ffffff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #ffffff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #3be9ab;
  background-color: transparent;
  background-image: none;
  border-color: #3be9ab; }
  .btn-outline-success:hover {
    color: #212529;
    background-color: #3be9ab;
    border-color: #3be9ab; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(59, 233, 171, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #3be9ab;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #212529;
    background-color: #3be9ab;
    border-color: #3be9ab; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(59, 233, 171, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #ffffff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #ffffff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #f8c13c;
  background-color: transparent;
  background-image: none;
  border-color: #f8c13c; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #f8c13c;
    border-color: #f8c13c; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 193, 60, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #f8c13c;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #f8c13c;
    border-color: #f8c13c; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 193, 60, 0.5); }

.btn-outline-danger {
  color: #ff5252;
  background-color: transparent;
  background-image: none;
  border-color: #ff5252; }
  .btn-outline-danger:hover {
    color: #ffffff;
    background-color: #ff5252;
    border-color: #ff5252; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 82, 82, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #ff5252;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #ffffff;
    background-color: #ff5252;
    border-color: #ff5252; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 82, 82, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #ffffff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #ffffff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #303f9f;
  background-color: transparent; }
  .btn-link:hover {
    color: #1e2864;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    border-color: transparent;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media screen and (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #ffffff;
    text-decoration: none;
    background-color: #303f9f; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -webkit-inline-flex;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    -webkit-flex: 0 1 auto;
            flex: 0 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }
  .btn-group .btn + .btn,
  .btn-group .btn + .btn-group,
  .btn-group .btn-group + .btn,
  .btn-group .btn-group + .btn-group,
  .btn-group-vertical .btn + .btn,
  .btn-group-vertical .btn + .btn-group,
  .btn-group-vertical .btn-group + .btn,
  .btn-group-vertical .btn-group + .btn-group {
    margin-left: -1px; }

.btn-toolbar {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: flex-start;
          justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:first-child {
  margin-left: 0; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: center;
          justify-content: center; }
  .btn-group-vertical .btn,
  .btn-group-vertical .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn + .btn,
  .btn-group-vertical > .btn + .btn-group,
  .btn-group-vertical > .btn-group + .btn,
  .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: stretch;
          align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: -webkit-flex;
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: -webkit-inline-flex;
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #ffffff;
    background-color: #303f9f; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fbfdfd, 0 0 0 0.2rem rgba(48, 63, 159, 0.25); }
  .custom-control-input:active ~ .custom-control-label::before {
    color: #ffffff;
    background-color: #9fa8e2; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    background-color: #dee2e6; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #303f9f; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23ffffff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #303f9f; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23ffffff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(48, 63, 159, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(48, 63, 159, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #303f9f; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23ffffff'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(48, 63, 159, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #ffffff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .custom-select:focus {
    border-color: #7885d6;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(120, 133, 214, 0.5); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #ffffff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    opacity: 0; }

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%; }

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #7885d6;
    box-shadow: 0 0 0 0.2rem rgba(48, 63, 159, 0.25); }
    .custom-file-input:focus ~ .custom-file-label::after {
      border-color: #7885d6; }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: 2.25rem;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: 1px solid #ced4da;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  padding-left: 0;
  background-color: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fbfdfd, 0 0 0 0.2rem rgba(48, 63, 159, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fbfdfd, 0 0 0 0.2rem rgba(48, 63, 159, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fbfdfd, 0 0 0 0.2rem rgba(48, 63, 159, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #303f9f;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
            appearance: none; }
    @media screen and (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #9fa8e2; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #303f9f;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -moz-appearance: none;
         appearance: none; }
    @media screen and (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #9fa8e2; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #303f9f;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media screen and (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #9fa8e2; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fbfdfd;
    border-color: #dee2e6 #dee2e6 #fbfdfd; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff;
  background-color: #303f9f; }

.nav-fill .nav-item {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  -webkit-flex-basis: 0;
          flex-basis: 0;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-align-items: center;
          align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }
  .navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: flex-start;
            justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      -webkit-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      -webkit-flex-wrap: nowrap;
              flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: -webkit-flex !important;
      display: flex !important;
      -webkit-flex-basis: auto;
              flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: flex-start;
            justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      -webkit-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      -webkit-flex-wrap: nowrap;
              flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: -webkit-flex !important;
      display: flex !important;
      -webkit-flex-basis: auto;
              flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: flex-start;
            justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      -webkit-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      -webkit-flex-wrap: nowrap;
              flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: -webkit-flex !important;
      display: flex !important;
      -webkit-flex-basis: auto;
              flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1399.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1400px) {
  .navbar-expand-xl {
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: flex-start;
            justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      -webkit-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      -webkit-flex-wrap: nowrap;
              flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: -webkit-flex !important;
      display: flex !important;
      -webkit-flex-basis: auto;
              flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-justify-content: flex-start;
          justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    -webkit-flex-direction: row;
            flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-flex-basis: auto;
            flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #ffffff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #ffffff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #ffffff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #ffffff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #ffffff; }

.card {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .card-deck .card {
    margin-bottom: 10px; }
  @media (min-width: 576px) {
    .card-deck {
      -webkit-flex-flow: row wrap;
              flex-flow: row wrap;
      margin-right: -10px;
      margin-left: -10px; }
      .card-deck .card {
        display: -webkit-flex;
        display: flex;
        -webkit-flex: 1 0;
                flex: 1 0;
        -webkit-flex-direction: column;
                flex-direction: column;
        margin-right: 10px;
        margin-bottom: 0;
        margin-left: 10px; } }

.card-group {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .card-group > .card {
    margin-bottom: 10px; }
  @media (min-width: 576px) {
    .card-group {
      -webkit-flex-flow: row wrap;
              flex-flow: row wrap; }
      .card-group > .card {
        -webkit-flex: 1 0;
                flex: 1 0;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:first-child .card-img-top,
          .card-group > .card:first-child .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:first-child .card-img-bottom,
          .card-group > .card:first-child .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:last-child .card-img-top,
          .card-group > .card:last-child .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:last-child .card-img-bottom,
          .card-group > .card:last-child .card-footer {
            border-bottom-left-radius: 0; }
        .card-group > .card:only-child {
          border-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-top,
          .card-group > .card:only-child .card-header {
            border-top-left-radius: 0.25rem;
            border-top-right-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-bottom,
          .card-group > .card:only-child .card-footer {
            border-bottom-right-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem; }
        .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
          border-radius: 0; }
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
            border-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
            column-count: 3;
    grid-column-gap: 1.25rem;
    -webkit-column-gap: 1.25rem;
            column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0; }

.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0; }

.accordion .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.accordion .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.breadcrumb {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: -webkit-flex;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #303f9f;
  background-color: #ffffff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #1e2864;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(48, 63, 159, 0.25); }
  .page-link:not(:disabled):not(.disabled) {
    cursor: pointer; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #ffffff;
  background-color: #303f9f;
  border-color: #303f9f; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #ffffff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #ffffff;
  background-color: #303f9f; }
  .badge-primary[href]:hover, .badge-primary[href]:focus {
    color: #ffffff;
    text-decoration: none;
    background-color: #242f78; }

.badge-secondary {
  color: #ffffff;
  background-color: #6c757d; }
  .badge-secondary[href]:hover, .badge-secondary[href]:focus {
    color: #ffffff;
    text-decoration: none;
    background-color: #545b62; }

.badge-success {
  color: #212529;
  background-color: #3be9ab; }
  .badge-success[href]:hover, .badge-success[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #18d994; }

.badge-info {
  color: #ffffff;
  background-color: #17a2b8; }
  .badge-info[href]:hover, .badge-info[href]:focus {
    color: #ffffff;
    text-decoration: none;
    background-color: #117a8b; }

.badge-warning {
  color: #212529;
  background-color: #f8c13c; }
  .badge-warning[href]:hover, .badge-warning[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #f6b10b; }

.badge-danger {
  color: #ffffff;
  background-color: #ff5252; }
  .badge-danger[href]:hover, .badge-danger[href]:focus {
    color: #ffffff;
    text-decoration: none;
    background-color: #ff1f1f; }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  .badge-light[href]:hover, .badge-light[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #dae0e5; }

.badge-dark {
  color: #ffffff;
  background-color: #343a40; }
  .badge-dark[href]:hover, .badge-dark[href]:focus {
    color: #ffffff;
    text-decoration: none;
    background-color: #1d2124; }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #192153;
  background-color: #d6d9ec;
  border-color: #c5c9e4; }
  .alert-primary hr {
    border-top-color: #b4b9dc; }
  .alert-primary .alert-link {
    color: #0d112c; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #1f7959;
  background-color: #d8fbee;
  border-color: #c8f9e7; }
  .alert-success hr {
    border-top-color: #b1f6dd; }
  .alert-success .alert-link {
    color: #15503b; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #81641f;
  background-color: #fef3d8;
  border-color: #fdeec8; }
  .alert-warning hr {
    border-top-color: #fce6af; }
  .alert-warning .alert-link {
    color: #584415; }

.alert-danger {
  color: #852b2b;
  background-color: #ffdcdc;
  border-color: #ffcfcf; }
  .alert-danger hr {
    border-top-color: #ffb6b6; }
  .alert-danger .alert-link {
    color: #5e1f1f; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: -webkit-flex;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  background-color: #303f9f;
  transition: width 0.6s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
          animation: progress-bar-stripes 1s linear infinite; }

.media {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start; }

.media-body {
  -webkit-flex: 1 1;
          flex: 1 1; }

.list-group {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item:hover, .list-group-item:focus {
    z-index: 1;
    text-decoration: none; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    background-color: #ffffff; }
  .list-group-item.active {
    z-index: 2;
    color: #ffffff;
    background-color: #303f9f;
    border-color: #303f9f; }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0; }

.list-group-item-primary {
  color: #192153;
  background-color: #c5c9e4; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #192153;
    background-color: #b4b9dc; }
  .list-group-item-primary.list-group-item-action.active {
    color: #ffffff;
    background-color: #192153;
    border-color: #192153; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #ffffff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #1f7959;
  background-color: #c8f9e7; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #1f7959;
    background-color: #b1f6dd; }
  .list-group-item-success.list-group-item-action.active {
    color: #ffffff;
    background-color: #1f7959;
    border-color: #1f7959; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #ffffff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #81641f;
  background-color: #fdeec8; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #81641f;
    background-color: #fce6af; }
  .list-group-item-warning.list-group-item-action.active {
    color: #ffffff;
    background-color: #81641f;
    border-color: #81641f; }

.list-group-item-danger {
  color: #852b2b;
  background-color: #ffcfcf; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #852b2b;
    background-color: #ffb6b6; }
  .list-group-item-danger.list-group-item-action.active {
    color: #ffffff;
    background-color: #852b2b;
    border-color: #852b2b; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #ffffff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #ffffff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #ffffff;
  opacity: .5; }
  .close:not(:disabled):not(.disabled) {
    cursor: pointer; }
    .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
      color: #000;
      text-decoration: none;
      opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -25%);
            transform: translate(0, -25%); }
    @media screen and (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }

.modal-dialog-centered {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  min-height: calc(100% - (0.5rem * 2)); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - (0.5rem * 2));
    content: ""; }

.modal-content {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }
    .modal-dialog-centered::before {
      height: calc(100vh - (1.75rem * 2)); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #ffffff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    border-width: 0.5rem 0.5rem 0; }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
    bottom: 0;
    border-top-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 1px;
    border-top-color: #ffffff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    border-width: 0.5rem 0.5rem 0.5rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
    left: 0;
    border-right-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    left: 1px;
    border-right-color: #ffffff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
    top: calc((0.5rem + 1px) * -1); }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-width: 0 0.5rem 0.5rem 0.5rem; }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
    top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 1px;
    border-bottom-color: #ffffff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    border-width: 0.5rem 0 0.5rem 0.5rem; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
    right: 0;
    border-left-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    right: 1px;
    border-left-color: #ffffff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-item {
  position: relative;
  display: none;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-perspective: 1000px;
          perspective: 1000px; }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
  transition: -webkit-transform 0.6s ease;
  transition: transform 0.6s ease;
  transition: transform 0.6s ease, -webkit-transform 0.6s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .carousel-item.active,
    .carousel-item-next,
    .carousel-item-prev {
      transition: none; } }

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0; }

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  -webkit-transform: translateX(0);
          transform: translateX(0); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0); } }

.carousel-item-next,
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next,
    .active.carousel-item-right {
      -webkit-transform: translate3d(100%, 0, 0);
              transform: translate3d(100%, 0, 0); } }

.carousel-item-prev,
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-prev,
    .active.carousel-item-left {
      -webkit-transform: translate3d(-100%, 0, 0);
              transform: translate3d(-100%, 0, 0); } }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-duration: .6s;
  transition-property: opacity; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  opacity: 0; }

.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
  -webkit-transform: translateX(0);
          transform: translateX(0); }
  @supports (transform-style: preserve-3d) {
    .carousel-fade .carousel-item-next,
    .carousel-fade .carousel-item-prev,
    .carousel-fade .carousel-item.active,
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-prev {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0); } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 15%;
  color: #ffffff;
  text-align: center;
  opacity: 0.5; }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #ffffff;
    text-decoration: none;
    outline: 0;
    opacity: .9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    position: relative;
    -webkit-flex: 0 1 auto;
            flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.5); }
    .carousel-indicators li::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
    .carousel-indicators li::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
  .carousel-indicators .active {
    background-color: #ffffff; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #ffffff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #303f9f !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #242f78 !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #3be9ab !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #18d994 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #f8c13c !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #f6b10b !important; }

.bg-danger {
  background-color: #ff5252 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ff1f1f !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #ffffff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #303f9f !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #3be9ab !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #f8c13c !important; }

.border-danger {
  border-color: #ff5252 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #ffffff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: -webkit-flex !important;
  display: flex !important; }

.d-inline-flex {
  display: -webkit-inline-flex !important;
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: -webkit-flex !important;
    display: flex !important; }
  .d-sm-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: -webkit-flex !important;
    display: flex !important; }
  .d-md-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: -webkit-flex !important;
    display: flex !important; }
  .d-lg-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important; } }

@media (min-width: 1400px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: -webkit-flex !important;
    display: flex !important; }
  .d-xl-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: -webkit-flex !important;
    display: flex !important; }
  .d-print-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  -webkit-flex-direction: row !important;
          flex-direction: row !important; }

.flex-column {
  -webkit-flex-direction: column !important;
          flex-direction: column !important; }

.flex-row-reverse {
  -webkit-flex-direction: row-reverse !important;
          flex-direction: row-reverse !important; }

.flex-column-reverse {
  -webkit-flex-direction: column-reverse !important;
          flex-direction: column-reverse !important; }

.flex-wrap {
  -webkit-flex-wrap: wrap !important;
          flex-wrap: wrap !important; }

.flex-nowrap {
  -webkit-flex-wrap: nowrap !important;
          flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  -webkit-flex-wrap: wrap-reverse !important;
          flex-wrap: wrap-reverse !important; }

.flex-fill {
  -webkit-flex: 1 1 auto !important;
          flex: 1 1 auto !important; }

.flex-grow-0 {
  -webkit-flex-grow: 0 !important;
          flex-grow: 0 !important; }

.flex-grow-1 {
  -webkit-flex-grow: 1 !important;
          flex-grow: 1 !important; }

.flex-shrink-0 {
  -webkit-flex-shrink: 0 !important;
          flex-shrink: 0 !important; }

.flex-shrink-1 {
  -webkit-flex-shrink: 1 !important;
          flex-shrink: 1 !important; }

.justify-content-start {
  -webkit-justify-content: flex-start !important;
          justify-content: flex-start !important; }

.justify-content-end {
  -webkit-justify-content: flex-end !important;
          justify-content: flex-end !important; }

.justify-content-center {
  -webkit-justify-content: center !important;
          justify-content: center !important; }

.justify-content-between {
  -webkit-justify-content: space-between !important;
          justify-content: space-between !important; }

.justify-content-around {
  -webkit-justify-content: space-around !important;
          justify-content: space-around !important; }

.align-items-start {
  -webkit-align-items: flex-start !important;
          align-items: flex-start !important; }

.align-items-end {
  -webkit-align-items: flex-end !important;
          align-items: flex-end !important; }

.align-items-center {
  -webkit-align-items: center !important;
          align-items: center !important; }

.align-items-baseline {
  -webkit-align-items: baseline !important;
          align-items: baseline !important; }

.align-items-stretch {
  -webkit-align-items: stretch !important;
          align-items: stretch !important; }

.align-content-start {
  -webkit-align-content: flex-start !important;
          align-content: flex-start !important; }

.align-content-end {
  -webkit-align-content: flex-end !important;
          align-content: flex-end !important; }

.align-content-center {
  -webkit-align-content: center !important;
          align-content: center !important; }

.align-content-between {
  -webkit-align-content: space-between !important;
          align-content: space-between !important; }

.align-content-around {
  -webkit-align-content: space-around !important;
          align-content: space-around !important; }

.align-content-stretch {
  -webkit-align-content: stretch !important;
          align-content: stretch !important; }

.align-self-auto {
  -webkit-align-self: auto !important;
          align-self: auto !important; }

.align-self-start {
  -webkit-align-self: flex-start !important;
          align-self: flex-start !important; }

.align-self-end {
  -webkit-align-self: flex-end !important;
          align-self: flex-end !important; }

.align-self-center {
  -webkit-align-self: center !important;
          align-self: center !important; }

.align-self-baseline {
  -webkit-align-self: baseline !important;
          align-self: baseline !important; }

.align-self-stretch {
  -webkit-align-self: stretch !important;
          align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-sm-column {
    -webkit-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-sm-row-reverse {
    -webkit-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    -webkit-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    -webkit-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    -webkit-flex-grow: 0 !important;
            flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    -webkit-flex-grow: 1 !important;
            flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    -webkit-flex-shrink: 0 !important;
            flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    -webkit-flex-shrink: 1 !important;
            flex-shrink: 1 !important; }
  .justify-content-sm-start {
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important; }
  .justify-content-sm-end {
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important; }
  .justify-content-sm-center {
    -webkit-justify-content: center !important;
            justify-content: center !important; }
  .justify-content-sm-between {
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important; }
  .justify-content-sm-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important; }
  .align-items-sm-start {
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important; }
  .align-items-sm-end {
    -webkit-align-items: flex-end !important;
            align-items: flex-end !important; }
  .align-items-sm-center {
    -webkit-align-items: center !important;
            align-items: center !important; }
  .align-items-sm-baseline {
    -webkit-align-items: baseline !important;
            align-items: baseline !important; }
  .align-items-sm-stretch {
    -webkit-align-items: stretch !important;
            align-items: stretch !important; }
  .align-content-sm-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important; }
  .align-content-sm-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important; }
  .align-content-sm-center {
    -webkit-align-content: center !important;
            align-content: center !important; }
  .align-content-sm-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important; }
  .align-content-sm-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important; }
  .align-content-sm-stretch {
    -webkit-align-content: stretch !important;
            align-content: stretch !important; }
  .align-self-sm-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important; }
  .align-self-sm-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important; }
  .align-self-sm-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important; }
  .align-self-sm-center {
    -webkit-align-self: center !important;
            align-self: center !important; }
  .align-self-sm-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important; }
  .align-self-sm-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    -webkit-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-md-column {
    -webkit-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-md-row-reverse {
    -webkit-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    -webkit-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-md-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important; }
  .flex-md-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    -webkit-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    -webkit-flex-grow: 0 !important;
            flex-grow: 0 !important; }
  .flex-md-grow-1 {
    -webkit-flex-grow: 1 !important;
            flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    -webkit-flex-shrink: 0 !important;
            flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    -webkit-flex-shrink: 1 !important;
            flex-shrink: 1 !important; }
  .justify-content-md-start {
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important; }
  .justify-content-md-end {
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important; }
  .justify-content-md-center {
    -webkit-justify-content: center !important;
            justify-content: center !important; }
  .justify-content-md-between {
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important; }
  .justify-content-md-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important; }
  .align-items-md-start {
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important; }
  .align-items-md-end {
    -webkit-align-items: flex-end !important;
            align-items: flex-end !important; }
  .align-items-md-center {
    -webkit-align-items: center !important;
            align-items: center !important; }
  .align-items-md-baseline {
    -webkit-align-items: baseline !important;
            align-items: baseline !important; }
  .align-items-md-stretch {
    -webkit-align-items: stretch !important;
            align-items: stretch !important; }
  .align-content-md-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important; }
  .align-content-md-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important; }
  .align-content-md-center {
    -webkit-align-content: center !important;
            align-content: center !important; }
  .align-content-md-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important; }
  .align-content-md-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important; }
  .align-content-md-stretch {
    -webkit-align-content: stretch !important;
            align-content: stretch !important; }
  .align-self-md-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important; }
  .align-self-md-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important; }
  .align-self-md-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important; }
  .align-self-md-center {
    -webkit-align-self: center !important;
            align-self: center !important; }
  .align-self-md-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important; }
  .align-self-md-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-lg-column {
    -webkit-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-lg-row-reverse {
    -webkit-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    -webkit-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    -webkit-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    -webkit-flex-grow: 0 !important;
            flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    -webkit-flex-grow: 1 !important;
            flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    -webkit-flex-shrink: 0 !important;
            flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    -webkit-flex-shrink: 1 !important;
            flex-shrink: 1 !important; }
  .justify-content-lg-start {
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important; }
  .justify-content-lg-end {
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important; }
  .justify-content-lg-center {
    -webkit-justify-content: center !important;
            justify-content: center !important; }
  .justify-content-lg-between {
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important; }
  .justify-content-lg-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important; }
  .align-items-lg-start {
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important; }
  .align-items-lg-end {
    -webkit-align-items: flex-end !important;
            align-items: flex-end !important; }
  .align-items-lg-center {
    -webkit-align-items: center !important;
            align-items: center !important; }
  .align-items-lg-baseline {
    -webkit-align-items: baseline !important;
            align-items: baseline !important; }
  .align-items-lg-stretch {
    -webkit-align-items: stretch !important;
            align-items: stretch !important; }
  .align-content-lg-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important; }
  .align-content-lg-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important; }
  .align-content-lg-center {
    -webkit-align-content: center !important;
            align-content: center !important; }
  .align-content-lg-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important; }
  .align-content-lg-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important; }
  .align-content-lg-stretch {
    -webkit-align-content: stretch !important;
            align-content: stretch !important; }
  .align-self-lg-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important; }
  .align-self-lg-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important; }
  .align-self-lg-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important; }
  .align-self-lg-center {
    -webkit-align-self: center !important;
            align-self: center !important; }
  .align-self-lg-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important; }
  .align-self-lg-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important; } }

@media (min-width: 1400px) {
  .flex-xl-row {
    -webkit-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-xl-column {
    -webkit-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-xl-row-reverse {
    -webkit-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    -webkit-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    -webkit-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    -webkit-flex-grow: 0 !important;
            flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    -webkit-flex-grow: 1 !important;
            flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    -webkit-flex-shrink: 0 !important;
            flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    -webkit-flex-shrink: 1 !important;
            flex-shrink: 1 !important; }
  .justify-content-xl-start {
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important; }
  .justify-content-xl-end {
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important; }
  .justify-content-xl-center {
    -webkit-justify-content: center !important;
            justify-content: center !important; }
  .justify-content-xl-between {
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important; }
  .justify-content-xl-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important; }
  .align-items-xl-start {
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important; }
  .align-items-xl-end {
    -webkit-align-items: flex-end !important;
            align-items: flex-end !important; }
  .align-items-xl-center {
    -webkit-align-items: center !important;
            align-items: center !important; }
  .align-items-xl-baseline {
    -webkit-align-items: baseline !important;
            align-items: baseline !important; }
  .align-items-xl-stretch {
    -webkit-align-items: stretch !important;
            align-items: stretch !important; }
  .align-content-xl-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important; }
  .align-content-xl-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important; }
  .align-content-xl-center {
    -webkit-align-content: center !important;
            align-content: center !important; }
  .align-content-xl-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important; }
  .align-content-xl-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important; }
  .align-content-xl-stretch {
    -webkit-align-content: stretch !important;
            align-content: stretch !important; }
  .align-self-xl-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important; }
  .align-self-xl-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important; }
  .align-self-xl-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important; }
  .align-self-xl-center {
    -webkit-align-self: center !important;
            align-self: center !important; }
  .align-self-xl-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important; }
  .align-self-xl-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1400px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1400px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1400px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #ffffff !important; }

.text-primary {
  color: #303f9f !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #242f78 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #545b62 !important; }

.text-success {
  color: #3be9ab !important; }

a.text-success:hover, a.text-success:focus {
  color: #18d994 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important; }

.text-warning {
  color: #f8c13c !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #f6b10b !important; }

.text-danger {
  color: #ff5252 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #ff1f1f !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #ffffff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

.away-daterange {
  max-width: 664px;
  width: auto;
  padding: 0;
  border: none;
  border-radius: 5px;
  box-shadow: 0 2px 20px 0 rgba(45, 47, 68, 0.12); }
  .away-daterange .popover-body {
    padding: 0;
    padding-bottom: 23px; }
  .away-daterange .away-daterange-content {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }
    .away-daterange .away-daterange-content .rdrDefinedRangesWrapper {
      display: none; }
    .away-daterange .away-daterange-content .rdrDateRangeWrapper {
      margin-bottom: 25px;
      -webkit-flex: 1 1;
              flex: 1 1; }
      .away-daterange .away-daterange-content .rdrDateRangeWrapper .rdrDateDisplayWrapper {
        border-bottom: 1px solid #e2e6ed; }
        .away-daterange .away-daterange-content .rdrDateRangeWrapper .rdrDateDisplayWrapper .rdrDateDisplay {
          max-width: 300px;
          background-color: #ffffff; }
          .away-daterange .away-daterange-content .rdrDateRangeWrapper .rdrDateDisplayWrapper .rdrDateDisplay .rdrDateDisplayItem {
            box-shadow: none;
            border: none;
            border-radius: 1px;
            background-color: #f6f8fc;
            color: #99a2b3;
            font-size: 16px;
            padding: 5px;
            text-align: left; }
            .away-daterange .away-daterange-content .rdrDateRangeWrapper .rdrDateDisplayWrapper .rdrDateDisplay .rdrDateDisplayItem input {
              line-height: 18px;
              height: 18px; }
            .away-daterange .away-daterange-content .rdrDateRangeWrapper .rdrDateDisplayWrapper .rdrDateDisplay .rdrDateDisplayItem.rdrDateDisplayItemActive {
              border: none; }
              .away-daterange .away-daterange-content .rdrDateRangeWrapper .rdrDateDisplayWrapper .rdrDateDisplay .rdrDateDisplayItem.rdrDateDisplayItemActive input {
                line-height: 18px;
                height: 18px; }
      .away-daterange .away-daterange-content .rdrDateRangeWrapper .rdrMonthAndYearWrapper {
        padding-top: 0;
        padding-left: 30px;
        padding-right: 30px;
        position: absolute;
        width: 100%;
        left: 0;
        top: 54px;
        z-index: 2; }
        .away-daterange .away-daterange-content .rdrDateRangeWrapper .rdrMonthAndYearWrapper .rdrMonthAndYearPickers {
          display: none; }
        .away-daterange .away-daterange-content .rdrDateRangeWrapper .rdrMonthAndYearWrapper .rdrNextPrevButton {
          display: block;
          width: 24px;
          height: 24px;
          margin: 0 0.833em;
          margin-top: -5px;
          padding: 0;
          border: 0;
          border-radius: 0;
          background: #ffffff;
          outline: none; }
          .away-daterange .away-daterange-content .rdrDateRangeWrapper .rdrMonthAndYearWrapper .rdrNextPrevButton:focus, .away-daterange .away-daterange-content .rdrDateRangeWrapper .rdrMonthAndYearWrapper .rdrNextPrevButton:hover {
            outline: none; }
          .away-daterange .away-daterange-content .rdrDateRangeWrapper .rdrMonthAndYearWrapper .rdrNextPrevButton i {
            border: none;
            width: 0;
            height: 0; }
          .away-daterange .away-daterange-content .rdrDateRangeWrapper .rdrMonthAndYearWrapper .rdrNextPrevButton.rdrPprevButton::after, .away-daterange .away-daterange-content .rdrDateRangeWrapper .rdrMonthAndYearWrapper .rdrNextPrevButton.rdrNextButton::after {
            font-size: 20px;
            color: #ff8a5d; }
          .away-daterange .away-daterange-content .rdrDateRangeWrapper .rdrMonthAndYearWrapper .rdrNextPrevButton.rdrPprevButton::after {
            content: '<'; }
          .away-daterange .away-daterange-content .rdrDateRangeWrapper .rdrMonthAndYearWrapper .rdrNextPrevButton.rdrNextButton::after {
            content: '>'; }
      .away-daterange .away-daterange-content .rdrDateRangeWrapper .rdrMonths {
        z-index: 1; }
        .away-daterange .away-daterange-content .rdrDateRangeWrapper .rdrMonths .rdrMonth:first-of-type {
          padding-left: 40px; }
        .away-daterange .away-daterange-content .rdrDateRangeWrapper .rdrMonths .rdrMonth:last-of-type {
          padding-right: 40px; }
        .away-daterange .away-daterange-content .rdrDateRangeWrapper .rdrMonths .rdrMonth .rdrMonthName {
          font-size: 21px;
          font-weight: 500;
          text-align: center;
          color: #2d2f44; }
      .away-daterange .away-daterange-content .rdrDateRangeWrapper .rdrDayEndOfMonth .rdrInRange,
      .away-daterange .away-daterange-content .rdrDateRangeWrapper .rdrDayEndOfMonth .rdrStartEdge,
      .away-daterange .away-daterange-content .rdrDateRangeWrapper .rdrDayEndOfWeek .rdrInRange,
      .away-daterange .away-daterange-content .rdrDateRangeWrapper .rdrDayEndOfWeek .rdrStartEdge {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
      .away-daterange .away-daterange-content .rdrDateRangeWrapper .rdrDayStartOfMonth .rdrInRange,
      .away-daterange .away-daterange-content .rdrDateRangeWrapper .rdrDayStartOfMonth .rdrEndEdge,
      .away-daterange .away-daterange-content .rdrDateRangeWrapper .rdrDayStartOfWeek .rdrInRange,
      .away-daterange .away-daterange-content .rdrDateRangeWrapper .rdrDayStartOfWeek .rdrEndEdge {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
      .away-daterange .away-daterange-content .rdrDateRangeWrapper .rdrDayToday .rdrDayNumber span:after {
        content: '';
        position: absolute;
        bottom: 4px;
        left: 50%;
        -webkit-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
        width: 18px;
        height: 2px;
        border-radius: 2px;
        background: #ff8a5d; }
    .away-daterange .away-daterange-content .rdrCalendarWrapper .rdrDayToday .rdrDayNumber span:after {
      content: '';
      position: absolute;
      bottom: 4px;
      left: 50%;
      -webkit-transform: translate(-50%, 0);
      transform: translate(-50%, 0);
      width: 18px;
      height: 2px;
      border-radius: 2px;
      background: #ff8a5d; }
    .away-daterange .away-daterange-content .rdrCalendarWrapper .rdrMonthAndYearWrapper {
      z-index: 2; }
      .away-daterange .away-daterange-content .rdrCalendarWrapper .rdrMonthAndYearWrapper .rdrMonthAndYearPickers {
        font-size: 16px; }
      .away-daterange .away-daterange-content .rdrCalendarWrapper .rdrMonthAndYearWrapper .rdrNextPrevButton {
        display: block;
        width: 24px;
        height: 24px;
        margin: 0 0.833em;
        margin-top: -5px;
        padding: 0;
        border: 0;
        border-radius: 0;
        background: #ffffff;
        outline: none; }
        .away-daterange .away-daterange-content .rdrCalendarWrapper .rdrMonthAndYearWrapper .rdrNextPrevButton:focus, .away-daterange .away-daterange-content .rdrCalendarWrapper .rdrMonthAndYearWrapper .rdrNextPrevButton:hover {
          outline: none; }
        .away-daterange .away-daterange-content .rdrCalendarWrapper .rdrMonthAndYearWrapper .rdrNextPrevButton i {
          border: none;
          width: 0;
          height: 0; }
        .away-daterange .away-daterange-content .rdrCalendarWrapper .rdrMonthAndYearWrapper .rdrNextPrevButton.rdrPprevButton::after, .away-daterange .away-daterange-content .rdrCalendarWrapper .rdrMonthAndYearWrapper .rdrNextPrevButton.rdrNextButton::after {
          font-size: 20px;
          color: #ff8a5d; }
        .away-daterange .away-daterange-content .rdrCalendarWrapper .rdrMonthAndYearWrapper .rdrNextPrevButton.rdrPprevButton::after {
          content: '<'; }
        .away-daterange .away-daterange-content .rdrCalendarWrapper .rdrMonthAndYearWrapper .rdrNextPrevButton.rdrNextButton::after {
          content: '>'; }
    .away-daterange .away-daterange-content .btn-primary {
      -webkit-align-self: center;
              align-self: center; }

.away-colorpicker {
  max-width: 400px;
  width: 100%;
  padding: 20px;
  margin-top: -20px;
  border: none;
  border-radius: 5px;
  box-shadow: 0 2px 20px 0 rgba(45, 47, 68, 0.12); }
  .away-colorpicker .popover-body {
    padding: 0;
    padding-bottom: 23px; }
  .away-colorpicker .away-colorpicker-content {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }
    .away-colorpicker .away-colorpicker-content .slider-picker {
      margin-bottom: 38px;
      -webkit-flex: 1 1;
              flex: 1 1; }
    .away-colorpicker .away-colorpicker-content .btn-primary {
      -webkit-align-self: center;
              align-self: center; }
    .away-colorpicker .away-colorpicker-content .chrome-picker {
      width: auto !important;
      box-shadow: none !important;
      margin-bottom: 15px; }
      .away-colorpicker .away-colorpicker-content .chrome-picker div:last-of-type .flexbox-fix:last-of-type {
        display: none !important; }

.away-table-b .react-bs-table-container {
  margin-bottom: 40px; }
  .away-table-b .react-bs-table-container .react-bs-table {
    border-radius: 0 !important;
    background: #ffffff; }
    .away-table-b .react-bs-table-container .react-bs-table .at-row {
      font-size: 12px;
      font-family: "Mark Pro Book";
      font-weight: normal;
      overflow: unset;
      background-color: #ffffff; }
      .away-table-b .react-bs-table-container .react-bs-table .at-row:hover {
        cursor: pointer;
        background-color: #f1f3f7; }
        .away-table-b .react-bs-table-container .react-bs-table .at-row:hover .expand-icon .fa {
          color: #ff6600; }
    .away-table-b .react-bs-table-container .react-bs-table .user-logList-wrapper {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-justify-content: flex-end;
              justify-content: flex-end;
      margin-right: 20px; }
      .away-table-b .react-bs-table-container .react-bs-table .user-logList-wrapper .user-logList {
        font-size: 12px;
        font-family: "Mark Pro Book";
        font-weight: normal;
        margin-bottom: 0; }
    .away-table-b .react-bs-table-container .react-bs-table .at-header {
      background-color: #ffffff; }
      .away-table-b .react-bs-table-container .react-bs-table .at-header th {
        padding: 8px;
        background: #ffffff;
        position: relative;
        border-bottom-width: 1px; }
        .away-table-b .react-bs-table-container .react-bs-table .at-header th span.at-th, .away-table-b .react-bs-table-container .react-bs-table .at-header th span.at-th-label, .away-table-b .react-bs-table-container .react-bs-table .at-header th span.at-th-title {
          font-weight: normal;
          font-family: "Mark Pro Book";
          color: #99a2b3;
          font-size: 12px;
          display: block; }
        .away-table-b .react-bs-table-container .react-bs-table .at-header th .order {
          bottom: -18px;
          position: absolute;
          right: 3px; }
          .away-table-b .react-bs-table-container .react-bs-table .at-header th .order .caret {
            display: inline-block;
            width: 0;
            height: 0;
            margin-left: 0.255em;
            margin-right: 0 !important;
            vertical-align: 0.255em;
            border-right: 0.3em solid #ffffff;
            border-left: 0.3em solid #ffffff;
            border-top: 0.3em solid;
            border-bottom: 0;
            color: #99a2b3 !important;
            content: ''; }
        .away-table-b .react-bs-table-container .react-bs-table .at-header th .dropdown .caret,
        .away-table-b .react-bs-table-container .react-bs-table .at-header th .dropup .caret {
          display: inline-block;
          width: 0;
          height: 0;
          margin-left: 0.255em;
          vertical-align: 0.255em;
          border-right: 0.3em solid #ffffff;
          border-left: 0.3em solid #ffffff;
          color: #99a2b3 !important;
          content: ''; }
        .away-table-b .react-bs-table-container .react-bs-table .at-header th .dropdown .caret {
          border-top: 0.3em solid;
          border-bottom: 0; }
        .away-table-b .react-bs-table-container .react-bs-table .at-header th .dropup .caret {
          border-top: 0;
          border-bottom: 0.3em solid; }
    .away-table-b .react-bs-table-container .react-bs-table .at-th {
      font-weight: bold;
      font-size: 16px;
      word-wrap: break-word; }
      .away-table-b .react-bs-table-container .react-bs-table .at-th .at-th-label {
        font-weight: 400;
        font-size: 12px; }
      .away-table-b .react-bs-table-container .react-bs-table .at-th .at-th-title {
        text-transform: capitalize; }
    .away-table-b .react-bs-table-container .react-bs-table .at-name {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      color: #2d2f44; }
      .away-table-b .react-bs-table-container .react-bs-table .at-name a {
        color: #2d2f44; }
        .away-table-b .react-bs-table-container .react-bs-table .at-name a:hover {
          color: #ff6600; }
    .away-table-b .react-bs-table-container .react-bs-table .at-cap {
      text-transform: capitalize; }
    .away-table-b .react-bs-table-container .react-bs-table .delete-icon .fa,
    .away-table-b .react-bs-table-container .react-bs-table .expand-icon .fa {
      color: #a5adbc;
      font-family: FontAwesome !important; }
      .away-table-b .react-bs-table-container .react-bs-table .delete-icon .fa.fa-small,
      .away-table-b .react-bs-table-container .react-bs-table .expand-icon .fa.fa-small {
        padding: 0; }
    .away-table-b .react-bs-table-container .react-bs-table .delete-icon .fa,
    .away-table-b .react-bs-table-container .react-bs-table .expand-icon .fa {
      cursor: pointer; }
    .away-table-b .react-bs-table-container .react-bs-table .delete-icon:focus .fa, .away-table-b .react-bs-table-container .react-bs-table .delete-icon:hover .fa, .away-table-b .react-bs-table-container .react-bs-table .delete-icon:active .fa {
      color: #ff5252; }
    .away-table-b .react-bs-table-container .react-bs-table .at-message {
      position: relative;
      display: -webkit-inline-flex;
      display: inline-flex;
      -webkit-justify-content: center;
              justify-content: center;
      width: 100%;
      padding-right: 20px; }
      .away-table-b .react-bs-table-container .react-bs-table .at-message .expand-icon {
        position: absolute;
        right: 0; }
  .away-table-b .react-bs-table-container .react-bs-table-pagination .react-bs-table-sizePerPage-dropdown .dropdown-toggle {
    border-radius: 25px;
    font-size: 14px;
    font-family: "Mark Pro";
    padding: 13px;
    letter-spacing: 0.4px;
    line-height: 1;
    outline: none;
    box-shadow: none;
    -webkit-box-shadow: none;
    border: none;
    color: #ffffff;
    background-image: linear-gradient(-121deg, #ff7575 0%, #ffb230 100%);
    min-width: 100px;
    transition: all 0.15s ease-in-out; }
    @media only screen and (max-width: 768px) {
      .away-table-b .react-bs-table-container .react-bs-table-pagination .react-bs-table-sizePerPage-dropdown .dropdown-toggle {
        font-size: 12px;
        padding: 10px; } }
    .away-table-b .react-bs-table-container .react-bs-table-pagination .react-bs-table-sizePerPage-dropdown .dropdown-toggle:focus, .away-table-b .react-bs-table-container .react-bs-table-pagination .react-bs-table-sizePerPage-dropdown .dropdown-toggle:active {
      outline: none;
      box-shadow: none; }
    @media only screen and (max-width: 768px) {
      .away-table-b .react-bs-table-container .react-bs-table-pagination .react-bs-table-sizePerPage-dropdown .dropdown-toggle {
        min-width: 100px; } }
  .away-table-b .react-bs-table-container .react-bs-table-pagination .react-bs-table-sizePerPage-dropdown .dropdown-menu {
    min-width: 0;
    margin-bottom: 8px;
    width: 124px; }
    @media only screen and (max-width: 768px) {
      .away-table-b .react-bs-table-container .react-bs-table-pagination .react-bs-table-sizePerPage-dropdown .dropdown-menu {
        left: 50%;
        -webkit-transform: translateX(-62px);
                transform: translateX(-62px);
        margin-bottom: 0px; } }
    .away-table-b .react-bs-table-container .react-bs-table-pagination .react-bs-table-sizePerPage-dropdown .dropdown-menu li:focus, .away-table-b .react-bs-table-container .react-bs-table-pagination .react-bs-table-sizePerPage-dropdown .dropdown-menu li:hover, .away-table-b .react-bs-table-container .react-bs-table-pagination .react-bs-table-sizePerPage-dropdown .dropdown-menu li:active {
      background-color: #f8f9fa; }
    .away-table-b .react-bs-table-container .react-bs-table-pagination .react-bs-table-sizePerPage-dropdown .dropdown-menu li a {
      color: #ff6600; }
  .away-table-b .react-bs-table-container .react-bs-table-pagination .pagination {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 0;
    -webkit-align-self: flex-end;
            align-self: flex-end; }
    @media only screen and (max-width: 768px) {
      .away-table-b .react-bs-table-container .react-bs-table-pagination .pagination {
        margin-top: 20px;
        margin-bottom: 0;
        margin-right: 0;
        -webkit-align-self: center;
                align-self: center; } }
    .away-table-b .react-bs-table-container .react-bs-table-pagination .pagination * {
      outline: none;
      box-shadow: none; }
    .away-table-b .react-bs-table-container .react-bs-table-pagination .pagination .page-item .page-link {
      margin-left: 8px;
      margin-right: 8px;
      background-color: transparent;
      border: 0;
      border-bottom: 1px solid transparent;
      color: #ff6600; }
      @media only screen and (max-width: 768px) {
        .away-table-b .react-bs-table-container .react-bs-table-pagination .pagination .page-item .page-link {
          margin-left: 4px;
          margin-right: 4px;
          font-size: 14px; } }
      .away-table-b .react-bs-table-container .react-bs-table-pagination .pagination .page-item .page-link:hover {
        background-color: transparent;
        color: #2d2f44; }
    .away-table-b .react-bs-table-container .react-bs-table-pagination .pagination .page-item.active .page-link {
      background-color: transparent;
      border-color: #ff6600;
      color: #ff6600; }
    .away-table-b .react-bs-table-container .react-bs-table-pagination .pagination .away-pagination-next.disabled a {
      color: #a5adbc; }
    .away-table-b .react-bs-table-container .react-bs-table-pagination .pagination .away-pagination-prev.disabled a {
      color: #a5adbc; }
  @media only screen and (max-width: 768px) {
    .away-table-b .react-bs-table-container .react-bs-table-pagination .row .col-xs-6 {
      display: -webkit-flex !important;
      display: flex !important;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center; } }

.away-tabs .card-shadow {
  padding: 0;
  min-height: 500px; }

.away-tabs .at-header-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  border-bottom: 1px solid #f1f3f7; }
  .away-tabs .at-header-wrapper .at-header {
    border-bottom: none; }
  @media only screen and (max-width: 768px) {
    .away-tabs .at-header-wrapper {
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center; }
      .away-tabs .at-header-wrapper .at-header {
        width: 100%;
        border-bottom: 1px solid #f1f3f7; } }

.away-tabs .at-header {
  padding-left: 20px;
  padding-right: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  border-bottom: 1px solid #f1f3f7; }
  @media only screen and (max-width: 768px) {
    .away-tabs .at-header {
      -webkit-flex-direction: column;
              flex-direction: column; } }
  .away-tabs .at-header .at-link {
    font-size: 16px;
    font-weight: normal;
    font-family: "Mark Pro Book";
    color: #a5adbc;
    background-color: transparent;
    padding-top: 15px;
    padding-bottom: 11px;
    margin-right: 40px;
    border: none;
    border-bottom: 4px solid transparent;
    outline: none;
    transition: all 0.1s ease-in-out;
    cursor: pointer; }
    @media only screen and (max-width: 768px) {
      .away-tabs .at-header .at-link {
        margin-right: 0;
        border-bottom: 1px solid #f1f3f7;
        padding-bottom: 14px; }
        .away-tabs .at-header .at-link:last-of-type {
          border: none; } }
    .away-tabs .at-header .at-link:hover {
      color: #2d2f44; }
    .away-tabs .at-header .at-link.active {
      background-color: transparent;
      border-bottom: 4px solid #ff6600;
      color: #2d2f44;
      padding-bottom: 12px;
      margin-bottom: -1px; }

.away-tabs .at-body {
  padding: 20px; }
  @media only screen and (max-width: 768px) {
    .away-tabs .at-body {
      padding-top: 0; } }
  .away-tabs .at-body.at-body-big {
    padding-top: 40px; }
    @media only screen and (max-width: 768px) {
      .away-tabs .at-body.at-body-big {
        padding-top: 0; } }
  .away-tabs .at-body .at-no-requests {
    padding: 15px 20px;
    margin-bottom: 0; }

.away-list .list-title-section {
  margin-bottom: 25px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center; }
  @media only screen and (max-width: 768px) {
    .away-list .list-title-section {
      margin-top: 20px; } }
  .away-list .list-title-section .list-title {
    margin-bottom: 0; }

.away-list .list-header {
  background-color: #fbfdfd;
  padding-top: 13px;
  padding-bottom: 12px;
  padding-left: 20px;
  padding-right: 20px; }
  @media only screen and (max-width: 768px) {
    .away-list .list-header {
      display: none; } }
  .away-list .list-header .list-cell {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center; }
    .away-list .list-header .list-cell .list-cell-text {
      font-size: 12px;
      color: #a5adbc; }

.away-list .list-item {
  padding: 15px 20px;
  border-bottom: 1px solid #f1f3f7;
  position: relative; }
  .away-list .list-item:last-of-type {
    border: none; }
  .away-list .list-item .away-loader {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    position: relative;
    top: auto;
    left: 50%;
    -webkit-transform: translate(-50%, 0%);
            transform: translate(-50%, 0%);
    -webkit-transform: -webkit-translate(-50%, 0%);
            transform: -webkit-translate(-50%, 0%);
    transform: -moz-translate(-50%, 0%);
    transform: -ms-translate(-50%, 0%); }
  @media only screen and (max-width: 768px) {
    .away-list .list-item {
      padding-top: 30px; } }
  .away-list .list-item .li-action-buttons {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-end;
            justify-content: flex-end; }
    .away-list .list-item .li-action-buttons span {
      cursor: pointer;
      margin-right: 15px; }
      .away-list .list-item .li-action-buttons span:last-of-type {
        margin-right: 0; }
      .away-list .list-item .li-action-buttons span .fa {
        color: #a5adbc; }
      .away-list .list-item .li-action-buttons span.edit-icon {
        margin-top: 2px;
        margin-right: 6px; }
        .away-list .list-item .li-action-buttons span.edit-icon .fa {
          margin-right: 0; }
        .away-list .list-item .li-action-buttons span.edit-icon:focus .fa, .away-list .list-item .li-action-buttons span.edit-icon:hover .fa, .away-list .list-item .li-action-buttons span.edit-icon:active .fa {
          color: #303f9f; }
      .away-list .list-item .li-action-buttons span.delete-icon {
        margin-right: 0; }
        .away-list .list-item .li-action-buttons span.delete-icon .fa {
          margin-right: 0; }
        .away-list .list-item .li-action-buttons span.delete-icon:focus .fa, .away-list .list-item .li-action-buttons span.delete-icon:hover .fa, .away-list .list-item .li-action-buttons span.delete-icon:active .fa {
          color: #ff5252; }
        .away-list .list-item .li-action-buttons span.delete-icon.not-allowed {
          cursor: not-allowed; }
      .away-list .list-item .li-action-buttons span .svg-approve:hover rect {
        fill: #9ccc65; }
      .away-list .list-item .li-action-buttons span .svg-approve:hover polyline {
        stroke: #ffffff; }
      .away-list .list-item .li-action-buttons span .svg-delete:hover rect {
        fill: #ff5252; }
      .away-list .list-item .li-action-buttons span .svg-delete:hover path {
        stroke: #ffffff; }
  .away-list .list-item .li-color {
    width: 40px;
    height: 40px;
    border-radius: 50%; }
  .away-list .list-item .li-row .li-cell {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    font-size: 14px;
    font-family: "Mark Pro Book"; }
    @media only screen and (max-width: 768px) {
      .away-list .list-item .li-row .li-cell {
        margin-bottom: 20px; } }
    .away-list .list-item .li-row .li-cell.li-cell-end {
      -webkit-justify-content: flex-end;
              justify-content: flex-end; }
      @media only screen and (max-width: 768px) {
        .away-list .list-item .li-row .li-cell.li-cell-end {
          -webkit-justify-content: flex-start;
                  justify-content: flex-start; } }
    .away-list .list-item .li-row .li-cell.li-capitalized {
      text-transform: capitalize; }
    .away-list .list-item .li-row .li-cell .li-card-text {
      margin-left: 20px; }
    .away-list .list-item .li-row .li-cell .li-status {
      font-size: 16px; }
      .away-list .list-item .li-row .li-cell .li-status.li-status-Approved {
        color: #9ccc65; }
      .away-list .list-item .li-row .li-cell .li-status.li-status-Pending {
        color: #ffcd34; }
      .away-list .list-item .li-row .li-cell .li-status.li-status-Rejected {
        color: #ff5252; }
  .away-list .list-item .li-row .li-cell-mobile {
    display: none; }
    @media only screen and (max-width: 768px) {
      .away-list .list-item .li-row .li-cell-mobile {
        display: block; } }
    .away-list .list-item .li-row .li-cell-mobile .li-cell-mobile-text {
      font-size: 12px;
      color: #a5adbc;
      margin-bottom: 5px;
      display: block; }

.away-list-divider {
  margin-bottom: 40px; }

.away-modal .away-modal-body {
  padding: 50px;
  padding-bottom: 10px; }
  .away-modal .away-modal-body .away-modal-line {
    margin-bottom: 30px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center; }
    .away-modal .away-modal-body .away-modal-line .form-group {
      width: 100%; }
  .away-modal .away-modal-body .alert-danger {
    color: #ff5252;
    margin-bottom: 50px;
    font-size: 12px;
    background-color: #ffffff;
    border-color: #ff5252; }
    .away-modal .away-modal-body .alert-danger.alert-dismissible .close {
      padding: 9px; }
  .away-modal .away-modal-body .away-dropdown {
    outline: none;
    width: 100%; }
    .away-modal .away-modal-body .away-dropdown:focus, .away-modal .away-modal-body .away-dropdown:hover {
      outline: none; }
  .away-modal .away-modal-body .public-holiday-content {
    font-size: 14px; }
  .away-modal .away-modal-body .away-modal-buttons {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-top: 40px; }
    .away-modal .away-modal-body .away-modal-buttons .btn {
      -webkit-align-self: center;
              align-self: center;
      margin-bottom: 5px; }
      .away-modal .away-modal-body .away-modal-buttons .btn:last-of-type {
        margin-bottom: 0; }
  .away-modal .away-modal-body .away-color-option {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    font-size: 16px;
    padding: 5px; }
    .away-modal .away-modal-body .away-color-option:hover {
      cursor: pointer; }
    .away-modal .away-modal-body .away-color-option .away-color-option-bubble {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-right: 10px; }

.away-modal .reject-person-info {
  text-align: center; }

.away-modal .vacation-type {
  font-size: 12px;
  text-transform: uppercase; }

body {
  font-family: "Mark Pro"; }

h2 {
  font-family: "Mark Pro";
  font-weight: 400; }

h3 {
  font-size: 21px;
  font-family: "Mark Pro Medium";
  font-weight: 500; }

.away-search-form {
  margin-top: 0;
  margin-bottom: -1px;
  min-width: 300px;
  padding-right: 20px;
  padding-left: 20px; }
  @media only screen and (max-width: 768px) {
    .away-search-form {
      margin-top: 10px;
      width: 100%; } }
  .away-search-form .away__indicators {
    margin-right: 0; }
    .away-search-form .away__indicators .away__dropdown-indicator {
      display: none; }
  .away-search-form .away__control {
    padding-left: 30px;
    min-height: 40px; }
  .away-search-form .form-group,
  .away-search-form .away-dropdown-form.version-2 {
    margin-bottom: 0; }
  .away-search-form .showSearch {
    position: absolute;
    left: 5px;
    top: 5px;
    z-index: 5; }
    .away-search-form .showSearch .fa {
      color: #c7cdd9; }
    .away-search-form .showSearch:focus .fa, .away-search-form .showSearch:hover .fa, .away-search-form .showSearch:active .fa {
      cursor: pointer; }
    .away-search-form .showSearch.disabled:focus .fa, .away-search-form .showSearch.disabled:hover .fa, .away-search-form .showSearch.disabled:active .fa {
      cursor: not-allowed;
      pointer-events: none; }

.fa-small {
  padding: 5px;
  font-size: 20px; }

.showPassword {
  position: absolute;
  right: 0;
  top: 5px;
  z-index: 5; }
  .showPassword .fa {
    color: #a5adbc; }
  .showPassword:focus .fa, .showPassword:hover .fa, .showPassword:active .fa {
    cursor: pointer; }
  .showPassword.disabled {
    opacity: 0.5; }
    .showPassword.disabled:focus .fa, .showPassword.disabled:hover .fa, .showPassword.disabled:active .fa {
      cursor: not-allowed;
      pointer-events: none; }

.btn {
  border-radius: 25px;
  font-size: 14px;
  font-family: "Mark Pro";
  padding: 13px;
  letter-spacing: 0.4px;
  line-height: 1;
  outline: none;
  box-shadow: none;
  -webkit-box-shadow: none;
  border: none; }
  @media only screen and (max-width: 768px) {
    .btn {
      font-size: 12px;
      padding: 10px; } }
  .btn:focus, .btn:active {
    outline: none;
    box-shadow: none; }
  .btn.btn-primary {
    background-image: linear-gradient(-121deg, #ff7575 0%, #ffb230 100%);
    min-width: 165px;
    transition: all 0.15s ease-in-out; }
    @media only screen and (max-width: 768px) {
      .btn.btn-primary {
        min-width: 135px; } }
    .btn.btn-primary.btn-small {
      min-width: 100px; }
  .btn.btn-primary:not(:disabled):active:focus, .btn.btn-secondary:not(:disabled):active:focus {
    outline: none;
    box-shadow: none; }
  .btn.btn-link {
    color: #ff6600; }
    .btn.btn-link:focus, .btn.btn-link:hover, .btn.btn-link:active {
      outline: none;
      box-shadow: none;
      background-color: transparent;
      color: #ff6600; }
    .btn.btn-link:not(:disabled):active:focus {
      outline: none;
      background-color: transparent;
      box-shadow: none;
      color: #ff6600; }
    .btn.btn-link.btn-grey {
      color: #c7cdd9; }
      .btn.btn-link.btn-grey:focus, .btn.btn-link.btn-grey:hover, .btn.btn-link.btn-grey:active {
        background-color: transparent;
        color: #c7cdd9; }

.info-container {
  padding-top: 0; }
  @media only screen and (max-width: 768px) {
    .info-container {
      padding-top: 20px; } }

.info-label {
  font-size: 12px;
  font-weight: 400;
  color: #a5adbc;
  margin-bottom: 4px; }

.info-text {
  font-size: 16px;
  font-weight: 400;
  color: #2d2f44;
  margin-bottom: 21px; }

.card-shadow {
  background-color: #ffffff;
  border: 0;
  margin-bottom: 20px;
  box-shadow: 0 2px 20px 0 rgba(34, 51, 73, 0.05);
  border-radius: 5px;
  position: relative;
  padding: 20px 30px; }

.popover {
  font-family: inherit; }
  .popover .rdrWeekDay {
    font-family: "Mark Pro Book";
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    color: #c7cdd9; }
  .popover .rdrDayNumber {
    font-size: 14px; }

.form-group {
  position: relative;
  margin-bottom: 40px; }
  .form-group label {
    font-size: 16px;
    font-family: "Mark Pro Book";
    color: #99a2b3;
    position: absolute;
    top: 3px;
    left: 0;
    transition: 0.2s ease-out;
    cursor: text;
    z-index: 1;
    margin-bottom: 0; }
    .form-group label.active {
      -webkit-transform: translateY(-110%);
      transform: translateY(-110%);
      font-size: 12px; }
  .form-group .invalid-feedback {
    font-family: "Mark Pro Book";
    font-size: 12px;
    color: #ff5252;
    font-weight: normal; }
  .form-group .form-control {
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    outline: 0;
    box-shadow: none;
    z-index: 5;
    position: relative;
    border: none;
    border-bottom: 1px solid #e2e6ed;
    border-radius: 0;
    padding: 5px 0 5px 0;
    height: 38px;
    background-color: transparent; }
    .form-group .form-control:focus {
      outline: none;
      box-shadow: 0 1px 0 0 #ff6600;
      border-bottom: 1px solid #ff6600; }
    .form-group .form-control.is-invalid {
      border-color: #ff5252; }
    .form-group .form-control:disabled {
      cursor: not-allowed;
      color: #99a2b3;
      border-bottom: 1px dotted #99a2b3; }

.form-group textarea.form-control {
  height: auto;
  resize: none; }

.force-focus {
  outline: none !important;
  box-shadow: 0 1px 0 0 #ff6600 !important;
  border-bottom: 1px solid #ff6600 !important; }

.custom-control-input:focus ~ .custom-control-indicator {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px #0275d8; }

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none; }

.custom-checkbox {
  margin-bottom: 40px; }
  .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #ff6600; }

.modal-backdrop {
  background-color: #181928; }

.modal-backdrop.show {
  opacity: 0.7; }

.away-dropdown {
  min-width: 180px;
  max-width: 180px;
  display: inline-block;
  margin-right: 20px;
  z-index: 1000; }
  .away-dropdown:last-of-type {
    margin-right: 0; }
  .away-dropdown .away__control,
  .away-dropdown .awayColor__control {
    border-radius: 18px;
    background-color: #f6f8fc;
    border: 0;
    min-height: 30px; }
    .away-dropdown .away__control.away__control--is-focused, .away-dropdown .away__control.awayColor__control--is-focused,
    .away-dropdown .awayColor__control.away__control--is-focused,
    .away-dropdown .awayColor__control.awayColor__control--is-focused {
      box-shadow: 0 0 0 1px #ff6600; }
    .away-dropdown .away__control.away__control--is-disabled, .away-dropdown .away__control.awayColor__control--is-disabled,
    .away-dropdown .awayColor__control.away__control--is-disabled,
    .away-dropdown .awayColor__control.awayColor__control--is-disabled {
      background-color: transparent; }
  .away-dropdown .away__value-container,
  .away-dropdown .awayColor__value-container {
    line-height: 1; }
  .away-dropdown .away__indicator-separator,
  .away-dropdown .awayColor__indicator-separator {
    display: none; }
  .away-dropdown .away__placeholder,
  .away-dropdown .awayColor__placeholder {
    font-family: "Mark Pro Book";
    font-weight: normal;
    font-size: 14px;
    color: #99a2b3;
    position: relative;
    top: auto;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    text-transform: capitalize; }
  .away-dropdown .away__single-value,
  .away-dropdown .awayColor__single-value {
    font-family: "Mark Pro Book";
    font-weight: normal;
    font-size: 14px;
    position: relative;
    top: auto;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    text-transform: capitalize; }

.away__menu {
  font-size: 12px;
  box-shadow: 0 2px 14px 0 rgba(12, 15, 19, 0.09);
  text-transform: capitalize;
  z-index: 2000; }
  .away__menu .away__menu-list {
    box-shadow: none; }
  .away__menu .away__option--is-focused {
    background-color: transparent;
    position: relative; }
    .away__menu .away__option--is-focused:after {
      content: '';
      width: 3px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: #ff6600; }
  .away__menu .away__option--is-selected {
    background-color: transparent;
    color: #2d2f44;
    position: relative; }
    .away__menu .away__option--is-selected:after {
      content: '';
      width: 3px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: #ff6600; }

.away__dropdown-indicator,
.awayColor__dropdown-indicator {
  padding: 4px; }

.away-dropdown-form.version-2 {
  margin-bottom: 40px; }
  .away-dropdown-form.version-2 .away__control,
  .away-dropdown-form.version-2 .awayColor__control {
    border: 0;
    border-bottom: 1px solid #e2e6ed;
    border-radius: 0; }
    .away-dropdown-form.version-2 .away__control .away__value-container,
    .away-dropdown-form.version-2 .away__control .awayColor__value-container,
    .away-dropdown-form.version-2 .awayColor__control .away__value-container,
    .away-dropdown-form.version-2 .awayColor__control .awayColor__value-container {
      padding-left: 0; }
    .away-dropdown-form.version-2 .away__control.away__control--is-focused, .away-dropdown-form.version-2 .away__control.awayColor__control--is-focused,
    .away-dropdown-form.version-2 .awayColor__control.away__control--is-focused,
    .away-dropdown-form.version-2 .awayColor__control.awayColor__control--is-focused {
      box-shadow: none;
      border-color: #ff6600; }
    .away-dropdown-form.version-2 .away__control.away__control--is-disabled .away-dropdown-form.version-2 .away__control.awayColor__control--is-disabled,
    .away-dropdown-form.version-2 .awayColor__control.away__control--is-disabled .away-dropdown-form.version-2 .away__control.awayColor__control--is-disabled, .away-dropdown-form.version-2 .away__control.away__control--is-disabled
    .away-dropdown-form.version-2 .awayColor__control.awayColor__control--is-disabled,
    .away-dropdown-form.version-2 .awayColor__control.away__control--is-disabled
    .away-dropdown-form.version-2 .awayColor__control.awayColor__control--is-disabled {
      background-color: transparent;
      cursor: not-allowed;
      pointer-events: all;
      border-bottom: 1px dotted #99a2b3; }
      .away-dropdown-form.version-2 .away__control.away__control--is-disabled .away-dropdown-form.version-2 .away__control.awayColor__control--is-disabled .away__dropdown-indicator svg, .away-dropdown-form.version-2 .away__control.away__control--is-disabled .away-dropdown-form.version-2 .away__control.awayColor__control--is-disabled.awayColor__dropdown-indicator svg,
      .away-dropdown-form.version-2 .awayColor__control.away__control--is-disabled .away-dropdown-form.version-2 .away__control.awayColor__control--is-disabled .away__dropdown-indicator svg,
      .away-dropdown-form.version-2 .awayColor__control.away__control--is-disabled .away-dropdown-form.version-2 .away__control.awayColor__control--is-disabled.awayColor__dropdown-indicator svg, .away-dropdown-form.version-2 .away__control.away__control--is-disabled
      .away-dropdown-form.version-2 .awayColor__control.awayColor__control--is-disabled .away__dropdown-indicator svg, .away-dropdown-form.version-2 .away__control.away__control--is-disabled
      .away-dropdown-form.version-2 .awayColor__control.awayColor__control--is-disabled.awayColor__dropdown-indicator svg,
      .away-dropdown-form.version-2 .awayColor__control.away__control--is-disabled
      .away-dropdown-form.version-2 .awayColor__control.awayColor__control--is-disabled .away__dropdown-indicator svg,
      .away-dropdown-form.version-2 .awayColor__control.away__control--is-disabled
      .away-dropdown-form.version-2 .awayColor__control.awayColor__control--is-disabled.awayColor__dropdown-indicator svg {
        color: #99a2b3; }
  .away-dropdown-form.version-2 .away__menu {
    z-index: 1000;
    box-shadow: 0 2px 14px 0 rgba(12, 15, 19, 0.09);
    text-transform: capitalize; }
    .away-dropdown-form.version-2 .away__menu .away__menu-list {
      box-shadow: none; }
    .away-dropdown-form.version-2 .away__menu .away__option--is-focused {
      background-color: transparent;
      position: relative; }
      .away-dropdown-form.version-2 .away__menu .away__option--is-focused:after {
        content: '';
        width: 3px;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: #ff6600; }
    .away-dropdown-form.version-2 .away__menu .away__option--is-selected {
      background-color: transparent;
      color: #2d2f44;
      position: relative; }
      .away-dropdown-form.version-2 .away__menu .away__option--is-selected:after {
        content: '';
        width: 3px;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: #ff6600; }
  .away-dropdown-form.version-2 .away__indicator-separator,
  .away-dropdown-form.version-2 .awayColor__indicator-separator {
    display: none; }
  .away-dropdown-form.version-2 .away__dropdown-indicator svg,
  .away-dropdown-form.version-2 .awayColor__dropdown-indicator svg {
    color: #ff6600; }
  .away-dropdown-form.version-2 .away__placeholder,
  .away-dropdown-form.version-2 .awayColor__placeholder {
    font-family: "Mark Pro Book";
    font-weight: normal;
    color: #99a2b3; }
  .away-dropdown-form.version-2 .away__single-value,
  .away-dropdown-form.version-2 .awayColor__single-value {
    font-family: "Mark Pro Book";
    font-weight: normal;
    text-transform: capitalize; }
    .away-dropdown-form.version-2 .away__single-value.away__single-value--is-disabled, .away-dropdown-form.version-2 .away__single-value.awayColor__single-value--is-disabled,
    .away-dropdown-form.version-2 .awayColor__single-value.away__single-value--is-disabled,
    .away-dropdown-form.version-2 .awayColor__single-value.awayColor__single-value--is-disabled {
      color: #99a2b3; }
  .away-dropdown-form.version-2 .away__multi-value {
    background-color: #ffffff;
    border: 1px solid #ff6600; }
    .away-dropdown-form.version-2 .away__multi-value .away__multi-value__remove {
      border-left: 1px solid #ff6600; }
      .away-dropdown-form.version-2 .away__multi-value .away__multi-value__remove:hover {
        border-left: 1px solid #ff6600; }

.away-dropdown-form.no-capitalize .away__menu {
  text-transform: none; }

.away-dropdown-form.no-capitalize .away__single-value,
.away-dropdown-form.no-capitalize .awayColor__single-value {
  text-transform: none; }

.away-loader {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.away-pagination {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  -webkit-justify-content: center;
          justify-content: center; }
  .away-pagination .pagination {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-right: 0;
    -webkit-align-self: flex-end;
            align-self: flex-end; }
    .away-pagination .pagination .page-item .page-link {
      margin-left: 8px;
      margin-right: 8px;
      background-color: transparent;
      border: 0;
      border-bottom: 1px solid transparent;
      color: #ff6600; }
      @media only screen and (max-width: 768px) {
        .away-pagination .pagination .page-item .page-link {
          margin-left: 4px;
          margin-right: 4px;
          font-size: 14px; } }
      .away-pagination .pagination .page-item .page-link:hover {
        background-color: transparent;
        color: #2d2f44; }
    .away-pagination .pagination .page-item.active .page-link {
      background-color: transparent;
      border-color: #ff6600;
      color: #ff6600; }
    .away-pagination .pagination .away-pagination-next.disabled a {
      color: #a5adbc; }
    .away-pagination .pagination .away-pagination-prev.disabled a {
      color: #a5adbc; }

.text-capitalize {
  text-transform: capitalize; }

.alert-wrapper {
  position: fixed;
  left: 40px;
  width: auto;
  bottom: 40px;
  z-index: 1051;
  right: auto;
  top: auto; }
  .alert-wrapper .alert {
    text-align: center;
    padding: 1rem;
    font-family: "Mark Pro Bold";
    border: 0;
    margin-bottom: 0;
    min-width: 250px;
    box-shadow: 0 2px 20px 0 rgba(34, 51, 73, 0.05); }
    .alert-wrapper .alert.alert-dismissible {
      padding-right: 2.5rem; }
      .alert-wrapper .alert.alert-dismissible .close {
        padding: 1rem;
        opacity: 1;
        text-shadow: none; }
  .alert-wrapper .alert-success {
    background-color: #3be9ab;
    color: #ffffff; }
  .alert-wrapper .alert-danger {
    background-color: #ff5252;
    color: #ffffff; }
  .alert-wrapper .alert-info {
    background-color: #0099cc;
    color: #ffffff; }

.react-grid-item.react-grid-placeholder {
  background-color: #ff6600 !important; }

.password-wrap {
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 100vh; }
  .password-wrap .card-shadow {
    margin-bottom: 0; }
  .password-wrap img {
    width: 120px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-bottom: 50px; }
  .password-wrap .btn {
    margin-top: 50px;
    display: block;
    margin-left: auto;
    margin-right: auto; }
    .password-wrap .btn.btn-link {
      margin-top: 10px; }
  .password-wrap .pass-error {
    font-size: 80%;
    color: #ff5252;
    margin-bottom: 40px;
    text-align: center; }
  .password-wrap .greeting {
    font-size: 20;
    margin-bottom: 35px; }
    .password-wrap .greeting p {
      margin-bottom: 5px; }
    .password-wrap .greeting .center {
      text-align: center; }

.away-progressbar.progressbar-sm {
  min-height: 42px; }

.away-progressbar .progress {
  background-color: #f6f8fc;
  border-radius: 6px;
  height: 7px;
  margin-top: 11px;
  margin-bottom: 10px; }
  .away-progressbar .progress.pg-small {
    height: 5px;
    margin-bottom: 0; }
  .away-progressbar .progress.pg-primary .progress-bar {
    background-color: #3be9ab; }
  .away-progressbar .progress.pg-warning .progress-bar {
    background-color: #f8c13c; }
  .away-progressbar .progress.pg-danger .progress-bar {
    background-color: #ff5252; }

.away-progressbar .label {
  font-weight: 400; }
  .away-progressbar .label.label-sm {
    font-size: 12px;
    color: #a5adbc; }
    .away-progressbar .label.label-sm.label-center {
      text-align: center; }
  .away-progressbar .label.label-top {
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 500;
    color: #2d2f44; }
    .away-progressbar .label.label-top p {
      margin-bottom: 0; }
  .away-progressbar .label.label-bottom {
    font-size: 14px;
    color: #99a2b3; }

.card-shadow.profile-card {
  padding-bottom: 10px;
  padding-top: 30px; }
  @media only screen and (max-width: 768px) {
    .card-shadow.profile-card .avatar {
      margin-bottom: 20px;
      margin-left: inherit; } }
  .card-shadow.profile-card .fa {
    font-family: FontAwesome !important; }
  .card-shadow.profile-card .top-row {
    margin-bottom: 30px; }
    .card-shadow.profile-card .top-row h3 {
      margin-bottom: 2px; }
    .card-shadow.profile-card .top-row p {
      font-size: 16px;
      font-family: "Mark Pro Book";
      margin-bottom: 5px; }
    .card-shadow.profile-card .top-row span {
      font-size: 14px;
      color: #99a2b3;
      font-family: "Mark Pro Book"; }
      .card-shadow.profile-card .top-row span img {
        width: 13px;
        margin-right: 7px;
        vertical-align: inherit; }

.drag-handle {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 24px;
  height: 24px;
  cursor: -webkit-grab;
  cursor: grab;
  padding: 4px;
  z-index: 999; }
  .drag-handle:active {
    cursor: -webkit-grabbing;
    cursor: grabbing; }
  .drag-handle__dots {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2px;
    width: 100%;
    height: 100%; }
  .drag-handle__dot {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #9ca3af;
    transition: background-color 0.2s ease;
    box-shadow: 0 0 0 1px #f9fafb; }
  .drag-handle:hover .drag-handle__dot {
    background-color: #6b7280; }

.away-modal .away-modal-body {
  padding: 50px;
  padding-bottom: 10px; }
  @media only screen and (max-width: 768px) {
    .away-modal .away-modal-body {
      padding: 20px !important; } }
  .away-modal .away-modal-body .away-modal-line {
    margin-bottom: 30px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center; }
    .away-modal .away-modal-body .away-modal-line h3 {
      font-family: "Mark Pro Bold"; }
    .away-modal .away-modal-body .away-modal-line .form-group {
      width: 100%; }
  .away-modal .away-modal-body .away-label {
    color: #a5adbc;
    font-size: 12px;
    font-family: "Mark Pro Book";
    margin-bottom: 5px; }
  .away-modal .away-modal-body .margin-bot-30 {
    margin-bottom: 30px; }
  .away-modal .away-modal-body .away-dropdown {
    outline: none;
    width: 100%; }
    .away-modal .away-modal-body .away-dropdown:focus, .away-modal .away-modal-body .away-dropdown:hover {
      outline: none; }
  .away-modal .away-modal-body .away-modal-buttons {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }
    .away-modal .away-modal-body .away-modal-buttons .btn {
      -webkit-align-self: center;
              align-self: center;
      margin-bottom: 5px; }
      .away-modal .away-modal-body .away-modal-buttons .btn:last-of-type {
        margin-bottom: 0; }

.away-header {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-bottom: 30px; }
  .away-header h2 {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 0; }
    .away-header h2 img {
      margin-right: 5px;
      padding-top: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      vertical-align: inherit;
      cursor: pointer; }
  .away-header .back {
    margin-right: 12px;
    padding-bottom: 5px;
    cursor: pointer; }
  @media only screen and (max-width: 768px) {
    .away-header {
      -webkit-flex-direction: column;
              flex-direction: column;
      padding-top: 5px;
      padding-bottom: 25px; }
      .away-header .btn-primary {
        margin-top: 15px; } }

.card-shadow.vacation-requests {
  padding-bottom: 10px;
  padding-top: 20px; }
  .card-shadow.vacation-requests .top-row {
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 25px; }
  .card-shadow.vacation-requests h3 {
    display: inline-block;
    margin-bottom: 0; }
  .card-shadow.vacation-requests .btn-link {
    position: absolute;
    right: 50px;
    top: 2px;
    padding: 0; }
  .card-shadow.vacation-requests p {
    margin-bottom: 20px; }
  .card-shadow.vacation-requests .away-avatar {
    display: inline-block;
    border: solid 2px #ffffff;
    border-radius: 50%;
    margin-right: -13px;
    margin-bottom: 20px;
    position: relative; }
    .card-shadow.vacation-requests .away-avatar:nth-child(0) {
      z-index: 1000; }
    .card-shadow.vacation-requests .away-avatar:nth-child(1) {
      z-index: 999; }
    .card-shadow.vacation-requests .away-avatar:nth-child(2) {
      z-index: 998; }
    .card-shadow.vacation-requests .away-avatar:nth-child(3) {
      z-index: 997; }
    .card-shadow.vacation-requests .away-avatar:nth-child(4) {
      z-index: 996; }
    .card-shadow.vacation-requests .away-avatar:nth-child(5) {
      z-index: 995; }
    .card-shadow.vacation-requests .away-avatar:nth-child(6) {
      z-index: 994; }
    .card-shadow.vacation-requests .away-avatar:nth-child(7) {
      z-index: 993; }
    .card-shadow.vacation-requests .away-avatar:nth-child(8) {
      z-index: 992; }
    .card-shadow.vacation-requests .away-avatar:nth-child(9) {
      z-index: 991; }
    .card-shadow.vacation-requests .away-avatar:nth-child(10) {
      z-index: 990; }
    .card-shadow.vacation-requests .away-avatar:nth-child(11) {
      z-index: 989; }
    .card-shadow.vacation-requests .away-avatar:nth-child(12) {
      z-index: 988; }
    .card-shadow.vacation-requests .away-avatar:nth-child(13) {
      z-index: 987; }
    .card-shadow.vacation-requests .away-avatar:nth-child(14) {
      z-index: 986; }
    .card-shadow.vacation-requests .away-avatar:nth-child(15) {
      z-index: 985; }
    .card-shadow.vacation-requests .away-avatar:nth-child(16) {
      z-index: 984; }
    .card-shadow.vacation-requests .away-avatar:nth-child(17) {
      z-index: 983; }
    .card-shadow.vacation-requests .away-avatar:nth-child(18) {
      z-index: 982; }
    .card-shadow.vacation-requests .away-avatar:nth-child(19) {
      z-index: 981; }
    .card-shadow.vacation-requests .away-avatar:nth-child(20) {
      z-index: 980; }
    .card-shadow.vacation-requests .away-avatar:nth-child(21) {
      z-index: 979; }
    .card-shadow.vacation-requests .away-avatar:nth-child(22) {
      z-index: 978; }
    .card-shadow.vacation-requests .away-avatar:nth-child(23) {
      z-index: 977; }
    .card-shadow.vacation-requests .away-avatar:nth-child(24) {
      z-index: 976; }
    .card-shadow.vacation-requests .away-avatar:nth-child(25) {
      z-index: 975; }
    .card-shadow.vacation-requests .away-avatar:nth-child(26) {
      z-index: 974; }
    .card-shadow.vacation-requests .away-avatar:nth-child(27) {
      z-index: 973; }
    .card-shadow.vacation-requests .away-avatar:nth-child(28) {
      z-index: 972; }
    .card-shadow.vacation-requests .away-avatar:nth-child(29) {
      z-index: 971; }
    .card-shadow.vacation-requests .away-avatar:nth-child(30) {
      z-index: 970; }
    .card-shadow.vacation-requests .away-avatar:nth-child(31) {
      z-index: 969; }
    .card-shadow.vacation-requests .away-avatar:nth-child(32) {
      z-index: 968; }
    .card-shadow.vacation-requests .away-avatar:nth-child(33) {
      z-index: 967; }
    .card-shadow.vacation-requests .away-avatar:nth-child(34) {
      z-index: 966; }
    .card-shadow.vacation-requests .away-avatar:nth-child(35) {
      z-index: 965; }
    .card-shadow.vacation-requests .away-avatar:nth-child(36) {
      z-index: 964; }
    .card-shadow.vacation-requests .away-avatar:nth-child(37) {
      z-index: 963; }
    .card-shadow.vacation-requests .away-avatar:nth-child(38) {
      z-index: 962; }
    .card-shadow.vacation-requests .away-avatar:nth-child(39) {
      z-index: 961; }
    .card-shadow.vacation-requests .away-avatar:nth-child(40) {
      z-index: 960; }
    .card-shadow.vacation-requests .away-avatar:nth-child(41) {
      z-index: 959; }
    .card-shadow.vacation-requests .away-avatar:nth-child(42) {
      z-index: 958; }
    .card-shadow.vacation-requests .away-avatar:nth-child(43) {
      z-index: 957; }
    .card-shadow.vacation-requests .away-avatar:nth-child(44) {
      z-index: 956; }
    .card-shadow.vacation-requests .away-avatar:nth-child(45) {
      z-index: 955; }
    .card-shadow.vacation-requests .away-avatar:nth-child(46) {
      z-index: 954; }
    .card-shadow.vacation-requests .away-avatar:nth-child(47) {
      z-index: 953; }
    .card-shadow.vacation-requests .away-avatar:nth-child(48) {
      z-index: 952; }
    .card-shadow.vacation-requests .away-avatar:nth-child(49) {
      z-index: 951; }
    .card-shadow.vacation-requests .away-avatar:nth-child(50) {
      z-index: 950; }
    .card-shadow.vacation-requests .away-avatar:nth-child(51) {
      z-index: 949; }
    .card-shadow.vacation-requests .away-avatar:nth-child(52) {
      z-index: 948; }
    .card-shadow.vacation-requests .away-avatar:nth-child(53) {
      z-index: 947; }
    .card-shadow.vacation-requests .away-avatar:nth-child(54) {
      z-index: 946; }
    .card-shadow.vacation-requests .away-avatar:nth-child(55) {
      z-index: 945; }
    .card-shadow.vacation-requests .away-avatar:nth-child(56) {
      z-index: 944; }
    .card-shadow.vacation-requests .away-avatar:nth-child(57) {
      z-index: 943; }
    .card-shadow.vacation-requests .away-avatar:nth-child(58) {
      z-index: 942; }
    .card-shadow.vacation-requests .away-avatar:nth-child(59) {
      z-index: 941; }
    .card-shadow.vacation-requests .away-avatar:nth-child(60) {
      z-index: 940; }
    .card-shadow.vacation-requests .away-avatar:nth-child(61) {
      z-index: 939; }
    .card-shadow.vacation-requests .away-avatar:nth-child(62) {
      z-index: 938; }
    .card-shadow.vacation-requests .away-avatar:nth-child(63) {
      z-index: 937; }
    .card-shadow.vacation-requests .away-avatar:nth-child(64) {
      z-index: 936; }
    .card-shadow.vacation-requests .away-avatar:nth-child(65) {
      z-index: 935; }
    .card-shadow.vacation-requests .away-avatar:nth-child(66) {
      z-index: 934; }
    .card-shadow.vacation-requests .away-avatar:nth-child(67) {
      z-index: 933; }
    .card-shadow.vacation-requests .away-avatar:nth-child(68) {
      z-index: 932; }
    .card-shadow.vacation-requests .away-avatar:nth-child(69) {
      z-index: 931; }
    .card-shadow.vacation-requests .away-avatar:nth-child(70) {
      z-index: 930; }
    .card-shadow.vacation-requests .away-avatar:nth-child(71) {
      z-index: 929; }
    .card-shadow.vacation-requests .away-avatar:nth-child(72) {
      z-index: 928; }
    .card-shadow.vacation-requests .away-avatar:nth-child(73) {
      z-index: 927; }
    .card-shadow.vacation-requests .away-avatar:nth-child(74) {
      z-index: 926; }
    .card-shadow.vacation-requests .away-avatar:nth-child(75) {
      z-index: 925; }
    .card-shadow.vacation-requests .away-avatar:nth-child(76) {
      z-index: 924; }
    .card-shadow.vacation-requests .away-avatar:nth-child(77) {
      z-index: 923; }
    .card-shadow.vacation-requests .away-avatar:nth-child(78) {
      z-index: 922; }
    .card-shadow.vacation-requests .away-avatar:nth-child(79) {
      z-index: 921; }
    .card-shadow.vacation-requests .away-avatar:nth-child(80) {
      z-index: 920; }
    .card-shadow.vacation-requests .away-avatar:nth-child(81) {
      z-index: 919; }
    .card-shadow.vacation-requests .away-avatar:nth-child(82) {
      z-index: 918; }
    .card-shadow.vacation-requests .away-avatar:nth-child(83) {
      z-index: 917; }
    .card-shadow.vacation-requests .away-avatar:nth-child(84) {
      z-index: 916; }
    .card-shadow.vacation-requests .away-avatar:nth-child(85) {
      z-index: 915; }
    .card-shadow.vacation-requests .away-avatar:nth-child(86) {
      z-index: 914; }
    .card-shadow.vacation-requests .away-avatar:nth-child(87) {
      z-index: 913; }
    .card-shadow.vacation-requests .away-avatar:nth-child(88) {
      z-index: 912; }
    .card-shadow.vacation-requests .away-avatar:nth-child(89) {
      z-index: 911; }
    .card-shadow.vacation-requests .away-avatar:nth-child(90) {
      z-index: 910; }
    .card-shadow.vacation-requests .away-avatar:nth-child(91) {
      z-index: 909; }
    .card-shadow.vacation-requests .away-avatar:nth-child(92) {
      z-index: 908; }
    .card-shadow.vacation-requests .away-avatar:nth-child(93) {
      z-index: 907; }
    .card-shadow.vacation-requests .away-avatar:nth-child(94) {
      z-index: 906; }
    .card-shadow.vacation-requests .away-avatar:nth-child(95) {
      z-index: 905; }
    .card-shadow.vacation-requests .away-avatar:nth-child(96) {
      z-index: 904; }
    .card-shadow.vacation-requests .away-avatar:nth-child(97) {
      z-index: 903; }
    .card-shadow.vacation-requests .away-avatar:nth-child(98) {
      z-index: 902; }
    .card-shadow.vacation-requests .away-avatar:nth-child(99) {
      z-index: 901; }
    .card-shadow.vacation-requests .away-avatar:nth-child(100) {
      z-index: 900; }
    .card-shadow.vacation-requests .away-avatar:nth-child(101) {
      z-index: 899; }
    .card-shadow.vacation-requests .away-avatar:nth-child(102) {
      z-index: 898; }
    .card-shadow.vacation-requests .away-avatar:nth-child(103) {
      z-index: 897; }
    .card-shadow.vacation-requests .away-avatar:nth-child(104) {
      z-index: 896; }
    .card-shadow.vacation-requests .away-avatar:nth-child(105) {
      z-index: 895; }
    .card-shadow.vacation-requests .away-avatar:nth-child(106) {
      z-index: 894; }
    .card-shadow.vacation-requests .away-avatar:nth-child(107) {
      z-index: 893; }
    .card-shadow.vacation-requests .away-avatar:nth-child(108) {
      z-index: 892; }
    .card-shadow.vacation-requests .away-avatar:nth-child(109) {
      z-index: 891; }
    .card-shadow.vacation-requests .away-avatar:nth-child(110) {
      z-index: 890; }
    .card-shadow.vacation-requests .away-avatar:nth-child(111) {
      z-index: 889; }
    .card-shadow.vacation-requests .away-avatar:nth-child(112) {
      z-index: 888; }
    .card-shadow.vacation-requests .away-avatar:nth-child(113) {
      z-index: 887; }
    .card-shadow.vacation-requests .away-avatar:nth-child(114) {
      z-index: 886; }
    .card-shadow.vacation-requests .away-avatar:nth-child(115) {
      z-index: 885; }
    .card-shadow.vacation-requests .away-avatar:nth-child(116) {
      z-index: 884; }
    .card-shadow.vacation-requests .away-avatar:nth-child(117) {
      z-index: 883; }
    .card-shadow.vacation-requests .away-avatar:nth-child(118) {
      z-index: 882; }
    .card-shadow.vacation-requests .away-avatar:nth-child(119) {
      z-index: 881; }
    .card-shadow.vacation-requests .away-avatar:nth-child(120) {
      z-index: 880; }
    .card-shadow.vacation-requests .away-avatar:nth-child(121) {
      z-index: 879; }
    .card-shadow.vacation-requests .away-avatar:nth-child(122) {
      z-index: 878; }
    .card-shadow.vacation-requests .away-avatar:nth-child(123) {
      z-index: 877; }
    .card-shadow.vacation-requests .away-avatar:nth-child(124) {
      z-index: 876; }
    .card-shadow.vacation-requests .away-avatar:nth-child(125) {
      z-index: 875; }
    .card-shadow.vacation-requests .away-avatar:nth-child(126) {
      z-index: 874; }
    .card-shadow.vacation-requests .away-avatar:nth-child(127) {
      z-index: 873; }
    .card-shadow.vacation-requests .away-avatar:nth-child(128) {
      z-index: 872; }
    .card-shadow.vacation-requests .away-avatar:nth-child(129) {
      z-index: 871; }
    .card-shadow.vacation-requests .away-avatar:nth-child(130) {
      z-index: 870; }
    .card-shadow.vacation-requests .away-avatar:nth-child(131) {
      z-index: 869; }
    .card-shadow.vacation-requests .away-avatar:nth-child(132) {
      z-index: 868; }
    .card-shadow.vacation-requests .away-avatar:nth-child(133) {
      z-index: 867; }
    .card-shadow.vacation-requests .away-avatar:nth-child(134) {
      z-index: 866; }
    .card-shadow.vacation-requests .away-avatar:nth-child(135) {
      z-index: 865; }
    .card-shadow.vacation-requests .away-avatar:nth-child(136) {
      z-index: 864; }
    .card-shadow.vacation-requests .away-avatar:nth-child(137) {
      z-index: 863; }
    .card-shadow.vacation-requests .away-avatar:nth-child(138) {
      z-index: 862; }
    .card-shadow.vacation-requests .away-avatar:nth-child(139) {
      z-index: 861; }
    .card-shadow.vacation-requests .away-avatar:nth-child(140) {
      z-index: 860; }
    .card-shadow.vacation-requests .away-avatar:nth-child(141) {
      z-index: 859; }
    .card-shadow.vacation-requests .away-avatar:nth-child(142) {
      z-index: 858; }
    .card-shadow.vacation-requests .away-avatar:nth-child(143) {
      z-index: 857; }
    .card-shadow.vacation-requests .away-avatar:nth-child(144) {
      z-index: 856; }
    .card-shadow.vacation-requests .away-avatar:nth-child(145) {
      z-index: 855; }
    .card-shadow.vacation-requests .away-avatar:nth-child(146) {
      z-index: 854; }
    .card-shadow.vacation-requests .away-avatar:nth-child(147) {
      z-index: 853; }
    .card-shadow.vacation-requests .away-avatar:nth-child(148) {
      z-index: 852; }
    .card-shadow.vacation-requests .away-avatar:nth-child(149) {
      z-index: 851; }
    .card-shadow.vacation-requests .away-avatar:nth-child(150) {
      z-index: 850; }
    .card-shadow.vacation-requests .away-avatar:nth-child(151) {
      z-index: 849; }
    .card-shadow.vacation-requests .away-avatar:nth-child(152) {
      z-index: 848; }
    .card-shadow.vacation-requests .away-avatar:nth-child(153) {
      z-index: 847; }
    .card-shadow.vacation-requests .away-avatar:nth-child(154) {
      z-index: 846; }
    .card-shadow.vacation-requests .away-avatar:nth-child(155) {
      z-index: 845; }
    .card-shadow.vacation-requests .away-avatar:nth-child(156) {
      z-index: 844; }
    .card-shadow.vacation-requests .away-avatar:nth-child(157) {
      z-index: 843; }
    .card-shadow.vacation-requests .away-avatar:nth-child(158) {
      z-index: 842; }
    .card-shadow.vacation-requests .away-avatar:nth-child(159) {
      z-index: 841; }
    .card-shadow.vacation-requests .away-avatar:nth-child(160) {
      z-index: 840; }
    .card-shadow.vacation-requests .away-avatar:nth-child(161) {
      z-index: 839; }
    .card-shadow.vacation-requests .away-avatar:nth-child(162) {
      z-index: 838; }
    .card-shadow.vacation-requests .away-avatar:nth-child(163) {
      z-index: 837; }
    .card-shadow.vacation-requests .away-avatar:nth-child(164) {
      z-index: 836; }
    .card-shadow.vacation-requests .away-avatar:nth-child(165) {
      z-index: 835; }
    .card-shadow.vacation-requests .away-avatar:nth-child(166) {
      z-index: 834; }
    .card-shadow.vacation-requests .away-avatar:nth-child(167) {
      z-index: 833; }
    .card-shadow.vacation-requests .away-avatar:nth-child(168) {
      z-index: 832; }
    .card-shadow.vacation-requests .away-avatar:nth-child(169) {
      z-index: 831; }
    .card-shadow.vacation-requests .away-avatar:nth-child(170) {
      z-index: 830; }
    .card-shadow.vacation-requests .away-avatar:nth-child(171) {
      z-index: 829; }
    .card-shadow.vacation-requests .away-avatar:nth-child(172) {
      z-index: 828; }
    .card-shadow.vacation-requests .away-avatar:nth-child(173) {
      z-index: 827; }
    .card-shadow.vacation-requests .away-avatar:nth-child(174) {
      z-index: 826; }
    .card-shadow.vacation-requests .away-avatar:nth-child(175) {
      z-index: 825; }
    .card-shadow.vacation-requests .away-avatar:nth-child(176) {
      z-index: 824; }
    .card-shadow.vacation-requests .away-avatar:nth-child(177) {
      z-index: 823; }
    .card-shadow.vacation-requests .away-avatar:nth-child(178) {
      z-index: 822; }
    .card-shadow.vacation-requests .away-avatar:nth-child(179) {
      z-index: 821; }
    .card-shadow.vacation-requests .away-avatar:nth-child(180) {
      z-index: 820; }
    .card-shadow.vacation-requests .away-avatar:nth-child(181) {
      z-index: 819; }
    .card-shadow.vacation-requests .away-avatar:nth-child(182) {
      z-index: 818; }
    .card-shadow.vacation-requests .away-avatar:nth-child(183) {
      z-index: 817; }
    .card-shadow.vacation-requests .away-avatar:nth-child(184) {
      z-index: 816; }
    .card-shadow.vacation-requests .away-avatar:nth-child(185) {
      z-index: 815; }
    .card-shadow.vacation-requests .away-avatar:nth-child(186) {
      z-index: 814; }
    .card-shadow.vacation-requests .away-avatar:nth-child(187) {
      z-index: 813; }
    .card-shadow.vacation-requests .away-avatar:nth-child(188) {
      z-index: 812; }
    .card-shadow.vacation-requests .away-avatar:nth-child(189) {
      z-index: 811; }
    .card-shadow.vacation-requests .away-avatar:nth-child(190) {
      z-index: 810; }
    .card-shadow.vacation-requests .away-avatar:nth-child(191) {
      z-index: 809; }
    .card-shadow.vacation-requests .away-avatar:nth-child(192) {
      z-index: 808; }
    .card-shadow.vacation-requests .away-avatar:nth-child(193) {
      z-index: 807; }
    .card-shadow.vacation-requests .away-avatar:nth-child(194) {
      z-index: 806; }
    .card-shadow.vacation-requests .away-avatar:nth-child(195) {
      z-index: 805; }
    .card-shadow.vacation-requests .away-avatar:nth-child(196) {
      z-index: 804; }
    .card-shadow.vacation-requests .away-avatar:nth-child(197) {
      z-index: 803; }
    .card-shadow.vacation-requests .away-avatar:nth-child(198) {
      z-index: 802; }
    .card-shadow.vacation-requests .away-avatar:nth-child(199) {
      z-index: 801; }
    .card-shadow.vacation-requests .away-avatar:nth-child(200) {
      z-index: 800; }
    .card-shadow.vacation-requests .away-avatar:nth-child(201) {
      z-index: 799; }
    .card-shadow.vacation-requests .away-avatar:nth-child(202) {
      z-index: 798; }
    .card-shadow.vacation-requests .away-avatar:nth-child(203) {
      z-index: 797; }
    .card-shadow.vacation-requests .away-avatar:nth-child(204) {
      z-index: 796; }
    .card-shadow.vacation-requests .away-avatar:nth-child(205) {
      z-index: 795; }
    .card-shadow.vacation-requests .away-avatar:nth-child(206) {
      z-index: 794; }
    .card-shadow.vacation-requests .away-avatar:nth-child(207) {
      z-index: 793; }
    .card-shadow.vacation-requests .away-avatar:nth-child(208) {
      z-index: 792; }
    .card-shadow.vacation-requests .away-avatar:nth-child(209) {
      z-index: 791; }
    .card-shadow.vacation-requests .away-avatar:nth-child(210) {
      z-index: 790; }
    .card-shadow.vacation-requests .away-avatar:nth-child(211) {
      z-index: 789; }
    .card-shadow.vacation-requests .away-avatar:nth-child(212) {
      z-index: 788; }
    .card-shadow.vacation-requests .away-avatar:nth-child(213) {
      z-index: 787; }
    .card-shadow.vacation-requests .away-avatar:nth-child(214) {
      z-index: 786; }
    .card-shadow.vacation-requests .away-avatar:nth-child(215) {
      z-index: 785; }
    .card-shadow.vacation-requests .away-avatar:nth-child(216) {
      z-index: 784; }
    .card-shadow.vacation-requests .away-avatar:nth-child(217) {
      z-index: 783; }
    .card-shadow.vacation-requests .away-avatar:nth-child(218) {
      z-index: 782; }
    .card-shadow.vacation-requests .away-avatar:nth-child(219) {
      z-index: 781; }
    .card-shadow.vacation-requests .away-avatar:nth-child(220) {
      z-index: 780; }
    .card-shadow.vacation-requests .away-avatar:nth-child(221) {
      z-index: 779; }
    .card-shadow.vacation-requests .away-avatar:nth-child(222) {
      z-index: 778; }
    .card-shadow.vacation-requests .away-avatar:nth-child(223) {
      z-index: 777; }
    .card-shadow.vacation-requests .away-avatar:nth-child(224) {
      z-index: 776; }
    .card-shadow.vacation-requests .away-avatar:nth-child(225) {
      z-index: 775; }
    .card-shadow.vacation-requests .away-avatar:nth-child(226) {
      z-index: 774; }
    .card-shadow.vacation-requests .away-avatar:nth-child(227) {
      z-index: 773; }
    .card-shadow.vacation-requests .away-avatar:nth-child(228) {
      z-index: 772; }
    .card-shadow.vacation-requests .away-avatar:nth-child(229) {
      z-index: 771; }
    .card-shadow.vacation-requests .away-avatar:nth-child(230) {
      z-index: 770; }
    .card-shadow.vacation-requests .away-avatar:nth-child(231) {
      z-index: 769; }
    .card-shadow.vacation-requests .away-avatar:nth-child(232) {
      z-index: 768; }
    .card-shadow.vacation-requests .away-avatar:nth-child(233) {
      z-index: 767; }
    .card-shadow.vacation-requests .away-avatar:nth-child(234) {
      z-index: 766; }
    .card-shadow.vacation-requests .away-avatar:nth-child(235) {
      z-index: 765; }
    .card-shadow.vacation-requests .away-avatar:nth-child(236) {
      z-index: 764; }
    .card-shadow.vacation-requests .away-avatar:nth-child(237) {
      z-index: 763; }
    .card-shadow.vacation-requests .away-avatar:nth-child(238) {
      z-index: 762; }
    .card-shadow.vacation-requests .away-avatar:nth-child(239) {
      z-index: 761; }
    .card-shadow.vacation-requests .away-avatar:nth-child(240) {
      z-index: 760; }
    .card-shadow.vacation-requests .away-avatar:nth-child(241) {
      z-index: 759; }
    .card-shadow.vacation-requests .away-avatar:nth-child(242) {
      z-index: 758; }
    .card-shadow.vacation-requests .away-avatar:nth-child(243) {
      z-index: 757; }
    .card-shadow.vacation-requests .away-avatar:nth-child(244) {
      z-index: 756; }
    .card-shadow.vacation-requests .away-avatar:nth-child(245) {
      z-index: 755; }
    .card-shadow.vacation-requests .away-avatar:nth-child(246) {
      z-index: 754; }
    .card-shadow.vacation-requests .away-avatar:nth-child(247) {
      z-index: 753; }
    .card-shadow.vacation-requests .away-avatar:nth-child(248) {
      z-index: 752; }
    .card-shadow.vacation-requests .away-avatar:nth-child(249) {
      z-index: 751; }
    .card-shadow.vacation-requests .away-avatar:nth-child(250) {
      z-index: 750; }
    .card-shadow.vacation-requests .away-avatar:nth-child(251) {
      z-index: 749; }
    .card-shadow.vacation-requests .away-avatar:nth-child(252) {
      z-index: 748; }
    .card-shadow.vacation-requests .away-avatar:nth-child(253) {
      z-index: 747; }
    .card-shadow.vacation-requests .away-avatar:nth-child(254) {
      z-index: 746; }
    .card-shadow.vacation-requests .away-avatar:nth-child(255) {
      z-index: 745; }
    .card-shadow.vacation-requests .away-avatar:nth-child(256) {
      z-index: 744; }
    .card-shadow.vacation-requests .away-avatar:nth-child(257) {
      z-index: 743; }
    .card-shadow.vacation-requests .away-avatar:nth-child(258) {
      z-index: 742; }
    .card-shadow.vacation-requests .away-avatar:nth-child(259) {
      z-index: 741; }
    .card-shadow.vacation-requests .away-avatar:nth-child(260) {
      z-index: 740; }
    .card-shadow.vacation-requests .away-avatar:nth-child(261) {
      z-index: 739; }
    .card-shadow.vacation-requests .away-avatar:nth-child(262) {
      z-index: 738; }
    .card-shadow.vacation-requests .away-avatar:nth-child(263) {
      z-index: 737; }
    .card-shadow.vacation-requests .away-avatar:nth-child(264) {
      z-index: 736; }
    .card-shadow.vacation-requests .away-avatar:nth-child(265) {
      z-index: 735; }
    .card-shadow.vacation-requests .away-avatar:nth-child(266) {
      z-index: 734; }
    .card-shadow.vacation-requests .away-avatar:nth-child(267) {
      z-index: 733; }
    .card-shadow.vacation-requests .away-avatar:nth-child(268) {
      z-index: 732; }
    .card-shadow.vacation-requests .away-avatar:nth-child(269) {
      z-index: 731; }
    .card-shadow.vacation-requests .away-avatar:nth-child(270) {
      z-index: 730; }
    .card-shadow.vacation-requests .away-avatar:nth-child(271) {
      z-index: 729; }
    .card-shadow.vacation-requests .away-avatar:nth-child(272) {
      z-index: 728; }
    .card-shadow.vacation-requests .away-avatar:nth-child(273) {
      z-index: 727; }
    .card-shadow.vacation-requests .away-avatar:nth-child(274) {
      z-index: 726; }
    .card-shadow.vacation-requests .away-avatar:nth-child(275) {
      z-index: 725; }
    .card-shadow.vacation-requests .away-avatar:nth-child(276) {
      z-index: 724; }
    .card-shadow.vacation-requests .away-avatar:nth-child(277) {
      z-index: 723; }
    .card-shadow.vacation-requests .away-avatar:nth-child(278) {
      z-index: 722; }
    .card-shadow.vacation-requests .away-avatar:nth-child(279) {
      z-index: 721; }
    .card-shadow.vacation-requests .away-avatar:nth-child(280) {
      z-index: 720; }
    .card-shadow.vacation-requests .away-avatar:nth-child(281) {
      z-index: 719; }
    .card-shadow.vacation-requests .away-avatar:nth-child(282) {
      z-index: 718; }
    .card-shadow.vacation-requests .away-avatar:nth-child(283) {
      z-index: 717; }
    .card-shadow.vacation-requests .away-avatar:nth-child(284) {
      z-index: 716; }
    .card-shadow.vacation-requests .away-avatar:nth-child(285) {
      z-index: 715; }
    .card-shadow.vacation-requests .away-avatar:nth-child(286) {
      z-index: 714; }
    .card-shadow.vacation-requests .away-avatar:nth-child(287) {
      z-index: 713; }
    .card-shadow.vacation-requests .away-avatar:nth-child(288) {
      z-index: 712; }
    .card-shadow.vacation-requests .away-avatar:nth-child(289) {
      z-index: 711; }
    .card-shadow.vacation-requests .away-avatar:nth-child(290) {
      z-index: 710; }
    .card-shadow.vacation-requests .away-avatar:nth-child(291) {
      z-index: 709; }
    .card-shadow.vacation-requests .away-avatar:nth-child(292) {
      z-index: 708; }
    .card-shadow.vacation-requests .away-avatar:nth-child(293) {
      z-index: 707; }
    .card-shadow.vacation-requests .away-avatar:nth-child(294) {
      z-index: 706; }
    .card-shadow.vacation-requests .away-avatar:nth-child(295) {
      z-index: 705; }
    .card-shadow.vacation-requests .away-avatar:nth-child(296) {
      z-index: 704; }
    .card-shadow.vacation-requests .away-avatar:nth-child(297) {
      z-index: 703; }
    .card-shadow.vacation-requests .away-avatar:nth-child(298) {
      z-index: 702; }
    .card-shadow.vacation-requests .away-avatar:nth-child(299) {
      z-index: 701; }
    .card-shadow.vacation-requests .away-avatar:nth-child(300) {
      z-index: 700; }
    .card-shadow.vacation-requests .away-avatar:nth-child(301) {
      z-index: 699; }
    .card-shadow.vacation-requests .away-avatar:nth-child(302) {
      z-index: 698; }
    .card-shadow.vacation-requests .away-avatar:nth-child(303) {
      z-index: 697; }
    .card-shadow.vacation-requests .away-avatar:nth-child(304) {
      z-index: 696; }
    .card-shadow.vacation-requests .away-avatar:nth-child(305) {
      z-index: 695; }
    .card-shadow.vacation-requests .away-avatar:nth-child(306) {
      z-index: 694; }
    .card-shadow.vacation-requests .away-avatar:nth-child(307) {
      z-index: 693; }
    .card-shadow.vacation-requests .away-avatar:nth-child(308) {
      z-index: 692; }
    .card-shadow.vacation-requests .away-avatar:nth-child(309) {
      z-index: 691; }
    .card-shadow.vacation-requests .away-avatar:nth-child(310) {
      z-index: 690; }
    .card-shadow.vacation-requests .away-avatar:nth-child(311) {
      z-index: 689; }
    .card-shadow.vacation-requests .away-avatar:nth-child(312) {
      z-index: 688; }
    .card-shadow.vacation-requests .away-avatar:nth-child(313) {
      z-index: 687; }
    .card-shadow.vacation-requests .away-avatar:nth-child(314) {
      z-index: 686; }
    .card-shadow.vacation-requests .away-avatar:nth-child(315) {
      z-index: 685; }
    .card-shadow.vacation-requests .away-avatar:nth-child(316) {
      z-index: 684; }
    .card-shadow.vacation-requests .away-avatar:nth-child(317) {
      z-index: 683; }
    .card-shadow.vacation-requests .away-avatar:nth-child(318) {
      z-index: 682; }
    .card-shadow.vacation-requests .away-avatar:nth-child(319) {
      z-index: 681; }
    .card-shadow.vacation-requests .away-avatar:nth-child(320) {
      z-index: 680; }
    .card-shadow.vacation-requests .away-avatar:nth-child(321) {
      z-index: 679; }
    .card-shadow.vacation-requests .away-avatar:nth-child(322) {
      z-index: 678; }
    .card-shadow.vacation-requests .away-avatar:nth-child(323) {
      z-index: 677; }
    .card-shadow.vacation-requests .away-avatar:nth-child(324) {
      z-index: 676; }
    .card-shadow.vacation-requests .away-avatar:nth-child(325) {
      z-index: 675; }
    .card-shadow.vacation-requests .away-avatar:nth-child(326) {
      z-index: 674; }
    .card-shadow.vacation-requests .away-avatar:nth-child(327) {
      z-index: 673; }
    .card-shadow.vacation-requests .away-avatar:nth-child(328) {
      z-index: 672; }
    .card-shadow.vacation-requests .away-avatar:nth-child(329) {
      z-index: 671; }
    .card-shadow.vacation-requests .away-avatar:nth-child(330) {
      z-index: 670; }
    .card-shadow.vacation-requests .away-avatar:nth-child(331) {
      z-index: 669; }
    .card-shadow.vacation-requests .away-avatar:nth-child(332) {
      z-index: 668; }
    .card-shadow.vacation-requests .away-avatar:nth-child(333) {
      z-index: 667; }
    .card-shadow.vacation-requests .away-avatar:nth-child(334) {
      z-index: 666; }
    .card-shadow.vacation-requests .away-avatar:nth-child(335) {
      z-index: 665; }
    .card-shadow.vacation-requests .away-avatar:nth-child(336) {
      z-index: 664; }
    .card-shadow.vacation-requests .away-avatar:nth-child(337) {
      z-index: 663; }
    .card-shadow.vacation-requests .away-avatar:nth-child(338) {
      z-index: 662; }
    .card-shadow.vacation-requests .away-avatar:nth-child(339) {
      z-index: 661; }
    .card-shadow.vacation-requests .away-avatar:nth-child(340) {
      z-index: 660; }
    .card-shadow.vacation-requests .away-avatar:nth-child(341) {
      z-index: 659; }
    .card-shadow.vacation-requests .away-avatar:nth-child(342) {
      z-index: 658; }
    .card-shadow.vacation-requests .away-avatar:nth-child(343) {
      z-index: 657; }
    .card-shadow.vacation-requests .away-avatar:nth-child(344) {
      z-index: 656; }
    .card-shadow.vacation-requests .away-avatar:nth-child(345) {
      z-index: 655; }
    .card-shadow.vacation-requests .away-avatar:nth-child(346) {
      z-index: 654; }
    .card-shadow.vacation-requests .away-avatar:nth-child(347) {
      z-index: 653; }
    .card-shadow.vacation-requests .away-avatar:nth-child(348) {
      z-index: 652; }
    .card-shadow.vacation-requests .away-avatar:nth-child(349) {
      z-index: 651; }
    .card-shadow.vacation-requests .away-avatar:nth-child(350) {
      z-index: 650; }
    .card-shadow.vacation-requests .away-avatar:nth-child(351) {
      z-index: 649; }
    .card-shadow.vacation-requests .away-avatar:nth-child(352) {
      z-index: 648; }
    .card-shadow.vacation-requests .away-avatar:nth-child(353) {
      z-index: 647; }
    .card-shadow.vacation-requests .away-avatar:nth-child(354) {
      z-index: 646; }
    .card-shadow.vacation-requests .away-avatar:nth-child(355) {
      z-index: 645; }
    .card-shadow.vacation-requests .away-avatar:nth-child(356) {
      z-index: 644; }
    .card-shadow.vacation-requests .away-avatar:nth-child(357) {
      z-index: 643; }
    .card-shadow.vacation-requests .away-avatar:nth-child(358) {
      z-index: 642; }
    .card-shadow.vacation-requests .away-avatar:nth-child(359) {
      z-index: 641; }
    .card-shadow.vacation-requests .away-avatar:nth-child(360) {
      z-index: 640; }
    .card-shadow.vacation-requests .away-avatar:nth-child(361) {
      z-index: 639; }
    .card-shadow.vacation-requests .away-avatar:nth-child(362) {
      z-index: 638; }
    .card-shadow.vacation-requests .away-avatar:nth-child(363) {
      z-index: 637; }
    .card-shadow.vacation-requests .away-avatar:nth-child(364) {
      z-index: 636; }
    .card-shadow.vacation-requests .away-avatar:nth-child(365) {
      z-index: 635; }
    .card-shadow.vacation-requests .away-avatar:nth-child(366) {
      z-index: 634; }
    .card-shadow.vacation-requests .away-avatar:nth-child(367) {
      z-index: 633; }
    .card-shadow.vacation-requests .away-avatar:nth-child(368) {
      z-index: 632; }
    .card-shadow.vacation-requests .away-avatar:nth-child(369) {
      z-index: 631; }
    .card-shadow.vacation-requests .away-avatar:nth-child(370) {
      z-index: 630; }
    .card-shadow.vacation-requests .away-avatar:nth-child(371) {
      z-index: 629; }
    .card-shadow.vacation-requests .away-avatar:nth-child(372) {
      z-index: 628; }
    .card-shadow.vacation-requests .away-avatar:nth-child(373) {
      z-index: 627; }
    .card-shadow.vacation-requests .away-avatar:nth-child(374) {
      z-index: 626; }
    .card-shadow.vacation-requests .away-avatar:nth-child(375) {
      z-index: 625; }
    .card-shadow.vacation-requests .away-avatar:nth-child(376) {
      z-index: 624; }
    .card-shadow.vacation-requests .away-avatar:nth-child(377) {
      z-index: 623; }
    .card-shadow.vacation-requests .away-avatar:nth-child(378) {
      z-index: 622; }
    .card-shadow.vacation-requests .away-avatar:nth-child(379) {
      z-index: 621; }
    .card-shadow.vacation-requests .away-avatar:nth-child(380) {
      z-index: 620; }
    .card-shadow.vacation-requests .away-avatar:nth-child(381) {
      z-index: 619; }
    .card-shadow.vacation-requests .away-avatar:nth-child(382) {
      z-index: 618; }
    .card-shadow.vacation-requests .away-avatar:nth-child(383) {
      z-index: 617; }
    .card-shadow.vacation-requests .away-avatar:nth-child(384) {
      z-index: 616; }
    .card-shadow.vacation-requests .away-avatar:nth-child(385) {
      z-index: 615; }
    .card-shadow.vacation-requests .away-avatar:nth-child(386) {
      z-index: 614; }
    .card-shadow.vacation-requests .away-avatar:nth-child(387) {
      z-index: 613; }
    .card-shadow.vacation-requests .away-avatar:nth-child(388) {
      z-index: 612; }
    .card-shadow.vacation-requests .away-avatar:nth-child(389) {
      z-index: 611; }
    .card-shadow.vacation-requests .away-avatar:nth-child(390) {
      z-index: 610; }
    .card-shadow.vacation-requests .away-avatar:nth-child(391) {
      z-index: 609; }
    .card-shadow.vacation-requests .away-avatar:nth-child(392) {
      z-index: 608; }
    .card-shadow.vacation-requests .away-avatar:nth-child(393) {
      z-index: 607; }
    .card-shadow.vacation-requests .away-avatar:nth-child(394) {
      z-index: 606; }
    .card-shadow.vacation-requests .away-avatar:nth-child(395) {
      z-index: 605; }
    .card-shadow.vacation-requests .away-avatar:nth-child(396) {
      z-index: 604; }
    .card-shadow.vacation-requests .away-avatar:nth-child(397) {
      z-index: 603; }
    .card-shadow.vacation-requests .away-avatar:nth-child(398) {
      z-index: 602; }
    .card-shadow.vacation-requests .away-avatar:nth-child(399) {
      z-index: 601; }
    .card-shadow.vacation-requests .away-avatar:nth-child(400) {
      z-index: 600; }
    .card-shadow.vacation-requests .away-avatar:nth-child(401) {
      z-index: 599; }
    .card-shadow.vacation-requests .away-avatar:nth-child(402) {
      z-index: 598; }
    .card-shadow.vacation-requests .away-avatar:nth-child(403) {
      z-index: 597; }
    .card-shadow.vacation-requests .away-avatar:nth-child(404) {
      z-index: 596; }
    .card-shadow.vacation-requests .away-avatar:nth-child(405) {
      z-index: 595; }
    .card-shadow.vacation-requests .away-avatar:nth-child(406) {
      z-index: 594; }
    .card-shadow.vacation-requests .away-avatar:nth-child(407) {
      z-index: 593; }
    .card-shadow.vacation-requests .away-avatar:nth-child(408) {
      z-index: 592; }
    .card-shadow.vacation-requests .away-avatar:nth-child(409) {
      z-index: 591; }
    .card-shadow.vacation-requests .away-avatar:nth-child(410) {
      z-index: 590; }
    .card-shadow.vacation-requests .away-avatar:nth-child(411) {
      z-index: 589; }
    .card-shadow.vacation-requests .away-avatar:nth-child(412) {
      z-index: 588; }
    .card-shadow.vacation-requests .away-avatar:nth-child(413) {
      z-index: 587; }
    .card-shadow.vacation-requests .away-avatar:nth-child(414) {
      z-index: 586; }
    .card-shadow.vacation-requests .away-avatar:nth-child(415) {
      z-index: 585; }
    .card-shadow.vacation-requests .away-avatar:nth-child(416) {
      z-index: 584; }
    .card-shadow.vacation-requests .away-avatar:nth-child(417) {
      z-index: 583; }
    .card-shadow.vacation-requests .away-avatar:nth-child(418) {
      z-index: 582; }
    .card-shadow.vacation-requests .away-avatar:nth-child(419) {
      z-index: 581; }
    .card-shadow.vacation-requests .away-avatar:nth-child(420) {
      z-index: 580; }
    .card-shadow.vacation-requests .away-avatar:nth-child(421) {
      z-index: 579; }
    .card-shadow.vacation-requests .away-avatar:nth-child(422) {
      z-index: 578; }
    .card-shadow.vacation-requests .away-avatar:nth-child(423) {
      z-index: 577; }
    .card-shadow.vacation-requests .away-avatar:nth-child(424) {
      z-index: 576; }
    .card-shadow.vacation-requests .away-avatar:nth-child(425) {
      z-index: 575; }
    .card-shadow.vacation-requests .away-avatar:nth-child(426) {
      z-index: 574; }
    .card-shadow.vacation-requests .away-avatar:nth-child(427) {
      z-index: 573; }
    .card-shadow.vacation-requests .away-avatar:nth-child(428) {
      z-index: 572; }
    .card-shadow.vacation-requests .away-avatar:nth-child(429) {
      z-index: 571; }
    .card-shadow.vacation-requests .away-avatar:nth-child(430) {
      z-index: 570; }
    .card-shadow.vacation-requests .away-avatar:nth-child(431) {
      z-index: 569; }
    .card-shadow.vacation-requests .away-avatar:nth-child(432) {
      z-index: 568; }
    .card-shadow.vacation-requests .away-avatar:nth-child(433) {
      z-index: 567; }
    .card-shadow.vacation-requests .away-avatar:nth-child(434) {
      z-index: 566; }
    .card-shadow.vacation-requests .away-avatar:nth-child(435) {
      z-index: 565; }
    .card-shadow.vacation-requests .away-avatar:nth-child(436) {
      z-index: 564; }
    .card-shadow.vacation-requests .away-avatar:nth-child(437) {
      z-index: 563; }
    .card-shadow.vacation-requests .away-avatar:nth-child(438) {
      z-index: 562; }
    .card-shadow.vacation-requests .away-avatar:nth-child(439) {
      z-index: 561; }
    .card-shadow.vacation-requests .away-avatar:nth-child(440) {
      z-index: 560; }
    .card-shadow.vacation-requests .away-avatar:nth-child(441) {
      z-index: 559; }
    .card-shadow.vacation-requests .away-avatar:nth-child(442) {
      z-index: 558; }
    .card-shadow.vacation-requests .away-avatar:nth-child(443) {
      z-index: 557; }
    .card-shadow.vacation-requests .away-avatar:nth-child(444) {
      z-index: 556; }
    .card-shadow.vacation-requests .away-avatar:nth-child(445) {
      z-index: 555; }
    .card-shadow.vacation-requests .away-avatar:nth-child(446) {
      z-index: 554; }
    .card-shadow.vacation-requests .away-avatar:nth-child(447) {
      z-index: 553; }
    .card-shadow.vacation-requests .away-avatar:nth-child(448) {
      z-index: 552; }
    .card-shadow.vacation-requests .away-avatar:nth-child(449) {
      z-index: 551; }
    .card-shadow.vacation-requests .away-avatar:nth-child(450) {
      z-index: 550; }
    .card-shadow.vacation-requests .away-avatar:nth-child(451) {
      z-index: 549; }
    .card-shadow.vacation-requests .away-avatar:nth-child(452) {
      z-index: 548; }
    .card-shadow.vacation-requests .away-avatar:nth-child(453) {
      z-index: 547; }
    .card-shadow.vacation-requests .away-avatar:nth-child(454) {
      z-index: 546; }
    .card-shadow.vacation-requests .away-avatar:nth-child(455) {
      z-index: 545; }
    .card-shadow.vacation-requests .away-avatar:nth-child(456) {
      z-index: 544; }
    .card-shadow.vacation-requests .away-avatar:nth-child(457) {
      z-index: 543; }
    .card-shadow.vacation-requests .away-avatar:nth-child(458) {
      z-index: 542; }
    .card-shadow.vacation-requests .away-avatar:nth-child(459) {
      z-index: 541; }
    .card-shadow.vacation-requests .away-avatar:nth-child(460) {
      z-index: 540; }
    .card-shadow.vacation-requests .away-avatar:nth-child(461) {
      z-index: 539; }
    .card-shadow.vacation-requests .away-avatar:nth-child(462) {
      z-index: 538; }
    .card-shadow.vacation-requests .away-avatar:nth-child(463) {
      z-index: 537; }
    .card-shadow.vacation-requests .away-avatar:nth-child(464) {
      z-index: 536; }
    .card-shadow.vacation-requests .away-avatar:nth-child(465) {
      z-index: 535; }
    .card-shadow.vacation-requests .away-avatar:nth-child(466) {
      z-index: 534; }
    .card-shadow.vacation-requests .away-avatar:nth-child(467) {
      z-index: 533; }
    .card-shadow.vacation-requests .away-avatar:nth-child(468) {
      z-index: 532; }
    .card-shadow.vacation-requests .away-avatar:nth-child(469) {
      z-index: 531; }
    .card-shadow.vacation-requests .away-avatar:nth-child(470) {
      z-index: 530; }
    .card-shadow.vacation-requests .away-avatar:nth-child(471) {
      z-index: 529; }
    .card-shadow.vacation-requests .away-avatar:nth-child(472) {
      z-index: 528; }
    .card-shadow.vacation-requests .away-avatar:nth-child(473) {
      z-index: 527; }
    .card-shadow.vacation-requests .away-avatar:nth-child(474) {
      z-index: 526; }
    .card-shadow.vacation-requests .away-avatar:nth-child(475) {
      z-index: 525; }
    .card-shadow.vacation-requests .away-avatar:nth-child(476) {
      z-index: 524; }
    .card-shadow.vacation-requests .away-avatar:nth-child(477) {
      z-index: 523; }
    .card-shadow.vacation-requests .away-avatar:nth-child(478) {
      z-index: 522; }
    .card-shadow.vacation-requests .away-avatar:nth-child(479) {
      z-index: 521; }
    .card-shadow.vacation-requests .away-avatar:nth-child(480) {
      z-index: 520; }
    .card-shadow.vacation-requests .away-avatar:nth-child(481) {
      z-index: 519; }
    .card-shadow.vacation-requests .away-avatar:nth-child(482) {
      z-index: 518; }
    .card-shadow.vacation-requests .away-avatar:nth-child(483) {
      z-index: 517; }
    .card-shadow.vacation-requests .away-avatar:nth-child(484) {
      z-index: 516; }
    .card-shadow.vacation-requests .away-avatar:nth-child(485) {
      z-index: 515; }
    .card-shadow.vacation-requests .away-avatar:nth-child(486) {
      z-index: 514; }
    .card-shadow.vacation-requests .away-avatar:nth-child(487) {
      z-index: 513; }
    .card-shadow.vacation-requests .away-avatar:nth-child(488) {
      z-index: 512; }
    .card-shadow.vacation-requests .away-avatar:nth-child(489) {
      z-index: 511; }
    .card-shadow.vacation-requests .away-avatar:nth-child(490) {
      z-index: 510; }
    .card-shadow.vacation-requests .away-avatar:nth-child(491) {
      z-index: 509; }
    .card-shadow.vacation-requests .away-avatar:nth-child(492) {
      z-index: 508; }
    .card-shadow.vacation-requests .away-avatar:nth-child(493) {
      z-index: 507; }
    .card-shadow.vacation-requests .away-avatar:nth-child(494) {
      z-index: 506; }
    .card-shadow.vacation-requests .away-avatar:nth-child(495) {
      z-index: 505; }
    .card-shadow.vacation-requests .away-avatar:nth-child(496) {
      z-index: 504; }
    .card-shadow.vacation-requests .away-avatar:nth-child(497) {
      z-index: 503; }
    .card-shadow.vacation-requests .away-avatar:nth-child(498) {
      z-index: 502; }
    .card-shadow.vacation-requests .away-avatar:nth-child(499) {
      z-index: 501; }
    .card-shadow.vacation-requests .away-avatar:nth-child(500) {
      z-index: 500; }
    .card-shadow.vacation-requests .away-avatar:nth-child(501) {
      z-index: 499; }
    .card-shadow.vacation-requests .away-avatar:nth-child(502) {
      z-index: 498; }
    .card-shadow.vacation-requests .away-avatar:nth-child(503) {
      z-index: 497; }
    .card-shadow.vacation-requests .away-avatar:nth-child(504) {
      z-index: 496; }
    .card-shadow.vacation-requests .away-avatar:nth-child(505) {
      z-index: 495; }
    .card-shadow.vacation-requests .away-avatar:nth-child(506) {
      z-index: 494; }
    .card-shadow.vacation-requests .away-avatar:nth-child(507) {
      z-index: 493; }
    .card-shadow.vacation-requests .away-avatar:nth-child(508) {
      z-index: 492; }
    .card-shadow.vacation-requests .away-avatar:nth-child(509) {
      z-index: 491; }
    .card-shadow.vacation-requests .away-avatar:nth-child(510) {
      z-index: 490; }
    .card-shadow.vacation-requests .away-avatar:nth-child(511) {
      z-index: 489; }
    .card-shadow.vacation-requests .away-avatar:nth-child(512) {
      z-index: 488; }
    .card-shadow.vacation-requests .away-avatar:nth-child(513) {
      z-index: 487; }
    .card-shadow.vacation-requests .away-avatar:nth-child(514) {
      z-index: 486; }
    .card-shadow.vacation-requests .away-avatar:nth-child(515) {
      z-index: 485; }
    .card-shadow.vacation-requests .away-avatar:nth-child(516) {
      z-index: 484; }
    .card-shadow.vacation-requests .away-avatar:nth-child(517) {
      z-index: 483; }
    .card-shadow.vacation-requests .away-avatar:nth-child(518) {
      z-index: 482; }
    .card-shadow.vacation-requests .away-avatar:nth-child(519) {
      z-index: 481; }
    .card-shadow.vacation-requests .away-avatar:nth-child(520) {
      z-index: 480; }
    .card-shadow.vacation-requests .away-avatar:nth-child(521) {
      z-index: 479; }
    .card-shadow.vacation-requests .away-avatar:nth-child(522) {
      z-index: 478; }
    .card-shadow.vacation-requests .away-avatar:nth-child(523) {
      z-index: 477; }
    .card-shadow.vacation-requests .away-avatar:nth-child(524) {
      z-index: 476; }
    .card-shadow.vacation-requests .away-avatar:nth-child(525) {
      z-index: 475; }
    .card-shadow.vacation-requests .away-avatar:nth-child(526) {
      z-index: 474; }
    .card-shadow.vacation-requests .away-avatar:nth-child(527) {
      z-index: 473; }
    .card-shadow.vacation-requests .away-avatar:nth-child(528) {
      z-index: 472; }
    .card-shadow.vacation-requests .away-avatar:nth-child(529) {
      z-index: 471; }
    .card-shadow.vacation-requests .away-avatar:nth-child(530) {
      z-index: 470; }
    .card-shadow.vacation-requests .away-avatar:nth-child(531) {
      z-index: 469; }
    .card-shadow.vacation-requests .away-avatar:nth-child(532) {
      z-index: 468; }
    .card-shadow.vacation-requests .away-avatar:nth-child(533) {
      z-index: 467; }
    .card-shadow.vacation-requests .away-avatar:nth-child(534) {
      z-index: 466; }
    .card-shadow.vacation-requests .away-avatar:nth-child(535) {
      z-index: 465; }
    .card-shadow.vacation-requests .away-avatar:nth-child(536) {
      z-index: 464; }
    .card-shadow.vacation-requests .away-avatar:nth-child(537) {
      z-index: 463; }
    .card-shadow.vacation-requests .away-avatar:nth-child(538) {
      z-index: 462; }
    .card-shadow.vacation-requests .away-avatar:nth-child(539) {
      z-index: 461; }
    .card-shadow.vacation-requests .away-avatar:nth-child(540) {
      z-index: 460; }
    .card-shadow.vacation-requests .away-avatar:nth-child(541) {
      z-index: 459; }
    .card-shadow.vacation-requests .away-avatar:nth-child(542) {
      z-index: 458; }
    .card-shadow.vacation-requests .away-avatar:nth-child(543) {
      z-index: 457; }
    .card-shadow.vacation-requests .away-avatar:nth-child(544) {
      z-index: 456; }
    .card-shadow.vacation-requests .away-avatar:nth-child(545) {
      z-index: 455; }
    .card-shadow.vacation-requests .away-avatar:nth-child(546) {
      z-index: 454; }
    .card-shadow.vacation-requests .away-avatar:nth-child(547) {
      z-index: 453; }
    .card-shadow.vacation-requests .away-avatar:nth-child(548) {
      z-index: 452; }
    .card-shadow.vacation-requests .away-avatar:nth-child(549) {
      z-index: 451; }
    .card-shadow.vacation-requests .away-avatar:nth-child(550) {
      z-index: 450; }
    .card-shadow.vacation-requests .away-avatar:nth-child(551) {
      z-index: 449; }
    .card-shadow.vacation-requests .away-avatar:nth-child(552) {
      z-index: 448; }
    .card-shadow.vacation-requests .away-avatar:nth-child(553) {
      z-index: 447; }
    .card-shadow.vacation-requests .away-avatar:nth-child(554) {
      z-index: 446; }
    .card-shadow.vacation-requests .away-avatar:nth-child(555) {
      z-index: 445; }
    .card-shadow.vacation-requests .away-avatar:nth-child(556) {
      z-index: 444; }
    .card-shadow.vacation-requests .away-avatar:nth-child(557) {
      z-index: 443; }
    .card-shadow.vacation-requests .away-avatar:nth-child(558) {
      z-index: 442; }
    .card-shadow.vacation-requests .away-avatar:nth-child(559) {
      z-index: 441; }
    .card-shadow.vacation-requests .away-avatar:nth-child(560) {
      z-index: 440; }
    .card-shadow.vacation-requests .away-avatar:nth-child(561) {
      z-index: 439; }
    .card-shadow.vacation-requests .away-avatar:nth-child(562) {
      z-index: 438; }
    .card-shadow.vacation-requests .away-avatar:nth-child(563) {
      z-index: 437; }
    .card-shadow.vacation-requests .away-avatar:nth-child(564) {
      z-index: 436; }
    .card-shadow.vacation-requests .away-avatar:nth-child(565) {
      z-index: 435; }
    .card-shadow.vacation-requests .away-avatar:nth-child(566) {
      z-index: 434; }
    .card-shadow.vacation-requests .away-avatar:nth-child(567) {
      z-index: 433; }
    .card-shadow.vacation-requests .away-avatar:nth-child(568) {
      z-index: 432; }
    .card-shadow.vacation-requests .away-avatar:nth-child(569) {
      z-index: 431; }
    .card-shadow.vacation-requests .away-avatar:nth-child(570) {
      z-index: 430; }
    .card-shadow.vacation-requests .away-avatar:nth-child(571) {
      z-index: 429; }
    .card-shadow.vacation-requests .away-avatar:nth-child(572) {
      z-index: 428; }
    .card-shadow.vacation-requests .away-avatar:nth-child(573) {
      z-index: 427; }
    .card-shadow.vacation-requests .away-avatar:nth-child(574) {
      z-index: 426; }
    .card-shadow.vacation-requests .away-avatar:nth-child(575) {
      z-index: 425; }
    .card-shadow.vacation-requests .away-avatar:nth-child(576) {
      z-index: 424; }
    .card-shadow.vacation-requests .away-avatar:nth-child(577) {
      z-index: 423; }
    .card-shadow.vacation-requests .away-avatar:nth-child(578) {
      z-index: 422; }
    .card-shadow.vacation-requests .away-avatar:nth-child(579) {
      z-index: 421; }
    .card-shadow.vacation-requests .away-avatar:nth-child(580) {
      z-index: 420; }
    .card-shadow.vacation-requests .away-avatar:nth-child(581) {
      z-index: 419; }
    .card-shadow.vacation-requests .away-avatar:nth-child(582) {
      z-index: 418; }
    .card-shadow.vacation-requests .away-avatar:nth-child(583) {
      z-index: 417; }
    .card-shadow.vacation-requests .away-avatar:nth-child(584) {
      z-index: 416; }
    .card-shadow.vacation-requests .away-avatar:nth-child(585) {
      z-index: 415; }
    .card-shadow.vacation-requests .away-avatar:nth-child(586) {
      z-index: 414; }
    .card-shadow.vacation-requests .away-avatar:nth-child(587) {
      z-index: 413; }
    .card-shadow.vacation-requests .away-avatar:nth-child(588) {
      z-index: 412; }
    .card-shadow.vacation-requests .away-avatar:nth-child(589) {
      z-index: 411; }
    .card-shadow.vacation-requests .away-avatar:nth-child(590) {
      z-index: 410; }
    .card-shadow.vacation-requests .away-avatar:nth-child(591) {
      z-index: 409; }
    .card-shadow.vacation-requests .away-avatar:nth-child(592) {
      z-index: 408; }
    .card-shadow.vacation-requests .away-avatar:nth-child(593) {
      z-index: 407; }
    .card-shadow.vacation-requests .away-avatar:nth-child(594) {
      z-index: 406; }
    .card-shadow.vacation-requests .away-avatar:nth-child(595) {
      z-index: 405; }
    .card-shadow.vacation-requests .away-avatar:nth-child(596) {
      z-index: 404; }
    .card-shadow.vacation-requests .away-avatar:nth-child(597) {
      z-index: 403; }
    .card-shadow.vacation-requests .away-avatar:nth-child(598) {
      z-index: 402; }
    .card-shadow.vacation-requests .away-avatar:nth-child(599) {
      z-index: 401; }
    .card-shadow.vacation-requests .away-avatar:nth-child(600) {
      z-index: 400; }
    .card-shadow.vacation-requests .away-avatar:nth-child(601) {
      z-index: 399; }
    .card-shadow.vacation-requests .away-avatar:nth-child(602) {
      z-index: 398; }
    .card-shadow.vacation-requests .away-avatar:nth-child(603) {
      z-index: 397; }
    .card-shadow.vacation-requests .away-avatar:nth-child(604) {
      z-index: 396; }
    .card-shadow.vacation-requests .away-avatar:nth-child(605) {
      z-index: 395; }
    .card-shadow.vacation-requests .away-avatar:nth-child(606) {
      z-index: 394; }
    .card-shadow.vacation-requests .away-avatar:nth-child(607) {
      z-index: 393; }
    .card-shadow.vacation-requests .away-avatar:nth-child(608) {
      z-index: 392; }
    .card-shadow.vacation-requests .away-avatar:nth-child(609) {
      z-index: 391; }
    .card-shadow.vacation-requests .away-avatar:nth-child(610) {
      z-index: 390; }
    .card-shadow.vacation-requests .away-avatar:nth-child(611) {
      z-index: 389; }
    .card-shadow.vacation-requests .away-avatar:nth-child(612) {
      z-index: 388; }
    .card-shadow.vacation-requests .away-avatar:nth-child(613) {
      z-index: 387; }
    .card-shadow.vacation-requests .away-avatar:nth-child(614) {
      z-index: 386; }
    .card-shadow.vacation-requests .away-avatar:nth-child(615) {
      z-index: 385; }
    .card-shadow.vacation-requests .away-avatar:nth-child(616) {
      z-index: 384; }
    .card-shadow.vacation-requests .away-avatar:nth-child(617) {
      z-index: 383; }
    .card-shadow.vacation-requests .away-avatar:nth-child(618) {
      z-index: 382; }
    .card-shadow.vacation-requests .away-avatar:nth-child(619) {
      z-index: 381; }
    .card-shadow.vacation-requests .away-avatar:nth-child(620) {
      z-index: 380; }
    .card-shadow.vacation-requests .away-avatar:nth-child(621) {
      z-index: 379; }
    .card-shadow.vacation-requests .away-avatar:nth-child(622) {
      z-index: 378; }
    .card-shadow.vacation-requests .away-avatar:nth-child(623) {
      z-index: 377; }
    .card-shadow.vacation-requests .away-avatar:nth-child(624) {
      z-index: 376; }
    .card-shadow.vacation-requests .away-avatar:nth-child(625) {
      z-index: 375; }
    .card-shadow.vacation-requests .away-avatar:nth-child(626) {
      z-index: 374; }
    .card-shadow.vacation-requests .away-avatar:nth-child(627) {
      z-index: 373; }
    .card-shadow.vacation-requests .away-avatar:nth-child(628) {
      z-index: 372; }
    .card-shadow.vacation-requests .away-avatar:nth-child(629) {
      z-index: 371; }
    .card-shadow.vacation-requests .away-avatar:nth-child(630) {
      z-index: 370; }
    .card-shadow.vacation-requests .away-avatar:nth-child(631) {
      z-index: 369; }
    .card-shadow.vacation-requests .away-avatar:nth-child(632) {
      z-index: 368; }
    .card-shadow.vacation-requests .away-avatar:nth-child(633) {
      z-index: 367; }
    .card-shadow.vacation-requests .away-avatar:nth-child(634) {
      z-index: 366; }
    .card-shadow.vacation-requests .away-avatar:nth-child(635) {
      z-index: 365; }
    .card-shadow.vacation-requests .away-avatar:nth-child(636) {
      z-index: 364; }
    .card-shadow.vacation-requests .away-avatar:nth-child(637) {
      z-index: 363; }
    .card-shadow.vacation-requests .away-avatar:nth-child(638) {
      z-index: 362; }
    .card-shadow.vacation-requests .away-avatar:nth-child(639) {
      z-index: 361; }
    .card-shadow.vacation-requests .away-avatar:nth-child(640) {
      z-index: 360; }
    .card-shadow.vacation-requests .away-avatar:nth-child(641) {
      z-index: 359; }
    .card-shadow.vacation-requests .away-avatar:nth-child(642) {
      z-index: 358; }
    .card-shadow.vacation-requests .away-avatar:nth-child(643) {
      z-index: 357; }
    .card-shadow.vacation-requests .away-avatar:nth-child(644) {
      z-index: 356; }
    .card-shadow.vacation-requests .away-avatar:nth-child(645) {
      z-index: 355; }
    .card-shadow.vacation-requests .away-avatar:nth-child(646) {
      z-index: 354; }
    .card-shadow.vacation-requests .away-avatar:nth-child(647) {
      z-index: 353; }
    .card-shadow.vacation-requests .away-avatar:nth-child(648) {
      z-index: 352; }
    .card-shadow.vacation-requests .away-avatar:nth-child(649) {
      z-index: 351; }
    .card-shadow.vacation-requests .away-avatar:nth-child(650) {
      z-index: 350; }
    .card-shadow.vacation-requests .away-avatar:nth-child(651) {
      z-index: 349; }
    .card-shadow.vacation-requests .away-avatar:nth-child(652) {
      z-index: 348; }
    .card-shadow.vacation-requests .away-avatar:nth-child(653) {
      z-index: 347; }
    .card-shadow.vacation-requests .away-avatar:nth-child(654) {
      z-index: 346; }
    .card-shadow.vacation-requests .away-avatar:nth-child(655) {
      z-index: 345; }
    .card-shadow.vacation-requests .away-avatar:nth-child(656) {
      z-index: 344; }
    .card-shadow.vacation-requests .away-avatar:nth-child(657) {
      z-index: 343; }
    .card-shadow.vacation-requests .away-avatar:nth-child(658) {
      z-index: 342; }
    .card-shadow.vacation-requests .away-avatar:nth-child(659) {
      z-index: 341; }
    .card-shadow.vacation-requests .away-avatar:nth-child(660) {
      z-index: 340; }
    .card-shadow.vacation-requests .away-avatar:nth-child(661) {
      z-index: 339; }
    .card-shadow.vacation-requests .away-avatar:nth-child(662) {
      z-index: 338; }
    .card-shadow.vacation-requests .away-avatar:nth-child(663) {
      z-index: 337; }
    .card-shadow.vacation-requests .away-avatar:nth-child(664) {
      z-index: 336; }
    .card-shadow.vacation-requests .away-avatar:nth-child(665) {
      z-index: 335; }
    .card-shadow.vacation-requests .away-avatar:nth-child(666) {
      z-index: 334; }
    .card-shadow.vacation-requests .away-avatar:nth-child(667) {
      z-index: 333; }
    .card-shadow.vacation-requests .away-avatar:nth-child(668) {
      z-index: 332; }
    .card-shadow.vacation-requests .away-avatar:nth-child(669) {
      z-index: 331; }
    .card-shadow.vacation-requests .away-avatar:nth-child(670) {
      z-index: 330; }
    .card-shadow.vacation-requests .away-avatar:nth-child(671) {
      z-index: 329; }
    .card-shadow.vacation-requests .away-avatar:nth-child(672) {
      z-index: 328; }
    .card-shadow.vacation-requests .away-avatar:nth-child(673) {
      z-index: 327; }
    .card-shadow.vacation-requests .away-avatar:nth-child(674) {
      z-index: 326; }
    .card-shadow.vacation-requests .away-avatar:nth-child(675) {
      z-index: 325; }
    .card-shadow.vacation-requests .away-avatar:nth-child(676) {
      z-index: 324; }
    .card-shadow.vacation-requests .away-avatar:nth-child(677) {
      z-index: 323; }
    .card-shadow.vacation-requests .away-avatar:nth-child(678) {
      z-index: 322; }
    .card-shadow.vacation-requests .away-avatar:nth-child(679) {
      z-index: 321; }
    .card-shadow.vacation-requests .away-avatar:nth-child(680) {
      z-index: 320; }
    .card-shadow.vacation-requests .away-avatar:nth-child(681) {
      z-index: 319; }
    .card-shadow.vacation-requests .away-avatar:nth-child(682) {
      z-index: 318; }
    .card-shadow.vacation-requests .away-avatar:nth-child(683) {
      z-index: 317; }
    .card-shadow.vacation-requests .away-avatar:nth-child(684) {
      z-index: 316; }
    .card-shadow.vacation-requests .away-avatar:nth-child(685) {
      z-index: 315; }
    .card-shadow.vacation-requests .away-avatar:nth-child(686) {
      z-index: 314; }
    .card-shadow.vacation-requests .away-avatar:nth-child(687) {
      z-index: 313; }
    .card-shadow.vacation-requests .away-avatar:nth-child(688) {
      z-index: 312; }
    .card-shadow.vacation-requests .away-avatar:nth-child(689) {
      z-index: 311; }
    .card-shadow.vacation-requests .away-avatar:nth-child(690) {
      z-index: 310; }
    .card-shadow.vacation-requests .away-avatar:nth-child(691) {
      z-index: 309; }
    .card-shadow.vacation-requests .away-avatar:nth-child(692) {
      z-index: 308; }
    .card-shadow.vacation-requests .away-avatar:nth-child(693) {
      z-index: 307; }
    .card-shadow.vacation-requests .away-avatar:nth-child(694) {
      z-index: 306; }
    .card-shadow.vacation-requests .away-avatar:nth-child(695) {
      z-index: 305; }
    .card-shadow.vacation-requests .away-avatar:nth-child(696) {
      z-index: 304; }
    .card-shadow.vacation-requests .away-avatar:nth-child(697) {
      z-index: 303; }
    .card-shadow.vacation-requests .away-avatar:nth-child(698) {
      z-index: 302; }
    .card-shadow.vacation-requests .away-avatar:nth-child(699) {
      z-index: 301; }
    .card-shadow.vacation-requests .away-avatar:nth-child(700) {
      z-index: 300; }
    .card-shadow.vacation-requests .away-avatar:nth-child(701) {
      z-index: 299; }
    .card-shadow.vacation-requests .away-avatar:nth-child(702) {
      z-index: 298; }
    .card-shadow.vacation-requests .away-avatar:nth-child(703) {
      z-index: 297; }
    .card-shadow.vacation-requests .away-avatar:nth-child(704) {
      z-index: 296; }
    .card-shadow.vacation-requests .away-avatar:nth-child(705) {
      z-index: 295; }
    .card-shadow.vacation-requests .away-avatar:nth-child(706) {
      z-index: 294; }
    .card-shadow.vacation-requests .away-avatar:nth-child(707) {
      z-index: 293; }
    .card-shadow.vacation-requests .away-avatar:nth-child(708) {
      z-index: 292; }
    .card-shadow.vacation-requests .away-avatar:nth-child(709) {
      z-index: 291; }
    .card-shadow.vacation-requests .away-avatar:nth-child(710) {
      z-index: 290; }
    .card-shadow.vacation-requests .away-avatar:nth-child(711) {
      z-index: 289; }
    .card-shadow.vacation-requests .away-avatar:nth-child(712) {
      z-index: 288; }
    .card-shadow.vacation-requests .away-avatar:nth-child(713) {
      z-index: 287; }
    .card-shadow.vacation-requests .away-avatar:nth-child(714) {
      z-index: 286; }
    .card-shadow.vacation-requests .away-avatar:nth-child(715) {
      z-index: 285; }
    .card-shadow.vacation-requests .away-avatar:nth-child(716) {
      z-index: 284; }
    .card-shadow.vacation-requests .away-avatar:nth-child(717) {
      z-index: 283; }
    .card-shadow.vacation-requests .away-avatar:nth-child(718) {
      z-index: 282; }
    .card-shadow.vacation-requests .away-avatar:nth-child(719) {
      z-index: 281; }
    .card-shadow.vacation-requests .away-avatar:nth-child(720) {
      z-index: 280; }
    .card-shadow.vacation-requests .away-avatar:nth-child(721) {
      z-index: 279; }
    .card-shadow.vacation-requests .away-avatar:nth-child(722) {
      z-index: 278; }
    .card-shadow.vacation-requests .away-avatar:nth-child(723) {
      z-index: 277; }
    .card-shadow.vacation-requests .away-avatar:nth-child(724) {
      z-index: 276; }
    .card-shadow.vacation-requests .away-avatar:nth-child(725) {
      z-index: 275; }
    .card-shadow.vacation-requests .away-avatar:nth-child(726) {
      z-index: 274; }
    .card-shadow.vacation-requests .away-avatar:nth-child(727) {
      z-index: 273; }
    .card-shadow.vacation-requests .away-avatar:nth-child(728) {
      z-index: 272; }
    .card-shadow.vacation-requests .away-avatar:nth-child(729) {
      z-index: 271; }
    .card-shadow.vacation-requests .away-avatar:nth-child(730) {
      z-index: 270; }
    .card-shadow.vacation-requests .away-avatar:nth-child(731) {
      z-index: 269; }
    .card-shadow.vacation-requests .away-avatar:nth-child(732) {
      z-index: 268; }
    .card-shadow.vacation-requests .away-avatar:nth-child(733) {
      z-index: 267; }
    .card-shadow.vacation-requests .away-avatar:nth-child(734) {
      z-index: 266; }
    .card-shadow.vacation-requests .away-avatar:nth-child(735) {
      z-index: 265; }
    .card-shadow.vacation-requests .away-avatar:nth-child(736) {
      z-index: 264; }
    .card-shadow.vacation-requests .away-avatar:nth-child(737) {
      z-index: 263; }
    .card-shadow.vacation-requests .away-avatar:nth-child(738) {
      z-index: 262; }
    .card-shadow.vacation-requests .away-avatar:nth-child(739) {
      z-index: 261; }
    .card-shadow.vacation-requests .away-avatar:nth-child(740) {
      z-index: 260; }
    .card-shadow.vacation-requests .away-avatar:nth-child(741) {
      z-index: 259; }
    .card-shadow.vacation-requests .away-avatar:nth-child(742) {
      z-index: 258; }
    .card-shadow.vacation-requests .away-avatar:nth-child(743) {
      z-index: 257; }
    .card-shadow.vacation-requests .away-avatar:nth-child(744) {
      z-index: 256; }
    .card-shadow.vacation-requests .away-avatar:nth-child(745) {
      z-index: 255; }
    .card-shadow.vacation-requests .away-avatar:nth-child(746) {
      z-index: 254; }
    .card-shadow.vacation-requests .away-avatar:nth-child(747) {
      z-index: 253; }
    .card-shadow.vacation-requests .away-avatar:nth-child(748) {
      z-index: 252; }
    .card-shadow.vacation-requests .away-avatar:nth-child(749) {
      z-index: 251; }
    .card-shadow.vacation-requests .away-avatar:nth-child(750) {
      z-index: 250; }
    .card-shadow.vacation-requests .away-avatar:nth-child(751) {
      z-index: 249; }
    .card-shadow.vacation-requests .away-avatar:nth-child(752) {
      z-index: 248; }
    .card-shadow.vacation-requests .away-avatar:nth-child(753) {
      z-index: 247; }
    .card-shadow.vacation-requests .away-avatar:nth-child(754) {
      z-index: 246; }
    .card-shadow.vacation-requests .away-avatar:nth-child(755) {
      z-index: 245; }
    .card-shadow.vacation-requests .away-avatar:nth-child(756) {
      z-index: 244; }
    .card-shadow.vacation-requests .away-avatar:nth-child(757) {
      z-index: 243; }
    .card-shadow.vacation-requests .away-avatar:nth-child(758) {
      z-index: 242; }
    .card-shadow.vacation-requests .away-avatar:nth-child(759) {
      z-index: 241; }
    .card-shadow.vacation-requests .away-avatar:nth-child(760) {
      z-index: 240; }
    .card-shadow.vacation-requests .away-avatar:nth-child(761) {
      z-index: 239; }
    .card-shadow.vacation-requests .away-avatar:nth-child(762) {
      z-index: 238; }
    .card-shadow.vacation-requests .away-avatar:nth-child(763) {
      z-index: 237; }
    .card-shadow.vacation-requests .away-avatar:nth-child(764) {
      z-index: 236; }
    .card-shadow.vacation-requests .away-avatar:nth-child(765) {
      z-index: 235; }
    .card-shadow.vacation-requests .away-avatar:nth-child(766) {
      z-index: 234; }
    .card-shadow.vacation-requests .away-avatar:nth-child(767) {
      z-index: 233; }
    .card-shadow.vacation-requests .away-avatar:nth-child(768) {
      z-index: 232; }
    .card-shadow.vacation-requests .away-avatar:nth-child(769) {
      z-index: 231; }
    .card-shadow.vacation-requests .away-avatar:nth-child(770) {
      z-index: 230; }
    .card-shadow.vacation-requests .away-avatar:nth-child(771) {
      z-index: 229; }
    .card-shadow.vacation-requests .away-avatar:nth-child(772) {
      z-index: 228; }
    .card-shadow.vacation-requests .away-avatar:nth-child(773) {
      z-index: 227; }
    .card-shadow.vacation-requests .away-avatar:nth-child(774) {
      z-index: 226; }
    .card-shadow.vacation-requests .away-avatar:nth-child(775) {
      z-index: 225; }
    .card-shadow.vacation-requests .away-avatar:nth-child(776) {
      z-index: 224; }
    .card-shadow.vacation-requests .away-avatar:nth-child(777) {
      z-index: 223; }
    .card-shadow.vacation-requests .away-avatar:nth-child(778) {
      z-index: 222; }
    .card-shadow.vacation-requests .away-avatar:nth-child(779) {
      z-index: 221; }
    .card-shadow.vacation-requests .away-avatar:nth-child(780) {
      z-index: 220; }
    .card-shadow.vacation-requests .away-avatar:nth-child(781) {
      z-index: 219; }
    .card-shadow.vacation-requests .away-avatar:nth-child(782) {
      z-index: 218; }
    .card-shadow.vacation-requests .away-avatar:nth-child(783) {
      z-index: 217; }
    .card-shadow.vacation-requests .away-avatar:nth-child(784) {
      z-index: 216; }
    .card-shadow.vacation-requests .away-avatar:nth-child(785) {
      z-index: 215; }
    .card-shadow.vacation-requests .away-avatar:nth-child(786) {
      z-index: 214; }
    .card-shadow.vacation-requests .away-avatar:nth-child(787) {
      z-index: 213; }
    .card-shadow.vacation-requests .away-avatar:nth-child(788) {
      z-index: 212; }
    .card-shadow.vacation-requests .away-avatar:nth-child(789) {
      z-index: 211; }
    .card-shadow.vacation-requests .away-avatar:nth-child(790) {
      z-index: 210; }
    .card-shadow.vacation-requests .away-avatar:nth-child(791) {
      z-index: 209; }
    .card-shadow.vacation-requests .away-avatar:nth-child(792) {
      z-index: 208; }
    .card-shadow.vacation-requests .away-avatar:nth-child(793) {
      z-index: 207; }
    .card-shadow.vacation-requests .away-avatar:nth-child(794) {
      z-index: 206; }
    .card-shadow.vacation-requests .away-avatar:nth-child(795) {
      z-index: 205; }
    .card-shadow.vacation-requests .away-avatar:nth-child(796) {
      z-index: 204; }
    .card-shadow.vacation-requests .away-avatar:nth-child(797) {
      z-index: 203; }
    .card-shadow.vacation-requests .away-avatar:nth-child(798) {
      z-index: 202; }
    .card-shadow.vacation-requests .away-avatar:nth-child(799) {
      z-index: 201; }
    .card-shadow.vacation-requests .away-avatar:nth-child(800) {
      z-index: 200; }
    .card-shadow.vacation-requests .away-avatar:nth-child(801) {
      z-index: 199; }
    .card-shadow.vacation-requests .away-avatar:nth-child(802) {
      z-index: 198; }
    .card-shadow.vacation-requests .away-avatar:nth-child(803) {
      z-index: 197; }
    .card-shadow.vacation-requests .away-avatar:nth-child(804) {
      z-index: 196; }
    .card-shadow.vacation-requests .away-avatar:nth-child(805) {
      z-index: 195; }
    .card-shadow.vacation-requests .away-avatar:nth-child(806) {
      z-index: 194; }
    .card-shadow.vacation-requests .away-avatar:nth-child(807) {
      z-index: 193; }
    .card-shadow.vacation-requests .away-avatar:nth-child(808) {
      z-index: 192; }
    .card-shadow.vacation-requests .away-avatar:nth-child(809) {
      z-index: 191; }
    .card-shadow.vacation-requests .away-avatar:nth-child(810) {
      z-index: 190; }
    .card-shadow.vacation-requests .away-avatar:nth-child(811) {
      z-index: 189; }
    .card-shadow.vacation-requests .away-avatar:nth-child(812) {
      z-index: 188; }
    .card-shadow.vacation-requests .away-avatar:nth-child(813) {
      z-index: 187; }
    .card-shadow.vacation-requests .away-avatar:nth-child(814) {
      z-index: 186; }
    .card-shadow.vacation-requests .away-avatar:nth-child(815) {
      z-index: 185; }
    .card-shadow.vacation-requests .away-avatar:nth-child(816) {
      z-index: 184; }
    .card-shadow.vacation-requests .away-avatar:nth-child(817) {
      z-index: 183; }
    .card-shadow.vacation-requests .away-avatar:nth-child(818) {
      z-index: 182; }
    .card-shadow.vacation-requests .away-avatar:nth-child(819) {
      z-index: 181; }
    .card-shadow.vacation-requests .away-avatar:nth-child(820) {
      z-index: 180; }
    .card-shadow.vacation-requests .away-avatar:nth-child(821) {
      z-index: 179; }
    .card-shadow.vacation-requests .away-avatar:nth-child(822) {
      z-index: 178; }
    .card-shadow.vacation-requests .away-avatar:nth-child(823) {
      z-index: 177; }
    .card-shadow.vacation-requests .away-avatar:nth-child(824) {
      z-index: 176; }
    .card-shadow.vacation-requests .away-avatar:nth-child(825) {
      z-index: 175; }
    .card-shadow.vacation-requests .away-avatar:nth-child(826) {
      z-index: 174; }
    .card-shadow.vacation-requests .away-avatar:nth-child(827) {
      z-index: 173; }
    .card-shadow.vacation-requests .away-avatar:nth-child(828) {
      z-index: 172; }
    .card-shadow.vacation-requests .away-avatar:nth-child(829) {
      z-index: 171; }
    .card-shadow.vacation-requests .away-avatar:nth-child(830) {
      z-index: 170; }
    .card-shadow.vacation-requests .away-avatar:nth-child(831) {
      z-index: 169; }
    .card-shadow.vacation-requests .away-avatar:nth-child(832) {
      z-index: 168; }
    .card-shadow.vacation-requests .away-avatar:nth-child(833) {
      z-index: 167; }
    .card-shadow.vacation-requests .away-avatar:nth-child(834) {
      z-index: 166; }
    .card-shadow.vacation-requests .away-avatar:nth-child(835) {
      z-index: 165; }
    .card-shadow.vacation-requests .away-avatar:nth-child(836) {
      z-index: 164; }
    .card-shadow.vacation-requests .away-avatar:nth-child(837) {
      z-index: 163; }
    .card-shadow.vacation-requests .away-avatar:nth-child(838) {
      z-index: 162; }
    .card-shadow.vacation-requests .away-avatar:nth-child(839) {
      z-index: 161; }
    .card-shadow.vacation-requests .away-avatar:nth-child(840) {
      z-index: 160; }
    .card-shadow.vacation-requests .away-avatar:nth-child(841) {
      z-index: 159; }
    .card-shadow.vacation-requests .away-avatar:nth-child(842) {
      z-index: 158; }
    .card-shadow.vacation-requests .away-avatar:nth-child(843) {
      z-index: 157; }
    .card-shadow.vacation-requests .away-avatar:nth-child(844) {
      z-index: 156; }
    .card-shadow.vacation-requests .away-avatar:nth-child(845) {
      z-index: 155; }
    .card-shadow.vacation-requests .away-avatar:nth-child(846) {
      z-index: 154; }
    .card-shadow.vacation-requests .away-avatar:nth-child(847) {
      z-index: 153; }
    .card-shadow.vacation-requests .away-avatar:nth-child(848) {
      z-index: 152; }
    .card-shadow.vacation-requests .away-avatar:nth-child(849) {
      z-index: 151; }
    .card-shadow.vacation-requests .away-avatar:nth-child(850) {
      z-index: 150; }
    .card-shadow.vacation-requests .away-avatar:nth-child(851) {
      z-index: 149; }
    .card-shadow.vacation-requests .away-avatar:nth-child(852) {
      z-index: 148; }
    .card-shadow.vacation-requests .away-avatar:nth-child(853) {
      z-index: 147; }
    .card-shadow.vacation-requests .away-avatar:nth-child(854) {
      z-index: 146; }
    .card-shadow.vacation-requests .away-avatar:nth-child(855) {
      z-index: 145; }
    .card-shadow.vacation-requests .away-avatar:nth-child(856) {
      z-index: 144; }
    .card-shadow.vacation-requests .away-avatar:nth-child(857) {
      z-index: 143; }
    .card-shadow.vacation-requests .away-avatar:nth-child(858) {
      z-index: 142; }
    .card-shadow.vacation-requests .away-avatar:nth-child(859) {
      z-index: 141; }
    .card-shadow.vacation-requests .away-avatar:nth-child(860) {
      z-index: 140; }
    .card-shadow.vacation-requests .away-avatar:nth-child(861) {
      z-index: 139; }
    .card-shadow.vacation-requests .away-avatar:nth-child(862) {
      z-index: 138; }
    .card-shadow.vacation-requests .away-avatar:nth-child(863) {
      z-index: 137; }
    .card-shadow.vacation-requests .away-avatar:nth-child(864) {
      z-index: 136; }
    .card-shadow.vacation-requests .away-avatar:nth-child(865) {
      z-index: 135; }
    .card-shadow.vacation-requests .away-avatar:nth-child(866) {
      z-index: 134; }
    .card-shadow.vacation-requests .away-avatar:nth-child(867) {
      z-index: 133; }
    .card-shadow.vacation-requests .away-avatar:nth-child(868) {
      z-index: 132; }
    .card-shadow.vacation-requests .away-avatar:nth-child(869) {
      z-index: 131; }
    .card-shadow.vacation-requests .away-avatar:nth-child(870) {
      z-index: 130; }
    .card-shadow.vacation-requests .away-avatar:nth-child(871) {
      z-index: 129; }
    .card-shadow.vacation-requests .away-avatar:nth-child(872) {
      z-index: 128; }
    .card-shadow.vacation-requests .away-avatar:nth-child(873) {
      z-index: 127; }
    .card-shadow.vacation-requests .away-avatar:nth-child(874) {
      z-index: 126; }
    .card-shadow.vacation-requests .away-avatar:nth-child(875) {
      z-index: 125; }
    .card-shadow.vacation-requests .away-avatar:nth-child(876) {
      z-index: 124; }
    .card-shadow.vacation-requests .away-avatar:nth-child(877) {
      z-index: 123; }
    .card-shadow.vacation-requests .away-avatar:nth-child(878) {
      z-index: 122; }
    .card-shadow.vacation-requests .away-avatar:nth-child(879) {
      z-index: 121; }
    .card-shadow.vacation-requests .away-avatar:nth-child(880) {
      z-index: 120; }
    .card-shadow.vacation-requests .away-avatar:nth-child(881) {
      z-index: 119; }
    .card-shadow.vacation-requests .away-avatar:nth-child(882) {
      z-index: 118; }
    .card-shadow.vacation-requests .away-avatar:nth-child(883) {
      z-index: 117; }
    .card-shadow.vacation-requests .away-avatar:nth-child(884) {
      z-index: 116; }
    .card-shadow.vacation-requests .away-avatar:nth-child(885) {
      z-index: 115; }
    .card-shadow.vacation-requests .away-avatar:nth-child(886) {
      z-index: 114; }
    .card-shadow.vacation-requests .away-avatar:nth-child(887) {
      z-index: 113; }
    .card-shadow.vacation-requests .away-avatar:nth-child(888) {
      z-index: 112; }
    .card-shadow.vacation-requests .away-avatar:nth-child(889) {
      z-index: 111; }
    .card-shadow.vacation-requests .away-avatar:nth-child(890) {
      z-index: 110; }
    .card-shadow.vacation-requests .away-avatar:nth-child(891) {
      z-index: 109; }
    .card-shadow.vacation-requests .away-avatar:nth-child(892) {
      z-index: 108; }
    .card-shadow.vacation-requests .away-avatar:nth-child(893) {
      z-index: 107; }
    .card-shadow.vacation-requests .away-avatar:nth-child(894) {
      z-index: 106; }
    .card-shadow.vacation-requests .away-avatar:nth-child(895) {
      z-index: 105; }
    .card-shadow.vacation-requests .away-avatar:nth-child(896) {
      z-index: 104; }
    .card-shadow.vacation-requests .away-avatar:nth-child(897) {
      z-index: 103; }
    .card-shadow.vacation-requests .away-avatar:nth-child(898) {
      z-index: 102; }
    .card-shadow.vacation-requests .away-avatar:nth-child(899) {
      z-index: 101; }
    .card-shadow.vacation-requests .away-avatar:nth-child(900) {
      z-index: 100; }
    .card-shadow.vacation-requests .away-avatar:nth-child(901) {
      z-index: 99; }
    .card-shadow.vacation-requests .away-avatar:nth-child(902) {
      z-index: 98; }
    .card-shadow.vacation-requests .away-avatar:nth-child(903) {
      z-index: 97; }
    .card-shadow.vacation-requests .away-avatar:nth-child(904) {
      z-index: 96; }
    .card-shadow.vacation-requests .away-avatar:nth-child(905) {
      z-index: 95; }
    .card-shadow.vacation-requests .away-avatar:nth-child(906) {
      z-index: 94; }
    .card-shadow.vacation-requests .away-avatar:nth-child(907) {
      z-index: 93; }
    .card-shadow.vacation-requests .away-avatar:nth-child(908) {
      z-index: 92; }
    .card-shadow.vacation-requests .away-avatar:nth-child(909) {
      z-index: 91; }
    .card-shadow.vacation-requests .away-avatar:nth-child(910) {
      z-index: 90; }
    .card-shadow.vacation-requests .away-avatar:nth-child(911) {
      z-index: 89; }
    .card-shadow.vacation-requests .away-avatar:nth-child(912) {
      z-index: 88; }
    .card-shadow.vacation-requests .away-avatar:nth-child(913) {
      z-index: 87; }
    .card-shadow.vacation-requests .away-avatar:nth-child(914) {
      z-index: 86; }
    .card-shadow.vacation-requests .away-avatar:nth-child(915) {
      z-index: 85; }
    .card-shadow.vacation-requests .away-avatar:nth-child(916) {
      z-index: 84; }
    .card-shadow.vacation-requests .away-avatar:nth-child(917) {
      z-index: 83; }
    .card-shadow.vacation-requests .away-avatar:nth-child(918) {
      z-index: 82; }
    .card-shadow.vacation-requests .away-avatar:nth-child(919) {
      z-index: 81; }
    .card-shadow.vacation-requests .away-avatar:nth-child(920) {
      z-index: 80; }
    .card-shadow.vacation-requests .away-avatar:nth-child(921) {
      z-index: 79; }
    .card-shadow.vacation-requests .away-avatar:nth-child(922) {
      z-index: 78; }
    .card-shadow.vacation-requests .away-avatar:nth-child(923) {
      z-index: 77; }
    .card-shadow.vacation-requests .away-avatar:nth-child(924) {
      z-index: 76; }
    .card-shadow.vacation-requests .away-avatar:nth-child(925) {
      z-index: 75; }
    .card-shadow.vacation-requests .away-avatar:nth-child(926) {
      z-index: 74; }
    .card-shadow.vacation-requests .away-avatar:nth-child(927) {
      z-index: 73; }
    .card-shadow.vacation-requests .away-avatar:nth-child(928) {
      z-index: 72; }
    .card-shadow.vacation-requests .away-avatar:nth-child(929) {
      z-index: 71; }
    .card-shadow.vacation-requests .away-avatar:nth-child(930) {
      z-index: 70; }
    .card-shadow.vacation-requests .away-avatar:nth-child(931) {
      z-index: 69; }
    .card-shadow.vacation-requests .away-avatar:nth-child(932) {
      z-index: 68; }
    .card-shadow.vacation-requests .away-avatar:nth-child(933) {
      z-index: 67; }
    .card-shadow.vacation-requests .away-avatar:nth-child(934) {
      z-index: 66; }
    .card-shadow.vacation-requests .away-avatar:nth-child(935) {
      z-index: 65; }
    .card-shadow.vacation-requests .away-avatar:nth-child(936) {
      z-index: 64; }
    .card-shadow.vacation-requests .away-avatar:nth-child(937) {
      z-index: 63; }
    .card-shadow.vacation-requests .away-avatar:nth-child(938) {
      z-index: 62; }
    .card-shadow.vacation-requests .away-avatar:nth-child(939) {
      z-index: 61; }
    .card-shadow.vacation-requests .away-avatar:nth-child(940) {
      z-index: 60; }
    .card-shadow.vacation-requests .away-avatar:nth-child(941) {
      z-index: 59; }
    .card-shadow.vacation-requests .away-avatar:nth-child(942) {
      z-index: 58; }
    .card-shadow.vacation-requests .away-avatar:nth-child(943) {
      z-index: 57; }
    .card-shadow.vacation-requests .away-avatar:nth-child(944) {
      z-index: 56; }
    .card-shadow.vacation-requests .away-avatar:nth-child(945) {
      z-index: 55; }
    .card-shadow.vacation-requests .away-avatar:nth-child(946) {
      z-index: 54; }
    .card-shadow.vacation-requests .away-avatar:nth-child(947) {
      z-index: 53; }
    .card-shadow.vacation-requests .away-avatar:nth-child(948) {
      z-index: 52; }
    .card-shadow.vacation-requests .away-avatar:nth-child(949) {
      z-index: 51; }
    .card-shadow.vacation-requests .away-avatar:nth-child(950) {
      z-index: 50; }
    .card-shadow.vacation-requests .away-avatar:nth-child(951) {
      z-index: 49; }
    .card-shadow.vacation-requests .away-avatar:nth-child(952) {
      z-index: 48; }
    .card-shadow.vacation-requests .away-avatar:nth-child(953) {
      z-index: 47; }
    .card-shadow.vacation-requests .away-avatar:nth-child(954) {
      z-index: 46; }
    .card-shadow.vacation-requests .away-avatar:nth-child(955) {
      z-index: 45; }
    .card-shadow.vacation-requests .away-avatar:nth-child(956) {
      z-index: 44; }
    .card-shadow.vacation-requests .away-avatar:nth-child(957) {
      z-index: 43; }
    .card-shadow.vacation-requests .away-avatar:nth-child(958) {
      z-index: 42; }
    .card-shadow.vacation-requests .away-avatar:nth-child(959) {
      z-index: 41; }
    .card-shadow.vacation-requests .away-avatar:nth-child(960) {
      z-index: 40; }
    .card-shadow.vacation-requests .away-avatar:nth-child(961) {
      z-index: 39; }
    .card-shadow.vacation-requests .away-avatar:nth-child(962) {
      z-index: 38; }
    .card-shadow.vacation-requests .away-avatar:nth-child(963) {
      z-index: 37; }
    .card-shadow.vacation-requests .away-avatar:nth-child(964) {
      z-index: 36; }
    .card-shadow.vacation-requests .away-avatar:nth-child(965) {
      z-index: 35; }
    .card-shadow.vacation-requests .away-avatar:nth-child(966) {
      z-index: 34; }
    .card-shadow.vacation-requests .away-avatar:nth-child(967) {
      z-index: 33; }
    .card-shadow.vacation-requests .away-avatar:nth-child(968) {
      z-index: 32; }
    .card-shadow.vacation-requests .away-avatar:nth-child(969) {
      z-index: 31; }
    .card-shadow.vacation-requests .away-avatar:nth-child(970) {
      z-index: 30; }
    .card-shadow.vacation-requests .away-avatar:nth-child(971) {
      z-index: 29; }
    .card-shadow.vacation-requests .away-avatar:nth-child(972) {
      z-index: 28; }
    .card-shadow.vacation-requests .away-avatar:nth-child(973) {
      z-index: 27; }
    .card-shadow.vacation-requests .away-avatar:nth-child(974) {
      z-index: 26; }
    .card-shadow.vacation-requests .away-avatar:nth-child(975) {
      z-index: 25; }
    .card-shadow.vacation-requests .away-avatar:nth-child(976) {
      z-index: 24; }
    .card-shadow.vacation-requests .away-avatar:nth-child(977) {
      z-index: 23; }
    .card-shadow.vacation-requests .away-avatar:nth-child(978) {
      z-index: 22; }
    .card-shadow.vacation-requests .away-avatar:nth-child(979) {
      z-index: 21; }
    .card-shadow.vacation-requests .away-avatar:nth-child(980) {
      z-index: 20; }
    .card-shadow.vacation-requests .away-avatar:nth-child(981) {
      z-index: 19; }
    .card-shadow.vacation-requests .away-avatar:nth-child(982) {
      z-index: 18; }
    .card-shadow.vacation-requests .away-avatar:nth-child(983) {
      z-index: 17; }
    .card-shadow.vacation-requests .away-avatar:nth-child(984) {
      z-index: 16; }
    .card-shadow.vacation-requests .away-avatar:nth-child(985) {
      z-index: 15; }
    .card-shadow.vacation-requests .away-avatar:nth-child(986) {
      z-index: 14; }
    .card-shadow.vacation-requests .away-avatar:nth-child(987) {
      z-index: 13; }
    .card-shadow.vacation-requests .away-avatar:nth-child(988) {
      z-index: 12; }
    .card-shadow.vacation-requests .away-avatar:nth-child(989) {
      z-index: 11; }
    .card-shadow.vacation-requests .away-avatar:nth-child(990) {
      z-index: 10; }
    .card-shadow.vacation-requests .away-avatar:nth-child(991) {
      z-index: 9; }
    .card-shadow.vacation-requests .away-avatar:nth-child(992) {
      z-index: 8; }
    .card-shadow.vacation-requests .away-avatar:nth-child(993) {
      z-index: 7; }
    .card-shadow.vacation-requests .away-avatar:nth-child(994) {
      z-index: 6; }
    .card-shadow.vacation-requests .away-avatar:nth-child(995) {
      z-index: 5; }
    .card-shadow.vacation-requests .away-avatar:nth-child(996) {
      z-index: 4; }
    .card-shadow.vacation-requests .away-avatar:nth-child(997) {
      z-index: 3; }
    .card-shadow.vacation-requests .away-avatar:nth-child(998) {
      z-index: 2; }
    .card-shadow.vacation-requests .away-avatar:nth-child(999) {
      z-index: 1; }

.card-shadow.upcoming-vacations {
  padding-bottom: 10px;
  padding-top: 20px; }
  .card-shadow.upcoming-vacations .top-row {
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 20px; }
  .card-shadow.upcoming-vacations h3 {
    margin-bottom: 0; }
  .card-shadow.upcoming-vacations p.when {
    color: #a5adbc;
    font-size: 12px;
    font-family: "Mark Pro Book";
    margin-bottom: 13px; }
  .card-shadow.upcoming-vacations .away-avatar {
    display: inline-block;
    border: solid 2px #ffffff;
    border-radius: 50%;
    margin-right: -13px;
    margin-bottom: 20px;
    position: relative; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(0) {
      z-index: 1000; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(1) {
      z-index: 999; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(2) {
      z-index: 998; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(3) {
      z-index: 997; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(4) {
      z-index: 996; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(5) {
      z-index: 995; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(6) {
      z-index: 994; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(7) {
      z-index: 993; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(8) {
      z-index: 992; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(9) {
      z-index: 991; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(10) {
      z-index: 990; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(11) {
      z-index: 989; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(12) {
      z-index: 988; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(13) {
      z-index: 987; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(14) {
      z-index: 986; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(15) {
      z-index: 985; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(16) {
      z-index: 984; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(17) {
      z-index: 983; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(18) {
      z-index: 982; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(19) {
      z-index: 981; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(20) {
      z-index: 980; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(21) {
      z-index: 979; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(22) {
      z-index: 978; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(23) {
      z-index: 977; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(24) {
      z-index: 976; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(25) {
      z-index: 975; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(26) {
      z-index: 974; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(27) {
      z-index: 973; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(28) {
      z-index: 972; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(29) {
      z-index: 971; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(30) {
      z-index: 970; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(31) {
      z-index: 969; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(32) {
      z-index: 968; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(33) {
      z-index: 967; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(34) {
      z-index: 966; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(35) {
      z-index: 965; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(36) {
      z-index: 964; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(37) {
      z-index: 963; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(38) {
      z-index: 962; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(39) {
      z-index: 961; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(40) {
      z-index: 960; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(41) {
      z-index: 959; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(42) {
      z-index: 958; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(43) {
      z-index: 957; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(44) {
      z-index: 956; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(45) {
      z-index: 955; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(46) {
      z-index: 954; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(47) {
      z-index: 953; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(48) {
      z-index: 952; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(49) {
      z-index: 951; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(50) {
      z-index: 950; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(51) {
      z-index: 949; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(52) {
      z-index: 948; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(53) {
      z-index: 947; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(54) {
      z-index: 946; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(55) {
      z-index: 945; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(56) {
      z-index: 944; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(57) {
      z-index: 943; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(58) {
      z-index: 942; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(59) {
      z-index: 941; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(60) {
      z-index: 940; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(61) {
      z-index: 939; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(62) {
      z-index: 938; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(63) {
      z-index: 937; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(64) {
      z-index: 936; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(65) {
      z-index: 935; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(66) {
      z-index: 934; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(67) {
      z-index: 933; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(68) {
      z-index: 932; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(69) {
      z-index: 931; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(70) {
      z-index: 930; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(71) {
      z-index: 929; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(72) {
      z-index: 928; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(73) {
      z-index: 927; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(74) {
      z-index: 926; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(75) {
      z-index: 925; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(76) {
      z-index: 924; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(77) {
      z-index: 923; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(78) {
      z-index: 922; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(79) {
      z-index: 921; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(80) {
      z-index: 920; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(81) {
      z-index: 919; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(82) {
      z-index: 918; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(83) {
      z-index: 917; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(84) {
      z-index: 916; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(85) {
      z-index: 915; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(86) {
      z-index: 914; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(87) {
      z-index: 913; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(88) {
      z-index: 912; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(89) {
      z-index: 911; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(90) {
      z-index: 910; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(91) {
      z-index: 909; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(92) {
      z-index: 908; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(93) {
      z-index: 907; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(94) {
      z-index: 906; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(95) {
      z-index: 905; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(96) {
      z-index: 904; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(97) {
      z-index: 903; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(98) {
      z-index: 902; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(99) {
      z-index: 901; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(100) {
      z-index: 900; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(101) {
      z-index: 899; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(102) {
      z-index: 898; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(103) {
      z-index: 897; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(104) {
      z-index: 896; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(105) {
      z-index: 895; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(106) {
      z-index: 894; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(107) {
      z-index: 893; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(108) {
      z-index: 892; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(109) {
      z-index: 891; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(110) {
      z-index: 890; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(111) {
      z-index: 889; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(112) {
      z-index: 888; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(113) {
      z-index: 887; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(114) {
      z-index: 886; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(115) {
      z-index: 885; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(116) {
      z-index: 884; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(117) {
      z-index: 883; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(118) {
      z-index: 882; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(119) {
      z-index: 881; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(120) {
      z-index: 880; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(121) {
      z-index: 879; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(122) {
      z-index: 878; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(123) {
      z-index: 877; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(124) {
      z-index: 876; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(125) {
      z-index: 875; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(126) {
      z-index: 874; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(127) {
      z-index: 873; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(128) {
      z-index: 872; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(129) {
      z-index: 871; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(130) {
      z-index: 870; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(131) {
      z-index: 869; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(132) {
      z-index: 868; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(133) {
      z-index: 867; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(134) {
      z-index: 866; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(135) {
      z-index: 865; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(136) {
      z-index: 864; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(137) {
      z-index: 863; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(138) {
      z-index: 862; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(139) {
      z-index: 861; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(140) {
      z-index: 860; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(141) {
      z-index: 859; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(142) {
      z-index: 858; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(143) {
      z-index: 857; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(144) {
      z-index: 856; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(145) {
      z-index: 855; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(146) {
      z-index: 854; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(147) {
      z-index: 853; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(148) {
      z-index: 852; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(149) {
      z-index: 851; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(150) {
      z-index: 850; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(151) {
      z-index: 849; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(152) {
      z-index: 848; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(153) {
      z-index: 847; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(154) {
      z-index: 846; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(155) {
      z-index: 845; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(156) {
      z-index: 844; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(157) {
      z-index: 843; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(158) {
      z-index: 842; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(159) {
      z-index: 841; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(160) {
      z-index: 840; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(161) {
      z-index: 839; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(162) {
      z-index: 838; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(163) {
      z-index: 837; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(164) {
      z-index: 836; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(165) {
      z-index: 835; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(166) {
      z-index: 834; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(167) {
      z-index: 833; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(168) {
      z-index: 832; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(169) {
      z-index: 831; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(170) {
      z-index: 830; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(171) {
      z-index: 829; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(172) {
      z-index: 828; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(173) {
      z-index: 827; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(174) {
      z-index: 826; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(175) {
      z-index: 825; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(176) {
      z-index: 824; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(177) {
      z-index: 823; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(178) {
      z-index: 822; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(179) {
      z-index: 821; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(180) {
      z-index: 820; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(181) {
      z-index: 819; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(182) {
      z-index: 818; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(183) {
      z-index: 817; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(184) {
      z-index: 816; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(185) {
      z-index: 815; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(186) {
      z-index: 814; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(187) {
      z-index: 813; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(188) {
      z-index: 812; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(189) {
      z-index: 811; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(190) {
      z-index: 810; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(191) {
      z-index: 809; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(192) {
      z-index: 808; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(193) {
      z-index: 807; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(194) {
      z-index: 806; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(195) {
      z-index: 805; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(196) {
      z-index: 804; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(197) {
      z-index: 803; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(198) {
      z-index: 802; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(199) {
      z-index: 801; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(200) {
      z-index: 800; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(201) {
      z-index: 799; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(202) {
      z-index: 798; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(203) {
      z-index: 797; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(204) {
      z-index: 796; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(205) {
      z-index: 795; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(206) {
      z-index: 794; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(207) {
      z-index: 793; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(208) {
      z-index: 792; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(209) {
      z-index: 791; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(210) {
      z-index: 790; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(211) {
      z-index: 789; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(212) {
      z-index: 788; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(213) {
      z-index: 787; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(214) {
      z-index: 786; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(215) {
      z-index: 785; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(216) {
      z-index: 784; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(217) {
      z-index: 783; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(218) {
      z-index: 782; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(219) {
      z-index: 781; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(220) {
      z-index: 780; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(221) {
      z-index: 779; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(222) {
      z-index: 778; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(223) {
      z-index: 777; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(224) {
      z-index: 776; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(225) {
      z-index: 775; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(226) {
      z-index: 774; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(227) {
      z-index: 773; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(228) {
      z-index: 772; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(229) {
      z-index: 771; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(230) {
      z-index: 770; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(231) {
      z-index: 769; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(232) {
      z-index: 768; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(233) {
      z-index: 767; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(234) {
      z-index: 766; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(235) {
      z-index: 765; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(236) {
      z-index: 764; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(237) {
      z-index: 763; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(238) {
      z-index: 762; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(239) {
      z-index: 761; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(240) {
      z-index: 760; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(241) {
      z-index: 759; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(242) {
      z-index: 758; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(243) {
      z-index: 757; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(244) {
      z-index: 756; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(245) {
      z-index: 755; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(246) {
      z-index: 754; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(247) {
      z-index: 753; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(248) {
      z-index: 752; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(249) {
      z-index: 751; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(250) {
      z-index: 750; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(251) {
      z-index: 749; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(252) {
      z-index: 748; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(253) {
      z-index: 747; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(254) {
      z-index: 746; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(255) {
      z-index: 745; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(256) {
      z-index: 744; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(257) {
      z-index: 743; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(258) {
      z-index: 742; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(259) {
      z-index: 741; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(260) {
      z-index: 740; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(261) {
      z-index: 739; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(262) {
      z-index: 738; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(263) {
      z-index: 737; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(264) {
      z-index: 736; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(265) {
      z-index: 735; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(266) {
      z-index: 734; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(267) {
      z-index: 733; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(268) {
      z-index: 732; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(269) {
      z-index: 731; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(270) {
      z-index: 730; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(271) {
      z-index: 729; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(272) {
      z-index: 728; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(273) {
      z-index: 727; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(274) {
      z-index: 726; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(275) {
      z-index: 725; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(276) {
      z-index: 724; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(277) {
      z-index: 723; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(278) {
      z-index: 722; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(279) {
      z-index: 721; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(280) {
      z-index: 720; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(281) {
      z-index: 719; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(282) {
      z-index: 718; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(283) {
      z-index: 717; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(284) {
      z-index: 716; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(285) {
      z-index: 715; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(286) {
      z-index: 714; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(287) {
      z-index: 713; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(288) {
      z-index: 712; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(289) {
      z-index: 711; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(290) {
      z-index: 710; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(291) {
      z-index: 709; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(292) {
      z-index: 708; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(293) {
      z-index: 707; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(294) {
      z-index: 706; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(295) {
      z-index: 705; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(296) {
      z-index: 704; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(297) {
      z-index: 703; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(298) {
      z-index: 702; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(299) {
      z-index: 701; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(300) {
      z-index: 700; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(301) {
      z-index: 699; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(302) {
      z-index: 698; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(303) {
      z-index: 697; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(304) {
      z-index: 696; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(305) {
      z-index: 695; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(306) {
      z-index: 694; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(307) {
      z-index: 693; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(308) {
      z-index: 692; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(309) {
      z-index: 691; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(310) {
      z-index: 690; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(311) {
      z-index: 689; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(312) {
      z-index: 688; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(313) {
      z-index: 687; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(314) {
      z-index: 686; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(315) {
      z-index: 685; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(316) {
      z-index: 684; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(317) {
      z-index: 683; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(318) {
      z-index: 682; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(319) {
      z-index: 681; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(320) {
      z-index: 680; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(321) {
      z-index: 679; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(322) {
      z-index: 678; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(323) {
      z-index: 677; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(324) {
      z-index: 676; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(325) {
      z-index: 675; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(326) {
      z-index: 674; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(327) {
      z-index: 673; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(328) {
      z-index: 672; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(329) {
      z-index: 671; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(330) {
      z-index: 670; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(331) {
      z-index: 669; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(332) {
      z-index: 668; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(333) {
      z-index: 667; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(334) {
      z-index: 666; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(335) {
      z-index: 665; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(336) {
      z-index: 664; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(337) {
      z-index: 663; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(338) {
      z-index: 662; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(339) {
      z-index: 661; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(340) {
      z-index: 660; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(341) {
      z-index: 659; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(342) {
      z-index: 658; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(343) {
      z-index: 657; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(344) {
      z-index: 656; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(345) {
      z-index: 655; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(346) {
      z-index: 654; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(347) {
      z-index: 653; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(348) {
      z-index: 652; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(349) {
      z-index: 651; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(350) {
      z-index: 650; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(351) {
      z-index: 649; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(352) {
      z-index: 648; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(353) {
      z-index: 647; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(354) {
      z-index: 646; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(355) {
      z-index: 645; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(356) {
      z-index: 644; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(357) {
      z-index: 643; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(358) {
      z-index: 642; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(359) {
      z-index: 641; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(360) {
      z-index: 640; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(361) {
      z-index: 639; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(362) {
      z-index: 638; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(363) {
      z-index: 637; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(364) {
      z-index: 636; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(365) {
      z-index: 635; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(366) {
      z-index: 634; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(367) {
      z-index: 633; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(368) {
      z-index: 632; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(369) {
      z-index: 631; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(370) {
      z-index: 630; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(371) {
      z-index: 629; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(372) {
      z-index: 628; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(373) {
      z-index: 627; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(374) {
      z-index: 626; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(375) {
      z-index: 625; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(376) {
      z-index: 624; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(377) {
      z-index: 623; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(378) {
      z-index: 622; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(379) {
      z-index: 621; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(380) {
      z-index: 620; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(381) {
      z-index: 619; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(382) {
      z-index: 618; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(383) {
      z-index: 617; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(384) {
      z-index: 616; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(385) {
      z-index: 615; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(386) {
      z-index: 614; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(387) {
      z-index: 613; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(388) {
      z-index: 612; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(389) {
      z-index: 611; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(390) {
      z-index: 610; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(391) {
      z-index: 609; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(392) {
      z-index: 608; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(393) {
      z-index: 607; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(394) {
      z-index: 606; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(395) {
      z-index: 605; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(396) {
      z-index: 604; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(397) {
      z-index: 603; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(398) {
      z-index: 602; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(399) {
      z-index: 601; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(400) {
      z-index: 600; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(401) {
      z-index: 599; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(402) {
      z-index: 598; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(403) {
      z-index: 597; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(404) {
      z-index: 596; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(405) {
      z-index: 595; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(406) {
      z-index: 594; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(407) {
      z-index: 593; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(408) {
      z-index: 592; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(409) {
      z-index: 591; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(410) {
      z-index: 590; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(411) {
      z-index: 589; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(412) {
      z-index: 588; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(413) {
      z-index: 587; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(414) {
      z-index: 586; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(415) {
      z-index: 585; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(416) {
      z-index: 584; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(417) {
      z-index: 583; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(418) {
      z-index: 582; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(419) {
      z-index: 581; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(420) {
      z-index: 580; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(421) {
      z-index: 579; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(422) {
      z-index: 578; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(423) {
      z-index: 577; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(424) {
      z-index: 576; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(425) {
      z-index: 575; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(426) {
      z-index: 574; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(427) {
      z-index: 573; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(428) {
      z-index: 572; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(429) {
      z-index: 571; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(430) {
      z-index: 570; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(431) {
      z-index: 569; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(432) {
      z-index: 568; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(433) {
      z-index: 567; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(434) {
      z-index: 566; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(435) {
      z-index: 565; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(436) {
      z-index: 564; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(437) {
      z-index: 563; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(438) {
      z-index: 562; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(439) {
      z-index: 561; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(440) {
      z-index: 560; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(441) {
      z-index: 559; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(442) {
      z-index: 558; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(443) {
      z-index: 557; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(444) {
      z-index: 556; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(445) {
      z-index: 555; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(446) {
      z-index: 554; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(447) {
      z-index: 553; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(448) {
      z-index: 552; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(449) {
      z-index: 551; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(450) {
      z-index: 550; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(451) {
      z-index: 549; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(452) {
      z-index: 548; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(453) {
      z-index: 547; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(454) {
      z-index: 546; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(455) {
      z-index: 545; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(456) {
      z-index: 544; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(457) {
      z-index: 543; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(458) {
      z-index: 542; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(459) {
      z-index: 541; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(460) {
      z-index: 540; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(461) {
      z-index: 539; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(462) {
      z-index: 538; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(463) {
      z-index: 537; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(464) {
      z-index: 536; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(465) {
      z-index: 535; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(466) {
      z-index: 534; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(467) {
      z-index: 533; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(468) {
      z-index: 532; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(469) {
      z-index: 531; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(470) {
      z-index: 530; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(471) {
      z-index: 529; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(472) {
      z-index: 528; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(473) {
      z-index: 527; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(474) {
      z-index: 526; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(475) {
      z-index: 525; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(476) {
      z-index: 524; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(477) {
      z-index: 523; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(478) {
      z-index: 522; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(479) {
      z-index: 521; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(480) {
      z-index: 520; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(481) {
      z-index: 519; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(482) {
      z-index: 518; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(483) {
      z-index: 517; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(484) {
      z-index: 516; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(485) {
      z-index: 515; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(486) {
      z-index: 514; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(487) {
      z-index: 513; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(488) {
      z-index: 512; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(489) {
      z-index: 511; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(490) {
      z-index: 510; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(491) {
      z-index: 509; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(492) {
      z-index: 508; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(493) {
      z-index: 507; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(494) {
      z-index: 506; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(495) {
      z-index: 505; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(496) {
      z-index: 504; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(497) {
      z-index: 503; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(498) {
      z-index: 502; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(499) {
      z-index: 501; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(500) {
      z-index: 500; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(501) {
      z-index: 499; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(502) {
      z-index: 498; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(503) {
      z-index: 497; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(504) {
      z-index: 496; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(505) {
      z-index: 495; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(506) {
      z-index: 494; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(507) {
      z-index: 493; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(508) {
      z-index: 492; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(509) {
      z-index: 491; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(510) {
      z-index: 490; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(511) {
      z-index: 489; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(512) {
      z-index: 488; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(513) {
      z-index: 487; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(514) {
      z-index: 486; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(515) {
      z-index: 485; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(516) {
      z-index: 484; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(517) {
      z-index: 483; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(518) {
      z-index: 482; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(519) {
      z-index: 481; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(520) {
      z-index: 480; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(521) {
      z-index: 479; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(522) {
      z-index: 478; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(523) {
      z-index: 477; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(524) {
      z-index: 476; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(525) {
      z-index: 475; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(526) {
      z-index: 474; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(527) {
      z-index: 473; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(528) {
      z-index: 472; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(529) {
      z-index: 471; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(530) {
      z-index: 470; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(531) {
      z-index: 469; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(532) {
      z-index: 468; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(533) {
      z-index: 467; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(534) {
      z-index: 466; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(535) {
      z-index: 465; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(536) {
      z-index: 464; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(537) {
      z-index: 463; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(538) {
      z-index: 462; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(539) {
      z-index: 461; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(540) {
      z-index: 460; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(541) {
      z-index: 459; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(542) {
      z-index: 458; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(543) {
      z-index: 457; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(544) {
      z-index: 456; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(545) {
      z-index: 455; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(546) {
      z-index: 454; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(547) {
      z-index: 453; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(548) {
      z-index: 452; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(549) {
      z-index: 451; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(550) {
      z-index: 450; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(551) {
      z-index: 449; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(552) {
      z-index: 448; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(553) {
      z-index: 447; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(554) {
      z-index: 446; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(555) {
      z-index: 445; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(556) {
      z-index: 444; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(557) {
      z-index: 443; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(558) {
      z-index: 442; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(559) {
      z-index: 441; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(560) {
      z-index: 440; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(561) {
      z-index: 439; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(562) {
      z-index: 438; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(563) {
      z-index: 437; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(564) {
      z-index: 436; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(565) {
      z-index: 435; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(566) {
      z-index: 434; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(567) {
      z-index: 433; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(568) {
      z-index: 432; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(569) {
      z-index: 431; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(570) {
      z-index: 430; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(571) {
      z-index: 429; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(572) {
      z-index: 428; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(573) {
      z-index: 427; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(574) {
      z-index: 426; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(575) {
      z-index: 425; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(576) {
      z-index: 424; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(577) {
      z-index: 423; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(578) {
      z-index: 422; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(579) {
      z-index: 421; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(580) {
      z-index: 420; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(581) {
      z-index: 419; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(582) {
      z-index: 418; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(583) {
      z-index: 417; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(584) {
      z-index: 416; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(585) {
      z-index: 415; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(586) {
      z-index: 414; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(587) {
      z-index: 413; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(588) {
      z-index: 412; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(589) {
      z-index: 411; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(590) {
      z-index: 410; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(591) {
      z-index: 409; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(592) {
      z-index: 408; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(593) {
      z-index: 407; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(594) {
      z-index: 406; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(595) {
      z-index: 405; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(596) {
      z-index: 404; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(597) {
      z-index: 403; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(598) {
      z-index: 402; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(599) {
      z-index: 401; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(600) {
      z-index: 400; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(601) {
      z-index: 399; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(602) {
      z-index: 398; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(603) {
      z-index: 397; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(604) {
      z-index: 396; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(605) {
      z-index: 395; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(606) {
      z-index: 394; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(607) {
      z-index: 393; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(608) {
      z-index: 392; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(609) {
      z-index: 391; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(610) {
      z-index: 390; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(611) {
      z-index: 389; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(612) {
      z-index: 388; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(613) {
      z-index: 387; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(614) {
      z-index: 386; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(615) {
      z-index: 385; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(616) {
      z-index: 384; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(617) {
      z-index: 383; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(618) {
      z-index: 382; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(619) {
      z-index: 381; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(620) {
      z-index: 380; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(621) {
      z-index: 379; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(622) {
      z-index: 378; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(623) {
      z-index: 377; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(624) {
      z-index: 376; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(625) {
      z-index: 375; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(626) {
      z-index: 374; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(627) {
      z-index: 373; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(628) {
      z-index: 372; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(629) {
      z-index: 371; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(630) {
      z-index: 370; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(631) {
      z-index: 369; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(632) {
      z-index: 368; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(633) {
      z-index: 367; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(634) {
      z-index: 366; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(635) {
      z-index: 365; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(636) {
      z-index: 364; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(637) {
      z-index: 363; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(638) {
      z-index: 362; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(639) {
      z-index: 361; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(640) {
      z-index: 360; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(641) {
      z-index: 359; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(642) {
      z-index: 358; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(643) {
      z-index: 357; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(644) {
      z-index: 356; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(645) {
      z-index: 355; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(646) {
      z-index: 354; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(647) {
      z-index: 353; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(648) {
      z-index: 352; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(649) {
      z-index: 351; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(650) {
      z-index: 350; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(651) {
      z-index: 349; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(652) {
      z-index: 348; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(653) {
      z-index: 347; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(654) {
      z-index: 346; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(655) {
      z-index: 345; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(656) {
      z-index: 344; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(657) {
      z-index: 343; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(658) {
      z-index: 342; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(659) {
      z-index: 341; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(660) {
      z-index: 340; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(661) {
      z-index: 339; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(662) {
      z-index: 338; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(663) {
      z-index: 337; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(664) {
      z-index: 336; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(665) {
      z-index: 335; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(666) {
      z-index: 334; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(667) {
      z-index: 333; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(668) {
      z-index: 332; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(669) {
      z-index: 331; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(670) {
      z-index: 330; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(671) {
      z-index: 329; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(672) {
      z-index: 328; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(673) {
      z-index: 327; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(674) {
      z-index: 326; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(675) {
      z-index: 325; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(676) {
      z-index: 324; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(677) {
      z-index: 323; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(678) {
      z-index: 322; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(679) {
      z-index: 321; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(680) {
      z-index: 320; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(681) {
      z-index: 319; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(682) {
      z-index: 318; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(683) {
      z-index: 317; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(684) {
      z-index: 316; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(685) {
      z-index: 315; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(686) {
      z-index: 314; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(687) {
      z-index: 313; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(688) {
      z-index: 312; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(689) {
      z-index: 311; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(690) {
      z-index: 310; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(691) {
      z-index: 309; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(692) {
      z-index: 308; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(693) {
      z-index: 307; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(694) {
      z-index: 306; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(695) {
      z-index: 305; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(696) {
      z-index: 304; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(697) {
      z-index: 303; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(698) {
      z-index: 302; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(699) {
      z-index: 301; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(700) {
      z-index: 300; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(701) {
      z-index: 299; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(702) {
      z-index: 298; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(703) {
      z-index: 297; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(704) {
      z-index: 296; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(705) {
      z-index: 295; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(706) {
      z-index: 294; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(707) {
      z-index: 293; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(708) {
      z-index: 292; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(709) {
      z-index: 291; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(710) {
      z-index: 290; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(711) {
      z-index: 289; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(712) {
      z-index: 288; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(713) {
      z-index: 287; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(714) {
      z-index: 286; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(715) {
      z-index: 285; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(716) {
      z-index: 284; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(717) {
      z-index: 283; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(718) {
      z-index: 282; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(719) {
      z-index: 281; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(720) {
      z-index: 280; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(721) {
      z-index: 279; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(722) {
      z-index: 278; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(723) {
      z-index: 277; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(724) {
      z-index: 276; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(725) {
      z-index: 275; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(726) {
      z-index: 274; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(727) {
      z-index: 273; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(728) {
      z-index: 272; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(729) {
      z-index: 271; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(730) {
      z-index: 270; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(731) {
      z-index: 269; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(732) {
      z-index: 268; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(733) {
      z-index: 267; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(734) {
      z-index: 266; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(735) {
      z-index: 265; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(736) {
      z-index: 264; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(737) {
      z-index: 263; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(738) {
      z-index: 262; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(739) {
      z-index: 261; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(740) {
      z-index: 260; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(741) {
      z-index: 259; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(742) {
      z-index: 258; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(743) {
      z-index: 257; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(744) {
      z-index: 256; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(745) {
      z-index: 255; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(746) {
      z-index: 254; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(747) {
      z-index: 253; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(748) {
      z-index: 252; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(749) {
      z-index: 251; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(750) {
      z-index: 250; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(751) {
      z-index: 249; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(752) {
      z-index: 248; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(753) {
      z-index: 247; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(754) {
      z-index: 246; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(755) {
      z-index: 245; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(756) {
      z-index: 244; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(757) {
      z-index: 243; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(758) {
      z-index: 242; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(759) {
      z-index: 241; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(760) {
      z-index: 240; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(761) {
      z-index: 239; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(762) {
      z-index: 238; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(763) {
      z-index: 237; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(764) {
      z-index: 236; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(765) {
      z-index: 235; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(766) {
      z-index: 234; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(767) {
      z-index: 233; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(768) {
      z-index: 232; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(769) {
      z-index: 231; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(770) {
      z-index: 230; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(771) {
      z-index: 229; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(772) {
      z-index: 228; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(773) {
      z-index: 227; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(774) {
      z-index: 226; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(775) {
      z-index: 225; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(776) {
      z-index: 224; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(777) {
      z-index: 223; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(778) {
      z-index: 222; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(779) {
      z-index: 221; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(780) {
      z-index: 220; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(781) {
      z-index: 219; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(782) {
      z-index: 218; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(783) {
      z-index: 217; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(784) {
      z-index: 216; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(785) {
      z-index: 215; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(786) {
      z-index: 214; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(787) {
      z-index: 213; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(788) {
      z-index: 212; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(789) {
      z-index: 211; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(790) {
      z-index: 210; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(791) {
      z-index: 209; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(792) {
      z-index: 208; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(793) {
      z-index: 207; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(794) {
      z-index: 206; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(795) {
      z-index: 205; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(796) {
      z-index: 204; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(797) {
      z-index: 203; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(798) {
      z-index: 202; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(799) {
      z-index: 201; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(800) {
      z-index: 200; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(801) {
      z-index: 199; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(802) {
      z-index: 198; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(803) {
      z-index: 197; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(804) {
      z-index: 196; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(805) {
      z-index: 195; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(806) {
      z-index: 194; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(807) {
      z-index: 193; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(808) {
      z-index: 192; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(809) {
      z-index: 191; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(810) {
      z-index: 190; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(811) {
      z-index: 189; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(812) {
      z-index: 188; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(813) {
      z-index: 187; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(814) {
      z-index: 186; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(815) {
      z-index: 185; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(816) {
      z-index: 184; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(817) {
      z-index: 183; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(818) {
      z-index: 182; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(819) {
      z-index: 181; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(820) {
      z-index: 180; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(821) {
      z-index: 179; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(822) {
      z-index: 178; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(823) {
      z-index: 177; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(824) {
      z-index: 176; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(825) {
      z-index: 175; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(826) {
      z-index: 174; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(827) {
      z-index: 173; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(828) {
      z-index: 172; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(829) {
      z-index: 171; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(830) {
      z-index: 170; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(831) {
      z-index: 169; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(832) {
      z-index: 168; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(833) {
      z-index: 167; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(834) {
      z-index: 166; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(835) {
      z-index: 165; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(836) {
      z-index: 164; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(837) {
      z-index: 163; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(838) {
      z-index: 162; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(839) {
      z-index: 161; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(840) {
      z-index: 160; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(841) {
      z-index: 159; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(842) {
      z-index: 158; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(843) {
      z-index: 157; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(844) {
      z-index: 156; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(845) {
      z-index: 155; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(846) {
      z-index: 154; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(847) {
      z-index: 153; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(848) {
      z-index: 152; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(849) {
      z-index: 151; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(850) {
      z-index: 150; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(851) {
      z-index: 149; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(852) {
      z-index: 148; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(853) {
      z-index: 147; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(854) {
      z-index: 146; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(855) {
      z-index: 145; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(856) {
      z-index: 144; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(857) {
      z-index: 143; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(858) {
      z-index: 142; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(859) {
      z-index: 141; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(860) {
      z-index: 140; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(861) {
      z-index: 139; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(862) {
      z-index: 138; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(863) {
      z-index: 137; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(864) {
      z-index: 136; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(865) {
      z-index: 135; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(866) {
      z-index: 134; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(867) {
      z-index: 133; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(868) {
      z-index: 132; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(869) {
      z-index: 131; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(870) {
      z-index: 130; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(871) {
      z-index: 129; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(872) {
      z-index: 128; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(873) {
      z-index: 127; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(874) {
      z-index: 126; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(875) {
      z-index: 125; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(876) {
      z-index: 124; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(877) {
      z-index: 123; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(878) {
      z-index: 122; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(879) {
      z-index: 121; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(880) {
      z-index: 120; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(881) {
      z-index: 119; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(882) {
      z-index: 118; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(883) {
      z-index: 117; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(884) {
      z-index: 116; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(885) {
      z-index: 115; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(886) {
      z-index: 114; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(887) {
      z-index: 113; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(888) {
      z-index: 112; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(889) {
      z-index: 111; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(890) {
      z-index: 110; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(891) {
      z-index: 109; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(892) {
      z-index: 108; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(893) {
      z-index: 107; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(894) {
      z-index: 106; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(895) {
      z-index: 105; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(896) {
      z-index: 104; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(897) {
      z-index: 103; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(898) {
      z-index: 102; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(899) {
      z-index: 101; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(900) {
      z-index: 100; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(901) {
      z-index: 99; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(902) {
      z-index: 98; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(903) {
      z-index: 97; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(904) {
      z-index: 96; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(905) {
      z-index: 95; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(906) {
      z-index: 94; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(907) {
      z-index: 93; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(908) {
      z-index: 92; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(909) {
      z-index: 91; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(910) {
      z-index: 90; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(911) {
      z-index: 89; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(912) {
      z-index: 88; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(913) {
      z-index: 87; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(914) {
      z-index: 86; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(915) {
      z-index: 85; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(916) {
      z-index: 84; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(917) {
      z-index: 83; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(918) {
      z-index: 82; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(919) {
      z-index: 81; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(920) {
      z-index: 80; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(921) {
      z-index: 79; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(922) {
      z-index: 78; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(923) {
      z-index: 77; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(924) {
      z-index: 76; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(925) {
      z-index: 75; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(926) {
      z-index: 74; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(927) {
      z-index: 73; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(928) {
      z-index: 72; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(929) {
      z-index: 71; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(930) {
      z-index: 70; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(931) {
      z-index: 69; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(932) {
      z-index: 68; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(933) {
      z-index: 67; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(934) {
      z-index: 66; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(935) {
      z-index: 65; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(936) {
      z-index: 64; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(937) {
      z-index: 63; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(938) {
      z-index: 62; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(939) {
      z-index: 61; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(940) {
      z-index: 60; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(941) {
      z-index: 59; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(942) {
      z-index: 58; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(943) {
      z-index: 57; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(944) {
      z-index: 56; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(945) {
      z-index: 55; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(946) {
      z-index: 54; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(947) {
      z-index: 53; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(948) {
      z-index: 52; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(949) {
      z-index: 51; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(950) {
      z-index: 50; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(951) {
      z-index: 49; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(952) {
      z-index: 48; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(953) {
      z-index: 47; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(954) {
      z-index: 46; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(955) {
      z-index: 45; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(956) {
      z-index: 44; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(957) {
      z-index: 43; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(958) {
      z-index: 42; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(959) {
      z-index: 41; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(960) {
      z-index: 40; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(961) {
      z-index: 39; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(962) {
      z-index: 38; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(963) {
      z-index: 37; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(964) {
      z-index: 36; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(965) {
      z-index: 35; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(966) {
      z-index: 34; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(967) {
      z-index: 33; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(968) {
      z-index: 32; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(969) {
      z-index: 31; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(970) {
      z-index: 30; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(971) {
      z-index: 29; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(972) {
      z-index: 28; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(973) {
      z-index: 27; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(974) {
      z-index: 26; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(975) {
      z-index: 25; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(976) {
      z-index: 24; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(977) {
      z-index: 23; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(978) {
      z-index: 22; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(979) {
      z-index: 21; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(980) {
      z-index: 20; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(981) {
      z-index: 19; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(982) {
      z-index: 18; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(983) {
      z-index: 17; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(984) {
      z-index: 16; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(985) {
      z-index: 15; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(986) {
      z-index: 14; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(987) {
      z-index: 13; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(988) {
      z-index: 12; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(989) {
      z-index: 11; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(990) {
      z-index: 10; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(991) {
      z-index: 9; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(992) {
      z-index: 8; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(993) {
      z-index: 7; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(994) {
      z-index: 6; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(995) {
      z-index: 5; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(996) {
      z-index: 4; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(997) {
      z-index: 3; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(998) {
      z-index: 2; }
    .card-shadow.upcoming-vacations .away-avatar:nth-child(999) {
      z-index: 1; }

.card-shadow.working-from-home {
  padding-bottom: 10px;
  padding-top: 20px; }
  .card-shadow.working-from-home .top-row {
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 20px; }
  .card-shadow.working-from-home h3 {
    margin-bottom: 0; }
  .card-shadow.working-from-home p.when {
    color: #a5adbc;
    font-size: 12px;
    font-family: "Mark Pro Book";
    margin-bottom: 13px; }
  .card-shadow.working-from-home .away-avatar {
    display: inline-block;
    border: solid 2px #ffffff;
    border-radius: 50%;
    margin-right: -13px;
    margin-bottom: 20px;
    position: relative; }
    .card-shadow.working-from-home .away-avatar:nth-child(0) {
      z-index: 1000; }
    .card-shadow.working-from-home .away-avatar:nth-child(1) {
      z-index: 999; }
    .card-shadow.working-from-home .away-avatar:nth-child(2) {
      z-index: 998; }
    .card-shadow.working-from-home .away-avatar:nth-child(3) {
      z-index: 997; }
    .card-shadow.working-from-home .away-avatar:nth-child(4) {
      z-index: 996; }
    .card-shadow.working-from-home .away-avatar:nth-child(5) {
      z-index: 995; }
    .card-shadow.working-from-home .away-avatar:nth-child(6) {
      z-index: 994; }
    .card-shadow.working-from-home .away-avatar:nth-child(7) {
      z-index: 993; }
    .card-shadow.working-from-home .away-avatar:nth-child(8) {
      z-index: 992; }
    .card-shadow.working-from-home .away-avatar:nth-child(9) {
      z-index: 991; }
    .card-shadow.working-from-home .away-avatar:nth-child(10) {
      z-index: 990; }
    .card-shadow.working-from-home .away-avatar:nth-child(11) {
      z-index: 989; }
    .card-shadow.working-from-home .away-avatar:nth-child(12) {
      z-index: 988; }
    .card-shadow.working-from-home .away-avatar:nth-child(13) {
      z-index: 987; }
    .card-shadow.working-from-home .away-avatar:nth-child(14) {
      z-index: 986; }
    .card-shadow.working-from-home .away-avatar:nth-child(15) {
      z-index: 985; }
    .card-shadow.working-from-home .away-avatar:nth-child(16) {
      z-index: 984; }
    .card-shadow.working-from-home .away-avatar:nth-child(17) {
      z-index: 983; }
    .card-shadow.working-from-home .away-avatar:nth-child(18) {
      z-index: 982; }
    .card-shadow.working-from-home .away-avatar:nth-child(19) {
      z-index: 981; }
    .card-shadow.working-from-home .away-avatar:nth-child(20) {
      z-index: 980; }
    .card-shadow.working-from-home .away-avatar:nth-child(21) {
      z-index: 979; }
    .card-shadow.working-from-home .away-avatar:nth-child(22) {
      z-index: 978; }
    .card-shadow.working-from-home .away-avatar:nth-child(23) {
      z-index: 977; }
    .card-shadow.working-from-home .away-avatar:nth-child(24) {
      z-index: 976; }
    .card-shadow.working-from-home .away-avatar:nth-child(25) {
      z-index: 975; }
    .card-shadow.working-from-home .away-avatar:nth-child(26) {
      z-index: 974; }
    .card-shadow.working-from-home .away-avatar:nth-child(27) {
      z-index: 973; }
    .card-shadow.working-from-home .away-avatar:nth-child(28) {
      z-index: 972; }
    .card-shadow.working-from-home .away-avatar:nth-child(29) {
      z-index: 971; }
    .card-shadow.working-from-home .away-avatar:nth-child(30) {
      z-index: 970; }
    .card-shadow.working-from-home .away-avatar:nth-child(31) {
      z-index: 969; }
    .card-shadow.working-from-home .away-avatar:nth-child(32) {
      z-index: 968; }
    .card-shadow.working-from-home .away-avatar:nth-child(33) {
      z-index: 967; }
    .card-shadow.working-from-home .away-avatar:nth-child(34) {
      z-index: 966; }
    .card-shadow.working-from-home .away-avatar:nth-child(35) {
      z-index: 965; }
    .card-shadow.working-from-home .away-avatar:nth-child(36) {
      z-index: 964; }
    .card-shadow.working-from-home .away-avatar:nth-child(37) {
      z-index: 963; }
    .card-shadow.working-from-home .away-avatar:nth-child(38) {
      z-index: 962; }
    .card-shadow.working-from-home .away-avatar:nth-child(39) {
      z-index: 961; }
    .card-shadow.working-from-home .away-avatar:nth-child(40) {
      z-index: 960; }
    .card-shadow.working-from-home .away-avatar:nth-child(41) {
      z-index: 959; }
    .card-shadow.working-from-home .away-avatar:nth-child(42) {
      z-index: 958; }
    .card-shadow.working-from-home .away-avatar:nth-child(43) {
      z-index: 957; }
    .card-shadow.working-from-home .away-avatar:nth-child(44) {
      z-index: 956; }
    .card-shadow.working-from-home .away-avatar:nth-child(45) {
      z-index: 955; }
    .card-shadow.working-from-home .away-avatar:nth-child(46) {
      z-index: 954; }
    .card-shadow.working-from-home .away-avatar:nth-child(47) {
      z-index: 953; }
    .card-shadow.working-from-home .away-avatar:nth-child(48) {
      z-index: 952; }
    .card-shadow.working-from-home .away-avatar:nth-child(49) {
      z-index: 951; }
    .card-shadow.working-from-home .away-avatar:nth-child(50) {
      z-index: 950; }
    .card-shadow.working-from-home .away-avatar:nth-child(51) {
      z-index: 949; }
    .card-shadow.working-from-home .away-avatar:nth-child(52) {
      z-index: 948; }
    .card-shadow.working-from-home .away-avatar:nth-child(53) {
      z-index: 947; }
    .card-shadow.working-from-home .away-avatar:nth-child(54) {
      z-index: 946; }
    .card-shadow.working-from-home .away-avatar:nth-child(55) {
      z-index: 945; }
    .card-shadow.working-from-home .away-avatar:nth-child(56) {
      z-index: 944; }
    .card-shadow.working-from-home .away-avatar:nth-child(57) {
      z-index: 943; }
    .card-shadow.working-from-home .away-avatar:nth-child(58) {
      z-index: 942; }
    .card-shadow.working-from-home .away-avatar:nth-child(59) {
      z-index: 941; }
    .card-shadow.working-from-home .away-avatar:nth-child(60) {
      z-index: 940; }
    .card-shadow.working-from-home .away-avatar:nth-child(61) {
      z-index: 939; }
    .card-shadow.working-from-home .away-avatar:nth-child(62) {
      z-index: 938; }
    .card-shadow.working-from-home .away-avatar:nth-child(63) {
      z-index: 937; }
    .card-shadow.working-from-home .away-avatar:nth-child(64) {
      z-index: 936; }
    .card-shadow.working-from-home .away-avatar:nth-child(65) {
      z-index: 935; }
    .card-shadow.working-from-home .away-avatar:nth-child(66) {
      z-index: 934; }
    .card-shadow.working-from-home .away-avatar:nth-child(67) {
      z-index: 933; }
    .card-shadow.working-from-home .away-avatar:nth-child(68) {
      z-index: 932; }
    .card-shadow.working-from-home .away-avatar:nth-child(69) {
      z-index: 931; }
    .card-shadow.working-from-home .away-avatar:nth-child(70) {
      z-index: 930; }
    .card-shadow.working-from-home .away-avatar:nth-child(71) {
      z-index: 929; }
    .card-shadow.working-from-home .away-avatar:nth-child(72) {
      z-index: 928; }
    .card-shadow.working-from-home .away-avatar:nth-child(73) {
      z-index: 927; }
    .card-shadow.working-from-home .away-avatar:nth-child(74) {
      z-index: 926; }
    .card-shadow.working-from-home .away-avatar:nth-child(75) {
      z-index: 925; }
    .card-shadow.working-from-home .away-avatar:nth-child(76) {
      z-index: 924; }
    .card-shadow.working-from-home .away-avatar:nth-child(77) {
      z-index: 923; }
    .card-shadow.working-from-home .away-avatar:nth-child(78) {
      z-index: 922; }
    .card-shadow.working-from-home .away-avatar:nth-child(79) {
      z-index: 921; }
    .card-shadow.working-from-home .away-avatar:nth-child(80) {
      z-index: 920; }
    .card-shadow.working-from-home .away-avatar:nth-child(81) {
      z-index: 919; }
    .card-shadow.working-from-home .away-avatar:nth-child(82) {
      z-index: 918; }
    .card-shadow.working-from-home .away-avatar:nth-child(83) {
      z-index: 917; }
    .card-shadow.working-from-home .away-avatar:nth-child(84) {
      z-index: 916; }
    .card-shadow.working-from-home .away-avatar:nth-child(85) {
      z-index: 915; }
    .card-shadow.working-from-home .away-avatar:nth-child(86) {
      z-index: 914; }
    .card-shadow.working-from-home .away-avatar:nth-child(87) {
      z-index: 913; }
    .card-shadow.working-from-home .away-avatar:nth-child(88) {
      z-index: 912; }
    .card-shadow.working-from-home .away-avatar:nth-child(89) {
      z-index: 911; }
    .card-shadow.working-from-home .away-avatar:nth-child(90) {
      z-index: 910; }
    .card-shadow.working-from-home .away-avatar:nth-child(91) {
      z-index: 909; }
    .card-shadow.working-from-home .away-avatar:nth-child(92) {
      z-index: 908; }
    .card-shadow.working-from-home .away-avatar:nth-child(93) {
      z-index: 907; }
    .card-shadow.working-from-home .away-avatar:nth-child(94) {
      z-index: 906; }
    .card-shadow.working-from-home .away-avatar:nth-child(95) {
      z-index: 905; }
    .card-shadow.working-from-home .away-avatar:nth-child(96) {
      z-index: 904; }
    .card-shadow.working-from-home .away-avatar:nth-child(97) {
      z-index: 903; }
    .card-shadow.working-from-home .away-avatar:nth-child(98) {
      z-index: 902; }
    .card-shadow.working-from-home .away-avatar:nth-child(99) {
      z-index: 901; }
    .card-shadow.working-from-home .away-avatar:nth-child(100) {
      z-index: 900; }
    .card-shadow.working-from-home .away-avatar:nth-child(101) {
      z-index: 899; }
    .card-shadow.working-from-home .away-avatar:nth-child(102) {
      z-index: 898; }
    .card-shadow.working-from-home .away-avatar:nth-child(103) {
      z-index: 897; }
    .card-shadow.working-from-home .away-avatar:nth-child(104) {
      z-index: 896; }
    .card-shadow.working-from-home .away-avatar:nth-child(105) {
      z-index: 895; }
    .card-shadow.working-from-home .away-avatar:nth-child(106) {
      z-index: 894; }
    .card-shadow.working-from-home .away-avatar:nth-child(107) {
      z-index: 893; }
    .card-shadow.working-from-home .away-avatar:nth-child(108) {
      z-index: 892; }
    .card-shadow.working-from-home .away-avatar:nth-child(109) {
      z-index: 891; }
    .card-shadow.working-from-home .away-avatar:nth-child(110) {
      z-index: 890; }
    .card-shadow.working-from-home .away-avatar:nth-child(111) {
      z-index: 889; }
    .card-shadow.working-from-home .away-avatar:nth-child(112) {
      z-index: 888; }
    .card-shadow.working-from-home .away-avatar:nth-child(113) {
      z-index: 887; }
    .card-shadow.working-from-home .away-avatar:nth-child(114) {
      z-index: 886; }
    .card-shadow.working-from-home .away-avatar:nth-child(115) {
      z-index: 885; }
    .card-shadow.working-from-home .away-avatar:nth-child(116) {
      z-index: 884; }
    .card-shadow.working-from-home .away-avatar:nth-child(117) {
      z-index: 883; }
    .card-shadow.working-from-home .away-avatar:nth-child(118) {
      z-index: 882; }
    .card-shadow.working-from-home .away-avatar:nth-child(119) {
      z-index: 881; }
    .card-shadow.working-from-home .away-avatar:nth-child(120) {
      z-index: 880; }
    .card-shadow.working-from-home .away-avatar:nth-child(121) {
      z-index: 879; }
    .card-shadow.working-from-home .away-avatar:nth-child(122) {
      z-index: 878; }
    .card-shadow.working-from-home .away-avatar:nth-child(123) {
      z-index: 877; }
    .card-shadow.working-from-home .away-avatar:nth-child(124) {
      z-index: 876; }
    .card-shadow.working-from-home .away-avatar:nth-child(125) {
      z-index: 875; }
    .card-shadow.working-from-home .away-avatar:nth-child(126) {
      z-index: 874; }
    .card-shadow.working-from-home .away-avatar:nth-child(127) {
      z-index: 873; }
    .card-shadow.working-from-home .away-avatar:nth-child(128) {
      z-index: 872; }
    .card-shadow.working-from-home .away-avatar:nth-child(129) {
      z-index: 871; }
    .card-shadow.working-from-home .away-avatar:nth-child(130) {
      z-index: 870; }
    .card-shadow.working-from-home .away-avatar:nth-child(131) {
      z-index: 869; }
    .card-shadow.working-from-home .away-avatar:nth-child(132) {
      z-index: 868; }
    .card-shadow.working-from-home .away-avatar:nth-child(133) {
      z-index: 867; }
    .card-shadow.working-from-home .away-avatar:nth-child(134) {
      z-index: 866; }
    .card-shadow.working-from-home .away-avatar:nth-child(135) {
      z-index: 865; }
    .card-shadow.working-from-home .away-avatar:nth-child(136) {
      z-index: 864; }
    .card-shadow.working-from-home .away-avatar:nth-child(137) {
      z-index: 863; }
    .card-shadow.working-from-home .away-avatar:nth-child(138) {
      z-index: 862; }
    .card-shadow.working-from-home .away-avatar:nth-child(139) {
      z-index: 861; }
    .card-shadow.working-from-home .away-avatar:nth-child(140) {
      z-index: 860; }
    .card-shadow.working-from-home .away-avatar:nth-child(141) {
      z-index: 859; }
    .card-shadow.working-from-home .away-avatar:nth-child(142) {
      z-index: 858; }
    .card-shadow.working-from-home .away-avatar:nth-child(143) {
      z-index: 857; }
    .card-shadow.working-from-home .away-avatar:nth-child(144) {
      z-index: 856; }
    .card-shadow.working-from-home .away-avatar:nth-child(145) {
      z-index: 855; }
    .card-shadow.working-from-home .away-avatar:nth-child(146) {
      z-index: 854; }
    .card-shadow.working-from-home .away-avatar:nth-child(147) {
      z-index: 853; }
    .card-shadow.working-from-home .away-avatar:nth-child(148) {
      z-index: 852; }
    .card-shadow.working-from-home .away-avatar:nth-child(149) {
      z-index: 851; }
    .card-shadow.working-from-home .away-avatar:nth-child(150) {
      z-index: 850; }
    .card-shadow.working-from-home .away-avatar:nth-child(151) {
      z-index: 849; }
    .card-shadow.working-from-home .away-avatar:nth-child(152) {
      z-index: 848; }
    .card-shadow.working-from-home .away-avatar:nth-child(153) {
      z-index: 847; }
    .card-shadow.working-from-home .away-avatar:nth-child(154) {
      z-index: 846; }
    .card-shadow.working-from-home .away-avatar:nth-child(155) {
      z-index: 845; }
    .card-shadow.working-from-home .away-avatar:nth-child(156) {
      z-index: 844; }
    .card-shadow.working-from-home .away-avatar:nth-child(157) {
      z-index: 843; }
    .card-shadow.working-from-home .away-avatar:nth-child(158) {
      z-index: 842; }
    .card-shadow.working-from-home .away-avatar:nth-child(159) {
      z-index: 841; }
    .card-shadow.working-from-home .away-avatar:nth-child(160) {
      z-index: 840; }
    .card-shadow.working-from-home .away-avatar:nth-child(161) {
      z-index: 839; }
    .card-shadow.working-from-home .away-avatar:nth-child(162) {
      z-index: 838; }
    .card-shadow.working-from-home .away-avatar:nth-child(163) {
      z-index: 837; }
    .card-shadow.working-from-home .away-avatar:nth-child(164) {
      z-index: 836; }
    .card-shadow.working-from-home .away-avatar:nth-child(165) {
      z-index: 835; }
    .card-shadow.working-from-home .away-avatar:nth-child(166) {
      z-index: 834; }
    .card-shadow.working-from-home .away-avatar:nth-child(167) {
      z-index: 833; }
    .card-shadow.working-from-home .away-avatar:nth-child(168) {
      z-index: 832; }
    .card-shadow.working-from-home .away-avatar:nth-child(169) {
      z-index: 831; }
    .card-shadow.working-from-home .away-avatar:nth-child(170) {
      z-index: 830; }
    .card-shadow.working-from-home .away-avatar:nth-child(171) {
      z-index: 829; }
    .card-shadow.working-from-home .away-avatar:nth-child(172) {
      z-index: 828; }
    .card-shadow.working-from-home .away-avatar:nth-child(173) {
      z-index: 827; }
    .card-shadow.working-from-home .away-avatar:nth-child(174) {
      z-index: 826; }
    .card-shadow.working-from-home .away-avatar:nth-child(175) {
      z-index: 825; }
    .card-shadow.working-from-home .away-avatar:nth-child(176) {
      z-index: 824; }
    .card-shadow.working-from-home .away-avatar:nth-child(177) {
      z-index: 823; }
    .card-shadow.working-from-home .away-avatar:nth-child(178) {
      z-index: 822; }
    .card-shadow.working-from-home .away-avatar:nth-child(179) {
      z-index: 821; }
    .card-shadow.working-from-home .away-avatar:nth-child(180) {
      z-index: 820; }
    .card-shadow.working-from-home .away-avatar:nth-child(181) {
      z-index: 819; }
    .card-shadow.working-from-home .away-avatar:nth-child(182) {
      z-index: 818; }
    .card-shadow.working-from-home .away-avatar:nth-child(183) {
      z-index: 817; }
    .card-shadow.working-from-home .away-avatar:nth-child(184) {
      z-index: 816; }
    .card-shadow.working-from-home .away-avatar:nth-child(185) {
      z-index: 815; }
    .card-shadow.working-from-home .away-avatar:nth-child(186) {
      z-index: 814; }
    .card-shadow.working-from-home .away-avatar:nth-child(187) {
      z-index: 813; }
    .card-shadow.working-from-home .away-avatar:nth-child(188) {
      z-index: 812; }
    .card-shadow.working-from-home .away-avatar:nth-child(189) {
      z-index: 811; }
    .card-shadow.working-from-home .away-avatar:nth-child(190) {
      z-index: 810; }
    .card-shadow.working-from-home .away-avatar:nth-child(191) {
      z-index: 809; }
    .card-shadow.working-from-home .away-avatar:nth-child(192) {
      z-index: 808; }
    .card-shadow.working-from-home .away-avatar:nth-child(193) {
      z-index: 807; }
    .card-shadow.working-from-home .away-avatar:nth-child(194) {
      z-index: 806; }
    .card-shadow.working-from-home .away-avatar:nth-child(195) {
      z-index: 805; }
    .card-shadow.working-from-home .away-avatar:nth-child(196) {
      z-index: 804; }
    .card-shadow.working-from-home .away-avatar:nth-child(197) {
      z-index: 803; }
    .card-shadow.working-from-home .away-avatar:nth-child(198) {
      z-index: 802; }
    .card-shadow.working-from-home .away-avatar:nth-child(199) {
      z-index: 801; }
    .card-shadow.working-from-home .away-avatar:nth-child(200) {
      z-index: 800; }
    .card-shadow.working-from-home .away-avatar:nth-child(201) {
      z-index: 799; }
    .card-shadow.working-from-home .away-avatar:nth-child(202) {
      z-index: 798; }
    .card-shadow.working-from-home .away-avatar:nth-child(203) {
      z-index: 797; }
    .card-shadow.working-from-home .away-avatar:nth-child(204) {
      z-index: 796; }
    .card-shadow.working-from-home .away-avatar:nth-child(205) {
      z-index: 795; }
    .card-shadow.working-from-home .away-avatar:nth-child(206) {
      z-index: 794; }
    .card-shadow.working-from-home .away-avatar:nth-child(207) {
      z-index: 793; }
    .card-shadow.working-from-home .away-avatar:nth-child(208) {
      z-index: 792; }
    .card-shadow.working-from-home .away-avatar:nth-child(209) {
      z-index: 791; }
    .card-shadow.working-from-home .away-avatar:nth-child(210) {
      z-index: 790; }
    .card-shadow.working-from-home .away-avatar:nth-child(211) {
      z-index: 789; }
    .card-shadow.working-from-home .away-avatar:nth-child(212) {
      z-index: 788; }
    .card-shadow.working-from-home .away-avatar:nth-child(213) {
      z-index: 787; }
    .card-shadow.working-from-home .away-avatar:nth-child(214) {
      z-index: 786; }
    .card-shadow.working-from-home .away-avatar:nth-child(215) {
      z-index: 785; }
    .card-shadow.working-from-home .away-avatar:nth-child(216) {
      z-index: 784; }
    .card-shadow.working-from-home .away-avatar:nth-child(217) {
      z-index: 783; }
    .card-shadow.working-from-home .away-avatar:nth-child(218) {
      z-index: 782; }
    .card-shadow.working-from-home .away-avatar:nth-child(219) {
      z-index: 781; }
    .card-shadow.working-from-home .away-avatar:nth-child(220) {
      z-index: 780; }
    .card-shadow.working-from-home .away-avatar:nth-child(221) {
      z-index: 779; }
    .card-shadow.working-from-home .away-avatar:nth-child(222) {
      z-index: 778; }
    .card-shadow.working-from-home .away-avatar:nth-child(223) {
      z-index: 777; }
    .card-shadow.working-from-home .away-avatar:nth-child(224) {
      z-index: 776; }
    .card-shadow.working-from-home .away-avatar:nth-child(225) {
      z-index: 775; }
    .card-shadow.working-from-home .away-avatar:nth-child(226) {
      z-index: 774; }
    .card-shadow.working-from-home .away-avatar:nth-child(227) {
      z-index: 773; }
    .card-shadow.working-from-home .away-avatar:nth-child(228) {
      z-index: 772; }
    .card-shadow.working-from-home .away-avatar:nth-child(229) {
      z-index: 771; }
    .card-shadow.working-from-home .away-avatar:nth-child(230) {
      z-index: 770; }
    .card-shadow.working-from-home .away-avatar:nth-child(231) {
      z-index: 769; }
    .card-shadow.working-from-home .away-avatar:nth-child(232) {
      z-index: 768; }
    .card-shadow.working-from-home .away-avatar:nth-child(233) {
      z-index: 767; }
    .card-shadow.working-from-home .away-avatar:nth-child(234) {
      z-index: 766; }
    .card-shadow.working-from-home .away-avatar:nth-child(235) {
      z-index: 765; }
    .card-shadow.working-from-home .away-avatar:nth-child(236) {
      z-index: 764; }
    .card-shadow.working-from-home .away-avatar:nth-child(237) {
      z-index: 763; }
    .card-shadow.working-from-home .away-avatar:nth-child(238) {
      z-index: 762; }
    .card-shadow.working-from-home .away-avatar:nth-child(239) {
      z-index: 761; }
    .card-shadow.working-from-home .away-avatar:nth-child(240) {
      z-index: 760; }
    .card-shadow.working-from-home .away-avatar:nth-child(241) {
      z-index: 759; }
    .card-shadow.working-from-home .away-avatar:nth-child(242) {
      z-index: 758; }
    .card-shadow.working-from-home .away-avatar:nth-child(243) {
      z-index: 757; }
    .card-shadow.working-from-home .away-avatar:nth-child(244) {
      z-index: 756; }
    .card-shadow.working-from-home .away-avatar:nth-child(245) {
      z-index: 755; }
    .card-shadow.working-from-home .away-avatar:nth-child(246) {
      z-index: 754; }
    .card-shadow.working-from-home .away-avatar:nth-child(247) {
      z-index: 753; }
    .card-shadow.working-from-home .away-avatar:nth-child(248) {
      z-index: 752; }
    .card-shadow.working-from-home .away-avatar:nth-child(249) {
      z-index: 751; }
    .card-shadow.working-from-home .away-avatar:nth-child(250) {
      z-index: 750; }
    .card-shadow.working-from-home .away-avatar:nth-child(251) {
      z-index: 749; }
    .card-shadow.working-from-home .away-avatar:nth-child(252) {
      z-index: 748; }
    .card-shadow.working-from-home .away-avatar:nth-child(253) {
      z-index: 747; }
    .card-shadow.working-from-home .away-avatar:nth-child(254) {
      z-index: 746; }
    .card-shadow.working-from-home .away-avatar:nth-child(255) {
      z-index: 745; }
    .card-shadow.working-from-home .away-avatar:nth-child(256) {
      z-index: 744; }
    .card-shadow.working-from-home .away-avatar:nth-child(257) {
      z-index: 743; }
    .card-shadow.working-from-home .away-avatar:nth-child(258) {
      z-index: 742; }
    .card-shadow.working-from-home .away-avatar:nth-child(259) {
      z-index: 741; }
    .card-shadow.working-from-home .away-avatar:nth-child(260) {
      z-index: 740; }
    .card-shadow.working-from-home .away-avatar:nth-child(261) {
      z-index: 739; }
    .card-shadow.working-from-home .away-avatar:nth-child(262) {
      z-index: 738; }
    .card-shadow.working-from-home .away-avatar:nth-child(263) {
      z-index: 737; }
    .card-shadow.working-from-home .away-avatar:nth-child(264) {
      z-index: 736; }
    .card-shadow.working-from-home .away-avatar:nth-child(265) {
      z-index: 735; }
    .card-shadow.working-from-home .away-avatar:nth-child(266) {
      z-index: 734; }
    .card-shadow.working-from-home .away-avatar:nth-child(267) {
      z-index: 733; }
    .card-shadow.working-from-home .away-avatar:nth-child(268) {
      z-index: 732; }
    .card-shadow.working-from-home .away-avatar:nth-child(269) {
      z-index: 731; }
    .card-shadow.working-from-home .away-avatar:nth-child(270) {
      z-index: 730; }
    .card-shadow.working-from-home .away-avatar:nth-child(271) {
      z-index: 729; }
    .card-shadow.working-from-home .away-avatar:nth-child(272) {
      z-index: 728; }
    .card-shadow.working-from-home .away-avatar:nth-child(273) {
      z-index: 727; }
    .card-shadow.working-from-home .away-avatar:nth-child(274) {
      z-index: 726; }
    .card-shadow.working-from-home .away-avatar:nth-child(275) {
      z-index: 725; }
    .card-shadow.working-from-home .away-avatar:nth-child(276) {
      z-index: 724; }
    .card-shadow.working-from-home .away-avatar:nth-child(277) {
      z-index: 723; }
    .card-shadow.working-from-home .away-avatar:nth-child(278) {
      z-index: 722; }
    .card-shadow.working-from-home .away-avatar:nth-child(279) {
      z-index: 721; }
    .card-shadow.working-from-home .away-avatar:nth-child(280) {
      z-index: 720; }
    .card-shadow.working-from-home .away-avatar:nth-child(281) {
      z-index: 719; }
    .card-shadow.working-from-home .away-avatar:nth-child(282) {
      z-index: 718; }
    .card-shadow.working-from-home .away-avatar:nth-child(283) {
      z-index: 717; }
    .card-shadow.working-from-home .away-avatar:nth-child(284) {
      z-index: 716; }
    .card-shadow.working-from-home .away-avatar:nth-child(285) {
      z-index: 715; }
    .card-shadow.working-from-home .away-avatar:nth-child(286) {
      z-index: 714; }
    .card-shadow.working-from-home .away-avatar:nth-child(287) {
      z-index: 713; }
    .card-shadow.working-from-home .away-avatar:nth-child(288) {
      z-index: 712; }
    .card-shadow.working-from-home .away-avatar:nth-child(289) {
      z-index: 711; }
    .card-shadow.working-from-home .away-avatar:nth-child(290) {
      z-index: 710; }
    .card-shadow.working-from-home .away-avatar:nth-child(291) {
      z-index: 709; }
    .card-shadow.working-from-home .away-avatar:nth-child(292) {
      z-index: 708; }
    .card-shadow.working-from-home .away-avatar:nth-child(293) {
      z-index: 707; }
    .card-shadow.working-from-home .away-avatar:nth-child(294) {
      z-index: 706; }
    .card-shadow.working-from-home .away-avatar:nth-child(295) {
      z-index: 705; }
    .card-shadow.working-from-home .away-avatar:nth-child(296) {
      z-index: 704; }
    .card-shadow.working-from-home .away-avatar:nth-child(297) {
      z-index: 703; }
    .card-shadow.working-from-home .away-avatar:nth-child(298) {
      z-index: 702; }
    .card-shadow.working-from-home .away-avatar:nth-child(299) {
      z-index: 701; }
    .card-shadow.working-from-home .away-avatar:nth-child(300) {
      z-index: 700; }
    .card-shadow.working-from-home .away-avatar:nth-child(301) {
      z-index: 699; }
    .card-shadow.working-from-home .away-avatar:nth-child(302) {
      z-index: 698; }
    .card-shadow.working-from-home .away-avatar:nth-child(303) {
      z-index: 697; }
    .card-shadow.working-from-home .away-avatar:nth-child(304) {
      z-index: 696; }
    .card-shadow.working-from-home .away-avatar:nth-child(305) {
      z-index: 695; }
    .card-shadow.working-from-home .away-avatar:nth-child(306) {
      z-index: 694; }
    .card-shadow.working-from-home .away-avatar:nth-child(307) {
      z-index: 693; }
    .card-shadow.working-from-home .away-avatar:nth-child(308) {
      z-index: 692; }
    .card-shadow.working-from-home .away-avatar:nth-child(309) {
      z-index: 691; }
    .card-shadow.working-from-home .away-avatar:nth-child(310) {
      z-index: 690; }
    .card-shadow.working-from-home .away-avatar:nth-child(311) {
      z-index: 689; }
    .card-shadow.working-from-home .away-avatar:nth-child(312) {
      z-index: 688; }
    .card-shadow.working-from-home .away-avatar:nth-child(313) {
      z-index: 687; }
    .card-shadow.working-from-home .away-avatar:nth-child(314) {
      z-index: 686; }
    .card-shadow.working-from-home .away-avatar:nth-child(315) {
      z-index: 685; }
    .card-shadow.working-from-home .away-avatar:nth-child(316) {
      z-index: 684; }
    .card-shadow.working-from-home .away-avatar:nth-child(317) {
      z-index: 683; }
    .card-shadow.working-from-home .away-avatar:nth-child(318) {
      z-index: 682; }
    .card-shadow.working-from-home .away-avatar:nth-child(319) {
      z-index: 681; }
    .card-shadow.working-from-home .away-avatar:nth-child(320) {
      z-index: 680; }
    .card-shadow.working-from-home .away-avatar:nth-child(321) {
      z-index: 679; }
    .card-shadow.working-from-home .away-avatar:nth-child(322) {
      z-index: 678; }
    .card-shadow.working-from-home .away-avatar:nth-child(323) {
      z-index: 677; }
    .card-shadow.working-from-home .away-avatar:nth-child(324) {
      z-index: 676; }
    .card-shadow.working-from-home .away-avatar:nth-child(325) {
      z-index: 675; }
    .card-shadow.working-from-home .away-avatar:nth-child(326) {
      z-index: 674; }
    .card-shadow.working-from-home .away-avatar:nth-child(327) {
      z-index: 673; }
    .card-shadow.working-from-home .away-avatar:nth-child(328) {
      z-index: 672; }
    .card-shadow.working-from-home .away-avatar:nth-child(329) {
      z-index: 671; }
    .card-shadow.working-from-home .away-avatar:nth-child(330) {
      z-index: 670; }
    .card-shadow.working-from-home .away-avatar:nth-child(331) {
      z-index: 669; }
    .card-shadow.working-from-home .away-avatar:nth-child(332) {
      z-index: 668; }
    .card-shadow.working-from-home .away-avatar:nth-child(333) {
      z-index: 667; }
    .card-shadow.working-from-home .away-avatar:nth-child(334) {
      z-index: 666; }
    .card-shadow.working-from-home .away-avatar:nth-child(335) {
      z-index: 665; }
    .card-shadow.working-from-home .away-avatar:nth-child(336) {
      z-index: 664; }
    .card-shadow.working-from-home .away-avatar:nth-child(337) {
      z-index: 663; }
    .card-shadow.working-from-home .away-avatar:nth-child(338) {
      z-index: 662; }
    .card-shadow.working-from-home .away-avatar:nth-child(339) {
      z-index: 661; }
    .card-shadow.working-from-home .away-avatar:nth-child(340) {
      z-index: 660; }
    .card-shadow.working-from-home .away-avatar:nth-child(341) {
      z-index: 659; }
    .card-shadow.working-from-home .away-avatar:nth-child(342) {
      z-index: 658; }
    .card-shadow.working-from-home .away-avatar:nth-child(343) {
      z-index: 657; }
    .card-shadow.working-from-home .away-avatar:nth-child(344) {
      z-index: 656; }
    .card-shadow.working-from-home .away-avatar:nth-child(345) {
      z-index: 655; }
    .card-shadow.working-from-home .away-avatar:nth-child(346) {
      z-index: 654; }
    .card-shadow.working-from-home .away-avatar:nth-child(347) {
      z-index: 653; }
    .card-shadow.working-from-home .away-avatar:nth-child(348) {
      z-index: 652; }
    .card-shadow.working-from-home .away-avatar:nth-child(349) {
      z-index: 651; }
    .card-shadow.working-from-home .away-avatar:nth-child(350) {
      z-index: 650; }
    .card-shadow.working-from-home .away-avatar:nth-child(351) {
      z-index: 649; }
    .card-shadow.working-from-home .away-avatar:nth-child(352) {
      z-index: 648; }
    .card-shadow.working-from-home .away-avatar:nth-child(353) {
      z-index: 647; }
    .card-shadow.working-from-home .away-avatar:nth-child(354) {
      z-index: 646; }
    .card-shadow.working-from-home .away-avatar:nth-child(355) {
      z-index: 645; }
    .card-shadow.working-from-home .away-avatar:nth-child(356) {
      z-index: 644; }
    .card-shadow.working-from-home .away-avatar:nth-child(357) {
      z-index: 643; }
    .card-shadow.working-from-home .away-avatar:nth-child(358) {
      z-index: 642; }
    .card-shadow.working-from-home .away-avatar:nth-child(359) {
      z-index: 641; }
    .card-shadow.working-from-home .away-avatar:nth-child(360) {
      z-index: 640; }
    .card-shadow.working-from-home .away-avatar:nth-child(361) {
      z-index: 639; }
    .card-shadow.working-from-home .away-avatar:nth-child(362) {
      z-index: 638; }
    .card-shadow.working-from-home .away-avatar:nth-child(363) {
      z-index: 637; }
    .card-shadow.working-from-home .away-avatar:nth-child(364) {
      z-index: 636; }
    .card-shadow.working-from-home .away-avatar:nth-child(365) {
      z-index: 635; }
    .card-shadow.working-from-home .away-avatar:nth-child(366) {
      z-index: 634; }
    .card-shadow.working-from-home .away-avatar:nth-child(367) {
      z-index: 633; }
    .card-shadow.working-from-home .away-avatar:nth-child(368) {
      z-index: 632; }
    .card-shadow.working-from-home .away-avatar:nth-child(369) {
      z-index: 631; }
    .card-shadow.working-from-home .away-avatar:nth-child(370) {
      z-index: 630; }
    .card-shadow.working-from-home .away-avatar:nth-child(371) {
      z-index: 629; }
    .card-shadow.working-from-home .away-avatar:nth-child(372) {
      z-index: 628; }
    .card-shadow.working-from-home .away-avatar:nth-child(373) {
      z-index: 627; }
    .card-shadow.working-from-home .away-avatar:nth-child(374) {
      z-index: 626; }
    .card-shadow.working-from-home .away-avatar:nth-child(375) {
      z-index: 625; }
    .card-shadow.working-from-home .away-avatar:nth-child(376) {
      z-index: 624; }
    .card-shadow.working-from-home .away-avatar:nth-child(377) {
      z-index: 623; }
    .card-shadow.working-from-home .away-avatar:nth-child(378) {
      z-index: 622; }
    .card-shadow.working-from-home .away-avatar:nth-child(379) {
      z-index: 621; }
    .card-shadow.working-from-home .away-avatar:nth-child(380) {
      z-index: 620; }
    .card-shadow.working-from-home .away-avatar:nth-child(381) {
      z-index: 619; }
    .card-shadow.working-from-home .away-avatar:nth-child(382) {
      z-index: 618; }
    .card-shadow.working-from-home .away-avatar:nth-child(383) {
      z-index: 617; }
    .card-shadow.working-from-home .away-avatar:nth-child(384) {
      z-index: 616; }
    .card-shadow.working-from-home .away-avatar:nth-child(385) {
      z-index: 615; }
    .card-shadow.working-from-home .away-avatar:nth-child(386) {
      z-index: 614; }
    .card-shadow.working-from-home .away-avatar:nth-child(387) {
      z-index: 613; }
    .card-shadow.working-from-home .away-avatar:nth-child(388) {
      z-index: 612; }
    .card-shadow.working-from-home .away-avatar:nth-child(389) {
      z-index: 611; }
    .card-shadow.working-from-home .away-avatar:nth-child(390) {
      z-index: 610; }
    .card-shadow.working-from-home .away-avatar:nth-child(391) {
      z-index: 609; }
    .card-shadow.working-from-home .away-avatar:nth-child(392) {
      z-index: 608; }
    .card-shadow.working-from-home .away-avatar:nth-child(393) {
      z-index: 607; }
    .card-shadow.working-from-home .away-avatar:nth-child(394) {
      z-index: 606; }
    .card-shadow.working-from-home .away-avatar:nth-child(395) {
      z-index: 605; }
    .card-shadow.working-from-home .away-avatar:nth-child(396) {
      z-index: 604; }
    .card-shadow.working-from-home .away-avatar:nth-child(397) {
      z-index: 603; }
    .card-shadow.working-from-home .away-avatar:nth-child(398) {
      z-index: 602; }
    .card-shadow.working-from-home .away-avatar:nth-child(399) {
      z-index: 601; }
    .card-shadow.working-from-home .away-avatar:nth-child(400) {
      z-index: 600; }
    .card-shadow.working-from-home .away-avatar:nth-child(401) {
      z-index: 599; }
    .card-shadow.working-from-home .away-avatar:nth-child(402) {
      z-index: 598; }
    .card-shadow.working-from-home .away-avatar:nth-child(403) {
      z-index: 597; }
    .card-shadow.working-from-home .away-avatar:nth-child(404) {
      z-index: 596; }
    .card-shadow.working-from-home .away-avatar:nth-child(405) {
      z-index: 595; }
    .card-shadow.working-from-home .away-avatar:nth-child(406) {
      z-index: 594; }
    .card-shadow.working-from-home .away-avatar:nth-child(407) {
      z-index: 593; }
    .card-shadow.working-from-home .away-avatar:nth-child(408) {
      z-index: 592; }
    .card-shadow.working-from-home .away-avatar:nth-child(409) {
      z-index: 591; }
    .card-shadow.working-from-home .away-avatar:nth-child(410) {
      z-index: 590; }
    .card-shadow.working-from-home .away-avatar:nth-child(411) {
      z-index: 589; }
    .card-shadow.working-from-home .away-avatar:nth-child(412) {
      z-index: 588; }
    .card-shadow.working-from-home .away-avatar:nth-child(413) {
      z-index: 587; }
    .card-shadow.working-from-home .away-avatar:nth-child(414) {
      z-index: 586; }
    .card-shadow.working-from-home .away-avatar:nth-child(415) {
      z-index: 585; }
    .card-shadow.working-from-home .away-avatar:nth-child(416) {
      z-index: 584; }
    .card-shadow.working-from-home .away-avatar:nth-child(417) {
      z-index: 583; }
    .card-shadow.working-from-home .away-avatar:nth-child(418) {
      z-index: 582; }
    .card-shadow.working-from-home .away-avatar:nth-child(419) {
      z-index: 581; }
    .card-shadow.working-from-home .away-avatar:nth-child(420) {
      z-index: 580; }
    .card-shadow.working-from-home .away-avatar:nth-child(421) {
      z-index: 579; }
    .card-shadow.working-from-home .away-avatar:nth-child(422) {
      z-index: 578; }
    .card-shadow.working-from-home .away-avatar:nth-child(423) {
      z-index: 577; }
    .card-shadow.working-from-home .away-avatar:nth-child(424) {
      z-index: 576; }
    .card-shadow.working-from-home .away-avatar:nth-child(425) {
      z-index: 575; }
    .card-shadow.working-from-home .away-avatar:nth-child(426) {
      z-index: 574; }
    .card-shadow.working-from-home .away-avatar:nth-child(427) {
      z-index: 573; }
    .card-shadow.working-from-home .away-avatar:nth-child(428) {
      z-index: 572; }
    .card-shadow.working-from-home .away-avatar:nth-child(429) {
      z-index: 571; }
    .card-shadow.working-from-home .away-avatar:nth-child(430) {
      z-index: 570; }
    .card-shadow.working-from-home .away-avatar:nth-child(431) {
      z-index: 569; }
    .card-shadow.working-from-home .away-avatar:nth-child(432) {
      z-index: 568; }
    .card-shadow.working-from-home .away-avatar:nth-child(433) {
      z-index: 567; }
    .card-shadow.working-from-home .away-avatar:nth-child(434) {
      z-index: 566; }
    .card-shadow.working-from-home .away-avatar:nth-child(435) {
      z-index: 565; }
    .card-shadow.working-from-home .away-avatar:nth-child(436) {
      z-index: 564; }
    .card-shadow.working-from-home .away-avatar:nth-child(437) {
      z-index: 563; }
    .card-shadow.working-from-home .away-avatar:nth-child(438) {
      z-index: 562; }
    .card-shadow.working-from-home .away-avatar:nth-child(439) {
      z-index: 561; }
    .card-shadow.working-from-home .away-avatar:nth-child(440) {
      z-index: 560; }
    .card-shadow.working-from-home .away-avatar:nth-child(441) {
      z-index: 559; }
    .card-shadow.working-from-home .away-avatar:nth-child(442) {
      z-index: 558; }
    .card-shadow.working-from-home .away-avatar:nth-child(443) {
      z-index: 557; }
    .card-shadow.working-from-home .away-avatar:nth-child(444) {
      z-index: 556; }
    .card-shadow.working-from-home .away-avatar:nth-child(445) {
      z-index: 555; }
    .card-shadow.working-from-home .away-avatar:nth-child(446) {
      z-index: 554; }
    .card-shadow.working-from-home .away-avatar:nth-child(447) {
      z-index: 553; }
    .card-shadow.working-from-home .away-avatar:nth-child(448) {
      z-index: 552; }
    .card-shadow.working-from-home .away-avatar:nth-child(449) {
      z-index: 551; }
    .card-shadow.working-from-home .away-avatar:nth-child(450) {
      z-index: 550; }
    .card-shadow.working-from-home .away-avatar:nth-child(451) {
      z-index: 549; }
    .card-shadow.working-from-home .away-avatar:nth-child(452) {
      z-index: 548; }
    .card-shadow.working-from-home .away-avatar:nth-child(453) {
      z-index: 547; }
    .card-shadow.working-from-home .away-avatar:nth-child(454) {
      z-index: 546; }
    .card-shadow.working-from-home .away-avatar:nth-child(455) {
      z-index: 545; }
    .card-shadow.working-from-home .away-avatar:nth-child(456) {
      z-index: 544; }
    .card-shadow.working-from-home .away-avatar:nth-child(457) {
      z-index: 543; }
    .card-shadow.working-from-home .away-avatar:nth-child(458) {
      z-index: 542; }
    .card-shadow.working-from-home .away-avatar:nth-child(459) {
      z-index: 541; }
    .card-shadow.working-from-home .away-avatar:nth-child(460) {
      z-index: 540; }
    .card-shadow.working-from-home .away-avatar:nth-child(461) {
      z-index: 539; }
    .card-shadow.working-from-home .away-avatar:nth-child(462) {
      z-index: 538; }
    .card-shadow.working-from-home .away-avatar:nth-child(463) {
      z-index: 537; }
    .card-shadow.working-from-home .away-avatar:nth-child(464) {
      z-index: 536; }
    .card-shadow.working-from-home .away-avatar:nth-child(465) {
      z-index: 535; }
    .card-shadow.working-from-home .away-avatar:nth-child(466) {
      z-index: 534; }
    .card-shadow.working-from-home .away-avatar:nth-child(467) {
      z-index: 533; }
    .card-shadow.working-from-home .away-avatar:nth-child(468) {
      z-index: 532; }
    .card-shadow.working-from-home .away-avatar:nth-child(469) {
      z-index: 531; }
    .card-shadow.working-from-home .away-avatar:nth-child(470) {
      z-index: 530; }
    .card-shadow.working-from-home .away-avatar:nth-child(471) {
      z-index: 529; }
    .card-shadow.working-from-home .away-avatar:nth-child(472) {
      z-index: 528; }
    .card-shadow.working-from-home .away-avatar:nth-child(473) {
      z-index: 527; }
    .card-shadow.working-from-home .away-avatar:nth-child(474) {
      z-index: 526; }
    .card-shadow.working-from-home .away-avatar:nth-child(475) {
      z-index: 525; }
    .card-shadow.working-from-home .away-avatar:nth-child(476) {
      z-index: 524; }
    .card-shadow.working-from-home .away-avatar:nth-child(477) {
      z-index: 523; }
    .card-shadow.working-from-home .away-avatar:nth-child(478) {
      z-index: 522; }
    .card-shadow.working-from-home .away-avatar:nth-child(479) {
      z-index: 521; }
    .card-shadow.working-from-home .away-avatar:nth-child(480) {
      z-index: 520; }
    .card-shadow.working-from-home .away-avatar:nth-child(481) {
      z-index: 519; }
    .card-shadow.working-from-home .away-avatar:nth-child(482) {
      z-index: 518; }
    .card-shadow.working-from-home .away-avatar:nth-child(483) {
      z-index: 517; }
    .card-shadow.working-from-home .away-avatar:nth-child(484) {
      z-index: 516; }
    .card-shadow.working-from-home .away-avatar:nth-child(485) {
      z-index: 515; }
    .card-shadow.working-from-home .away-avatar:nth-child(486) {
      z-index: 514; }
    .card-shadow.working-from-home .away-avatar:nth-child(487) {
      z-index: 513; }
    .card-shadow.working-from-home .away-avatar:nth-child(488) {
      z-index: 512; }
    .card-shadow.working-from-home .away-avatar:nth-child(489) {
      z-index: 511; }
    .card-shadow.working-from-home .away-avatar:nth-child(490) {
      z-index: 510; }
    .card-shadow.working-from-home .away-avatar:nth-child(491) {
      z-index: 509; }
    .card-shadow.working-from-home .away-avatar:nth-child(492) {
      z-index: 508; }
    .card-shadow.working-from-home .away-avatar:nth-child(493) {
      z-index: 507; }
    .card-shadow.working-from-home .away-avatar:nth-child(494) {
      z-index: 506; }
    .card-shadow.working-from-home .away-avatar:nth-child(495) {
      z-index: 505; }
    .card-shadow.working-from-home .away-avatar:nth-child(496) {
      z-index: 504; }
    .card-shadow.working-from-home .away-avatar:nth-child(497) {
      z-index: 503; }
    .card-shadow.working-from-home .away-avatar:nth-child(498) {
      z-index: 502; }
    .card-shadow.working-from-home .away-avatar:nth-child(499) {
      z-index: 501; }
    .card-shadow.working-from-home .away-avatar:nth-child(500) {
      z-index: 500; }
    .card-shadow.working-from-home .away-avatar:nth-child(501) {
      z-index: 499; }
    .card-shadow.working-from-home .away-avatar:nth-child(502) {
      z-index: 498; }
    .card-shadow.working-from-home .away-avatar:nth-child(503) {
      z-index: 497; }
    .card-shadow.working-from-home .away-avatar:nth-child(504) {
      z-index: 496; }
    .card-shadow.working-from-home .away-avatar:nth-child(505) {
      z-index: 495; }
    .card-shadow.working-from-home .away-avatar:nth-child(506) {
      z-index: 494; }
    .card-shadow.working-from-home .away-avatar:nth-child(507) {
      z-index: 493; }
    .card-shadow.working-from-home .away-avatar:nth-child(508) {
      z-index: 492; }
    .card-shadow.working-from-home .away-avatar:nth-child(509) {
      z-index: 491; }
    .card-shadow.working-from-home .away-avatar:nth-child(510) {
      z-index: 490; }
    .card-shadow.working-from-home .away-avatar:nth-child(511) {
      z-index: 489; }
    .card-shadow.working-from-home .away-avatar:nth-child(512) {
      z-index: 488; }
    .card-shadow.working-from-home .away-avatar:nth-child(513) {
      z-index: 487; }
    .card-shadow.working-from-home .away-avatar:nth-child(514) {
      z-index: 486; }
    .card-shadow.working-from-home .away-avatar:nth-child(515) {
      z-index: 485; }
    .card-shadow.working-from-home .away-avatar:nth-child(516) {
      z-index: 484; }
    .card-shadow.working-from-home .away-avatar:nth-child(517) {
      z-index: 483; }
    .card-shadow.working-from-home .away-avatar:nth-child(518) {
      z-index: 482; }
    .card-shadow.working-from-home .away-avatar:nth-child(519) {
      z-index: 481; }
    .card-shadow.working-from-home .away-avatar:nth-child(520) {
      z-index: 480; }
    .card-shadow.working-from-home .away-avatar:nth-child(521) {
      z-index: 479; }
    .card-shadow.working-from-home .away-avatar:nth-child(522) {
      z-index: 478; }
    .card-shadow.working-from-home .away-avatar:nth-child(523) {
      z-index: 477; }
    .card-shadow.working-from-home .away-avatar:nth-child(524) {
      z-index: 476; }
    .card-shadow.working-from-home .away-avatar:nth-child(525) {
      z-index: 475; }
    .card-shadow.working-from-home .away-avatar:nth-child(526) {
      z-index: 474; }
    .card-shadow.working-from-home .away-avatar:nth-child(527) {
      z-index: 473; }
    .card-shadow.working-from-home .away-avatar:nth-child(528) {
      z-index: 472; }
    .card-shadow.working-from-home .away-avatar:nth-child(529) {
      z-index: 471; }
    .card-shadow.working-from-home .away-avatar:nth-child(530) {
      z-index: 470; }
    .card-shadow.working-from-home .away-avatar:nth-child(531) {
      z-index: 469; }
    .card-shadow.working-from-home .away-avatar:nth-child(532) {
      z-index: 468; }
    .card-shadow.working-from-home .away-avatar:nth-child(533) {
      z-index: 467; }
    .card-shadow.working-from-home .away-avatar:nth-child(534) {
      z-index: 466; }
    .card-shadow.working-from-home .away-avatar:nth-child(535) {
      z-index: 465; }
    .card-shadow.working-from-home .away-avatar:nth-child(536) {
      z-index: 464; }
    .card-shadow.working-from-home .away-avatar:nth-child(537) {
      z-index: 463; }
    .card-shadow.working-from-home .away-avatar:nth-child(538) {
      z-index: 462; }
    .card-shadow.working-from-home .away-avatar:nth-child(539) {
      z-index: 461; }
    .card-shadow.working-from-home .away-avatar:nth-child(540) {
      z-index: 460; }
    .card-shadow.working-from-home .away-avatar:nth-child(541) {
      z-index: 459; }
    .card-shadow.working-from-home .away-avatar:nth-child(542) {
      z-index: 458; }
    .card-shadow.working-from-home .away-avatar:nth-child(543) {
      z-index: 457; }
    .card-shadow.working-from-home .away-avatar:nth-child(544) {
      z-index: 456; }
    .card-shadow.working-from-home .away-avatar:nth-child(545) {
      z-index: 455; }
    .card-shadow.working-from-home .away-avatar:nth-child(546) {
      z-index: 454; }
    .card-shadow.working-from-home .away-avatar:nth-child(547) {
      z-index: 453; }
    .card-shadow.working-from-home .away-avatar:nth-child(548) {
      z-index: 452; }
    .card-shadow.working-from-home .away-avatar:nth-child(549) {
      z-index: 451; }
    .card-shadow.working-from-home .away-avatar:nth-child(550) {
      z-index: 450; }
    .card-shadow.working-from-home .away-avatar:nth-child(551) {
      z-index: 449; }
    .card-shadow.working-from-home .away-avatar:nth-child(552) {
      z-index: 448; }
    .card-shadow.working-from-home .away-avatar:nth-child(553) {
      z-index: 447; }
    .card-shadow.working-from-home .away-avatar:nth-child(554) {
      z-index: 446; }
    .card-shadow.working-from-home .away-avatar:nth-child(555) {
      z-index: 445; }
    .card-shadow.working-from-home .away-avatar:nth-child(556) {
      z-index: 444; }
    .card-shadow.working-from-home .away-avatar:nth-child(557) {
      z-index: 443; }
    .card-shadow.working-from-home .away-avatar:nth-child(558) {
      z-index: 442; }
    .card-shadow.working-from-home .away-avatar:nth-child(559) {
      z-index: 441; }
    .card-shadow.working-from-home .away-avatar:nth-child(560) {
      z-index: 440; }
    .card-shadow.working-from-home .away-avatar:nth-child(561) {
      z-index: 439; }
    .card-shadow.working-from-home .away-avatar:nth-child(562) {
      z-index: 438; }
    .card-shadow.working-from-home .away-avatar:nth-child(563) {
      z-index: 437; }
    .card-shadow.working-from-home .away-avatar:nth-child(564) {
      z-index: 436; }
    .card-shadow.working-from-home .away-avatar:nth-child(565) {
      z-index: 435; }
    .card-shadow.working-from-home .away-avatar:nth-child(566) {
      z-index: 434; }
    .card-shadow.working-from-home .away-avatar:nth-child(567) {
      z-index: 433; }
    .card-shadow.working-from-home .away-avatar:nth-child(568) {
      z-index: 432; }
    .card-shadow.working-from-home .away-avatar:nth-child(569) {
      z-index: 431; }
    .card-shadow.working-from-home .away-avatar:nth-child(570) {
      z-index: 430; }
    .card-shadow.working-from-home .away-avatar:nth-child(571) {
      z-index: 429; }
    .card-shadow.working-from-home .away-avatar:nth-child(572) {
      z-index: 428; }
    .card-shadow.working-from-home .away-avatar:nth-child(573) {
      z-index: 427; }
    .card-shadow.working-from-home .away-avatar:nth-child(574) {
      z-index: 426; }
    .card-shadow.working-from-home .away-avatar:nth-child(575) {
      z-index: 425; }
    .card-shadow.working-from-home .away-avatar:nth-child(576) {
      z-index: 424; }
    .card-shadow.working-from-home .away-avatar:nth-child(577) {
      z-index: 423; }
    .card-shadow.working-from-home .away-avatar:nth-child(578) {
      z-index: 422; }
    .card-shadow.working-from-home .away-avatar:nth-child(579) {
      z-index: 421; }
    .card-shadow.working-from-home .away-avatar:nth-child(580) {
      z-index: 420; }
    .card-shadow.working-from-home .away-avatar:nth-child(581) {
      z-index: 419; }
    .card-shadow.working-from-home .away-avatar:nth-child(582) {
      z-index: 418; }
    .card-shadow.working-from-home .away-avatar:nth-child(583) {
      z-index: 417; }
    .card-shadow.working-from-home .away-avatar:nth-child(584) {
      z-index: 416; }
    .card-shadow.working-from-home .away-avatar:nth-child(585) {
      z-index: 415; }
    .card-shadow.working-from-home .away-avatar:nth-child(586) {
      z-index: 414; }
    .card-shadow.working-from-home .away-avatar:nth-child(587) {
      z-index: 413; }
    .card-shadow.working-from-home .away-avatar:nth-child(588) {
      z-index: 412; }
    .card-shadow.working-from-home .away-avatar:nth-child(589) {
      z-index: 411; }
    .card-shadow.working-from-home .away-avatar:nth-child(590) {
      z-index: 410; }
    .card-shadow.working-from-home .away-avatar:nth-child(591) {
      z-index: 409; }
    .card-shadow.working-from-home .away-avatar:nth-child(592) {
      z-index: 408; }
    .card-shadow.working-from-home .away-avatar:nth-child(593) {
      z-index: 407; }
    .card-shadow.working-from-home .away-avatar:nth-child(594) {
      z-index: 406; }
    .card-shadow.working-from-home .away-avatar:nth-child(595) {
      z-index: 405; }
    .card-shadow.working-from-home .away-avatar:nth-child(596) {
      z-index: 404; }
    .card-shadow.working-from-home .away-avatar:nth-child(597) {
      z-index: 403; }
    .card-shadow.working-from-home .away-avatar:nth-child(598) {
      z-index: 402; }
    .card-shadow.working-from-home .away-avatar:nth-child(599) {
      z-index: 401; }
    .card-shadow.working-from-home .away-avatar:nth-child(600) {
      z-index: 400; }
    .card-shadow.working-from-home .away-avatar:nth-child(601) {
      z-index: 399; }
    .card-shadow.working-from-home .away-avatar:nth-child(602) {
      z-index: 398; }
    .card-shadow.working-from-home .away-avatar:nth-child(603) {
      z-index: 397; }
    .card-shadow.working-from-home .away-avatar:nth-child(604) {
      z-index: 396; }
    .card-shadow.working-from-home .away-avatar:nth-child(605) {
      z-index: 395; }
    .card-shadow.working-from-home .away-avatar:nth-child(606) {
      z-index: 394; }
    .card-shadow.working-from-home .away-avatar:nth-child(607) {
      z-index: 393; }
    .card-shadow.working-from-home .away-avatar:nth-child(608) {
      z-index: 392; }
    .card-shadow.working-from-home .away-avatar:nth-child(609) {
      z-index: 391; }
    .card-shadow.working-from-home .away-avatar:nth-child(610) {
      z-index: 390; }
    .card-shadow.working-from-home .away-avatar:nth-child(611) {
      z-index: 389; }
    .card-shadow.working-from-home .away-avatar:nth-child(612) {
      z-index: 388; }
    .card-shadow.working-from-home .away-avatar:nth-child(613) {
      z-index: 387; }
    .card-shadow.working-from-home .away-avatar:nth-child(614) {
      z-index: 386; }
    .card-shadow.working-from-home .away-avatar:nth-child(615) {
      z-index: 385; }
    .card-shadow.working-from-home .away-avatar:nth-child(616) {
      z-index: 384; }
    .card-shadow.working-from-home .away-avatar:nth-child(617) {
      z-index: 383; }
    .card-shadow.working-from-home .away-avatar:nth-child(618) {
      z-index: 382; }
    .card-shadow.working-from-home .away-avatar:nth-child(619) {
      z-index: 381; }
    .card-shadow.working-from-home .away-avatar:nth-child(620) {
      z-index: 380; }
    .card-shadow.working-from-home .away-avatar:nth-child(621) {
      z-index: 379; }
    .card-shadow.working-from-home .away-avatar:nth-child(622) {
      z-index: 378; }
    .card-shadow.working-from-home .away-avatar:nth-child(623) {
      z-index: 377; }
    .card-shadow.working-from-home .away-avatar:nth-child(624) {
      z-index: 376; }
    .card-shadow.working-from-home .away-avatar:nth-child(625) {
      z-index: 375; }
    .card-shadow.working-from-home .away-avatar:nth-child(626) {
      z-index: 374; }
    .card-shadow.working-from-home .away-avatar:nth-child(627) {
      z-index: 373; }
    .card-shadow.working-from-home .away-avatar:nth-child(628) {
      z-index: 372; }
    .card-shadow.working-from-home .away-avatar:nth-child(629) {
      z-index: 371; }
    .card-shadow.working-from-home .away-avatar:nth-child(630) {
      z-index: 370; }
    .card-shadow.working-from-home .away-avatar:nth-child(631) {
      z-index: 369; }
    .card-shadow.working-from-home .away-avatar:nth-child(632) {
      z-index: 368; }
    .card-shadow.working-from-home .away-avatar:nth-child(633) {
      z-index: 367; }
    .card-shadow.working-from-home .away-avatar:nth-child(634) {
      z-index: 366; }
    .card-shadow.working-from-home .away-avatar:nth-child(635) {
      z-index: 365; }
    .card-shadow.working-from-home .away-avatar:nth-child(636) {
      z-index: 364; }
    .card-shadow.working-from-home .away-avatar:nth-child(637) {
      z-index: 363; }
    .card-shadow.working-from-home .away-avatar:nth-child(638) {
      z-index: 362; }
    .card-shadow.working-from-home .away-avatar:nth-child(639) {
      z-index: 361; }
    .card-shadow.working-from-home .away-avatar:nth-child(640) {
      z-index: 360; }
    .card-shadow.working-from-home .away-avatar:nth-child(641) {
      z-index: 359; }
    .card-shadow.working-from-home .away-avatar:nth-child(642) {
      z-index: 358; }
    .card-shadow.working-from-home .away-avatar:nth-child(643) {
      z-index: 357; }
    .card-shadow.working-from-home .away-avatar:nth-child(644) {
      z-index: 356; }
    .card-shadow.working-from-home .away-avatar:nth-child(645) {
      z-index: 355; }
    .card-shadow.working-from-home .away-avatar:nth-child(646) {
      z-index: 354; }
    .card-shadow.working-from-home .away-avatar:nth-child(647) {
      z-index: 353; }
    .card-shadow.working-from-home .away-avatar:nth-child(648) {
      z-index: 352; }
    .card-shadow.working-from-home .away-avatar:nth-child(649) {
      z-index: 351; }
    .card-shadow.working-from-home .away-avatar:nth-child(650) {
      z-index: 350; }
    .card-shadow.working-from-home .away-avatar:nth-child(651) {
      z-index: 349; }
    .card-shadow.working-from-home .away-avatar:nth-child(652) {
      z-index: 348; }
    .card-shadow.working-from-home .away-avatar:nth-child(653) {
      z-index: 347; }
    .card-shadow.working-from-home .away-avatar:nth-child(654) {
      z-index: 346; }
    .card-shadow.working-from-home .away-avatar:nth-child(655) {
      z-index: 345; }
    .card-shadow.working-from-home .away-avatar:nth-child(656) {
      z-index: 344; }
    .card-shadow.working-from-home .away-avatar:nth-child(657) {
      z-index: 343; }
    .card-shadow.working-from-home .away-avatar:nth-child(658) {
      z-index: 342; }
    .card-shadow.working-from-home .away-avatar:nth-child(659) {
      z-index: 341; }
    .card-shadow.working-from-home .away-avatar:nth-child(660) {
      z-index: 340; }
    .card-shadow.working-from-home .away-avatar:nth-child(661) {
      z-index: 339; }
    .card-shadow.working-from-home .away-avatar:nth-child(662) {
      z-index: 338; }
    .card-shadow.working-from-home .away-avatar:nth-child(663) {
      z-index: 337; }
    .card-shadow.working-from-home .away-avatar:nth-child(664) {
      z-index: 336; }
    .card-shadow.working-from-home .away-avatar:nth-child(665) {
      z-index: 335; }
    .card-shadow.working-from-home .away-avatar:nth-child(666) {
      z-index: 334; }
    .card-shadow.working-from-home .away-avatar:nth-child(667) {
      z-index: 333; }
    .card-shadow.working-from-home .away-avatar:nth-child(668) {
      z-index: 332; }
    .card-shadow.working-from-home .away-avatar:nth-child(669) {
      z-index: 331; }
    .card-shadow.working-from-home .away-avatar:nth-child(670) {
      z-index: 330; }
    .card-shadow.working-from-home .away-avatar:nth-child(671) {
      z-index: 329; }
    .card-shadow.working-from-home .away-avatar:nth-child(672) {
      z-index: 328; }
    .card-shadow.working-from-home .away-avatar:nth-child(673) {
      z-index: 327; }
    .card-shadow.working-from-home .away-avatar:nth-child(674) {
      z-index: 326; }
    .card-shadow.working-from-home .away-avatar:nth-child(675) {
      z-index: 325; }
    .card-shadow.working-from-home .away-avatar:nth-child(676) {
      z-index: 324; }
    .card-shadow.working-from-home .away-avatar:nth-child(677) {
      z-index: 323; }
    .card-shadow.working-from-home .away-avatar:nth-child(678) {
      z-index: 322; }
    .card-shadow.working-from-home .away-avatar:nth-child(679) {
      z-index: 321; }
    .card-shadow.working-from-home .away-avatar:nth-child(680) {
      z-index: 320; }
    .card-shadow.working-from-home .away-avatar:nth-child(681) {
      z-index: 319; }
    .card-shadow.working-from-home .away-avatar:nth-child(682) {
      z-index: 318; }
    .card-shadow.working-from-home .away-avatar:nth-child(683) {
      z-index: 317; }
    .card-shadow.working-from-home .away-avatar:nth-child(684) {
      z-index: 316; }
    .card-shadow.working-from-home .away-avatar:nth-child(685) {
      z-index: 315; }
    .card-shadow.working-from-home .away-avatar:nth-child(686) {
      z-index: 314; }
    .card-shadow.working-from-home .away-avatar:nth-child(687) {
      z-index: 313; }
    .card-shadow.working-from-home .away-avatar:nth-child(688) {
      z-index: 312; }
    .card-shadow.working-from-home .away-avatar:nth-child(689) {
      z-index: 311; }
    .card-shadow.working-from-home .away-avatar:nth-child(690) {
      z-index: 310; }
    .card-shadow.working-from-home .away-avatar:nth-child(691) {
      z-index: 309; }
    .card-shadow.working-from-home .away-avatar:nth-child(692) {
      z-index: 308; }
    .card-shadow.working-from-home .away-avatar:nth-child(693) {
      z-index: 307; }
    .card-shadow.working-from-home .away-avatar:nth-child(694) {
      z-index: 306; }
    .card-shadow.working-from-home .away-avatar:nth-child(695) {
      z-index: 305; }
    .card-shadow.working-from-home .away-avatar:nth-child(696) {
      z-index: 304; }
    .card-shadow.working-from-home .away-avatar:nth-child(697) {
      z-index: 303; }
    .card-shadow.working-from-home .away-avatar:nth-child(698) {
      z-index: 302; }
    .card-shadow.working-from-home .away-avatar:nth-child(699) {
      z-index: 301; }
    .card-shadow.working-from-home .away-avatar:nth-child(700) {
      z-index: 300; }
    .card-shadow.working-from-home .away-avatar:nth-child(701) {
      z-index: 299; }
    .card-shadow.working-from-home .away-avatar:nth-child(702) {
      z-index: 298; }
    .card-shadow.working-from-home .away-avatar:nth-child(703) {
      z-index: 297; }
    .card-shadow.working-from-home .away-avatar:nth-child(704) {
      z-index: 296; }
    .card-shadow.working-from-home .away-avatar:nth-child(705) {
      z-index: 295; }
    .card-shadow.working-from-home .away-avatar:nth-child(706) {
      z-index: 294; }
    .card-shadow.working-from-home .away-avatar:nth-child(707) {
      z-index: 293; }
    .card-shadow.working-from-home .away-avatar:nth-child(708) {
      z-index: 292; }
    .card-shadow.working-from-home .away-avatar:nth-child(709) {
      z-index: 291; }
    .card-shadow.working-from-home .away-avatar:nth-child(710) {
      z-index: 290; }
    .card-shadow.working-from-home .away-avatar:nth-child(711) {
      z-index: 289; }
    .card-shadow.working-from-home .away-avatar:nth-child(712) {
      z-index: 288; }
    .card-shadow.working-from-home .away-avatar:nth-child(713) {
      z-index: 287; }
    .card-shadow.working-from-home .away-avatar:nth-child(714) {
      z-index: 286; }
    .card-shadow.working-from-home .away-avatar:nth-child(715) {
      z-index: 285; }
    .card-shadow.working-from-home .away-avatar:nth-child(716) {
      z-index: 284; }
    .card-shadow.working-from-home .away-avatar:nth-child(717) {
      z-index: 283; }
    .card-shadow.working-from-home .away-avatar:nth-child(718) {
      z-index: 282; }
    .card-shadow.working-from-home .away-avatar:nth-child(719) {
      z-index: 281; }
    .card-shadow.working-from-home .away-avatar:nth-child(720) {
      z-index: 280; }
    .card-shadow.working-from-home .away-avatar:nth-child(721) {
      z-index: 279; }
    .card-shadow.working-from-home .away-avatar:nth-child(722) {
      z-index: 278; }
    .card-shadow.working-from-home .away-avatar:nth-child(723) {
      z-index: 277; }
    .card-shadow.working-from-home .away-avatar:nth-child(724) {
      z-index: 276; }
    .card-shadow.working-from-home .away-avatar:nth-child(725) {
      z-index: 275; }
    .card-shadow.working-from-home .away-avatar:nth-child(726) {
      z-index: 274; }
    .card-shadow.working-from-home .away-avatar:nth-child(727) {
      z-index: 273; }
    .card-shadow.working-from-home .away-avatar:nth-child(728) {
      z-index: 272; }
    .card-shadow.working-from-home .away-avatar:nth-child(729) {
      z-index: 271; }
    .card-shadow.working-from-home .away-avatar:nth-child(730) {
      z-index: 270; }
    .card-shadow.working-from-home .away-avatar:nth-child(731) {
      z-index: 269; }
    .card-shadow.working-from-home .away-avatar:nth-child(732) {
      z-index: 268; }
    .card-shadow.working-from-home .away-avatar:nth-child(733) {
      z-index: 267; }
    .card-shadow.working-from-home .away-avatar:nth-child(734) {
      z-index: 266; }
    .card-shadow.working-from-home .away-avatar:nth-child(735) {
      z-index: 265; }
    .card-shadow.working-from-home .away-avatar:nth-child(736) {
      z-index: 264; }
    .card-shadow.working-from-home .away-avatar:nth-child(737) {
      z-index: 263; }
    .card-shadow.working-from-home .away-avatar:nth-child(738) {
      z-index: 262; }
    .card-shadow.working-from-home .away-avatar:nth-child(739) {
      z-index: 261; }
    .card-shadow.working-from-home .away-avatar:nth-child(740) {
      z-index: 260; }
    .card-shadow.working-from-home .away-avatar:nth-child(741) {
      z-index: 259; }
    .card-shadow.working-from-home .away-avatar:nth-child(742) {
      z-index: 258; }
    .card-shadow.working-from-home .away-avatar:nth-child(743) {
      z-index: 257; }
    .card-shadow.working-from-home .away-avatar:nth-child(744) {
      z-index: 256; }
    .card-shadow.working-from-home .away-avatar:nth-child(745) {
      z-index: 255; }
    .card-shadow.working-from-home .away-avatar:nth-child(746) {
      z-index: 254; }
    .card-shadow.working-from-home .away-avatar:nth-child(747) {
      z-index: 253; }
    .card-shadow.working-from-home .away-avatar:nth-child(748) {
      z-index: 252; }
    .card-shadow.working-from-home .away-avatar:nth-child(749) {
      z-index: 251; }
    .card-shadow.working-from-home .away-avatar:nth-child(750) {
      z-index: 250; }
    .card-shadow.working-from-home .away-avatar:nth-child(751) {
      z-index: 249; }
    .card-shadow.working-from-home .away-avatar:nth-child(752) {
      z-index: 248; }
    .card-shadow.working-from-home .away-avatar:nth-child(753) {
      z-index: 247; }
    .card-shadow.working-from-home .away-avatar:nth-child(754) {
      z-index: 246; }
    .card-shadow.working-from-home .away-avatar:nth-child(755) {
      z-index: 245; }
    .card-shadow.working-from-home .away-avatar:nth-child(756) {
      z-index: 244; }
    .card-shadow.working-from-home .away-avatar:nth-child(757) {
      z-index: 243; }
    .card-shadow.working-from-home .away-avatar:nth-child(758) {
      z-index: 242; }
    .card-shadow.working-from-home .away-avatar:nth-child(759) {
      z-index: 241; }
    .card-shadow.working-from-home .away-avatar:nth-child(760) {
      z-index: 240; }
    .card-shadow.working-from-home .away-avatar:nth-child(761) {
      z-index: 239; }
    .card-shadow.working-from-home .away-avatar:nth-child(762) {
      z-index: 238; }
    .card-shadow.working-from-home .away-avatar:nth-child(763) {
      z-index: 237; }
    .card-shadow.working-from-home .away-avatar:nth-child(764) {
      z-index: 236; }
    .card-shadow.working-from-home .away-avatar:nth-child(765) {
      z-index: 235; }
    .card-shadow.working-from-home .away-avatar:nth-child(766) {
      z-index: 234; }
    .card-shadow.working-from-home .away-avatar:nth-child(767) {
      z-index: 233; }
    .card-shadow.working-from-home .away-avatar:nth-child(768) {
      z-index: 232; }
    .card-shadow.working-from-home .away-avatar:nth-child(769) {
      z-index: 231; }
    .card-shadow.working-from-home .away-avatar:nth-child(770) {
      z-index: 230; }
    .card-shadow.working-from-home .away-avatar:nth-child(771) {
      z-index: 229; }
    .card-shadow.working-from-home .away-avatar:nth-child(772) {
      z-index: 228; }
    .card-shadow.working-from-home .away-avatar:nth-child(773) {
      z-index: 227; }
    .card-shadow.working-from-home .away-avatar:nth-child(774) {
      z-index: 226; }
    .card-shadow.working-from-home .away-avatar:nth-child(775) {
      z-index: 225; }
    .card-shadow.working-from-home .away-avatar:nth-child(776) {
      z-index: 224; }
    .card-shadow.working-from-home .away-avatar:nth-child(777) {
      z-index: 223; }
    .card-shadow.working-from-home .away-avatar:nth-child(778) {
      z-index: 222; }
    .card-shadow.working-from-home .away-avatar:nth-child(779) {
      z-index: 221; }
    .card-shadow.working-from-home .away-avatar:nth-child(780) {
      z-index: 220; }
    .card-shadow.working-from-home .away-avatar:nth-child(781) {
      z-index: 219; }
    .card-shadow.working-from-home .away-avatar:nth-child(782) {
      z-index: 218; }
    .card-shadow.working-from-home .away-avatar:nth-child(783) {
      z-index: 217; }
    .card-shadow.working-from-home .away-avatar:nth-child(784) {
      z-index: 216; }
    .card-shadow.working-from-home .away-avatar:nth-child(785) {
      z-index: 215; }
    .card-shadow.working-from-home .away-avatar:nth-child(786) {
      z-index: 214; }
    .card-shadow.working-from-home .away-avatar:nth-child(787) {
      z-index: 213; }
    .card-shadow.working-from-home .away-avatar:nth-child(788) {
      z-index: 212; }
    .card-shadow.working-from-home .away-avatar:nth-child(789) {
      z-index: 211; }
    .card-shadow.working-from-home .away-avatar:nth-child(790) {
      z-index: 210; }
    .card-shadow.working-from-home .away-avatar:nth-child(791) {
      z-index: 209; }
    .card-shadow.working-from-home .away-avatar:nth-child(792) {
      z-index: 208; }
    .card-shadow.working-from-home .away-avatar:nth-child(793) {
      z-index: 207; }
    .card-shadow.working-from-home .away-avatar:nth-child(794) {
      z-index: 206; }
    .card-shadow.working-from-home .away-avatar:nth-child(795) {
      z-index: 205; }
    .card-shadow.working-from-home .away-avatar:nth-child(796) {
      z-index: 204; }
    .card-shadow.working-from-home .away-avatar:nth-child(797) {
      z-index: 203; }
    .card-shadow.working-from-home .away-avatar:nth-child(798) {
      z-index: 202; }
    .card-shadow.working-from-home .away-avatar:nth-child(799) {
      z-index: 201; }
    .card-shadow.working-from-home .away-avatar:nth-child(800) {
      z-index: 200; }
    .card-shadow.working-from-home .away-avatar:nth-child(801) {
      z-index: 199; }
    .card-shadow.working-from-home .away-avatar:nth-child(802) {
      z-index: 198; }
    .card-shadow.working-from-home .away-avatar:nth-child(803) {
      z-index: 197; }
    .card-shadow.working-from-home .away-avatar:nth-child(804) {
      z-index: 196; }
    .card-shadow.working-from-home .away-avatar:nth-child(805) {
      z-index: 195; }
    .card-shadow.working-from-home .away-avatar:nth-child(806) {
      z-index: 194; }
    .card-shadow.working-from-home .away-avatar:nth-child(807) {
      z-index: 193; }
    .card-shadow.working-from-home .away-avatar:nth-child(808) {
      z-index: 192; }
    .card-shadow.working-from-home .away-avatar:nth-child(809) {
      z-index: 191; }
    .card-shadow.working-from-home .away-avatar:nth-child(810) {
      z-index: 190; }
    .card-shadow.working-from-home .away-avatar:nth-child(811) {
      z-index: 189; }
    .card-shadow.working-from-home .away-avatar:nth-child(812) {
      z-index: 188; }
    .card-shadow.working-from-home .away-avatar:nth-child(813) {
      z-index: 187; }
    .card-shadow.working-from-home .away-avatar:nth-child(814) {
      z-index: 186; }
    .card-shadow.working-from-home .away-avatar:nth-child(815) {
      z-index: 185; }
    .card-shadow.working-from-home .away-avatar:nth-child(816) {
      z-index: 184; }
    .card-shadow.working-from-home .away-avatar:nth-child(817) {
      z-index: 183; }
    .card-shadow.working-from-home .away-avatar:nth-child(818) {
      z-index: 182; }
    .card-shadow.working-from-home .away-avatar:nth-child(819) {
      z-index: 181; }
    .card-shadow.working-from-home .away-avatar:nth-child(820) {
      z-index: 180; }
    .card-shadow.working-from-home .away-avatar:nth-child(821) {
      z-index: 179; }
    .card-shadow.working-from-home .away-avatar:nth-child(822) {
      z-index: 178; }
    .card-shadow.working-from-home .away-avatar:nth-child(823) {
      z-index: 177; }
    .card-shadow.working-from-home .away-avatar:nth-child(824) {
      z-index: 176; }
    .card-shadow.working-from-home .away-avatar:nth-child(825) {
      z-index: 175; }
    .card-shadow.working-from-home .away-avatar:nth-child(826) {
      z-index: 174; }
    .card-shadow.working-from-home .away-avatar:nth-child(827) {
      z-index: 173; }
    .card-shadow.working-from-home .away-avatar:nth-child(828) {
      z-index: 172; }
    .card-shadow.working-from-home .away-avatar:nth-child(829) {
      z-index: 171; }
    .card-shadow.working-from-home .away-avatar:nth-child(830) {
      z-index: 170; }
    .card-shadow.working-from-home .away-avatar:nth-child(831) {
      z-index: 169; }
    .card-shadow.working-from-home .away-avatar:nth-child(832) {
      z-index: 168; }
    .card-shadow.working-from-home .away-avatar:nth-child(833) {
      z-index: 167; }
    .card-shadow.working-from-home .away-avatar:nth-child(834) {
      z-index: 166; }
    .card-shadow.working-from-home .away-avatar:nth-child(835) {
      z-index: 165; }
    .card-shadow.working-from-home .away-avatar:nth-child(836) {
      z-index: 164; }
    .card-shadow.working-from-home .away-avatar:nth-child(837) {
      z-index: 163; }
    .card-shadow.working-from-home .away-avatar:nth-child(838) {
      z-index: 162; }
    .card-shadow.working-from-home .away-avatar:nth-child(839) {
      z-index: 161; }
    .card-shadow.working-from-home .away-avatar:nth-child(840) {
      z-index: 160; }
    .card-shadow.working-from-home .away-avatar:nth-child(841) {
      z-index: 159; }
    .card-shadow.working-from-home .away-avatar:nth-child(842) {
      z-index: 158; }
    .card-shadow.working-from-home .away-avatar:nth-child(843) {
      z-index: 157; }
    .card-shadow.working-from-home .away-avatar:nth-child(844) {
      z-index: 156; }
    .card-shadow.working-from-home .away-avatar:nth-child(845) {
      z-index: 155; }
    .card-shadow.working-from-home .away-avatar:nth-child(846) {
      z-index: 154; }
    .card-shadow.working-from-home .away-avatar:nth-child(847) {
      z-index: 153; }
    .card-shadow.working-from-home .away-avatar:nth-child(848) {
      z-index: 152; }
    .card-shadow.working-from-home .away-avatar:nth-child(849) {
      z-index: 151; }
    .card-shadow.working-from-home .away-avatar:nth-child(850) {
      z-index: 150; }
    .card-shadow.working-from-home .away-avatar:nth-child(851) {
      z-index: 149; }
    .card-shadow.working-from-home .away-avatar:nth-child(852) {
      z-index: 148; }
    .card-shadow.working-from-home .away-avatar:nth-child(853) {
      z-index: 147; }
    .card-shadow.working-from-home .away-avatar:nth-child(854) {
      z-index: 146; }
    .card-shadow.working-from-home .away-avatar:nth-child(855) {
      z-index: 145; }
    .card-shadow.working-from-home .away-avatar:nth-child(856) {
      z-index: 144; }
    .card-shadow.working-from-home .away-avatar:nth-child(857) {
      z-index: 143; }
    .card-shadow.working-from-home .away-avatar:nth-child(858) {
      z-index: 142; }
    .card-shadow.working-from-home .away-avatar:nth-child(859) {
      z-index: 141; }
    .card-shadow.working-from-home .away-avatar:nth-child(860) {
      z-index: 140; }
    .card-shadow.working-from-home .away-avatar:nth-child(861) {
      z-index: 139; }
    .card-shadow.working-from-home .away-avatar:nth-child(862) {
      z-index: 138; }
    .card-shadow.working-from-home .away-avatar:nth-child(863) {
      z-index: 137; }
    .card-shadow.working-from-home .away-avatar:nth-child(864) {
      z-index: 136; }
    .card-shadow.working-from-home .away-avatar:nth-child(865) {
      z-index: 135; }
    .card-shadow.working-from-home .away-avatar:nth-child(866) {
      z-index: 134; }
    .card-shadow.working-from-home .away-avatar:nth-child(867) {
      z-index: 133; }
    .card-shadow.working-from-home .away-avatar:nth-child(868) {
      z-index: 132; }
    .card-shadow.working-from-home .away-avatar:nth-child(869) {
      z-index: 131; }
    .card-shadow.working-from-home .away-avatar:nth-child(870) {
      z-index: 130; }
    .card-shadow.working-from-home .away-avatar:nth-child(871) {
      z-index: 129; }
    .card-shadow.working-from-home .away-avatar:nth-child(872) {
      z-index: 128; }
    .card-shadow.working-from-home .away-avatar:nth-child(873) {
      z-index: 127; }
    .card-shadow.working-from-home .away-avatar:nth-child(874) {
      z-index: 126; }
    .card-shadow.working-from-home .away-avatar:nth-child(875) {
      z-index: 125; }
    .card-shadow.working-from-home .away-avatar:nth-child(876) {
      z-index: 124; }
    .card-shadow.working-from-home .away-avatar:nth-child(877) {
      z-index: 123; }
    .card-shadow.working-from-home .away-avatar:nth-child(878) {
      z-index: 122; }
    .card-shadow.working-from-home .away-avatar:nth-child(879) {
      z-index: 121; }
    .card-shadow.working-from-home .away-avatar:nth-child(880) {
      z-index: 120; }
    .card-shadow.working-from-home .away-avatar:nth-child(881) {
      z-index: 119; }
    .card-shadow.working-from-home .away-avatar:nth-child(882) {
      z-index: 118; }
    .card-shadow.working-from-home .away-avatar:nth-child(883) {
      z-index: 117; }
    .card-shadow.working-from-home .away-avatar:nth-child(884) {
      z-index: 116; }
    .card-shadow.working-from-home .away-avatar:nth-child(885) {
      z-index: 115; }
    .card-shadow.working-from-home .away-avatar:nth-child(886) {
      z-index: 114; }
    .card-shadow.working-from-home .away-avatar:nth-child(887) {
      z-index: 113; }
    .card-shadow.working-from-home .away-avatar:nth-child(888) {
      z-index: 112; }
    .card-shadow.working-from-home .away-avatar:nth-child(889) {
      z-index: 111; }
    .card-shadow.working-from-home .away-avatar:nth-child(890) {
      z-index: 110; }
    .card-shadow.working-from-home .away-avatar:nth-child(891) {
      z-index: 109; }
    .card-shadow.working-from-home .away-avatar:nth-child(892) {
      z-index: 108; }
    .card-shadow.working-from-home .away-avatar:nth-child(893) {
      z-index: 107; }
    .card-shadow.working-from-home .away-avatar:nth-child(894) {
      z-index: 106; }
    .card-shadow.working-from-home .away-avatar:nth-child(895) {
      z-index: 105; }
    .card-shadow.working-from-home .away-avatar:nth-child(896) {
      z-index: 104; }
    .card-shadow.working-from-home .away-avatar:nth-child(897) {
      z-index: 103; }
    .card-shadow.working-from-home .away-avatar:nth-child(898) {
      z-index: 102; }
    .card-shadow.working-from-home .away-avatar:nth-child(899) {
      z-index: 101; }
    .card-shadow.working-from-home .away-avatar:nth-child(900) {
      z-index: 100; }
    .card-shadow.working-from-home .away-avatar:nth-child(901) {
      z-index: 99; }
    .card-shadow.working-from-home .away-avatar:nth-child(902) {
      z-index: 98; }
    .card-shadow.working-from-home .away-avatar:nth-child(903) {
      z-index: 97; }
    .card-shadow.working-from-home .away-avatar:nth-child(904) {
      z-index: 96; }
    .card-shadow.working-from-home .away-avatar:nth-child(905) {
      z-index: 95; }
    .card-shadow.working-from-home .away-avatar:nth-child(906) {
      z-index: 94; }
    .card-shadow.working-from-home .away-avatar:nth-child(907) {
      z-index: 93; }
    .card-shadow.working-from-home .away-avatar:nth-child(908) {
      z-index: 92; }
    .card-shadow.working-from-home .away-avatar:nth-child(909) {
      z-index: 91; }
    .card-shadow.working-from-home .away-avatar:nth-child(910) {
      z-index: 90; }
    .card-shadow.working-from-home .away-avatar:nth-child(911) {
      z-index: 89; }
    .card-shadow.working-from-home .away-avatar:nth-child(912) {
      z-index: 88; }
    .card-shadow.working-from-home .away-avatar:nth-child(913) {
      z-index: 87; }
    .card-shadow.working-from-home .away-avatar:nth-child(914) {
      z-index: 86; }
    .card-shadow.working-from-home .away-avatar:nth-child(915) {
      z-index: 85; }
    .card-shadow.working-from-home .away-avatar:nth-child(916) {
      z-index: 84; }
    .card-shadow.working-from-home .away-avatar:nth-child(917) {
      z-index: 83; }
    .card-shadow.working-from-home .away-avatar:nth-child(918) {
      z-index: 82; }
    .card-shadow.working-from-home .away-avatar:nth-child(919) {
      z-index: 81; }
    .card-shadow.working-from-home .away-avatar:nth-child(920) {
      z-index: 80; }
    .card-shadow.working-from-home .away-avatar:nth-child(921) {
      z-index: 79; }
    .card-shadow.working-from-home .away-avatar:nth-child(922) {
      z-index: 78; }
    .card-shadow.working-from-home .away-avatar:nth-child(923) {
      z-index: 77; }
    .card-shadow.working-from-home .away-avatar:nth-child(924) {
      z-index: 76; }
    .card-shadow.working-from-home .away-avatar:nth-child(925) {
      z-index: 75; }
    .card-shadow.working-from-home .away-avatar:nth-child(926) {
      z-index: 74; }
    .card-shadow.working-from-home .away-avatar:nth-child(927) {
      z-index: 73; }
    .card-shadow.working-from-home .away-avatar:nth-child(928) {
      z-index: 72; }
    .card-shadow.working-from-home .away-avatar:nth-child(929) {
      z-index: 71; }
    .card-shadow.working-from-home .away-avatar:nth-child(930) {
      z-index: 70; }
    .card-shadow.working-from-home .away-avatar:nth-child(931) {
      z-index: 69; }
    .card-shadow.working-from-home .away-avatar:nth-child(932) {
      z-index: 68; }
    .card-shadow.working-from-home .away-avatar:nth-child(933) {
      z-index: 67; }
    .card-shadow.working-from-home .away-avatar:nth-child(934) {
      z-index: 66; }
    .card-shadow.working-from-home .away-avatar:nth-child(935) {
      z-index: 65; }
    .card-shadow.working-from-home .away-avatar:nth-child(936) {
      z-index: 64; }
    .card-shadow.working-from-home .away-avatar:nth-child(937) {
      z-index: 63; }
    .card-shadow.working-from-home .away-avatar:nth-child(938) {
      z-index: 62; }
    .card-shadow.working-from-home .away-avatar:nth-child(939) {
      z-index: 61; }
    .card-shadow.working-from-home .away-avatar:nth-child(940) {
      z-index: 60; }
    .card-shadow.working-from-home .away-avatar:nth-child(941) {
      z-index: 59; }
    .card-shadow.working-from-home .away-avatar:nth-child(942) {
      z-index: 58; }
    .card-shadow.working-from-home .away-avatar:nth-child(943) {
      z-index: 57; }
    .card-shadow.working-from-home .away-avatar:nth-child(944) {
      z-index: 56; }
    .card-shadow.working-from-home .away-avatar:nth-child(945) {
      z-index: 55; }
    .card-shadow.working-from-home .away-avatar:nth-child(946) {
      z-index: 54; }
    .card-shadow.working-from-home .away-avatar:nth-child(947) {
      z-index: 53; }
    .card-shadow.working-from-home .away-avatar:nth-child(948) {
      z-index: 52; }
    .card-shadow.working-from-home .away-avatar:nth-child(949) {
      z-index: 51; }
    .card-shadow.working-from-home .away-avatar:nth-child(950) {
      z-index: 50; }
    .card-shadow.working-from-home .away-avatar:nth-child(951) {
      z-index: 49; }
    .card-shadow.working-from-home .away-avatar:nth-child(952) {
      z-index: 48; }
    .card-shadow.working-from-home .away-avatar:nth-child(953) {
      z-index: 47; }
    .card-shadow.working-from-home .away-avatar:nth-child(954) {
      z-index: 46; }
    .card-shadow.working-from-home .away-avatar:nth-child(955) {
      z-index: 45; }
    .card-shadow.working-from-home .away-avatar:nth-child(956) {
      z-index: 44; }
    .card-shadow.working-from-home .away-avatar:nth-child(957) {
      z-index: 43; }
    .card-shadow.working-from-home .away-avatar:nth-child(958) {
      z-index: 42; }
    .card-shadow.working-from-home .away-avatar:nth-child(959) {
      z-index: 41; }
    .card-shadow.working-from-home .away-avatar:nth-child(960) {
      z-index: 40; }
    .card-shadow.working-from-home .away-avatar:nth-child(961) {
      z-index: 39; }
    .card-shadow.working-from-home .away-avatar:nth-child(962) {
      z-index: 38; }
    .card-shadow.working-from-home .away-avatar:nth-child(963) {
      z-index: 37; }
    .card-shadow.working-from-home .away-avatar:nth-child(964) {
      z-index: 36; }
    .card-shadow.working-from-home .away-avatar:nth-child(965) {
      z-index: 35; }
    .card-shadow.working-from-home .away-avatar:nth-child(966) {
      z-index: 34; }
    .card-shadow.working-from-home .away-avatar:nth-child(967) {
      z-index: 33; }
    .card-shadow.working-from-home .away-avatar:nth-child(968) {
      z-index: 32; }
    .card-shadow.working-from-home .away-avatar:nth-child(969) {
      z-index: 31; }
    .card-shadow.working-from-home .away-avatar:nth-child(970) {
      z-index: 30; }
    .card-shadow.working-from-home .away-avatar:nth-child(971) {
      z-index: 29; }
    .card-shadow.working-from-home .away-avatar:nth-child(972) {
      z-index: 28; }
    .card-shadow.working-from-home .away-avatar:nth-child(973) {
      z-index: 27; }
    .card-shadow.working-from-home .away-avatar:nth-child(974) {
      z-index: 26; }
    .card-shadow.working-from-home .away-avatar:nth-child(975) {
      z-index: 25; }
    .card-shadow.working-from-home .away-avatar:nth-child(976) {
      z-index: 24; }
    .card-shadow.working-from-home .away-avatar:nth-child(977) {
      z-index: 23; }
    .card-shadow.working-from-home .away-avatar:nth-child(978) {
      z-index: 22; }
    .card-shadow.working-from-home .away-avatar:nth-child(979) {
      z-index: 21; }
    .card-shadow.working-from-home .away-avatar:nth-child(980) {
      z-index: 20; }
    .card-shadow.working-from-home .away-avatar:nth-child(981) {
      z-index: 19; }
    .card-shadow.working-from-home .away-avatar:nth-child(982) {
      z-index: 18; }
    .card-shadow.working-from-home .away-avatar:nth-child(983) {
      z-index: 17; }
    .card-shadow.working-from-home .away-avatar:nth-child(984) {
      z-index: 16; }
    .card-shadow.working-from-home .away-avatar:nth-child(985) {
      z-index: 15; }
    .card-shadow.working-from-home .away-avatar:nth-child(986) {
      z-index: 14; }
    .card-shadow.working-from-home .away-avatar:nth-child(987) {
      z-index: 13; }
    .card-shadow.working-from-home .away-avatar:nth-child(988) {
      z-index: 12; }
    .card-shadow.working-from-home .away-avatar:nth-child(989) {
      z-index: 11; }
    .card-shadow.working-from-home .away-avatar:nth-child(990) {
      z-index: 10; }
    .card-shadow.working-from-home .away-avatar:nth-child(991) {
      z-index: 9; }
    .card-shadow.working-from-home .away-avatar:nth-child(992) {
      z-index: 8; }
    .card-shadow.working-from-home .away-avatar:nth-child(993) {
      z-index: 7; }
    .card-shadow.working-from-home .away-avatar:nth-child(994) {
      z-index: 6; }
    .card-shadow.working-from-home .away-avatar:nth-child(995) {
      z-index: 5; }
    .card-shadow.working-from-home .away-avatar:nth-child(996) {
      z-index: 4; }
    .card-shadow.working-from-home .away-avatar:nth-child(997) {
      z-index: 3; }
    .card-shadow.working-from-home .away-avatar:nth-child(998) {
      z-index: 2; }
    .card-shadow.working-from-home .away-avatar:nth-child(999) {
      z-index: 1; }

.card-shadow.upcoming-holidays .top-row {
  margin-bottom: 10px; }

.card-shadow.upcoming-holidays span {
  font-size: 12px; }

.toolbar-container {
  padding: 14px 20px; }
  .toolbar-container .away-dropdown {
    max-width: 150px;
    min-width: 150px; }
  .toolbar-container .filters-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap; }
    .toolbar-container .filters-container .select-item {
      margin: 0 5px; }
    .toolbar-container .filters-container .overflow-menu button,
    .toolbar-container .filters-container .overflow-menu .btn-secondary.dropdown-toggle {
      border-radius: 18px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      background-color: #f6f8fc;
      border: 0;
      padding: 0;
      font-size: 14px;
      color: #333333; }
      .toolbar-container .filters-container .overflow-menu button:focus,
      .toolbar-container .filters-container .overflow-menu .btn-secondary.dropdown-toggle:focus {
        box-shadow: 0 0 0 1px #ff6600; }
      .toolbar-container .filters-container .overflow-menu button .text,
      .toolbar-container .filters-container .overflow-menu .btn-secondary.dropdown-toggle .text {
        padding: 2px 0 2px 8px; }
      .toolbar-container .filters-container .overflow-menu button::after,
      .toolbar-container .filters-container .overflow-menu .btn-secondary.dropdown-toggle::after {
        display: none; }
      .toolbar-container .filters-container .overflow-menu button svg,
      .toolbar-container .filters-container .overflow-menu .btn-secondary.dropdown-toggle svg {
        margin: 4px 4px 4px 0;
        fill: #cccccc; }
      .toolbar-container .filters-container .overflow-menu button.is-open svg,
      .toolbar-container .filters-container .overflow-menu .btn-secondary.dropdown-toggle.is-open svg {
        fill: #666666; }
    .toolbar-container .filters-container .overflow-menu .overflow-item {
      padding: 0px 10px; }
      .toolbar-container .filters-container .overflow-menu .overflow-item .away-dropdown {
        z-index: unset !important; }
    .toolbar-container .filters-container .overflow-menu .bank-holidays-control {
      padding: 0px 10px; }
      .toolbar-container .filters-container .overflow-menu .bank-holidays-control h6 {
        font-size: 14px;
        color: #a5adbc;
        margin-bottom: 0; }
      .toolbar-container .filters-container .overflow-menu .bank-holidays-control label {
        font-size: 14px;
        font-family: "Mark Pro Light";
        color: #2d2f44; }
        .toolbar-container .filters-container .overflow-menu .bank-holidays-control label input[type='radio'] {
          -webkit-appearance: none;
             -moz-appearance: none;
                  appearance: none;
          width: 12px;
          height: 12px;
          border: 2px solid #ff6600;
          border-radius: 50%;
          outline: none;
          cursor: pointer;
          transition: background 0.3s, border-color 0.3s; }
          .toolbar-container .filters-container .overflow-menu .bank-holidays-control label input[type='radio']:checked {
            background-color: #ff6600;
            border-color: #ff6600; }
          .toolbar-container .filters-container .overflow-menu .bank-holidays-control label input[type='radio']:hover {
            border-color: #cc5200; }
          .toolbar-container .filters-container .overflow-menu .bank-holidays-control label input[type='radio']:focus {
            box-shadow: 0 0 0 3px rgba(255, 102, 0, 0.3); }
      .toolbar-container .filters-container .overflow-menu .bank-holidays-control .country-select {
        font-size: 12px;
        min-width: 250px;
        max-width: 250px;
        margin-bottom: 10px; }
      .toolbar-container .filters-container .overflow-menu .bank-holidays-control button {
        padding: 8px 10px;
        font-size: 14px;
        font-family: "Mark Pro Medium";
        color: #ffffff;
        background-color: #ff6600;
        border: none;
        cursor: pointer;
        transition: background-color 0.3s; }
        .toolbar-container .filters-container .overflow-menu .bank-holidays-control button:hover {
          background-color: #cc5200; }
  @media only screen and (max-width: 1260px) {
    .toolbar-container .away-dropdown {
      max-width: 150px;
      min-width: 150px; }
      .toolbar-container .away-dropdown:first-child {
        margin-bottom: 10px; }
      .toolbar-container .away-dropdown .away__single-value {
        font-size: 12px; } }
  .toolbar-container .row {
    -webkit-align-items: center;
            align-items: center; }
  .toolbar-container .toolbar-togglers {
    position: absolute;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: center;
            align-items: center;
    right: 0;
    top: -60px;
    letter-spacing: 0;
    font-family: "Mark Pro Medium"; }
  .toolbar-container .back-next-buttons {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
    .toolbar-container .back-next-buttons .toolbar-date {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center; }
      .toolbar-container .back-next-buttons .toolbar-date .btn-today {
        padding: 5px; }
    .toolbar-container .back-next-buttons .label-date {
      font-family: "Mark Pro Medium";
      font-weight: normal;
      font-size: clamp(14px, 1.1vw, 20px);
      margin-bottom: 0; }
      @media only screen and (max-width: 768px) {
        .toolbar-container .back-next-buttons .label-date {
          text-align: center;
          margin-left: 5px;
          margin-right: 5px; } }
      .toolbar-container .back-next-buttons .label-date b {
        font-weight: normal; }
      .toolbar-container .back-next-buttons .label-date .year {
        font-size: clamp(14px, 1.1vw, 18px); }
    .toolbar-container .back-next-buttons .btn-link {
      padding: 10px; }
      .toolbar-container .back-next-buttons .btn-link:hover {
        text-decoration: none; }

.switch-container {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 20px 0; }

.switch {
  position: relative;
  display: -webkit-flex;
  display: flex;
  min-width: 170px;
  max-width: 300px;
  width: auto;
  height: 30px;
  border-radius: 15px;
  padding: 2px;
  cursor: pointer;
  background-color: #f6f8fc;
  border: 0; }

.option {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  z-index: 1;
  font-family: "Mark Pro Book";
  color: #ff6600;
  transition: color 0.3s ease;
  padding: 5px 12px;
  font-size: 14px;
  white-space: nowrap;
  min-width: 60px; }

.option.selected {
  color: white; }

.slider {
  position: absolute;
  top: 2px;
  height: calc(100% - 4px);
  background-image: linear-gradient(-121deg, #ff7575 0%, #ffb230 100%);
  border-radius: 13px;
  transition: all 0.3s ease; }

.card-shadow.calendar {
  padding: 0; }
  .card-shadow.calendar.admin-mobile {
    margin-top: 30px; }
  @media only screen and (max-width: 550px) {
    .card-shadow.calendar {
      display: none; } }

.away-calendar {
  height: 740px; }
  .away-calendar .rbc-month-view {
    border: 0;
    height: auto; }
    .away-calendar .rbc-month-view .rbc-overlay {
      border-radius: 5px;
      box-shadow: 0 2px 14px 0 rgba(12, 15, 19, 0.09);
      border: 0;
      width: -webkit-min-content;
      width: -moz-min-content;
      width: min-content;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      z-index: 20; }
      .away-calendar .rbc-month-view .rbc-overlay .rbc-overlay-header {
        font-size: 16px;
        font-family: "Mark Pro Bold";
        -webkit-order: 1;
                order: 1; }
    .away-calendar .rbc-month-view .rbc-month-header {
      border-top: 1px solid #f1f3f7; }
      .away-calendar .rbc-month-view .rbc-month-header .rbc-header {
        font-size: 100%;
        text-align: right;
        padding: 5px 10px 5px 0;
        border-bottom: 1px solid #f1f3f7;
        border-left: 1px solid #f1f3f7; }
        .away-calendar .rbc-month-view .rbc-month-header .rbc-header:first-child {
          border-left: 0; }
        .away-calendar .rbc-month-view .rbc-month-header .rbc-header span {
          font-family: "Mark Pro Medium";
          color: #2d2f44;
          font-size: 12px;
          font-weight: normal; }
    .away-calendar .rbc-month-view .rbc-event-continues-prior {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px; }
      .away-calendar .rbc-month-view .rbc-event-continues-prior .publicHolidayEvent,
      .away-calendar .rbc-month-view .rbc-event-continues-prior .publicHolidayExtraEvent {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px; }
      .away-calendar .rbc-month-view .rbc-event-continues-prior .event-border {
        visibility: hidden; }
    .away-calendar .rbc-month-view .rbc-day-bg + .rbc-day-bg {
      border-left: 1px solid #f1f3f7; }
    .away-calendar .rbc-month-view .rbc-day-bg.rbc-today {
      background: transparent; }
    .away-calendar .rbc-month-view .rbc-day-bg:hover, .away-calendar .rbc-month-view .rbc-day-bg:active, .away-calendar .rbc-month-view .rbc-day-bg:focus {
      cursor: pointer; }
    .away-calendar .rbc-month-view .rbc-month-row + .rbc-month-row {
      border-top: 1px solid #f1f3f7; }
    .away-calendar .rbc-month-view .rbc-off-range-bg {
      background: transparent; }
    .away-calendar .rbc-month-view .rbc-selected-cell {
      background: rgba(0, 0, 0, 0.05); }
    .away-calendar .rbc-month-view .rbc-date-cell {
      padding-right: 10px;
      padding-top: 2px;
      padding-bottom: 3px; }
      .away-calendar .rbc-month-view .rbc-date-cell.rbc-now a {
        font-size: 11px;
        background: #ff5252;
        border-radius: 50%;
        width: 23px;
        height: 23px;
        display: -webkit-inline-flex;
        display: inline-flex;
        -webkit-align-items: center;
                align-items: center;
        line-height: 1;
        -webkit-justify-content: center;
                justify-content: center;
        color: #ffffff !important; }
      .away-calendar .rbc-month-view .rbc-date-cell.rbc-off-range a {
        color: #c7cdd9; }
      .away-calendar .rbc-month-view .rbc-date-cell a {
        font-size: 12px;
        font-family: "Mark Pro Medium";
        color: #2d2f44;
        font-weight: normal; }
      .away-calendar .rbc-month-view .rbc-date-cell:hover, .away-calendar .rbc-month-view .rbc-date-cell:active, .away-calendar .rbc-month-view .rbc-date-cell:focus {
        cursor: pointer; }
    .away-calendar .rbc-month-view .holiday-row {
      white-space: normal;
      position: relative;
      display: -webkit-flex;
      display: flex; }
      .away-calendar .rbc-month-view .holiday-row p {
        max-width: 100px;
        margin-left: 10px !important;
        line-height: 1 !important; }
    .away-calendar .rbc-month-view .rbc-event {
      padding-left: 0;
      padding: 0;
      width: calc(100% - 10px);
      margin-bottom: 3px;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px; }
      .away-calendar .rbc-month-view .rbc-event .publicHolidayEvent,
      .away-calendar .rbc-month-view .rbc-event .publicHolidayExtraEvent {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px; }
      .away-calendar .rbc-month-view .rbc-event.rbc-event-continues-after {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        width: 100%; }
        .away-calendar .rbc-month-view .rbc-event.rbc-event-continues-after .publicHolidayEvent,
        .away-calendar .rbc-month-view .rbc-event.rbc-event-continues-after .publicHolidayExtraEvent {
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px; }
      .away-calendar .rbc-month-view .rbc-event:hover, .away-calendar .rbc-month-view .rbc-event:active, .away-calendar .rbc-month-view .rbc-event:focus {
        outline: none; }
      .away-calendar .rbc-month-view .rbc-event.publicHolidayEvent p {
        color: #ff1744; }
      .away-calendar .rbc-month-view .rbc-event.publicHolidayExtraEvent p {
        color: rgba(255, 23, 68, 0.8); }
      .away-calendar .rbc-month-view .rbc-event.publicHolidayEvent, .away-calendar .rbc-month-view .rbc-event.publicHolidayExtraEvent {
        background-color: transparent;
        display: block;
        z-index: 9; }
        .away-calendar .rbc-month-view .rbc-event.publicHolidayEvent:hover, .away-calendar .rbc-month-view .rbc-event.publicHolidayEvent:active, .away-calendar .rbc-month-view .rbc-event.publicHolidayEvent:focus, .away-calendar .rbc-month-view .rbc-event.publicHolidayExtraEvent:hover, .away-calendar .rbc-month-view .rbc-event.publicHolidayExtraEvent:active, .away-calendar .rbc-month-view .rbc-event.publicHolidayExtraEvent:focus {
          background-color: transparent !important;
          cursor: default; }
      .away-calendar .rbc-month-view .rbc-event .row {
        margin: 0; }
      .away-calendar .rbc-month-view .rbc-event p {
        color: #2d2f44;
        margin: 0 0 0 7px;
        font-size: 10px;
        font-family: "Mark Pro Medium";
        font-weight: normal;
        line-height: 1.2;
        padding: 4px 0; }
      .away-calendar .rbc-month-view .rbc-event .user-name {
        color: #2d2f44;
        margin: 0 0 0 7px;
        font-size: 10px;
        font-family: "Mark Pro Medium";
        font-weight: normal;
        line-height: 1;
        padding: 4px 0;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 95px; }
    .away-calendar .rbc-month-view .rbc-row-segment {
      padding: 0;
      z-index: 11;
      margin-bottom: -3px; }
    .away-calendar .rbc-month-view .rbc-row-content {
      z-index: inherit; }
      .away-calendar .rbc-month-view .rbc-row-content:hover, .away-calendar .rbc-month-view .rbc-row-content:active, .away-calendar .rbc-month-view .rbc-row-content:focus {
        cursor: pointer; }
    .away-calendar .rbc-month-view .rbc-show-more {
      font-size: 10px;
      color: #b9c0cf;
      font-family: "Mark Pro";
      font-weight: normal;
      text-align: center; }
    .away-calendar .rbc-month-view .publicHoliday,
    .away-calendar .rbc-month-view .publicHolidayExtra {
      position: relative;
      z-index: 10; }
      .away-calendar .rbc-month-view .publicHoliday:after,
      .away-calendar .rbc-month-view .publicHolidayExtra:after {
        content: '';
        width: 4px;
        height: 100%;
        position: absolute;
        left: -1px;
        top: 0;
        z-index: 10; }
      .away-calendar .rbc-month-view .publicHoliday.weekEnd:hover, .away-calendar .rbc-month-view .publicHoliday.weekEnd:active, .away-calendar .rbc-month-view .publicHoliday.weekEnd:focus,
      .away-calendar .rbc-month-view .publicHolidayExtra.weekEnd:hover,
      .away-calendar .rbc-month-view .publicHolidayExtra.weekEnd:active,
      .away-calendar .rbc-month-view .publicHolidayExtra.weekEnd:focus {
        cursor: default !important; }
    .away-calendar .rbc-month-view .publicHoliday {
      background-color: rgba(255, 0, 0, 0.1); }
      .away-calendar .rbc-month-view .publicHoliday:after {
        background-color: #ff1744; }
    .away-calendar .rbc-month-view .publicHolidayExtra {
      background-color: rgba(255, 23, 68, 0.05); }
      .away-calendar .rbc-month-view .publicHolidayExtra:after {
        background-color: rgba(255, 23, 68, 0.3); }
    .away-calendar .rbc-month-view .weekEnd {
      background-color: rgba(0, 0, 0, 0.02);
      position: relative;
      z-index: 10; }
      .away-calendar .rbc-month-view .weekEnd.rbc-selected-cell {
        background: rgba(0, 0, 0, 0.05); }
      .away-calendar .rbc-month-view .weekEnd.publicHoliday {
        background-color: rgba(255, 0, 0, 0.05); }
      .away-calendar .rbc-month-view .weekEnd.publicHolidayExtra {
        background-color: rgba(255, 23, 68, 0.05); }
      .away-calendar .rbc-month-view .weekEnd:hover, .away-calendar .rbc-month-view .weekEnd:active, .away-calendar .rbc-month-view .weekEnd:focus {
        cursor: default; }
  .away-calendar .rbc-toolbar {
    padding: 15px; }

.popover.calendar-popover {
  max-width: 320px;
  min-width: 250px;
  border: 0;
  top: -10px !important;
  border-radius: 5px;
  box-shadow: 0 2px 14px 0 rgba(12, 15, 19, 0.09); }
  .popover.calendar-popover .row-margin {
    margin-bottom: 15px; }
  .popover.calendar-popover h3 {
    font-size: 18px;
    font-family: "Mark Pro Bold";
    font-weight: normal;
    margin-bottom: 15px; }
  .popover.calendar-popover label {
    color: #a5adbc;
    font-family: "Mark Pro Light";
    font-size: 10px;
    margin-bottom: 0;
    font-weight: normal; }
  .popover.calendar-popover p {
    font-family: "Mark Pro Book";
    font-size: 13px;
    color: #2d2f44;
    font-weight: normal;
    margin-bottom: 0;
    line-height: 1.2; }
  .popover.calendar-popover .popover-inner {
    border-radius: 5px;
    padding: 20px;
    background-color: #ffffff; }
  .popover.calendar-popover .popover-body {
    border: 0;
    padding: 0; }

.bs-popover-top .arrow::before,
.bs-popover-auto[x-placement^='top'] .arrow::before {
  border-top-color: transparent !important; }

.bs-popover-bottom .arrow::before,
.bs-popover-auto[x-placement^='bottom'] .arrow::before {
  border-bottom-color: transparent !important; }

.away-teamListCard a:hover {
  cursor: pointer;
  text-decoration: none; }

.away-teamListCard .team-list {
  margin-bottom: 10px;
  -webkit-align-items: center;
          align-items: center;
  padding: 20px 10px;
  margin-left: 0;
  margin-right: 0; }
  @media only screen and (max-width: 768px) {
    .away-teamListCard .team-list .team-list-avatar .away-avatar .avatar {
      margin-bottom: 20px;
      margin-left: inherit; }
    .away-teamListCard .team-list .team-list-avatar .away-avatar:hover * {
      text-decoration: none;
      cursor: pointer; } }
  .away-teamListCard .team-list.team-list-full {
    -webkit-justify-content: center;
            justify-content: center; }
  .away-teamListCard .team-list .team-list-name {
    font-size: 18px;
    font-weight: normal;
    font-family: "Mark Pro Bold";
    color: #2d2f44; }
  .away-teamListCard .team-list .team-list-text {
    font-size: 16px;
    font-weight: 400;
    color: #2d2f44; }
    @media only screen and (max-width: 768px) {
      .away-teamListCard .team-list .team-list-text {
        margin-bottom: 10px; } }
  .away-teamListCard .team-list .team-list-label {
    font-size: 12px;
    font-weight: 400;
    color: #a5adbc; }

.away-teamGridCard a:hover {
  cursor: pointer;
  text-decoration: none; }

.away-teamGridCard .team-grid {
  box-shadow: 0px 2px 20px 6px rgba(34, 51, 73, 0.05);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  .away-teamGridCard .team-grid.small-grid {
    min-height: 325px; }
  .away-teamGridCard .team-grid.large-grid {
    min-height: 368px; }
  .away-teamGridCard .team-grid .fa {
    font-size: 15px; }
  .away-teamGridCard .team-grid .team-grid-avatar {
    margin-bottom: 28px;
    -webkit-justify-content: center;
            justify-content: center; }
    .away-teamGridCard .team-grid .team-grid-avatar .away-avatar *:hover {
      cursor: pointer; }
  .away-teamGridCard .team-grid .team-grid-name {
    font-size: 18px;
    font-weight: normal;
    font-family: "Mark Pro Bold";
    color: #2d2f44;
    text-align: center; }
  .away-teamGridCard .team-grid .team-grid-text {
    font-size: 16px;
    font-weight: normal;
    font-family: "Mark Pro Book";
    color: #2d2f44;
    margin-bottom: 21px;
    text-align: center; }
  .away-teamGridCard .team-grid .team-grid-label {
    font-size: 14px;
    font-weight: 400;
    color: #99a2b3;
    margin-bottom: 24px;
    text-align: center; }
    .away-teamGridCard .team-grid .team-grid-label img {
      width: 13px;
      margin-right: 7px;
      vertical-align: inherit; }

.team-admin-row {
  margin-top: -55px;
  padding: relative; }
  .team-admin-row .btn-primary {
    margin-left: 20px; }
  @media only screen and (max-width: 768px) {
    .team-admin-row {
      margin-top: 0; } }
  .team-admin-row .filter-area {
    position: relative;
    top: -15px; }
    @media only screen and (max-width: 768px) {
      .team-admin-row .filter-area {
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: center;
                justify-content: center;
        top: 0; }
        .team-admin-row .filter-area .admin-filters {
          -webkit-align-items: center;
                  align-items: center;
          -webkit-justify-content: center;
                  justify-content: center;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: column;
                  flex-direction: column;
          margin-bottom: 0; }
          .team-admin-row .filter-area .admin-filters .away-dropdown {
            margin-right: 0;
            margin-bottom: 20px;
            max-width: 250px;
            min-width: 250px; }
        .team-admin-row .filter-area .btn-primary {
          max-width: 130px;
          min-width: 130px;
          -webkit-align-self: center;
                  align-self: center;
          margin-bottom: 10px; } }

.filter-area {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding-bottom: 15px; }
  .filter-area .away-dropdown {
    margin-right: 20px; }
    .filter-area .away-dropdown:last-of-type {
      margin-right: 0; }
  @media only screen and (max-width: 768px) {
    .filter-area {
      padding-bottom: 20px;
      -webkit-justify-content: center;
              justify-content: center; }
      .filter-area .away-dropdown {
        max-width: 130px;
        min-width: 130px; }
        .filter-area .away-dropdown:first-child {
          z-index: 1002; }
        .filter-area .away-dropdown:nth-child(2n) {
          z-index: 1001; }
        .filter-area .away-dropdown .away__single-value {
          font-size: 12px; } }
  .filter-area .filter-grid {
    display: -webkit-flex;
    display: flex;
    min-height: 36px;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin-left: 20px; }
    .filter-area .filter-grid .fa {
      margin-right: 5px;
      vertical-align: inherit;
      cursor: pointer; }
      .filter-area .filter-grid .fa:last-of-type {
        margin-right: 0; }
    .filter-area .filter-grid .fl-active {
      opacity: 1; }
    .filter-area .filter-grid .fl-notActive {
      opacity: 0.5; }

.section-title {
  margin-bottom: 16px;
  font-size: 1rem;
  font-weight: 500;
  border-bottom: 1px solid #f6f8fc;
  color: #a5adbc; }

.away-modal-body .section-title {
  margin-bottom: 24px;
  border-bottom: 0; }

.info-text .btn.btn-link {
  margin: 0;
  padding: 0;
  font-size: 16px; }

.away-avatar-editor {
  margin-bottom: 20px; }
  .away-avatar-editor label {
    width: 100%; }
  .away-avatar-editor .aae-cell {
    margin-bottom: 20px; }
  .away-avatar-editor .away-avatar-preview {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center; }
    .away-avatar-editor .away-avatar-preview .away-avatar-preview-img {
      width: 140px;
      height: 140px;
      border-radius: 50%;
      border: 1px dashed grey;
      overflow: hidden; }

.away-modal .away-modal-body {
  padding: 50px;
  padding-bottom: 10px; }
  .away-modal .away-modal-body .away-modal-line {
    margin-bottom: 30px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center; }
    .away-modal .away-modal-body .away-modal-line .form-group {
      width: 100%; }
  .away-modal .away-modal-body .away-dropdown {
    outline: none;
    width: 100%; }
    .away-modal .away-modal-body .away-dropdown:focus, .away-modal .away-modal-body .away-dropdown:hover {
      outline: none; }
  .away-modal .away-modal-body .away-modal-buttons {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }
    .away-modal .away-modal-body .away-modal-buttons .btn {
      -webkit-align-self: center;
              align-self: center;
      margin-bottom: 5px; }
      .away-modal .away-modal-body .away-modal-buttons .btn:last-of-type {
        margin-bottom: 0; }

.away-info-card .card-shadow {
  padding: 50px 58px 71px 50px; }
  @media only screen and (max-width: 768px) {
    .away-info-card .card-shadow {
      padding: 20px !important; } }

.away-info-card .fa {
  font-family: FontAwesome !important; }

.away-info-card .top-row {
  margin-bottom: 37px; }
  .away-info-card .top-row.no-margin {
    margin-bottom: 0; }
  .away-info-card .top-row h3 {
    margin-bottom: 2px; }
  .away-info-card .top-row p {
    font-size: 16px;
    font-family: "Mark Pro Book";
    margin-bottom: 5px; }
  .away-info-card .top-row span {
    font-size: 14px;
    color: #99a2b3;
    font-family: "Mark Pro Book"; }
    .away-info-card .top-row span img {
      width: 13px;
      margin-right: 7px;
      vertical-align: inherit; }
  .away-info-card .top-row .btn-link {
    padding-left: 0; }

.away-info-card .desc-row {
  margin-bottom: 66px; }
  .away-info-card .desc-row p {
    margin-bottom: 0; }

.away-info-card .row-space {
  margin-bottom: 14px; }

.away-info-card .info-label {
  font-size: 12px;
  font-weight: 400;
  color: #a5adbc;
  margin-bottom: 4px; }
  .away-info-card .info-label.info-label-big {
    margin-bottom: 15px; }

.away-info-card .info-text {
  font-size: 16px;
  font-weight: 400;
  color: #2d2f44;
  margin-bottom: 21px; }

.away-info-card .away-avatar {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 20px; }

.away-info-card .aic-divider {
  border-bottom: 1px solid #f6f8fc;
  margin-bottom: 30px;
  margin-top: 9px; }

.away-team-item-card {
  text-align: center; }
  .away-team-item-card .team-card {
    box-shadow: 0px 2px 20px 6px rgba(34, 51, 73, 0.05); }
    .away-team-item-card .team-card .edit-team-btn {
      visibility: hidden;
      opacity: 0;
      color: #ff8a5d;
      background-color: rgba(255, 102, 0, 0.3);
      font-weight: 900;
      font-size: 24px;
      position: absolute;
      right: -10px;
      top: -10px;
      box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
      -webkit-animation: jiggle 2s infinite ease-in;
              animation: jiggle 2s infinite ease-in;
      transition: all 0.5s ease 0s; }
    .away-team-item-card .team-card:hover .edit-team-btn {
      visibility: visible;
      opacity: 1; }
    .away-team-item-card .team-card .away-card-pairs {
      color: #a5adbc;
      font-weight: 400; }
      .away-team-item-card .team-card .away-card-pairs:not(:nth-child(3)) {
        border-bottom: 1px solid #e2e6ed; }
      .away-team-item-card .team-card .away-card-pairs span {
        color: #2d2f44;
        font-weight: 700; }
    .away-team-item-card .team-card .away-team-members-container {
      border-top: 2px solid #e2e6ed;
      padding-top: 10px;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap; }
      .away-team-item-card .team-card .away-team-members-container .avatar {
        margin: 5px -5px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; }

@-webkit-keyframes jiggle {
  45%,
  65% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); }
  50% {
    -webkit-transform: scale(1.1, 0.9);
            transform: scale(1.1, 0.9); }
  55% {
    -webkit-transform: scale(0.9, 1.1) translate(0, -5px);
            transform: scale(0.9, 1.1) translate(0, -5px); }
  60% {
    -webkit-transform: scale(1, 1) translate(0, -5px);
            transform: scale(1, 1) translate(0, -5px); } }

@keyframes jiggle {
  45%,
  65% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); }
  50% {
    -webkit-transform: scale(1.1, 0.9);
            transform: scale(1.1, 0.9); }
  55% {
    -webkit-transform: scale(0.9, 1.1) translate(0, -5px);
            transform: scale(0.9, 1.1) translate(0, -5px); }
  60% {
    -webkit-transform: scale(1, 1) translate(0, -5px);
            transform: scale(1, 1) translate(0, -5px); } }

.away-manage-team .create-team {
  margin-bottom: 20px;
  padding: 20px 10px 5px 10px;
  border-radius: 20px;
  transition: all 0.7 ease;
  position: relative; }
  .away-manage-team .create-team .form-group {
    margin: 16px; }
  .away-manage-team .create-team .create-team-btn {
    background-image: linear-gradient(-121deg, #ff7575 0%, #ffb230 100%); }

.away-manage-team .add-shadow {
  box-shadow: 0px 2px 20px 6px rgba(34, 51, 73, 0.05);
  background-color: #ffffff; }

.away-info-card .card-shadow {
  padding: 50px 58px 71px 50px; }

.away-info-card .top-row {
  margin-bottom: 37px; }
  .away-info-card .top-row.no-margin {
    margin-bottom: 10px; }
  .away-info-card .top-row h3 {
    margin-bottom: 2px; }
  .away-info-card .top-row p {
    font-size: 16px;
    font-family: "Mark Pro Book";
    margin-bottom: 5px; }
  .away-info-card .top-row span {
    font-size: 14px;
    color: #99a2b3;
    font-family: "Mark Pro Book"; }
    .away-info-card .top-row span img {
      width: 13px;
      margin-right: 7px;
      vertical-align: inherit; }
  .away-info-card .top-row .btn-link {
    padding-left: 0; }

.away-info-card .desc-row {
  margin-bottom: 66px; }
  .away-info-card .desc-row p {
    margin-bottom: 0; }

.away-info-card .row-space {
  margin-bottom: 14px; }

.away-info-card .info-label {
  font-size: 12px;
  font-weight: 400;
  color: #a5adbc;
  margin-bottom: 4px; }
  .away-info-card .info-label.info-label-big {
    margin-bottom: 15px; }
  .away-info-card .info-label .info-label-title {
    text-transform: capitalize; }

.away-info-card .info-text {
  font-size: 16px;
  font-weight: 400;
  color: #2d2f44;
  margin-bottom: 21px; }

.away-info-card .away-avatar {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 20px; }

.away-info-card .aic-divider {
  border-bottom: 1px solid #f6f8fc;
  margin-bottom: 30px;
  margin-top: 30px; }

.away-info-card .aic-subtitle {
  margin-bottom: 30px; }

.away-info-card .log-list {
  -webkit-align-self: flex-end;
          align-self: flex-end;
  padding-right: 105px;
  margin-top: 5px;
  margin-bottom: 15px; }

.away-info-card .stats-link {
  margin-top: -7px; }

.away-modal .away-modal-body {
  padding: 50px;
  padding-bottom: 10px; }
  .away-modal .away-modal-body .away-modal-columns {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-bottom: 30px; }
    .away-modal .away-modal-body .away-modal-columns .away-modal-column {
      width: 48%; }
    @media (max-width: 768px) {
      .away-modal .away-modal-body .away-modal-columns {
        -webkit-flex-direction: column;
                flex-direction: column; }
        .away-modal .away-modal-body .away-modal-columns .away-modal-column {
          width: 100%; } }
  .away-modal .away-modal-body .away-modal-line {
    margin-bottom: 30px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center; }
    .away-modal .away-modal-body .away-modal-line .form-group {
      width: 100%; }
    .away-modal .away-modal-body .away-modal-line h3 {
      text-align: center; }
  .away-modal .away-modal-body .away-dropdown {
    outline: none;
    width: 100%; }
    .away-modal .away-modal-body .away-dropdown:focus, .away-modal .away-modal-body .away-dropdown:hover {
      outline: none; }
  .away-modal .away-modal-body .away-modal-buttons {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }
    .away-modal .away-modal-body .away-modal-buttons .btn {
      -webkit-align-self: center;
              align-self: center;
      margin-bottom: 5px; }
      .away-modal .away-modal-body .away-modal-buttons .btn:last-of-type {
        margin-bottom: 0; }

.large {
  max-width: 750px; }

.reports-row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center; }
  @media only screen and (max-width: 768px) {
    .reports-row {
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: center;
              align-items: center; } }
  .reports-row .report-card {
    min-width: 300px;
    max-width: 300px;
    margin-right: 40px; }
    .reports-row .report-card:last-of-type {
      margin-right: 0; }
    @media only screen and (max-width: 768px) {
      .reports-row .report-card {
        margin-right: 0; } }
    .reports-row .report-card .card-shadow {
      min-height: 360px;
      padding: 40px 25px;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: space-between;
              justify-content: space-between; }
      .reports-row .report-card .card-shadow .report-title {
        text-align: center;
        margin-bottom: 20px; }
      .reports-row .report-card .card-shadow .report-icon {
        margin-bottom: 40px; }
        .reports-row .report-card .card-shadow .report-icon img {
          max-height: 100px; }

