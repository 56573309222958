@import '../../../styles/variables.scss';

.away-daterange-line {
  width: 100%;

  .away-daterange-inputs {
    display: flex;
    justify-content: center;

    .form-group {
      margin-bottom: 0;
    }
  }
}
