@import '../../styles/variables.scss';

.away-modal {
  .away-modal-body {
    padding: 50px;
    padding-bottom: 10px;

    .away-modal-columns {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 30px;

      .away-modal-column {
        width: 48%;
      }

      @media (max-width: 768px) {
        flex-direction: column;

        .away-modal-column {
          width: 100%;
        }
      }
    }

    .away-modal-line {
      margin-bottom: 30px;
      display: flex;
      flex-direction: row;
      justify-content: center;

      .form-group {
        width: 100%;
      }

      h3 {
        text-align: center;
      }
    }

    .away-dropdown {
      outline: none;
      width: 100%;

      &:focus,
      &:hover {
        outline: none;
      }
    }

    .away-modal-buttons {
      display: flex;
      flex-direction: column;

      .btn {
        align-self: center;
        margin-bottom: 5px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}

.large {
  max-width: 750px;
}
