.drag-handle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  cursor: grab;
  padding: 4px;
  z-index: 999;

  &:active {
    cursor: grabbing;
  }

  &__dots {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2px;
    width: 100%;
    height: 100%;
  }

  &__dot {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #9ca3af;
    transition: background-color 0.2s ease;
    box-shadow: 0 0 0 1px #f9fafb;
  }

  &:hover &__dot {
    background-color: #6b7280;
  }
}
