@import '../../../styles/variables.scss';

.away-colorpicker-line {
  width: 100%;

  .away-colorpicker-inputs {
    display: flex;
    justify-content: center;

    .form-group {
      margin-bottom: 0;
    }
  }
}

.color-input {
  position: relative;

  .color-badge {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 10px;
    position: absolute;
    left: 0;
    transform: translateY(50%);
    top: 0;
    margin-top: -2px;
  }

  .has-color {
    padding-left: 30px !important;
  }
}
