@import '../../../../styles/variables.scss';

.away-team-item-card {
  text-align: center;
  .team-card {
    box-shadow: 0px 2px 20px 6px rgba(34, 51, 73, 0.05);
    .edit-team-btn {
      visibility: hidden;
      opacity: 0;
      color: $orange-2;
      background-color: rgba($orange, $alpha: 0.3);
      font-weight: 900;
      font-size: 24px;
      position: absolute;
      right: -10px;
      top: -10px;
      box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
      animation: jiggle 2s infinite ease-in;
      transition: all 0.5s ease 0s;
    }
    &:hover .edit-team-btn {
      visibility: visible;
      opacity: 1;
    }
    .away-card-pairs {
      color: $grey;
      font-weight: 400;
      &:not(:nth-child(3)) {
        border-bottom: 1px solid $grey-4;
      }
      span {
        color: $text-color;
        font-weight: 700;
      }
    }
    .away-team-members-container {
      border-top: 2px solid $grey-4;
      padding-top: 10px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      .avatar {
        margin: 5px -5px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      }
    }
  }
}
@keyframes jiggle {
  45%,
  65% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 0.9);
  }
  55% {
    transform: scale(0.9, 1.1) translate(0, -5px);
  }
  60% {
    transform: scale(1, 1) translate(0, -5px);
  }
}
