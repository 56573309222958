@import '../../../styles/variables.scss';
@import '~bootstrap/scss/_mixins.scss';
.away-navbar {
  .navbar {
    background-color: $white;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.05);
    padding: 0;
    margin-bottom: 30px;
    @media only screen and (max-width: 768px) {
      margin-bottom: 15px;
    }
    &.navbar-expand-lg > .container {
      padding-left: 10px;
      padding-right: 10px;
    }
    .hamburger {
      display: none;
      @media only screen and (max-width: 991px) {
        display: flex;
      }
    }
    .logo {
      padding-top: 18px;
      padding-bottom: 18px;
      margin-right: 0;
      img {
        width: 120px;
      }
    }
    .navbar-collapse {
      justify-content: space-between;
    }
    .navbar-nav {
      align-items: center;
      .nav-item {
        margin-left: 10px;
        margin-right: 10px;
        &.active {
          .nav-link {
            border-bottom: 4px solid $orange;
            color: $text-color;
          }
        }
        &:hover {
          color: $text-color;
        }
      }
      .nav-link {
        font-size: 16px;
        font-family: $font-bold;
        color: $grey;
        padding-top: 28px;
        padding-bottom: 25px;
        border-bottom: 4px solid transparent;
        transition: all 0.1s ease-in-out;
        @media only screen and (max-width: 768px) {
          padding-top: 10px;
          padding-bottom: 10px;
          border-bottom: 0;
        }
        &.active {
          border-bottom: 4px solid $orange;
          color: $text-color;
          @media only screen and (max-width: 768px) {
            border-bottom: 0;
          }
        }
      }

      .profile-img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $green;
        span {
          font-size: 12px;
          color: $white;
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      .badge {
        font-size: 12px;
        &.badge-secondary {
          background-color: $orange;
        }
      }
    }

    .away-nav-dropdown {
      @include media-breakpoint-down(md) {
        margin-bottom: 30px;
        button {
          margin-left: auto;
          margin-right: auto;
        }
        .dropdown-menu {
          .dropdown-item {
            a {
              text-align: center;
            }
          }
        }
      }

      .dropdown-toggle {
        background-color: transparent;
        display: flex;
        flex-direction: row;
        align-items: center;
        outline: none;
        border: none;

        span {
          margin-left: 15px;
          color: $text-color;
          font-size: 16px;
          font-weight: bold;
        }

        &:focus,
        &:hover,
        &:active {
          outline: none;
          border: none;
          box-shadow: none;
          background-color: transparent;
        }

        &.btn-secondary {
          color: $grey-5;
        }

        &::after {
          margin-left: 8px;
          margin-top: 4px;
        }
      }

      .dropdown-menu {
        padding: 0;
        width: 100%;
        border-radius: 0;
        border: none;
        margin-top: 12px;
        box-shadow: 0 2px 2px 0 rgba(34, 51, 73, 0.05);
        z-index: 1049;
        min-width: 200px;
        .dropdown-item {
          outline: none;
          padding: 0;

          &:focus,
          &:hover {
            background-color: $white;
          }

          button,
          a {
            width: 100%;
            text-align: left;
            padding: 15px 25px;
            border-bottom: 1px solid $grey-3;
            @include media-breakpoint-down(md) {
              text-align: center;
            }
          }

          * {
            color: $grey;
            font-size: 16px;
            line-height: 18px;

            &:focus,
            &:hover {
              text-decoration: none;
              color: $text-color;
              background-color: $white;
              cursor: pointer;
            }
          }

          button {
            box-shadow: none;
            outline: none;
            border: none;
            border-radius: 0;

            &:focus,
            &:hover {
              text-decoration: none;
              color: $text-color;
              background-color: $white;
              box-shadow: none;
              outline: none;
              border: none;
              border-bottom: 1px solid $grey-3;
            }
          }

          a,
          button {
            border-bottom: 1px solid $grey-3;

            &.active {
              color: $text-color;
              border-color: $orange;
            }
          }
        }
      }

      @media only screen and (max-width: 768px) {
        .dropdown {
          &.show {
            .dropdown-menu {
              position: relative !important;
              transform: none !important;
              margin-top: 0;
              box-shadow: none;
              border-top: 1px solid #f6f8fc;

              .dropdown-item {
                &:last-of-type {
                  button {
                    border: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
